import {
    CLEAR_GRAPH_STARTED,
    CLEAR_GRAPH_SUCCESS,
    CLEAR_SAVE_ERROR,
    DIRTY_STORAGE_LOADED,
    INITALISE_GRAPH,
    LOAD_NODES_FAILURE,
    LOAD_NODES_START,
    NODE_PROPERTY_BLUR,
    NODE_PROPERTY_FOCUS,
    NODE_RECOMPUTE_CHECK,
    NODES_LOADED_SERVER,
    NODES_LOADED_STORAGE,
    NODES_LOADED_STORAGE_FAILED,
    NODES_SAVE_ERROR_LOCAL_DB,
    NODES_SAVE_SUCCESS_LOCAL_DB,
    PUT_NODE,
    PUT_NODE_PROPERTY,
    PUT_NODES,
    RECOMPUTE_ALL,
    REPORT_EVENT,
    RESET_GRAPH,
    SAVE_NODE_FAILURE,
    SAVE_NODE_SUCCESS,
    SAVE_NODES_FINISH,
    SAVE_NODES_START
} from "../actions/types";
import {
    deleteDbNode,
    getPreviousVersionNumber,
    isIndexedDbAvailable,
    patchDbNodesIfPresent,
    storeDbNodes
} from "../util/offline";
import get from 'lodash/get';
import {
    cypress,
    getTime,
    reportBusinessError,
    reportDebug,
    reportDeveloperInfo,
    reportDeveloperWarning,
    reportError,
    reportEvent,
    SharedAuth,
    tbfLocalStorage,
    validateEmail
} from "tbf-react-library";
import {
    getActiveChildrenDescendantsAndSelf,
    getActiveChildrenOrError,
    getActiveChildrenSafe,
    getActiveDescendants,
    getActiveDescendantsAndSelfIfPresent,
    getActivesNodesSafe,
    getChildrenSafe,
    getDeepClonedNodeOrError,
    getDescendantsAndSelf,
    getDescendantsAndSelfIfPresent,
    getDirty,
    getDirtyNodes,
    getFirstTaskOrNull,
    getNodeByProperty,
    getNodeOrError,
    getNodeOrNull,
    getNodeSchemaOrError,
    getNodesIfPresent,
    getNodesOrError,
    getNodesSafe,
    getReportData,
    getRootNodeOrError,
    getShallowClonedNodeOrError,
    getVisibleNodesSafe,
    isRootNodeDirty,
    shallowClone
} from "../selectors/graphSelectors";
import {
    arrayMinus,
    arrayMinusItem,
    createImageObjectUrl,
    dateToJson,
    defaultValue,
    doesIntersect,
    firstOrNull,
    getIsDocumentHidden,
    getJsonDate,
    getMaxJsonDate,
    hasValue,
    isDateGreaterThan,
    isDateGreaterThanOrEqual,
    isDraftJsString,
    isNullOrUndefined,
    isRecent,
    makeArray,
    mergeSet,
    mergeUnique,
    nop,
    objectIdNameToKeyValue,
    stripAfter,
    stripNulls,
    toDraftJs,
    tryParseValue,
    upgradePhoneNumber
} from "../util/util";
import {checkLocationCoordinatesAreInBounds, checkLocationFeatureInvalid} from "../util/location";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import {createChildNode, createNode} from "../factory/graphFactory";
import {
    addDependency,
    combineCompileWarnings,
    convertExecutionQuestionTime,
    createExecutions,
    evaluateLinkAddOptions,
    evaluateLinkRule,
    evaluateVisibleRule,
    extractReferencedNodeDep,
    formatValue,
    getDependentTemplateIds,
    getSummaryId,
    isDeviceLocationKnown,
    isNodeSaved,
    isSaveRunningOnExecution,
    isSummaryId,
    linkExecutions,
    nullOrTrue,
    parseOptions,
    rewriteRules,
    synchronousExecution,
    textTemplateFromHuman,
    textTemplateToFormatted,
    textTemplateToHuman,
    uniqueChildren,
    validateVisibleRule
} from "../factory/executionFactory";
import {checkHasValue} from "../util/common/precondition";
import moment from "moment";
import jsonDiff from "json-diff";
import keyBy from "lodash/keyBy";
import without from "lodash/without";
import {strings} from "../layouts/components/SopLocalizedStrings";
import {
    attachmentsUploaded,
    computeCompleteAccess,
    getActiveExecutionQuestions,
    getActiveSteps,
    getExecutionFullOrNull,
    getExecutionFullSummaryByIdIfPresent,
    getExecutionFullSummaryOrNull,
    getExecutionLinkNewFrom,
    getExecutionSummaryFullByIdIfPresent,
    getNavigationStyle,
} from "../selectors/executionSelectors";
import {
    getGlobalNavStyle,
    getGrantRulesForProcedure,
    getLinkToQuestionRules,
    getParentRuleOrNull,
    getRoleHasProcedurePermission,
    getRootRuleOrNull,
    hasProcedurePermission,
    hasProcedurePermissionLoaded
} from "../selectors/procedureSelectors";
import {
    computeDefaultColumns,
    computeDynamicColumns,
    computeHasLocation,
    computeLinkTree,
    computeListingPageFilter,
    computeListingPageOrderBy,
    computeNodeNumber,
    computePivotSettings,
    computePivotTableColumns,
    computeQuestionColumns,
    computeTableColumns,
    computeTableViews,
    postProcessColumns,
    processJsonLogicFormula,
    processSelectorLoaders,
    reviseNodeNumber
} from "../factory/procedureFactory";
import {getProcedureColumnOptions} from "../hooks/procedureHooks";
import first from "lodash/first";
import {
    computeExecutionSelectOptions,
    computeFilter,
    convertJsonLogicForQuery,
    evaluateRule,
    extractJsonLogicProperties,
    extractJsonLogicReturnFields,
    extractJsonLogicVars,
    SELECTOR_FILTER_TYPE,
    validateConditionQueryRule
} from "../factory/sopJsonLogic";
import {
    getActiveChildRuleByActionOrNull,
    getActiveChildRulesForNodeByActionOrNull,
    getActiveMentionedRules,
    getActiveRulesByActionForNode,
    getActiveRulesForNode,
    getChildRuleByActionOrNull,
    getChildRulesByAction,
    getEnabledChildRulesForNodeByActionOrNull,
    getRulesForNodeByActionIfPresent,
    isRuleOn
} from "../selectors/ruleSelectors";
import {computeRuleIds, syncRuleIds} from "../factory/ruleFactory";
import {DAYS_7, HOURS_24} from "../util/constants";
import {parseFormat, validateFormat} from "tbf-jsonlogic";
import {MAP_SHAPE_TYPE_OPTIONS} from "../layouts/MapView/constants";

import {Permissions, SECURITY_SCOPES} from "../permissions";
import {isValidPhoneNumber} from "react-phone-number-input";
import {shallowEqual} from "react-redux";
import {calculateLoadedFull, domainRuleNOP, nodeFactory, SYSTEM_PROPERTIES} from "./graph/common";
import {CLIENT_CONFIG_DOMAIN_RULES} from "./graph/clientConfigRules";
import {USER_DOMAIN_RULES} from "./graph/userRules";
import {CLIENT_CONFIG_SCHEMA} from "./graph/clientConfigSchema";

const typeFilter = (type) => {
    return {type: type};
};
export const NODE_TYPE_OPTIONS = {
    ProcedureRoot: "ProcedureRoot",
    ProcedureStep: "ProcedureStep",
    ProcedureTask: "ProcedureTask",
    ProcedureQuestion: "ProcedureQuestion",
    ProcedureRule: 'ProcedureRule',
    ExecutionRoot: "ExecutionRoot",
    ExecutionStep: "ExecutionStep",
    ExecutionTask: "ExecutionTask",
    ExecutionLink: "ExecutionLink",
    ExecutionRule: "ExecutionRule",
    ExecutionQuestion: "ExecutionQuestion",
    ExecutionLinkNew: 'ExecutionLinkNew',
    ResourceSync: 'ResourceSync',
    NodeView: 'NodeView',
    NodeAssignment: 'NodeAssignment',
    NodeActivity: 'NodeActivity',
    ProjectRoot: 'ProjectRoot',
    ExecutionPreview: 'ExecutionPreview',
    ExecutionSelector: 'ExecutionSelector',
    ExecutionListPage: 'ExecutionListPage',
    MyAssignmentPage: 'MyAssignmentPage',
    MapView: 'MapView',
    GroupRoot: 'GroupRoot',
    UserDevice: 'UserDevice',
    Photo: 'Photo',
    PhotoCapture: 'PhotoCapture',
    ProcedureConfig: 'ProcedureConfig',
    ClientConfig: 'ClientConfig',
    ExecutionLinkTree: 'ExecutionLinkTree',
}

const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp', 'tiff', 'tif', 'ico'];
export const KEY_FIELD_COUNT = 5;
export const FORMATS = {
    null: "",
    "ccw": {id: "ccw", postfix: "CCW", name: "CCW"},
    "cw": {id: "cw", postfix: "CW", name: "CW"},
    "degrees": {id: "degrees", postfix: "degrees", name: "degrees"},
    "kn": {id: "kn", postfix: "kN", name: "kN"},
    // Volts
    "v": {id: "v", postfix: "V", name: "V"},
    "kv": {id: "kv", postfix: "kV", name: "kV"},
    // Ampere
    "ampere": {id: "ampere", postfix: "ampere", name: "ampere"},
    "kva": {id: "kva", postfix: "kVA", name: "kVA"},
    "mva": {id: "mva", postfix: "MVA", name: "MVA"},
    // Distance
    "mm": {id: "mm", postfix: "mm", name: "mm"},
    "cm": {id: "cm", postfix: "cm", name: "cm"},
    "m": {id: "m", postfix: "m", name: "m"},
    "km": {id: "km", postfix: "km", name: "km"},
    // Area
    "mm2": {id: "mm2", postfix: "mm²", name: "mm²"},
    "m2": {id: "m2", postfix: "m²", name: "m²"},
    "km2": {id: "km2", postfix: "km²", name: "km²"},
    // Ohms
    "ohms": {id: "ohms", postfix: "Ω", name: "Ω"},
    "microohms": {id: "microohms", postfix: "µΩ", name: "µΩ"},
    "milliohms": {id: "milliohms", postfix: "mΩ", name: "mΩ"},
    "kilohms": {id: "kilohms", postfix: "KΩ", name: "KΩ"},
    "megohms": {id: "megohms", postfix: "MΩ", name: "MΩ"},
    "gigaohms": {id: "gigaohms", postfix: "GΩ", name: "GΩ"},
    // Ohn Meteres
    "ohmsmeters": {id: "ohmsmeters", postfix: "Ωm", name: "Ωm"},
    // Temperature
    "dc": {id: "dc", postfix: "°C", name: "°C"},
    // Will use $ as the default currency, but need to deal with all the different symbols somehow
    "dollar2": {id: "dollar2", prefix: "$", name: "$"},
    // Watt Hours
    "wh": {id: "wh", postfix: "Wh", name: "Wh"},
    "kwh": {id: "kwh", postfix: "kWh", name: "kWh"},
    "mwh": {id: "mwh", postfix: "MWh", name: "MWh"},
    "gwh": {id: "gwh", postfix: "GWh", name: "GWh"},
    "twh": {id: "twh", postfix: "TWh", name: "TWh"},
    // Custom
    "custom": {id: "custom", name: "Custom"},
};
export const FORMAT_OPTIONS = objectIdNameToKeyValue(FORMATS);

export const QUESTION_TYPES = {
    "text": {id: "text", name: "Text"},
    "number": {id: "number", name: "Number"},
    "select": {id: "select", name: "Select"},
    "message": {id: "message", name: "Message"},
    "yesno": {id: "yesno", name: "YesNo"},
    "email": {id: "email", name: "Email"},
    "photo": {id: "photo", name: "Photo"},
    "phoneNumber": {id: "phoneNumber", name: "Phone Number"},
    "link": {id: "link", name: "Query"},
    "date": {id: "date", name: "Date"},
    "datetime": {id: "datetime", name: "Date Time"},
    "time": {id: "time", name: "Time"},
    "richText": {id: "richText", name: "Rich Text"},
    "geographic": {id: "geographic", name: "Geographic"},
    "signature": {id: "signature", name: "Signature"},
    "json": {id: "json", name: "Json"},
};

export const QUESTION_TYPES_SCHEMA = {
    [QUESTION_TYPES.number.id]: "number",
    [QUESTION_TYPES.yesno.id]: "boolean"
}

export const QUESTION_TYPES_FORMAT = {
    [QUESTION_TYPES.datetime.id]: "date-time",
    [QUESTION_TYPES.date.id]: "date",
    [QUESTION_TYPES.time.id]: "time",
    [QUESTION_TYPES.email.id]: "email"
}


export const QUESTION_TYPE_OPTIONS = objectIdNameToKeyValue(QUESTION_TYPES);
export const CONDITIONAL_VALUES = {
    optional: {id:'optional', name: 'Optional'},
    none: {id:'none', name: 'None'},
    required: {id:'required', name: 'Required'},
}
export const CONDITIONAL_VALUES_OPTIONS = objectIdNameToKeyValue(CONDITIONAL_VALUES);
export const VISIBLE_MODES = {
    "visible": {id: "visible", name: "Visible"},
    "hidden": {id: "hidden", name: "Hidden"},
    "rule": {id: "rule", name: "Rule"}
};
export const VISIBLE_MODE_OPTIONS = objectIdNameToKeyValue(VISIBLE_MODES);
export const UNKNOWN_TYPES = {
    "allowed": {id: "allowed", name: "Allowed"},
    "notallowed": {id: "notallowed", name: "Not Allowed"}
};
const UNKNOWN_VALUES = objectIdNameToKeyValue(UNKNOWN_TYPES);
const YES_NO_OPTIONS_STRING = "true=Yes\nfalse=No";
export const ISSUE_RESOLUTION_TYPES = {
    "resolved": {id: "resolved", name: "Resolved"},
    "notanissue": {id: "notanissue", name: "Not An Issue"}
};
const ISSUE_RESOLUTION_TYPE_OPTIONS = objectIdNameToKeyValue(ISSUE_RESOLUTION_TYPES);
export const SELECT_RENDER_MODES = {
    "radio": {
        id: "radio", name: "Radio", disabled: false,
        guidance: "Suitable for options that are long phrases",
        applicability: "Supports upto 8 options",
        order: 1
    },
    "checkbox": {
        id: "checkbox", name: "Checkbox", disabled: false,
        guidance: "Suitable for options that are long phrases",
        applicability: "Supports upto 8 options",
        order: 2
    },
    "chip": {
        id: "chip", name: "Chip", disabled: false,
        guidance: "Suitable for options that are short. For e.g. Monday, Tuesday, Wednesday etc.",
        applicability: "Supports upto 8 options",
        order: 3
    },
    "autocomplete": {
        id: "autocomplete", name: "Dropdown", disabled: false,
        guidance: "Suitable for all types of options",
        applicability: "Default for over 8 options",
        order: 4
    },
};
const SELECT_RENDER_MODE_OPTIONS = objectIdNameToKeyValue(SELECT_RENDER_MODES);
export const SELECT_DATA_SOURCES = {
    "executionDynamic": {id: "executionDynamic", name: "Worq item search"},
    "static": {id: "static", name: "Static list"},
};
const SELECT_DATA_SOURCES_OPTIONS = objectIdNameToKeyValue(SELECT_DATA_SOURCES);
export const MESSAGE_TYPES = {
    "info": {id: "info", name: "Information"},
    "plain": {id: "plain", name: "Plain"},
    "warning": {id: "warning", name: "Warning"},
    "error": {id: "error", name: "Error"},
    "success": {id: "success", name: "Success"},
    "hint": {id: "hint", name: "Hint"},
};
const MESSAGE_TYPE_OPTIONS = objectIdNameToKeyValue(MESSAGE_TYPES);
export const ISSUE_TYPES = {
    "warning": {id: "warning", name: "Warning"}
};
const ISSUE_TYPE_OPTIONS = objectIdNameToKeyValue(ISSUE_TYPES);
export const FIX_MODES = {
    "none": {"id": "none", name: "None"},
    "comment": {"id": "comment", name: "Comment"},
    "photo": {"id": "photo", name: "Attachment"},
    "commentphoto": {"id": "commentphoto", name: "Comment + Attachment"},
    "resolve": {"id": "resolve", name: "Resolve"},
    "resolvecomment": {"id": "resolvecomment", name: "Resolve + Comment"},
    "resolvephoto": {"id": "resolvephoto", name: "Resolve + Attachment"},
    "resolvecommentphoto": {"id": "resolvecommentphoto", name: "Resolve + Comment + Attachment"}
};
export const FIX_MODES_OPTIONS = objectIdNameToKeyValue(FIX_MODES);
export const ASSIGNMENT_ENTITY_TYPES = {
    "group": {"id": "group", name: "Group"},
    "user": {"id": "user", name: "User"},
}
export const ASSIGNMENT_ENTITY_TYPE_OPTIONS = objectIdNameToKeyValue(ASSIGNMENT_ENTITY_TYPES);
export const COMPLETE_MODES = {
    step: {id: 'step', name: 'Step'},
    task: {id: 'task', name: 'Task'},
    none: {id: 'none', name: 'None'},
};
export const DEFAULT_LAYOUT_COLUMNS_WIDTH = 1;
export const LAYOUT_COLUMN_WIDTHS = {
    '1': {id: '1', name: '1'},
    '2': {id: '2', name: '2'},
    '3': {id: '3', name: '3'},
    '4': {id: '4', name: '4'},
}
export const LAYOUT_COLUMN_WIDTHS_OPTIONS = objectIdNameToKeyValue(LAYOUT_COLUMN_WIDTHS);

const COMPLETE_MODES_OPTIONS = objectIdNameToKeyValue(COMPLETE_MODES);
export const STATISTICS_MODES = {
    include: {id: 'include', name: 'Include'},
    exclude: {id: 'exclude', name: 'Exclude'}
};
const STATISTICS_MODES_OPTIONS = objectIdNameToKeyValue(STATISTICS_MODES);

export const PROCEDURE_LINK_STYLE = {
    table: {id: 'table', name: 'Table'},
    legacyProject: {id: 'legacyProject', name: 'Legacy Project'},
    report: {id: 'report', name: 'Report'},
    repeatingSections: {id: 'repeatingSections', name: 'Repeating Sections'},
    //list: {id: 'list', name: 'List'},
    //field: {id: 'field', name: 'Field'}
};
export const PROCEDURE_LINK_STYLE_OPTIONS = objectIdNameToKeyValue(PROCEDURE_LINK_STYLE);

export const DIAGNOSTIC_MODES = {
    full: {id: 'full', name: 'Full'},
    none: {id: 'none', name: 'None'},
};
export const LINK_TYPES_SELECTABLE = {
    auditOf: {id: 'auditOf', name: 'Audit Of', reverseId: 'auditedBy'},
    auditedBy: {id: 'auditedBy', name: 'Audited By', reverseId: 'auditOf'},
    related: {id: 'related', name: 'Related', reverseId: 'related'},
    child: {id: 'child', name: 'Child', reverseId: 'parent'},
    parent: {id: 'parent', name: 'Parent', reverseId: 'child'},
    before: {id: 'before', name: 'Previous', reverseId: 'after'},
    after: {id: 'after', name: 'Next', reverseId: 'before'},
    contains: {id: 'contains', name: 'Contains', reverseId: 'containedBy'},
    containedBy: {id: 'containedBy', name: 'Contained By', reverseId: 'contains'},
};
export const LINK_TYPES = {
    ...LINK_TYPES_SELECTABLE,
    none: {id: 'none', name: 'None', reverseId: 'none'},
};
export const LINK_TYPE_OPTIONS = objectIdNameToKeyValue(LINK_TYPES);
export const LINK_TYPE_SELECTABLE_OPTIONS = objectIdNameToKeyValue(LINK_TYPES_SELECTABLE);
export const LINK_SOURCES = {
    rule: {id: 'rule', name: 'Rule', reverseId: 'rule'},
    user: {id: 'user', name: 'User', reverseId: 'user'},
};
export const LINK_SOURCES_OPTIONS = objectIdNameToKeyValue(LINK_TYPES);
export const JSON_LOGIC_EDITORS = {
    logic: {id: 'logic', name: 'Logic builder'},
    formula: {id: 'formula', name: 'Formula'},
}
export const JSON_LOGIC_EDITOR_OPTIONS = objectIdNameToKeyValue(JSON_LOGIC_EDITORS);
export const ASSIGNMENT_TYPES = {
    task: {id: 'task', name: 'Task'}
};
export const PROCEDURE_TYPES = {
    itp: {id: 'itp', name: 'ITP', keyPrefix: 'ITP-'},
    project: {id: 'project', name: 'Project', keyPrefix: 'PRJ-'},
    audit: {id: 'audit', name: 'Audit', keyPrefix: 'DASH-'},
    swms: {id: 'swms', name: 'SWMS', keyPrefix: 'SWMS-'},
    subject: {id: 'subject', name: 'Subject', keyPrefix: 'SBJ-'},
    form: {id: 'form', name: 'Form', keyPrefix: 'FORM-'},
    dashboard: {id: 'dashboard', name: 'Dashboard', keyPrefix: 'DASH-'},
    issue: {id: 'issue', name: 'Issue', keyPrefix: 'ISSUE-'},
    workspace: {id: 'workspace', name: 'Workspace', keyPrefix: 'WORQ-'},
};
export const PROCEDURE_TYPE_OPTIONS = objectIdNameToKeyValue(PROCEDURE_TYPES);

export const PROCEDURE_TYPE_COLORS = {
    [PROCEDURE_TYPES.itp.id]: "#e6ee9c",
    [PROCEDURE_TYPES.audit.id]: "#b3e5fc",
    [PROCEDURE_TYPES.swms.id]: "#b2dfdb",
    [PROCEDURE_TYPES.issue.id]: "#ff9eab",
    [PROCEDURE_TYPES.workspace.id]: "#dddbff",
    [PROCEDURE_TYPES.dashboard.id]: "#f5ca8a",
    [PROCEDURE_TYPES.form.id]: "#a1f2ae",
    [PROCEDURE_TYPES.project.id]: "#b3e5fc",
    [PROCEDURE_TYPES.subject.id]: "#d2bff5",
}
export const ATTACHMENT_TYPES = {
    photo: {id: 'photo', name: 'Photo'},
    file: {id: 'file', name: 'File'}
};

export const ATTACHMENT_TYPE_OPTIONS = objectIdNameToKeyValue(ATTACHMENT_TYPES);
export const RULE_APPLY_MODE_MODE = {
    procedure: {id: 'procedure', name: 'Rule is never copied onto execution.'},
    execution: {id: 'execution', name: 'Rule is always copied onto execution.'},
    both: {id: 'both', name: 'Rule is sometimes copied, sometimes not.'}
}
export const RULE_ACTION_TYPE_PASTE_MODE = {
    cloneAlways: {id: 'cloneAlways', name: 'Clone Always'},
    reuseAlways: {id: 'reuseAlways', name: 'Reuse Always'},
    skip: {id: 'skip', name: 'Skip'},
    smartClone: {id: 'smartClone', name: 'Smart Clone'}
}
export const RULE_FORMAT_OPTION = {
    name: {id: 'name', name: 'Name'},
    addButton: {id: 'addButton', name: 'Add Button'},
}
export const RULE_FORMAT_OPTIONS = objectIdNameToKeyValue(RULE_FORMAT_OPTION);
export const RULE_DATA_STRUCTURE_TYPE = {
    on:{ id:"on" },
    action:{ id: "action" }
}
export const EXECUTION_SOURCE_TYPES = {
    autoCreate: {id: 'autoCreate', name: 'From Auto-create'},
    queryAdd: {id: 'queryAdd', name: 'From Query'},
    add: {id: 'add', name: 'From Add'},
    actionAdd: {id: 'actionAdd', name: 'From Action'},
    workFlow: {id: 'workFlow', name: 'From Workflow'},
};
export const EXECUTION_SOURCE_OPTIONS = objectIdNameToKeyValue(EXECUTION_SOURCE_TYPES);
export const RULE_ACTION_TYPE = {
    block: {
        id: 'block',
        name: 'Block',
        applyToDescendantQuestions: false,
        procedureOnlyMode: RULE_APPLY_MODE_MODE.both.id,
        dataStructureType: RULE_DATA_STRUCTURE_TYPE.action.id
    },
    uniqueConstraint: {
        id: 'uniqueConstraint',
        name: 'Unique Constraint',
        applyToDescendantQuestions: false,
        procedureOnlyMode: RULE_APPLY_MODE_MODE.execution.id,
        dataStructureType: RULE_DATA_STRUCTURE_TYPE.action.id
    },
    assignment: {
        id: 'assignment',
        name: 'Assign to user',
        applyToDescendantQuestions: false,
        procedureOnlyMode: RULE_APPLY_MODE_MODE.execution.id,
        dataStructureType: RULE_DATA_STRUCTURE_TYPE.action.id,
        calculateValueOn: true
    },
    commentHideAction: {
        id: 'commentHideAction',
        name: 'Remove comment open/close button',
        recomputeNodeIdsAfter: true,
        procedureOnlyMode: RULE_APPLY_MODE_MODE.execution.id,
        applyToDescendants: true,
        applyToTypes: [NODE_TYPE_OPTIONS.ExecutionQuestion],
        dataStructureType: RULE_DATA_STRUCTURE_TYPE.action.id
    },
    textSearch: {
        id: 'textSearch',
        name: 'Include in text search',
        applyToDescendants: true,
        applyToTypes: [NODE_TYPE_OPTIONS.ExecutionQuestion],
        procedureOnlyMode: RULE_APPLY_MODE_MODE.both.id,
        dataStructureType: RULE_DATA_STRUCTURE_TYPE.action.id
    },
    fieldSearch: {
        id: 'fieldSearch',
        name: 'Available in column filtering',
        applyToDescendants: true,
        applyToTypes: [NODE_TYPE_OPTIONS.ExecutionQuestion],
        procedureOnlyMode: RULE_APPLY_MODE_MODE.both.id,
        dataStructureType: RULE_DATA_STRUCTURE_TYPE.action.id
    },
    filter: {
        id: 'filter',
        name: 'Filter',
        procedureOnlyMode: RULE_APPLY_MODE_MODE.both.id,
        dataStructureType: RULE_DATA_STRUCTURE_TYPE.action.id
    },
    collectionView: {
        id: 'collection',
        name: 'Collection',
        procedureOnlyMode: RULE_APPLY_MODE_MODE.both.id,
        dataStructureType: RULE_DATA_STRUCTURE_TYPE.action.id,
        pasteMode: RULE_ACTION_TYPE_PASTE_MODE.cloneAlways.id
    },
    collectionColumn: {
        id: 'collectionColumn',
        name: 'Column',
        procedureOnlyMode: RULE_APPLY_MODE_MODE.both.id,
        dataStructureType: RULE_DATA_STRUCTURE_TYPE.action.id
    },
    collectionColumnSource: {
        id: 'collectionColumnSource',
        name: 'Column source',
        procedureOnlyMode: RULE_APPLY_MODE_MODE.both.id,
        dataStructureType: RULE_DATA_STRUCTURE_TYPE.action.id,
        calculateValueOn: true,
        skipCalculate: true
    },
    collectionOrder: {
        id: 'collectionOrder',
        name: 'Order',
        procedureOnlyMode: RULE_APPLY_MODE_MODE.both.id,
        dataStructureType: RULE_DATA_STRUCTURE_TYPE.action.id,
        applyToTypes: [NODE_TYPE_OPTIONS.ExecutionQuestion],
        skipCalculate: true
    },
    security: {
        id: 'security',
        name: 'Security',
        procedureOnlyMode: RULE_APPLY_MODE_MODE.procedure.id,
        dataStructureType: RULE_DATA_STRUCTURE_TYPE.action.id
    },
    grant: {
        id: 'grant',
        name: 'Grant access',
        procedureOnlyMode: RULE_APPLY_MODE_MODE.both.id,
        dataStructureType: RULE_DATA_STRUCTURE_TYPE.action.id,
        calculateValueOn: true
    },
    deny: {
        id: 'deny',
        name: 'Deny access',
        procedureOnlyMode: RULE_APPLY_MODE_MODE.execution.id,
        dataStructureType: RULE_DATA_STRUCTURE_TYPE.action.id
    },
    customKey: {
        id: 'customKey',
        name: 'Custom key',
        procedureOnlyMode: RULE_APPLY_MODE_MODE.execution.id,
        dataStructureType: RULE_DATA_STRUCTURE_TYPE.action.id,
        calculateValueOn: true
    },
    reloadInterval: {
        id: 'reloadInterval',
        name: 'Load changes interval',
        procedureOnlyMode: RULE_APPLY_MODE_MODE.execution.id,
        dataStructureType: RULE_DATA_STRUCTURE_TYPE.action.id,
        calculateValueOn: true
    },
    computeOnClient: {
        id: 'computeOnClient',
        name: 'Compute on client',
        procedureOnlyMode: RULE_APPLY_MODE_MODE.execution.id,
        dataStructureType: RULE_DATA_STRUCTURE_TYPE.action.id
    },
    computeOnServer: {
        id: 'computeOnServer',
        name: 'Compute on server',
        procedureOnlyMode: RULE_APPLY_MODE_MODE.execution.id,
        dataStructureType: RULE_DATA_STRUCTURE_TYPE.action.id
    },
    navigationStyle: {
        id: 'navigationStyle',
        name: 'Navigation style',
        procedureOnlyMode: RULE_APPLY_MODE_MODE.execution.id,
        dataStructureType: RULE_DATA_STRUCTURE_TYPE.action.id
    },
    completeActionStyle: {
        id: 'completeActionStyle',
        name: 'Complete style',
        procedureOnlyMode: RULE_APPLY_MODE_MODE.execution.id,
        dataStructureType: RULE_DATA_STRUCTURE_TYPE.action.id
    },
    completeActionLabel: {
        id: 'completeActionLabel',
        name: 'Complete action label',
        procedureOnlyMode: RULE_APPLY_MODE_MODE.execution.id,
        dataStructureType: RULE_DATA_STRUCTURE_TYPE.action.id
    },
    editActionLabel: {
        id: 'editActionLabel',
        name: 'Edit action label',
        procedureOnlyMode: RULE_APPLY_MODE_MODE.execution.id,
        dataStructureType: RULE_DATA_STRUCTURE_TYPE.action.id
    },
    layoutColumns: {
        id: 'layoutColumns',
        name: 'Desktop Columns',
        procedureOnlyMode: RULE_APPLY_MODE_MODE.execution.id,
        applyToTypes: [NODE_TYPE_OPTIONS.ExecutionTask, NODE_TYPE_OPTIONS.ExecutionQuestion],
        dataStructureType: RULE_DATA_STRUCTURE_TYPE.action.id,
        pasteMode: RULE_ACTION_TYPE_PASTE_MODE.reuseAlways.id
    },
    readOnly: {
        id: 'readOnly',
        name: 'Read Only',
        // No need to cascade this down as the UI does that for us by passing down disabled
        applyToDescendants: true,
        applyToTypes: [NODE_TYPE_OPTIONS.ExecutionQuestion],
        procedureOnlyMode: RULE_APPLY_MODE_MODE.execution.id,
        dataStructureType: RULE_DATA_STRUCTURE_TYPE.action.id
    },
    invalidInputOn: {id: 'invalidInputOn', name: 'Make input invalid', dataStructureType:RULE_DATA_STRUCTURE_TYPE.on.id, procedureOnlyMode: RULE_APPLY_MODE_MODE.execution.id},
    visibleOn: {id: 'visibleOn', name: 'Show/Hide', dataStructureType:RULE_DATA_STRUCTURE_TYPE.on.id, procedureOnlyMode: RULE_APPLY_MODE_MODE.execution.id},
    messageOn: {id: 'messageOn', name: 'Display a message', dataStructureType:RULE_DATA_STRUCTURE_TYPE.on.id, procedureOnlyMode: RULE_APPLY_MODE_MODE.execution.id},
    raiseIssueOn: {id: 'raiseIssueOn', name: 'Raise an issue', dataStructureType:RULE_DATA_STRUCTURE_TYPE.on.id, procedureOnlyMode: RULE_APPLY_MODE_MODE.execution.id},
    photoRequiredOn: {id: 'photoRequiredOn', name: 'Make photo required', dataStructureType:RULE_DATA_STRUCTURE_TYPE.on.id, procedureOnlyMode: RULE_APPLY_MODE_MODE.execution.id},
    photoInstructionsOn: {id: 'photoInstructionsOn', name: 'Display photo instructions', dataStructureType:RULE_DATA_STRUCTURE_TYPE.on.id, procedureOnlyMode: RULE_APPLY_MODE_MODE.execution.id},
    commentRequiredOn: {id: 'commentRequiredOn', name: 'Make comment required', dataStructureType:RULE_DATA_STRUCTURE_TYPE.on.id, procedureOnlyMode: RULE_APPLY_MODE_MODE.execution.id},
    commentInstructionsOn: {id: 'commentInstructionsOn', name: 'Display comment instructions', dataStructureType:RULE_DATA_STRUCTURE_TYPE.on.id, procedureOnlyMode: RULE_APPLY_MODE_MODE.execution.id},
    createExecutionOn: {id: 'createExecutionOn', name: 'Automatically create a ...', dataStructureType:RULE_DATA_STRUCTURE_TYPE.on.id, procedureOnlyMode: RULE_APPLY_MODE_MODE.execution.id},
    calculateValueOn: {id: 'calculateValueOn', name: 'Calculate', dataStructureType:RULE_DATA_STRUCTURE_TYPE.on.id, procedureOnlyMode: RULE_APPLY_MODE_MODE.execution.id},
    linkToQuestionOn: {id: 'linkToQuestionOn', name: 'Link worq item to question', dataStructureType:RULE_DATA_STRUCTURE_TYPE.on.id, procedureOnlyMode: RULE_APPLY_MODE_MODE.execution.id},
    copyToOn: {id: 'copyToOn', name: 'Copy', dataStructureType:RULE_DATA_STRUCTURE_TYPE.on.id, procedureOnlyMode: RULE_APPLY_MODE_MODE.execution.id},
    label: {
        id: 'label',
        name: 'Dynamic Label',
        applyToDescendants: false,
        applyToTypes: [NODE_TYPE_OPTIONS.ExecutionQuestion, NODE_TYPE_OPTIONS.ExecutionTask, NODE_TYPE_OPTIONS.ExecutionStep],
        procedureOnlyMode: RULE_APPLY_MODE_MODE.execution.id,
        dataStructureType: RULE_DATA_STRUCTURE_TYPE.action.id,
        calculateValueOn: true
    },
    manuallyAddExecution: {
        id: 'manuallyAddExecution',
        name: 'User may create a...',
        applyToDescendants: false,
        procedureOnlyMode: RULE_APPLY_MODE_MODE.execution.id,
        dataStructureType: RULE_DATA_STRUCTURE_TYPE.action.id,
    },
    manuallyAddExecutionBulk: {
        id: 'manuallyAddExecutionBulk',
        name: 'User may create a...',
        applyToDescendants: false,
        procedureOnlyMode: RULE_APPLY_MODE_MODE.procedure.id,
        dataStructureType: RULE_DATA_STRUCTURE_TYPE.action.id,
    },
    completeLabels: {
        id: 'completeLabels',
        name: 'Collection Complete Custom Label',
        applyToTypes: [NODE_TYPE_OPTIONS.ExecutionTask, NODE_TYPE_OPTIONS.ExecutionStep],
        procedureOnlyMode: RULE_APPLY_MODE_MODE.both.id,
        dataStructureType: RULE_DATA_STRUCTURE_TYPE.action.id,
        applyToDescendants: true
    },
    navigateNextOnComplete: {
        id: 'navigateNextOnComplete',
        name: 'Navigate to next step/task on complete',
        applyToTypes: [NODE_TYPE_OPTIONS.ExecutionTask, NODE_TYPE_OPTIONS.ExecutionStep],
        procedureOnlyMode: RULE_APPLY_MODE_MODE.both.id,
        dataStructureType: RULE_DATA_STRUCTURE_TYPE.action.id
    },
    geographicTools: {
        id: 'geographicTools',
        name: 'Geographic tools',
        applyToTypes: [NODE_TYPE_OPTIONS.ExecutionQuestion],
        procedureOnlyMode: RULE_APPLY_MODE_MODE.execution.id,
        dataStructureType: RULE_DATA_STRUCTURE_TYPE.action.id,
        calculateValueOn: true
    },
    inlineCamera: {
        id: 'inlineCamera',
        name: 'Inline Camera',
        applyToTypes: [NODE_TYPE_OPTIONS.ExecutionQuestion],
        procedureOnlyMode: RULE_APPLY_MODE_MODE.execution.id,
        dataStructureType: RULE_DATA_STRUCTURE_TYPE.action.id
    },
    photoAspectRatio: {
        id: 'photoAspectRatio',
        name: 'Crop photo to aspect ratio',
        applyToTypes: [NODE_TYPE_OPTIONS.ExecutionQuestion],
        procedureOnlyMode: RULE_APPLY_MODE_MODE.execution.id,
        dataStructureType: RULE_DATA_STRUCTURE_TYPE.action.id,
        calculateValueOn: true
    },
    addMoreAvailable: {
        id: 'addMoreAvailable',
        name: 'Add more available',
        applyToDescendants: false,
        procedureOnlyMode: RULE_APPLY_MODE_MODE.procedure.id,
        dataStructureType: RULE_DATA_STRUCTURE_TYPE.action.id,
    },
    afterAddNavigationMode: {
        id: 'afterAddNavigationMode',
        name: 'After add navigation mode',
        applyToDescendants: false,
        procedureOnlyMode: RULE_APPLY_MODE_MODE.procedure.id,
        dataStructureType: RULE_DATA_STRUCTURE_TYPE.action.id,
    },
    pivotSettings: {
        id: 'pivotSettings',
        name: 'Pivot settings',
        applyToDescendants: false,
        procedureOnlyMode: RULE_APPLY_MODE_MODE.execution.id,
        applyToTypes: [NODE_TYPE_OPTIONS.ExecutionRule],
        dataStructureType: RULE_DATA_STRUCTURE_TYPE.action.id,
        pasteMode: RULE_ACTION_TYPE_PASTE_MODE.cloneAlways.id
    },
    calculatePreviousVersions: {
        id: 'calculatePreviousVersions',
        name: 'Calculate for previous versions',
        applyToDescendants: false,
        procedureOnlyMode: RULE_APPLY_MODE_MODE.procedure.id,
        applyToTypes: [NODE_TYPE_OPTIONS.ProcedureQuestion],
    },
    theme: {
        id: 'theme',
        name: 'Theme of the procedure',
        applyToDescendants: false,
        procedureOnlyMode: RULE_APPLY_MODE_MODE.procedure.id,
        applyToTypes: [NODE_TYPE_OPTIONS.ProcedureRoot],
    },
    globalNavigationStyle: {
        id: 'globalNavigationStyle',
        name: 'Global navigation style',
        applyToDescendants: false,
        procedureOnlyMode: RULE_APPLY_MODE_MODE.procedure.id,
        dataStructureType: RULE_DATA_STRUCTURE_TYPE.action.id,
    },
};

export const RULE_ACTION_TYPE_ON = Object.values(RULE_ACTION_TYPE).filter(a => a.dataStructureType === RULE_DATA_STRUCTURE_TYPE.on.id);
export const RULE_ACTION_TYPE_OPTIONS = objectIdNameToKeyValue(RULE_ACTION_TYPE);
export const NAVIGATION_STYLES = {
    tab: {id: 'tab', name: 'Tabs'},
    toc: {id: 'toc', name: 'Table of content'},
    none: {id: 'none', name: 'None'},
}
export const COMPLETE_ACTION_STYLES = {
    standard: {id: 'standard', name: 'Standard'},
    form: {id: 'form', name: 'Form'},
    calculator: {id: 'calculator', name: 'Calculator'},
}

export const AFTER_ADD_NAVIGATION_MODE = {
    default: {id: 'default', name: 'Default'},
    addAndClose: {id: 'addAndClose', name: 'Add & Close'},
    addAndOpen: {id: 'addAndOpen', name: 'Add & Open'},
}

export const GLOBAL_NAVIGATION_STYLE = {
    default: {id: 'default', name: 'Default'},
    wiki: {id: 'wiki', name: 'Wiki'},
}

export const COMPLETE_ACTION_STYLES_OPTIONS = objectIdNameToKeyValue(COMPLETE_ACTION_STYLES);

export const ADD_ACTION_PLACEMENT_OPTIONS = {
    top: {id: 'top', name: 'Top', label: 'Top'},
}

export const ADD_ACTION_LABEL_FORMATS = {
    group: {id: 'group', name: 'Group label'},
    button: {id: 'button', name: 'Button label'},
}

export const PROCEDURE_THEME_FORMATS = {
    icon: {id: 'icon', name: 'Icon'},
    colour: {id: 'colour', name: 'Colour'},
}

export const LAYOUT_COLUMN_FORMATS = {
    desktop: {id: 'desktop', name: 'Desktop layout columns'},
    mobile: {id: 'mobile', name: 'Mobile layout columns'},
}

export const GRANT_DENY_PERMISSIONS = {
    create: {id: 'create', name: 'Create'},
    view: {id: 'view', name: 'View'},
    edit: {id: 'edit', name: 'Revise'},
    complete: {id: 'complete', name: 'Complete'},
    link: {id: 'link', name: 'Link'},
    assign: {id: 'assign', name: 'Assign'},
    delete: {id: 'delete', name: 'Delete'},
    search: {id: 'search', name: 'Search'},
    list: {id: 'list', name: 'List'},
    all: {id: 'all', name: 'All'},
    design: {id: 'design', name: 'Design'},
    // Note: create/list implicitly grant viewTemplate.
    inspect: {id: 'inspect', name: 'Inspect'},
    publish: {id: 'publish', name: 'Publish'},
    //assignable: {id: 'assignable', name: 'assignable'},
}
export const GRANT_DENY_PERMISSION_OPTIONS = objectIdNameToKeyValue(GRANT_DENY_PERMISSIONS);
export const MOBILE_CAMERA_MODES = {
    cameraonly: {id: 'cameraonly', name: 'Camera Only'},
    cameragallery: {id: 'cameragallery', name: 'Camera or Gallery'}
};
export const MOBILE_CAMERA_MODES_OPTIONS = objectIdNameToKeyValue(MOBILE_CAMERA_MODES);

export const EXECUTION_STATUS = {
    notstarted: {id: 'notstarted', name: 'Not Started'},
    inprogress: {id: 'inprogress', name: 'In Progress'},
    done: {id: 'done', name: 'Done'},
};
export const EXECUTION_STATUS_OPTIONS = objectIdNameToKeyValue(EXECUTION_STATUS);

export const EXECUTION_SEARCH_FORMATS = {
    link: {id: 'link'},
    percentage: {id: 'percentage'},
    status: {id: 'status'},
    age: {id: 'age'},
    distanceToMe: {id: 'distanceToMe'},
    plain: {id: 'plain'}
}

const DEFAULT_MAX_ACTIONABLE_ITEMS = 20;

export const DEFAULT_LISTING_PAGE_BULK_ACTIONS = {
    assign: {id: 'assign', name: 'Assign', maxItems: DEFAULT_MAX_ACTIONABLE_ITEMS},
    delete: {id: 'delete', name: 'Delete', maxItems: DEFAULT_MAX_ACTIONABLE_ITEMS},
}

export const LISTING_PAGE_BULK_ACTIONS = {
    ...DEFAULT_LISTING_PAGE_BULK_ACTIONS,
}

export const EXECUTION_SEARCH_COLUMNS = {
    key: {
        id: 'key',
        field: 'key',
        format: {id: EXECUTION_SEARCH_FORMATS.link.id},
        title: strings.execution.columns.key,
        filterByExact: 'key',
        filterBySearch: 'key',
        orderBy: 'key',
        sourceType: 'default',
        dataType: QUESTION_TYPES.text.id,
        hidden: false
      },
    title: {
        id: 'title',
        field: 'title',
        format: {id: EXECUTION_SEARCH_FORMATS.link.id},
        title: strings.execution.columns.title,
        filterByExact: 'title',
        filterBySearch: 'title',
        orderBy: 'title',
        sourceType: 'default',
        dataType: QUESTION_TYPES.text.id,
        hidden: false
      },
    name: {
        id: 'name',
        field: 'name',
        format: {id: EXECUTION_SEARCH_FORMATS.link.id},
        title: strings.execution.columns.name,
        filterByExact: 'name',
        filterBySearch: 'name',
        orderBy: 'name',
        sourceType: 'default',
        dataType: QUESTION_TYPES.text.id,
        hidden: false
      },
    status: {
        id: 'status',
        field: 'status',
        format: {id: EXECUTION_SEARCH_FORMATS.status.id},
        title: strings.execution.columns.status,
        filterByExact: 'status',
        filterBySearch: 'status',
        orderBy: 'status',
        sourceType: 'default',
        dataType: QUESTION_TYPES.text.id,
        options: EXECUTION_STATUS_OPTIONS,
        hidden: false
      },
    completedRatio: {
        id: 'completedRatio',
        field: 'completedRatio',
        format: {id: EXECUTION_SEARCH_FORMATS.percentage.id},
        title: strings.execution.columns.completedRatio,
        filterByExact: 'completedRatio',
        filterBySearch: 'completedRatio',
        orderBy: 'completedRatio',
        sourceType: 'default',
        dataType: QUESTION_TYPES.number.id,
        hidden: false
      },
    createdDateTime: {
        id: 'createdDateTime',
        field: 'createdDateTime',
        format: {id: EXECUTION_SEARCH_FORMATS.age.id},
        title: strings.execution.columns.createdDateTime,
        filterByExact: 'createdDateTime',
        filterBySearch: 'createdDateTime',
        orderBy: 'createdDateTime',
        sourceType: 'default',
        dataType: QUESTION_TYPES.datetime.id,
        hidden: false
      },
    lastUpdatedDateTime: {
        id: 'lastUpdatedDateTime',
        field: 'lastUpdatedDateTime',
        format: {id: EXECUTION_SEARCH_FORMATS.age.id},
        title: strings.execution.columns.lastUpdatedDateTime,
        filterByExact: 'lastUpdatedDateTime',
        filterBySearch: 'lastUpdatedDateTime',
        orderBy: 'lastUpdatedDateTime',
        sourceType: 'default',
        dataType: QUESTION_TYPES.datetime.id,
        hidden: false
    },
    completedDate: {
        id: 'completedDate',
        field: 'completedDate',
        format: {id: EXECUTION_SEARCH_FORMATS.age.id},
        title: strings.execution.columns.completedDate,
        filterByExact: 'completedDate',
        filterBySearch: 'completedDate',
        orderBy: 'completedDate',
        sourceType: 'default',
        dataType: QUESTION_TYPES.datetime.id,
        hidden: false
    },
    feature: {
        id: 'feature',
        field: 'feature',
        format: {id: EXECUTION_SEARCH_FORMATS.distanceToMe.id},
        title: strings.execution.columns.feature,
        filterByExact: null,
        filterBySearch: null,
        orderBy: 'feature',
        sourceType: 'default',
        dataType: QUESTION_TYPES.geographic.id,
        hidden: false
    },
    totalPhotoCount: {
        id: 'totalPhotoCount',
        field: 'totalPhotoCount',
        format: {id: EXECUTION_SEARCH_FORMATS.plain.id},
        title: strings.execution.columns.totalPhotoCount,
        filterByExact: null,
        filterBySearch: null,
        orderBy: null,
        sourceType: 'default',
        dataType: QUESTION_TYPES.number.id,
        hidden: false
    }
}
export const EXECUTION_STANDARD_PIVOT_COLUMNS = {
    ...EXECUTION_SEARCH_COLUMNS,
    id: {
        id: 'id',
        field: 'id',
        format: {id: EXECUTION_SEARCH_FORMATS.link.id},
        title: strings.execution.columns.id,
        filterByExact: 'id',
        filterBySearch: 'id',
        orderBy: 'id',
        sourceType: 'default',
        dataType: QUESTION_TYPES.text.id,
        hidden: false,
    },
    completed: {
        id: 'completed',
        field: 'completed',
        format: {id: EXECUTION_SEARCH_FORMATS.plain.id},
        title: strings.execution.columns.completed,
        filterByExact: 'completed',
        filterBySearch: 'completed',
        orderBy: 'completed',
        sourceType: 'default',
        dataType: QUESTION_TYPES.yesno.id,
        hidden: false,
    }
}
export const EXECUTION_ORDER_BY_DIRECTION = {
    ascending: {id: 'ascending', name: 'Ascending', dataTableValue: 'asc'},
    descending: {id: 'descending', name: 'Descending', dataTableValue: 'desc'}
}
export const EXECUTION_FILTER_MODE = {
    client: {id: 'client', name: 'client'},
    server: {id: 'server', name: 'server'}
}

export const EXECUTION_ORDER_BY_DIRECTION_OPTIONS = objectIdNameToKeyValue(EXECUTION_ORDER_BY_DIRECTION);
export const EXECUTION_SEARCH_VIEWS = {
    assignedToMe: {id: 'assignedToMe', name: 'Assigned to me', orderBy: 'modified', orderByDirection: 'descending'},
    created: {id: 'created', name: 'Recently created', orderBy: 'created', orderByDirection: 'descending'},
    modified: {id: 'modified', name: 'Recently modified', orderBy: 'modified', orderByDirection: 'descending'},
    completed: {id: 'completed', name: 'Recently completed', orderBy: 'completed', orderByDirection: 'descending'},
    nearMe: {id: 'nearMe', name: 'Near me', orderBy: 'distance', orderByDirection: 'ascending'},
};
export const EXECUTION_SEARCH_FILTER_OPTIONS = objectIdNameToKeyValue(EXECUTION_SEARCH_VIEWS);
// Russ preferred 5, Iain thought 10 was ok. Just choose a sensible limit.
export const EXECUTION_MAX_DEPTH = 5;
// This is to protect accidental creates. If we do subject create (i.e. per employee) this may be required
export const EXECUTION_MAX_CREATE = 100;

export const EXECUTION_CREATE_STOP_CODE = {
    PARENT_CHILD_TOO_DEEP: {
        id: 'PARENT_CHILD_TOO_DEEP',
        message: `Creating an execution would have exceeded the max depth of ${EXECUTION_MAX_DEPTH}.`
    },
    EXECUTION_MAX_CREATE: {
        id: 'EXECUTION_MAX_CREATE',
        message: `Creating an execution would have exceeded the maximium allowable create per rule of ${EXECUTION_MAX_CREATE}.`,
    }
};
export const EXECUTION_CREATE_STOP_CODE_OPTIONS = objectIdNameToKeyValue(EXECUTION_CREATE_STOP_CODE);


export const DATE_FORMAT_STORED = 'YYYY-MM-DD';
export const DATE_FORMAT_DISPLAY = 'DD/MM/YYYY';
export const DATETIME_FORMAT_STORED = 'YYYY-MM-DDTHH:mmZ';
export const DATETIME_FORMAT_DISPLAY = 'DD/MM/YYYY h:mm a';
export const DATETIME_FORMAT_RULE_DISPLAY = 'YYYY-MM-DD HH:mm:00';
export const TIME_FORMAT_STORED = 'HH:mm';
export const TIME_FORMAT_RULE_DISPLAY = 'HH:mm';
export const TIME_FORMAT_DISPLAY = 'h:mm a';
export const DATETIME_TIMEZONE_DISPLAY = 'Australia/Sydney';
export const DEFAULT_PHONE_NUMBER_COUNTRY = 'AU';
export const UserConstant = 'User';

export const NODE_IDS = {
    UserSettings: 'UserSettings',
    User: 'user',
    ClientConfig: 'ClientConfig',
    ReduxDependencies: 'ReduxDependencies',
    Dashboards: `/executions?procedureType=${PROCEDURE_TYPES.dashboard.id}&summary=true&includeDeleted=false`,
    MyRecentWorkspaces: `/executions/mine/recent?procedureType=${PROCEDURE_TYPES.workspace.id}&limit=10`,
    MyRecentProjects: `/executions/mine/recent?procedureType=${PROCEDURE_TYPES.project.id}&limit=10`,
    MyAssignments: `/assignments/mine?statuses=notstarted&statuses=inprogress&limit=1000`,
    MyAssignedExecutions: `/executions?assignedEntities=me&orderBy=modified&orderByDirection=ascending&limit=10`,
    MyActivityRecent: '/activity/mine/recent?limit=10',
    ExecutionSearchRecentlyCreated: (procedureType, procedureId, defaultsLimit, pageNumber) => `/executions?procedureType=${encodeURIComponent(procedureType || '')}&procedureId=${encodeURIComponent(procedureId || '')}&limit=${defaultsLimit}&summary=true&pageNumber=${pageNumber}&orderByDirection=descending&orderBy=created`,
    ExecutionSearchRecentlyModified: (procedureType, procedureId, defaultsLimit, pageNumber) => `/executions?procedureType=${encodeURIComponent(procedureType || '')}&procedureId=${encodeURIComponent(procedureId || '')}&limit=${defaultsLimit}&summary=true&pageNumber=${pageNumber}&orderByDirection=descending&orderBy=modified`,
    Me: `/executions/me`,
    Groups: `/groups`,
    Location: 'location',
    ProcedureSummaryAll: '/procedures?summary=true',
    ProcedureFull: id => `/procedures?id=${id}&includeDeleted=true`,
    UserSubject: '562842c4-0e99-4236-b967-01cdf5f877c7',
    AssignmentMapView: 'AssignmentMapView',
    ExecutionsMapView: url => 'ExecutionsMapView-' + url,
    MyAssignmentPage: 'MyAssignmentPage',
    UserDevice: 'UserDevice',
    ExecutionListingPage: (procedureId, procedureType, defaultViewId) => {
        const useId = (defaultViewId || 'default') + '-' + (procedureId || procedureType || 'all');
        return 'ExecutionListingPage-' + useId;
    },
    ExecutionSummaryScoped: (nodeId, returnFields) => `/executions?scopeId=${nodeId}&summary=true&includeDeleted=true&${(returnFields || []).map(a => "returnFields=" + encodeURIComponent(a)).join('&')}`,
    ExecutionsForProject: (projectId) => `/executions?projectId=${projectId}`,
    PhotosForExecution: (nodeId, executionProjectId) => executionProjectId ? `/photos?projectId=${executionProjectId}` : `/photos?executionId=${nodeId}`,
    Project: (executionProjectId) => `/projects?id=${executionProjectId}&includeDeleted=true`,
    ProjectExecutionSummary: (projectId, displayDeleted) => `/executions?projectId=${projectId}&includeDeleted=${displayDeleted}&summary=true`,
    ExecutionQuestionSelect: (q) => {
        let defaultsLimit = 100;
        let loadFilteredUrl = `/executions?summary=true&limit=${defaultsLimit}`;
        if (q?.selectExecutionFilter?.search) {
            loadFilteredUrl += `&q=${encodeURIComponent(q?.selectExecutionFilter?.search)}`;
        }
        let procedureIds = q?.selectExecutionFilter?.procedureIds || [];
        procedureIds.forEach(procedureId => loadFilteredUrl += `&procedureIds=${procedureId}`);
        if (q?.selectExecutionFilter?.where) {
            loadFilteredUrl += "&where=" + encodeURIComponent(q?.selectExecutionFilter?.where);
        }

        if (q?.selectExecutionFilter?.orderBy) {
            loadFilteredUrl += `&orderBy=${encodeURIComponent(q.selectExecutionFilter.orderBy)}&orderByDirection=${encodeURIComponent(q.selectExecutionFilter.orderByDirection ?? 'ascending')}`;
        }

        return loadFilteredUrl;
    },
    ExecutionSummaryQuery: (selector) => {
        const {procedureIds, filter, orderBy, orderByDirection} = selector?.queryFilter ?? {};
        let loadFilteredUrl = `/executions?summary=true`;
        procedureIds?.forEach(procedureId => loadFilteredUrl += `&procedureIds=${procedureId}`);
        if (filter) {
            loadFilteredUrl += `&where=${encodeURIComponent(filter)}`;
        }
        if (orderBy) {
            loadFilteredUrl += `&orderBy=${encodeURIComponent(orderBy)}&orderByDirection=${encodeURIComponent(orderByDirection ?? 'ascending')}`;
        }
        return loadFilteredUrl;
    },
    ExecutionLinkTree: (procedureId) => `ExecutionLinkTree-${procedureId}`,
    ProcedureSchema: 'ProcedureSchema',
    ProcedureSchemaProperty: 'ProcedureSchemaProperty',
};

export const PROCEDURE_EXECUTION_VIEW_TYPES = {
    tabs: 'tabs',
    toc: 'toc'
}

export const SAVE_TIMEOUT_SECONDS = 240;

export const FILTER_BY_EXACT_MODE = {
    "words": {id: "words", name: "Words"},
    "entireValue": {id: "entireValue", name: "Entire Value"}
};

export const COMPLETE_LABELS_FORMAT = {
    completeButton:{ id:"completeButton", name: "Complete Button Label" },
    undoCompleteButton:{ id:"undoCompleteButton", name: "Revise Button Label" },
    nodeCompletedPrefix:{ id:"nodeCompletedPrefix", name: "Completed by prefix" },
    notCompleted:{ id:"notCompleted", name: "Not Completed and Readonly label" },
    submitCompleted:{ id:"submitCompleted", name: "Last Complete Button Label" },
    nextCompleted:{ id:"nextCompleted", name: "Next Button Label" },
    previousCompleted:{ id:"previousCompleted", name: "Previous Button Label" },

}


/**
 * This is used by resource that are a resource root and as such loadable.
 * i.e. Usable with GraphResourceLoad.
 */
const RESOURCE_SYSTEM_PROPERTIES = {
    ...SYSTEM_PROPERTIES,
    loading: {kind: 'boolean', storeServer: false, storeDb: false},
    /***
     * True if the node is loaded into redux fully, including all children (if any).
     * False if just a summary level.
     */
    loadedFull: {kind: 'boolean', storeServer: false},
    /**
     * When offline and the execution summary is loaded but its full data is available in indexeddb, then
     * this will be true.
     */
    availableOffline: {kind: 'boolean', storeServer: false, keepLocal: true},
    loadingError: {kind: 'string', storeServer: false},
    loadingException: {kind: 'object', storeServer: false},
    loadingErrorCount: {kind: 'string', storeServer: false},
    /**
     * Have we looked in indexdb yet for this node?
     */
    indexedDbChecked: {kind: 'boolean', storeServer: false, storeDb: false, keepLocal: true},
    /**
     * Have we looked in indexdb yet for just this item, and not its scoped items
     */
    indexedDbOfflineChecked: {kind: 'boolean', storeServer: false, storeDb: false, keepLocal: true},
    loadingDbError: {kind: 'string', storeServer: false},
    nodeExists: {kind: 'boolean', storeServer: false},
    loadingDbErrorCount: {kind: 'string', storeServer: false},
}
/**
 * Defines the standard properties of a nodes meta data. Used for reference only, not used in code.
 * @type {{loaded: {server: boolean, kind: string}, server: {kind: string, displayName: string}, deleted: {kind: string}, lastReloadTicks: {server: boolean, kind: string}, loadingError: {server: boolean, kind: string}, lastSaveTicks: {server: boolean, kind: string, displayName: string}, storeRedux: {server: boolean, kind: string, displayName: string}, id: {kind: string}, lastUpdatedDateTime: {dirtyCompare: boolean, kind: string}, type: {kind: string}, loading: {server: boolean, kind: string}}}
 */
const META_PROPERTIES = {
    id: {kind: 'id'},
    storeServer: {kind: 'object', displayName: 'Server version of the node. Used to determine when node is dirty.'},
    dirty: {kind: 'boolean', displayName: 'True if a server property has changed and is not yet saved.'},
    dirtyProperties: [{kind: 'string', display: 'List of property names that are considered dirty.'}],
    lastSaveTicks: {
        kind: 'integer',
        storeServer: false,
        displayName: 'The last time the node was attempted to be saved'
    },
    nextSaveTicks: {
        kind: 'integer',
        storeServer: false,
        displayName: 'The next time the node will be saved'
    },
    lastSaveError: {kind: 'string', displayName: 'The error from the last attempted save.'},
    lastSavedException: {kind: 'object', displayName: 'The exception detail from the last attempted save.'},
    saveErrorCount: {kind: 'integer', storeServer: false},
    saveAborted: {kind: 'boolean', displayName: 'True saving has failed too many times and we have given up.'},
    storedDb: {kind: 'boolean', displayName: 'True if saved into indexeddb.'},
    storedServer: {kind: 'boolean', displayName: 'True if saved onto server.'},
    lastUpdatedDateTime: {kind: 'date', storeServer: false},
};
nop(META_PROPERTIES);
const VISIBLE_PROPERTIES = {
    visible: {kind: 'enum', displayName: 'Visible', values: VISIBLE_MODE_OPTIONS},
    visibleRuleTree: {
        kind: 'string',
        displayName: 'Query builder tree data'
    },
    visibleRuleQuery: {
        kind: 'string',
        displayName: 'Code executable version of the rule'
    },
    visibleRuleHuman: {
        kind: 'string',
        displayName: 'Visible Rule',
        multiline: true
    },
    visibleRuleEnabled: {
        kind: 'boolean',
        displayName: 'Is the options photoInstructionsE on. True when photo != none',
        storeServer: false
    },
};

export const LOCATION_MODES = {
    unavailable: {id: 'unavailable'},
    userDenied: {id: 'userDenied'},
    available: {id: 'available'},
};

const ref = (type, attributes) => {
    return {kind: 'nodeReference', allowed: typeFilter(type), ...attributes};
};

export const RELOAD_INTERVAL_DEFAULT = 60 * 5;
export const GRAPH_INITIAL_STATE = {
    nodes: {
        [NODE_IDS.UserSettings]: {
            id: 'UserSettings',
            rootId: 'UserSettings',
            type: 'UserSettings',
            indexedDbChecked: false,
            scopes: ['AlwaysLoad'],
            selectedProcedureTabIndex: 0,
            recentExecutionsTabIndex: 0
        },
        [NODE_IDS.User]: {
            id: NODE_IDS.User,
            rootId: NODE_IDS.User,
            scopes: [NODE_IDS.User, 'AlwaysLoad'],
            type: 'User',
            name: null,
            email: null,
            auditName: null,
            roles: [],
            permissions: [],
            permissionsMap: {},
            groups: [],
            executionId: null
        },
        [NODE_IDS.Location]: {
            id: NODE_IDS.Location,
            rootId: NODE_IDS.Location,
            scopes: [NODE_IDS.Location],
            type: 'Location',
            mode: LOCATION_MODES.unavailable.id,
        },
        [NODE_IDS.UserDevice]: {
            id: NODE_IDS.UserDevice,
            rootId: NODE_IDS.UserDevice,
            indexedDbChecked: false,
            type: 'UserDevice',
            scopes: [NODE_IDS.UserDevice, 'AlwaysLoad'],
            assignmentsOffline: false,
            assignmentsOfflineCompleted: false,
            assignmentsOfflineState: null,
            offlineExecutions: {},
            offlineResources: {
                procedureIds: [],
                photoPaths: [],
                executionListPaths: [],
                projectIds: []
            },
            mapsOfflineState: {},
            cachedAssignments: [],
            internetAvailable: true,
            indexeddbClearedAge: DAYS_7,
            diagnosticMode: tbfLocalStorage.getItem('perm_diagnosticsOn') === 'true' ? DIAGNOSTIC_MODES.full.id : DIAGNOSTIC_MODES.none.id,
            troubleshootOn: tbfLocalStorage.getItem('perm_trooubleShootOn') === 'true',
            saveRunning: false
        },
    },
    dirtyNodes: {},
    dirtyNodeIds: {},
    dirtyRootIds: {},
    dirtyNodesLoaded: false,
    savingRootIds: {},
    saveRunning: false,
    saveStartedTicks: false,
    serverLoading: false,
    serverLoadingCount: 0,
    serverLoadingStartedTicks: null,
    serverLoadingNodeIds: {},
    nodesLoadedStorage: false,
    nodesClearing: false,
    storedDbError: null,
    storedDbErrorNodeIds: {},
    pendingStoreDbNodeCount: 0,
    pendingSaveNodeCount: 0,
    // The user does not see all pending saves (views)
    pendingUserSaveNodeCount: 0,
    pendingUserSaveNodeIds: [],
    versionNumber: 1,
    // Used for live preview
    reduxDependentNodeMap: {},
    focusedNode: null,
    rootNodesCreatedThisAction: 0,
    processingErrorCount: 0,
    processingWarningCount: 0,
    developerWarningLog: [],
    /**
     * The structure of each node is as follows. This is not yet used in code.
     */
    schema: {
        ProcedureRoot: {
            type: 'ProcedureRoot',
            displayName: 'Procedure',
            properties: {
                ...RESOURCE_SYSTEM_PROPERTIES,
                name: {kind: 'string', displayName: 'Name', multiline: true},
                number: {
                    kind: 'string',
                    displayName: 'Row number (starting at 1)',
                    storeServer: false,
                    keepLocal: true
                },
                category: {kind: 'string', displayName: 'Category'},
                procedureType: {kind: 'enum', displayName: 'Type', values: PROCEDURE_TYPE_OPTIONS},
                keyField1QuestionId: ref('ProcedureQuestion'),
                keyField2QuestionId: ref('ProcedureQuestion'),
                keyField3QuestionId: ref('ProcedureQuestion'),
                keyField4QuestionId: ref('ProcedureQuestion'),
                keyField5QuestionId: ref('ProcedureQuestion'),
                titleTemplate: {kind: 'string', displayName: 'Title template'},
                titleTemplateEditor: {
                    kind: 'string',
                    displayName: 'Title template',
                    storeServer: false,
                    keepLocal: true
                },
                titleTemplateEditorEnabled: {
                    kind: 'boolean',
                    displayName: 'Customise title',
                    storeServer: false,
                    keepLocal: true
                },
                children: {kind: [ref('ProcedureStep')]},
                compileWarnings: {
                    kind: 'array',
                    displayName: 'Procedure level warnings',
                    apiClientDisagreementOn: false
                },
                compileSuggestions: {
                    kind: 'array',
                    displayName: 'Any suggestions with this question',
                    storeServer: false
                },
                sideBySidePreviewOn: {kind: 'boolean', storeServer: false, keepLocal: true},
                hasLocationField: {kind: 'boolean', storeServer: false, keepLocal: true},
                rules: {kind: [ref('ProcedureRule')]},
                destroyed: {kind: 'boolean', retrieveServer: false},
                editOn: {kind: 'boolean', storeServer: false, keepLocal: true},
                importOn: {kind: 'boolean', storeServer: false, keepLocal: false},
                pruneOn: {kind: 'boolean', storeServer: false, keepLocal: false},
                releaseVersion: {kind: 'string', displayName: 'Release version'},
                selectedShowTab: {
                    kind: 'enum',
                    storeServer: false,
                    keepLocal: true,
                    displayName: 'Selected tab on the show procedure page'
                },
                selectedSettingTab: {
                    kind: 'enum',
                    storeServer: false,
                    keepLocal: true,
                    displayName: 'Selected tab on the show procedure page'
                },
                selectedRowIndex: {
                    kind: 'int',
                    storeServer: false,
                    keepLocal: true,
                    displayName: 'Selected row on procedure edit page'
                },
                ruleIds: {kind: [ref('ProcedureRule')], storeServer: false, keepLocal: true},
                serverCompileWarnings: {kind: ['string'], storeServer: false, retrieveServer: true},
                storeServer: {kind: 'boolean', storeServer: false, retrieveServer: false},
                _metadata: {
                    kind: 'object',
                    storeServer: false,
                    retrieveServer: true,
                    displayName: 'For now just permissions the user has on this template'
                },
                fieldSearchNodeIds: {kind: [ref('string')], storeServer: false, retrieveServer: true},
                schemas:  {kind: [ref('ProcedureSchema')]},
                includeSchema: {kind: 'boolean', storeServer: false, keepLocal: false}
            },
            factory: nodeFactory('ProcedureRoot', {
                name: 'Title',
                children: [],
                deleted: false,
                rules: [],
                compileWarnings: [],
                canComplete: true,
                editOn: true,
                schemas: []
            }),
            isRoot: true,
            viewUrl: '/procedure/',
            storeServer: true,
            storeDb: true,
            storeRedux: true,
            dependentNodePaths: {},
            incrementalLoad: {
                property: 'lastUpdatedDateTime',
                getParameter: 'updatedAfterDateTime'
            },
            processOrder: 4
        },
        ProcedureStep: {
            type: 'ProcedureStep',
            displayName: 'Step',
            properties: {
                ...SYSTEM_PROPERTIES,
                parentId: ref('ProcedureRoot'),
                name: {kind: 'string', displayName: 'Step Name', multiline: true},
                number: {
                    kind: 'string',
                    displayName: 'Row number (starting at 1)',
                    storeServer: false,
                    keepLocal: true
                },
                completeMode: {kind: 'enum', displayName: 'Sign Off Mode', values: COMPLETE_MODES_OPTIONS},
                statisticsMode: {kind: 'enum', displayName: 'Statistics Mode', values: STATISTICS_MODES_OPTIONS},
                statisticsModeEnabled: {kind: 'boolean', displayName: 'User editable', storeServer: false},
                ...VISIBLE_PROPERTIES,
                children: {kind: [ref('ProcedureTask')]},
                compileWarnings: {
                    kind: 'array',
                    displayName: 'Step level warnings',
                    storeServer: false,
                    apiClientDisagreementOn: false
                },
                ruleIds: {kind: [ref('ProcedureRule')], storeServer: false, keepLocal: true},
            },
            factory: nodeFactory('ProcedureStep', {
                name: 'New Step',
                children: [],
                completeMode: 'step',
                statisticsMode: STATISTICS_MODES.include.id,
                deleted: false,
                visible: 'visible'
            }),
            isRoot: false,
            storeServer: true,
            storeDb: true,
            storeRedux: true,
            dependentNodePaths: {
                parentId: ['deleted'],
                children: ['deleted', 'parentDeleted', 'statisticsMode']
            },
            processOrder: 3
        },
        ProcedureTask: {
            type: 'ProcedureTask',
            displayName: 'Task',
            properties: {
                ...SYSTEM_PROPERTIES,
                parentId: ref('ProcedureStep'),
                name: {kind: 'string', displayName: 'Task Name', multiline: true},
                number: {
                    kind: 'string',
                    displayName: 'Row number (starting at 1)',
                    storeServer: false,
                    keepLocal: true
                },
                statisticsMode: {kind: 'enum', displayName: 'Statistics Mode', values: STATISTICS_MODES_OPTIONS},
                statisticsModeEnabled: {kind: 'boolean', displayName: 'User editable', storeServer: false},
                parentDeleted: {kind: 'boolean', storeServer: false},
                ...VISIBLE_PROPERTIES,
                children: {kind: [ref('ProcedureQuestion')]},
                compileWarnings: {
                    kind: 'array',
                    displayName: 'Task level warnings',
                    storeServer: false,
                    visible: 'visible',
                    apiClientDisagreementOn: false
                },
                ruleIds: {kind: [ref('ProcedureRule')], storeServer: false, keepLocal: true},
            },
            factory: nodeFactory('ProcedureTask', {
                name: 'New Task',
                statisticsMode: STATISTICS_MODES.include.id,
                children: [],
                deleted: false
            }),
            isRoot: false,
            storeServer: true,
            storeDb: true,
            storeRedux: true,
            dependentNodePaths: {children: ['deleted', 'parentDeleted']},
            processOrder: 2
        },
        ProcedureQuestion: {
            type: 'ProcedureQuestion',
            displayName: 'Question',
            properties: {
                ...SYSTEM_PROPERTIES,
                parentId: ref('ProcedureTask'),
                name: {kind: 'string', displayName: 'Question Name', multiline: true},
                number: {
                    kind: 'string',
                    displayName: 'Row number (starting at 1)',
                    storeServer: false,
                    keepLocal: true
                },
                parentDeleted: {kind: 'boolean', storeServer: false},
                questionType: {
                    kind: 'enum',
                    displayName: 'Type',
                    values: QUESTION_TYPE_OPTIONS
                },
                format: {
                    kind: 'enum',
                    displayName: 'Format',
                },
                formatOptions: {
                    kind: ['enum'],
                    displayName: 'Format options',
                    storeServer: false
                },
                formatDisplay: {
                    kind: 'string',
                    displayName: 'Custom Number Format'
                },
                formatDisplayVisible: {
                    kind: 'string',
                    displayName: 'Custom number format visible',
                    storeServer: false
                },
                formatEnabled: {
                    kind: 'boolean',
                    displayName: 'Is the format question on. True when questionType = number',
                    storeServer: false
                },
                inputPattern: {
                    kind: 'string',
                    displayName: 'Input pattern to apply to the min/max field',
                    storeServer: false
                },
                textMultipleLines: {
                    kind: 'boolean',
                    displayName: 'Multiple lines',
                },
                textMultipleLinesEnabled: {
                    kind: 'boolean',
                    displayName: 'Can the user modify textMultipleLines. True when type = text',
                    storeServer: false
                },
                options: {
                    kind: 'string',
                    displayName: 'Options',
                    multiline: true,
                    rows: 5
                },
                optionsParsed: {
                    kind: ['string'],
                    displayName: 'Valid options',
                    storeServer: false
                },
                optionsEnabled: {
                    kind: 'boolean',
                    displayName: 'Is the options question on. True when questionType = select',
                    storeServer: false
                },
                selectMany: {
                    kind: 'boolean',
                    displayName: 'Select multiple options',
                },
                selectManyEnabled: {
                    kind: 'boolean',
                    displayName: 'Multiple options are allowed or not. True when questionType = select',
                    storeServer: false
                },
                selectRenderMode: {
                    kind: 'enum',
                    displayName: 'Display using',
                    values: SELECT_RENDER_MODE_OPTIONS,
                },
                selectRenderModeOptions: {
                    kind: ['enum'],
                    displayName: 'Display using',
                    storeServer: false
                },
                selectRenderModeEnabled: {
                    kind: 'boolean',
                    displayName: 'True when questionType = select',
                    storeServer: false, keepLocal: true
                },
                selectDataSource: {
                    kind: 'enum',
                    displayName: 'Options source',
                    values: SELECT_DATA_SOURCES_OPTIONS
                },
                selectDataSourceEnabled: {
                    kind: 'boolean',
                    displayName: 'True when questionType = select',
                    storeServer: false, keepLocal: true
                },
                validOptions: {
                    kind: ['string'],
                    displayName: 'Valid options',
                    multiline: true,
                    rows: 5
                },
                validOptionsEnabled: {
                    kind: 'boolean',
                    displayName: 'Is the validOptions question on. True when questionType = select',
                    storeServer: false
                },
                minInclusive: {
                    kind: 'number',
                    displayName: 'Min (Inclusive)'
                },
                minInclusiveEnabled: {
                    kind: 'boolean',
                    displayName: 'Is the options question on. True when questionType = number',
                    storeServer: false
                },
                minInclusivePattern: {
                    kind: 'string',
                    displayName: 'Min (Inclusive)',
                    storeServer: false
                },
                maxInclusive: {
                    kind: 'number',
                    displayName: 'Max (Inclusive)'
                },
                maxInclusiveEnabled: {
                    kind: 'boolean',
                    displayName: 'Is the options question on. True when questionType = number',
                    storeServer: false
                },
                maxInclusivePattern: {
                    kind: 'string',
                    displayName: 'Max (Inclusive)',
                    storeServer: false
                },
                warningMessage: {
                    kind: 'string',
                    displayName: 'Warning Message'
                },
                warningMessageEnabled: {
                    kind: 'boolean',
                    displayName: 'Is the options question on. True when questionType = number',
                    storeServer: false
                },
                fixMode: {
                    kind: 'enum',
                    displayName: 'Fix Mode',
                    values: FIX_MODES_OPTIONS
                },
                fixModeEnabled: {
                    kind: 'boolean',
                    displayName: 'Is the fixMode question on. True when questionType = number',
                    storeServer: false
                },
                fixModeResolveFlag: {
                    kind: 'boolean',
                    displayName: 'Fix Mode Resolve',
                    storeServer: false
                },
                messageType: {
                    kind: 'enum',
                    displayName: 'Message type',
                    values: MESSAGE_TYPE_OPTIONS
                },
                messageTypeEnabled: {
                    kind: 'boolean',
                    displayName: 'Is the messageType question on. True when questionType = message',
                    storeServer: false
                },
                messageRichTextEnabled: {
                    kind: 'boolean',
                    displayName: 'Is the messageType question on. True when questionType = message',
                    storeServer: false
                },
                comment: {kind: 'enum', displayName: 'Comment', values: CONDITIONAL_VALUES_OPTIONS},
                initialCommentInstructions: {
                    kind: 'string',
                    displayName: 'Comment Instructions'
                },
                initialCommentInstructionsEnabled: {
                    kind: 'boolean',
                    displayName: 'Is the options comment on. True when comment != none',
                    storeServer: false
                },
                resolvedCommentInstructions: {
                    kind: 'string',
                    displayName: 'Resolved Comment Instructions'
                },
                resolvedCommentInstructionsEnabled: {
                    kind: 'boolean',
                    displayName: 'Is the options comment on. True when comment != none',
                    storeServer: false
                },
                photo: {kind: 'enum', displayName: 'Attachment', values: CONDITIONAL_VALUES_OPTIONS},
                photoInstructions: {
                    kind: 'string',
                    displayName: 'Photo Instructions'
                },
                photoInstructionsEnabled: {
                    kind: 'boolean',
                    displayName: 'Is the options photoInstructionsE on. True when photo != none',
                    storeServer: false
                },
                attachmentType: {
                    kind: 'enum',
                    displayName: 'Attachment type',
                    values: ATTACHMENT_TYPE_OPTIONS
                },
                attachmentTypeEnabled: {
                    kind: 'boolean',
                    displayName: 'Is the options attachmentType on. True when photo != none',
                    storeServer: false
                },
                mobilePhotoMode: {
                    kind: 'enum',
                    displayName: 'Mobile camera mode',
                    values: MOBILE_CAMERA_MODES_OPTIONS
                },
                mobilePhotoModeEnabled: {
                    kind: 'boolean',
                    displayName: 'Is the options mobilePhotoMode on. True when photo != none',
                    storeServer: false
                },
                linkStyle: {
                    kind: 'enum',
                    displayName: 'Display using'
                },
                linkStyleOptions: {
                    kind: 'enum',
                    displayName: 'Link Style Options',
                    storeServer: false
                },
                linkStyleEnabled: {
                    kind: 'boolean',
                    displayName: 'Is link style required',
                    storeServer: false
                },
                linkRuleTree: {
                    kind: 'string',
                    displayName: 'Query builder tree data'
                },
                linkRuleQuery: {
                    kind: 'string',
                    displayName: 'Code executable version of the rule'
                },
                linkRuleHuman: {
                    kind: 'string',
                    displayName: 'Link Rule',
                    multiline: true
                },
                linkRuleEnabled: {
                    kind: 'boolean',
                    displayName: 'Is link filter required',
                    storeServer: false,
                },
                ...VISIBLE_PROPERTIES,
                unknown: {kind: 'enum', displayName: 'Unknown', values: UNKNOWN_VALUES},
                unknownVisible: {
                    kind: 'boolean',
                    displayName: 'Is link unknown available',
                    storeServer: false,
                },
                compileWarnings: {
                    kind: 'array',
                    displayName: 'Any issues with this question',
                    storeServer: false,
                    apiClientDisagreementOn: false
                },
                compileSuggestions: {
                    kind: 'array',
                    displayName: 'Any suggestions with this question',
                    storeServer: false
                },
                initialValue: {
                    kind: 'object',
                    displayName: 'Message content'
                },
                ruleIds: {kind: [ref('ProcedureRule')], storeServer: false, keepLocal: true},
                selectExecutionFilter: {
                    kind: 'object',
                    displayName: 'For worq item select the where clause to pass to the server',
                    storeServer: false,
                    storeDb: false
                },
            },
            factory: nodeFactory('ProcedureQuestion', {
                name: 'New Question',
                questionType: 'text',
                comment: 'optional',
                photo: 'optional',
                unknown: UNKNOWN_TYPES.notallowed.id,
                visible: 'visible',
                fixMode: 'none',
                attachmentType: 'photo',
                mobilePhotoMode: 'cameraonly',
                textMultipleLines: false
            }),
            isRoot: false,
            storeServer: true,
            storeDb: true,
            storeRedux: true,
            dependentNodePaths: {},
            processOrder: 1
        },
        ProcedureRule: {
            type: NODE_TYPE_OPTIONS.ProcedureRule,
            displayName: 'Rule',
            properties: {
                ...SYSTEM_PROPERTIES,
                parentId: ref('ProcedureRoot'),
                number: {kind: 'string', displayName: 'Rule number (starting at 1)', storeServer: false},
                name: {kind: 'string', displayName: 'Rule Name', storeServer: false},
                actionType: {kind: 'enum', displayName: 'Action', values: RULE_ACTION_TYPE_OPTIONS},
                // FOR
                selfOn: {kind: 'boolean', displayName: 'This worq item', storeServer: false},
                nodeIds: {kind: ['string'], displayName: 'Item(s)'},
                nodeIdsVisible: {kind: 'boolean', displayName: 'Is option visible', storeServer: false},
                linkMatchOn: {kind: 'boolean', nullFalse: true, displayName: "Linked work item(s)"},
                linkMatchLinkTypes: {kind: ['enum'], displayName: 'Link types', values: LINK_TYPE_OPTIONS},
                linkMatchProcedureIds: {kind: ['string'], displayName: 'Template'},
                // CONDITION
                alwaysOn: {kind: 'boolean', nullFalse: true, displayName: 'Always'},
                conditionOn: {kind: 'boolean', nullFalse: true, displayName: 'Condition'},
                conditionTree: {kind: 'string', displayName: 'Rule'},
                conditionQuery: {kind: 'string', displayName: 'Rule'},
                conditionHuman: {kind: 'string', displayName: 'Formula'},
                conditionHumanStored: {kind: 'string', displayName: 'Rule'},
                conditionError: {kind: 'string', displayName: 'Rule', storeServer: false, keepLocal: true},
                conditionStyle: {
                    kind: 'enum',
                    displayName: 'Editor',
                    values: JSON_LOGIC_EDITOR_OPTIONS,
                    storeServer: false,
                    keepLocal: false
                },
                numberMinOn: {kind: 'boolean', nullFalse: true, displayName: 'Number is less than', storeServer: false},
                numberMinOnVisible: {kind: 'boolean', displayName: 'Is option visible', storeServer: false},
                numberMaxOn: {
                    kind: 'boolean',
                    nullFalse: true,
                    displayName: 'Number is greater than',
                    storeServer: false
                },
                numberMaxOnVisible: {kind: 'boolean', displayName: 'Is option visible', storeServer: false},
                numberBetweenOn: {
                    kind: 'boolean',
                    nullFalse: true,
                    displayName: 'Number is between',
                    storeServer: false
                },
                numberBetweenOnVisible: {kind: 'boolean', displayName: 'Is option visible', storeServer: false},
                selectAnyOn: {
                    kind: 'boolean',
                    nullFalse: true,
                    displayName: 'Selected value is one of',
                    storeServer: false
                },
                selectAnyOnVisible: {kind: 'boolean', displayName: 'Is option visible', storeServer: false},
                // THEN
                messageOn: {kind: 'boolean', nullFalse: true, displayName: RULE_ACTION_TYPE.messageOn.name},
                messageOnVisible: {kind: 'boolean', displayName: 'Is option visible', storeServer: false},
                message: {kind: 'string', displayName: 'Message to display'},
                messageType: {
                    kind: 'enum',
                    displayName: 'Message type',
                    values: MESSAGE_TYPE_OPTIONS
                },
                invalidInputOn: {kind: 'boolean', nullFalse: true, displayName: RULE_ACTION_TYPE.invalidInputOn.name},
                implicitlyDeleted: {kind: 'boolean', displayName: 'Implicitly Deleted'},
                invalidInputOnVisible: {kind: 'boolean', displayName: 'Is option visible', storeServer: false},
                invalidInputMessage: {kind: 'string', displayName: 'Invalid input error message'},
                raiseIssueOn: {kind: 'boolean', nullFalse: true, displayName: RULE_ACTION_TYPE.raiseIssueOn.name},
                raiseIssueOnVisible: {kind: 'boolean', displayName: 'Is option visible', storeServer: false},
                raiseIssueResolveOn: {kind: 'boolean', nullFalse: true, displayName: 'Resolve required'},
                raiseIssueResolveOnVisible: {kind: 'boolean', displayName: 'Is option visible', storeServer: false},
                raiseIssueReAnswerOn: {kind: 'boolean', nullFalse: true, displayName: 'Answer again once resolved'},
                raiseIssueReAnswerOnVisible: {kind: 'boolean', displayName: 'Is option visible', storeServer: false},
                raiseIssueMessage: {kind: 'string', displayName: 'Warning message to display to user'},
                photoRequiredOn: {kind: 'boolean', nullFalse: true, displayName: RULE_ACTION_TYPE.photoRequiredOn.name},
                photoRequiredOnVisible: {kind: 'boolean', displayName: 'Is option visible', storeServer: false},
                photoInstructionsOn: {kind: 'boolean', nullFalse: true, displayName: RULE_ACTION_TYPE.photoInstructionsOn.name},
                photoInstructionsOnVisible: {kind: 'boolean', displayName: 'Is option visible', storeServer: false},
                photoInstructionsMessage: {kind: 'string', displayName: 'Instructions'},
                commentRequiredOn: {kind: 'boolean', nullFalse: true, displayName: RULE_ACTION_TYPE.commentRequiredOn.name},
                commentRequiredOnVisible: {kind: 'boolean', displayName: 'Is option visible', storeServer: false},
                commentInstructionsOn: {kind: 'boolean', nullFalse: true, displayName: RULE_ACTION_TYPE.commentInstructionsOn.name},
                commentInstructionsOnVisible: {kind: 'boolean', displayName: 'Is option visible', storeServer: false},
                commentInstructionsMessage: {kind: 'string', displayName: 'Instructions'},
                createExecutionOn: {kind: 'boolean', nullFalse: true, displayName:RULE_ACTION_TYPE.createExecutionOn.name },
                createExecutionOnVisible: {kind: 'boolean', displayName: 'Is option visible', storeServer: false},
                createExecutionProcedureId: ref('ProcedureRoot'),
                createExecutionLinkType: {kind: 'enum', displayName: 'Link Type', values: LINK_TYPE_SELECTABLE_OPTIONS},
                assignToOn: {kind: 'boolean', nullFalse: true, displayName: 'Assign to', storeServer: false},
                assignToOnVisible: {kind: 'boolean', displayName: 'Is option visible', storeServer: false},
                linkToQuestionOn: {kind: 'boolean', nullFalse: true, displayName: RULE_ACTION_TYPE.linkToQuestionOn.name},
                linkToQuestionOnVisible: {kind: 'boolean', displayName: 'Is option visible', storeServer: false},
                addExistingOn: {kind: 'boolean', nullFalse: true, displayName: 'Select an existing worq item'},
                addExistingOnVisible: {kind: 'boolean', displayName: 'Is option visible', storeServer: false},
                addNewOn: {kind: 'boolean', nullFalse: true, displayName: 'Add a new worq item'},
                addNewOnVisible: {kind: 'boolean', displayName: 'Is option visible', storeServer: false},
                calculateValueOn: {
                    kind: 'boolean',
                    nullFalse: true,
                    displayName: RULE_ACTION_TYPE.calculateValueOn.name
                },
                calculateValueOnVisible: {kind: 'boolean', displayName: 'Is option visible', storeServer: false},
                calculateValueQuery: {kind: 'string', displayName: 'Formula'},
                calculateValueHuman: {kind: 'string', displayName: 'Formula'},
                calculateValueHumanStored: {kind: 'string', displayName: 'Formula'},
                calculateValueError: {kind: 'string', displayName: 'Formula', storeServer: false},
                calculateValueQueryOptions: {kind: 'array', displayName: 'Options', storeServer: false},
                copyToOn: {kind: 'boolean', nullFalse: true, displayName: RULE_ACTION_TYPE.copyToOn.name},
                copyToOnVisible: {kind: 'boolean', displayName: 'Is option visible', storeServer: false},
                copyToNodeIds: {kind: ['string'], displayName: 'Copy to question'},
                visibleOn: {kind: 'boolean', nullFalse: true, displayName: RULE_ACTION_TYPE.visibleOn.name},
                visibleOnVisible: {kind: 'boolean', displayName: 'Is option visible', storeServer: false},
                actionTypesOn: {kind: ['string'], displayName: 'Action types that are enabled', storeServer: false},
                actionTypesAvailable: {
                    kind: ['string'],
                    displayName: 'List of action types that are available for the current context',
                    storeServer: false
                },
                draft: {kind: 'boolean', displayName: 'Rule not to be saved yet', storeServer: false},
                compileWarnings: {
                    kind: 'array',
                    displayName: 'Any issues with this question',
                    storeServer: false,
                    apiClientDisagreementOn: false
                },
                procedureOnly: {
                    kind: 'boolean',
                    displayName: 'Procedure configuration, not for execution',
                    storeServer: false,
                    retrieveServer: true
                },
                orderBy: {kind: 'string', displayName: 'Order by', notes: 'Column id to order by'},
                orderByDirection: {kind: 'enum', displayName: 'Order by', values: EXECUTION_ORDER_BY_DIRECTION_OPTIONS},
                permissions: {kind: ['enum'], displayName: 'Permissions', values: GRANT_DENY_PERMISSION_OPTIONS},
                procedureId: {kind: ['string'], displayName: 'Template for this column'},
                procedureName: {kind: ['string'], displayName: 'Template name for this column', storeServer: false},
                format: {kind: 'string', displayName: 'Used by custom key for zero fill'},
                formatVisible: {kind: 'boolean', displayName: 'if format is visible', storeServer: false},
                formatOptions: {kind: ['string'], displayName: 'format options', storeServer: false},
                collectionProcedureIds: {
                    kind: ['string'],
                    displayName: 'Templates available for this column',
                    storeServer: false
                },
                // OBSOLETE but leaving so existing offline data doesn't cause me a headache
                globalName: {kind: 'string', storeServer: false, displayName: 'Global Name'},
                messageDraft: {kind: 'string', storeServer: false, displayName: 'Global Name'},
                ruleIds: {kind: [ref('ProcedureRule')], storeServer: false, keepLocal: true},
            },
            isRoot: false,
            factory: nodeFactory(NODE_TYPE_OPTIONS.ProcedureRule, {nodeIds: [], selfOn: true, alwaysOn: true}),
            storeServer: true,
            storeDb: true,
            storeRedux: true,
            dependentNodePaths: {},
            processOrder: 5
        },
        ProcedureSchema: {
            type: 'ProcedureSchema',
            displayName: 'Schema',
            properties: {
                ...SYSTEM_PROPERTIES,
                parentId: ref('ProcedureRoot'),
                name: {kind: 'string', displayName: 'Schema Name'},
                schemaId: {kind: 'string', displayName: 'Schema Id'},
                properties: {kind: [ref('ProcedureSchemaProperty')]}
            },
            factory: nodeFactory('ProcedureSchema', {
                name: 'New Procedure Schema',
                properties: [],
                deleted: false
            }),
            isRoot: false,
            storeServer: true,
            storeDb: true,
            storeRedux: true,
            dependentNodePaths: {
                parentId: ['deleted'],
                children: ['deleted']
            },
            processOrder: 3
        },
        ProcedureSchemaProperty: {
            type: 'ProcedureSchemaProperty',
            displayName: 'Schema Property',
            properties: {
                ...SYSTEM_PROPERTIES,
                parentId: ref('ProcedureSchema'),
                elementName: {kind: 'string'},
                description: {kind: 'string', displayName: 'Property Description'},
                nodeId: ref('ProcedureQuestion'),
                source: {kind: 'string', storeServer: false},
                propertyType: {kind: 'string', displayName: 'Property Type'},
                questionType: {kind: 'string', displayName: 'Question Type'},
                format: {kind: 'string', displayName: 'Format'},
                proposed: {kind: 'boolean', displayName: 'Property has not been modified'}
            },
            factory: nodeFactory('ProcedureSchemaProperty', {
                elementName: 'New Schema Property',
                deleted: false,
            }),
            isRoot: false,
            storeServer: true,
            storeDb: true,
            storeRedux: true,
            dependentNodePaths: {},
            processOrder: 2
        },
        ExecutionRoot: {
            type: 'ExecutionRoot',
            displayName: 'Execution',
            properties: {
                ...RESOURCE_SYSTEM_PROPERTIES,
                processedFull: {kind: 'boolean', displayName: 'Node tree has been evaluated once', storeRedux: true, storeDb: false, storeServer: false, apiClientDisagreementOn: false},
                name: {kind: 'string', displayName: 'Name'},
                title: {kind: 'string', displayName: 'Title', storeServer: false, retrieveServer: true},
                category: {kind: 'string', displayName: 'Category'},
                procedureType: {kind: 'enum', displayName: 'Type', values: PROCEDURE_TYPE_OPTIONS},
                keyField1QuestionId: {
                    kind: 'string',
                    displayName: 'Key Field 1 Question Id',
                    storeServer: false,
                    retrieveServer: true
                },
                keyField1ExecutionQuestionId: {
                    kind: 'string',
                    displayName: 'Key Field 1 Execution Question Id',
                    storeServer: false,
                    fullOnly: true
                },
                keyField1Name: {
                    kind: 'string',
                    displayName: 'Key Field 1 Name',
                    storeServer: false,
                    retrieveServer: true
                },
                keyField1Value: {
                    kind: 'string',
                    displayName: 'Key Field 1 Value',
                    storeServer: false,
                    retrieveServer: true
                },
                keyField2QuestionId: {
                    kind: 'string',
                    displayName: 'Key Field 2 Question Id',
                    storeServer: false,
                    retrieveServer: true
                },
                keyField2ExecutionQuestionId: {
                    kind: 'string',
                    displayName: 'Key Field 2 Execution Question Id',
                    storeServer: false,
                    fullOnly: true
                },
                keyField2Name: {
                    kind: 'string',
                    displayName: 'Key Field 2 Name',
                    storeServer: false,
                    retrieveServer: true
                },
                keyField2Value: {
                    kind: 'string',
                    displayName: 'Key Field 2 Value',
                    storeServer: false,
                    retrieveServer: true
                },
                keyField3QuestionId: {
                    kind: 'string',
                    displayName: 'Key Field 3 Question Id',
                    storeServer: false,
                    retrieveServer: true
                },
                keyField3ExecutionQuestionId: {
                    kind: 'string',
                    displayName: 'Key Field 3 Execution Question Id',
                    storeServer: false,
                    fullOnly: true
                },
                keyField3Name: {
                    kind: 'string',
                    displayName: 'Key Field 3 Name',
                    storeServer: false,
                    retrieveServer: true
                },
                keyField3Value: {
                    kind: 'string',
                    displayName: 'Key Field 3 Value',
                    storeServer: false,
                    retrieveServer: true
                },
                keyField4QuestionId: {
                    kind: 'string',
                    displayName: 'Key Field 4 Question Id',
                    storeServer: false,
                    retrieveServer: true
                },
                keyField4ExecutionQuestionId: {
                    kind: 'string',
                    displayName: 'Key Field 4 Execution Question Id',
                    storeServer: false,
                    fullOnly: true
                },
                keyField4Name: {
                    kind: 'string',
                    displayName: 'Key Field 4 Name',
                    storeServer: false,
                    retrieveServer: true
                },
                keyField4Value: {
                    kind: 'string',
                    displayName: 'Key Field 4 Value',
                    storeServer: false,
                    retrieveServer: true
                },
                keyField5QuestionId: {
                    kind: 'string',
                    displayName: 'Key Field 5 Question Id',
                    storeServer: false,
                    retrieveServer: true
                },
                keyField5ExecutionQuestionId: {
                    kind: 'string',
                    displayName: 'Key Field 5 Execution Question Id',
                    storeServer: false,
                    fullOnly: true
                },
                keyField5Name: {
                    kind: 'string',
                    displayName: 'Key Field 5 Name',
                    storeServer: false,
                    retrieveServer: true
                },
                keyField5Value: {
                    kind: 'string',
                    displayName: 'Key Field 5 Value',
                    storeServer: false,
                    retrieveServer: true
                },
                fields: {
                    kind: 'object',
                    displayName: 'Key fields displayed in table',
                    storeServer: false,
                    retrieveServer: true
                },
                titleTemplate: {kind: 'string', displayName: 'Title template', fullOnly: true},
                procedureId: ref('ProcedureRoot'),
                projectId: ref('ProjectRoot'),
                preview: {kind: 'boolean', displayName: 'Preview mode', storeServer: false, fullOnly: true},
                draft: {kind: 'boolean', displayName: 'Draft mode', storeServer: false, fullOnly: true},
                children: {kind: [ref('ExecutionStep')], fullOnly: true},
                rules: {kind: [ref('ExecutionRule')], fullOnly: true},
                completed: {kind: 'boolean', storeServer: false, retrieveServer: true},
                completedDate: {kind: 'date', storeServer: false, retrieveServer: true},
                completedQuestionCount: {kind: 'integer', storeServer: false, retrieveServer: true},
                totalQuestionCount: {kind: 'integer', storeServer: false, retrieveServer: true},
                completedSignoffCount: {kind: 'integer', storeServer: false, retrieveServer: true},
                totalSignoffCount: {kind: 'integer', storeServer: false, retrieveServer: true},
                completedRatio: {kind: 'float', displayName: '', storeServer: false, retrieveServer: true},
                totalPhotoCount: {kind: 'integer', storeServer: false, retrieveServer: true},
                warningCount: {kind: 'integer', storeServer: false, retrieveServer: true},
                feature: {kind: 'object', storeServer: false, retrieveServer: true},
                status: {
                    kind: 'enum',
                    displayName: 'Type',
                    values: EXECUTION_STATUS_OPTIONS,
                    storeServer: false,
                    retrieveServer: true
                },
                selectedStepIndex: {kind: 'int', storeServer: false, keepLocal: true, fullOnly: true},
                treeViewToggleState: {kind: 'boolean', storeServer: false, keepLocal: true, fullOnly: true},
                treViewSelectedState: {kind: ['string'], storeServer: false, keepLocal: true, fullOnly: true},
                treeViewExpandedState: {kind: ['string'], storeServer: false, keepLocal: true, fullOnly: true},
                links: {kind: [ref('ExecutionLink')], storeServer: false, retrieveServer: true, fullOnly: true},
                assignments: {
                    kind: [ref('ExecutionAssignment')],
                    storeServer: false,
                    retrieveServer: true,
                    fullOnly: true
                },
                denormalised: {
                    kind: 'boolean',
                    storeServer: false,
                    displayName: 'Has the data been denormalised',
                    fullOnly: true
                },
                key: {kind: 'string'},
                parents: {
                    kind: ['object'],
                    storeServer: false,
                    retrieveServer: true,
                    displayName: 'List of parent nodes'
                },
                destroyed: {kind: 'boolean', retrieveServer: false},
                storeServer: {kind: 'boolean', storeServer: false, retrieveServer: false},
                scopeReturnFields: {kind: ['string'], storeServer: false, retrieveServer: false, summaryOnly: true},
                maxLinkLastUpdatedDateTime: {
                    kind: 'string',
                    storeServer: false,
                    retrieveServer: false,
                    keepLocal: true,
                    displayName: 'Used to determine when we need to reload to get fresh scoped data.',
                    fullOnly: true
                },
                resourceDependencies: {
                    ...ref('ExecutionResourceDependencies'),
                    storeServer: false,
                    storeDb: true,
                    storeRedux: true,
                    fullOnly: true,
                    apiClientDisagreementOn: false
                },
                ruleIds: {...ref(['ExecutionRule']), storeServer: false, keepLocal: true, fullOnly: true},
                selectDynamicQuestionIds: {
                    ...ref(['ExecutionQuestion']),
                    storeServer: false,
                    keepLocal: true,
                    fullOnly: true,
                    storeDb: false
                },
                queryQuestionIds: {
                    ...ref(['ExecutionQuestion']),
                    storeServer: false,
                    keepLocal: true,
                    fullOnly: true,
                    storeDb: false
                },
                procedureReleaseVersion: {kind: 'string', fullOnly: true},
                source: {...ref('ExecutionSource'), fullOnly: true},
                _metadata: {
                    kind: 'object',
                    storeServer: false,
                    retrieveServer: true,
                    displayName: 'For now just permissions the user has on this template'
                },
                selectedCategory: {
                    kind: 'int',
                    storeServer: false,
                    keepLocal: true,
                    displayName: 'Selected category when displaying legacy table'
                },
                navigationStyle: {
                    kind: 'string',
                    storeServer: false,
                    keepLocal: true
                },
                activeChildren: {
                    kind: ['string'],
                    storeServer: false,
                    keepLocal: true
                },
                newLinksForLoad: {
                    kind: ['string'],
                    storeServer: false,
                    keepLocal: true,
                    storeDb: false,
                    displayName: 'Recently created links'
                },
                reloadInterval: {
                    kind: 'int',
                    storeServer: false,
                    keepLocal: true
                },
                completeAccess: {
                    ...ref('NodeCompleteAccess'),
                    storeServer: false,
                    storeDb: false,
                    storeRedux: false,
                    apiClientDisagreementOn: false
                },
                disabled: {kind: 'int', displayName: 'Node disabled', storeServer: false, storeDb: false},
            },
            factory: nodeFactory('ExecutionRoot', {
                children: [],
                links: [],
                assignments: [],
                rules: [],
            }),
            isRoot: true,
            viewUrl: '/procedure/',
            storeServer: true,
            storeDb: true,
            storeRedux: true,
            dependentNodePaths: {
                links: ['preview']
            },
            incrementalLoad: {
                property: 'lastUpdatedDateTime',
                getParameter: 'updatedAfterDateTime'
            },
            processOrder: 9
        },
        ExecutionStep: {
            type: 'ExecutionStep',
            displayName: 'Step',
            properties: {
                ...SYSTEM_PROPERTIES,
                processedFull: {kind: 'boolean', displayName: 'Node tree has been evaluated once', storeRedux: true, storeDb: false, storeServer: false, apiClientDisagreementOn: false},
                parentId: ref('ExecutionRoot'),
                name: {kind: 'string', displayName: 'Step Name'},
                procedureStepId: ref('ProcedureStep'),
                completeMode: {kind: 'enum', displayName: 'Sign Off Mode', values: COMPLETE_MODES_OPTIONS},
                visibleMode: {kind: 'enum', displayName: 'Visible', values: VISIBLE_MODE_OPTIONS},
                statisticsMode: {kind: 'enum', displayName: 'Statistics Mode', values: STATISTICS_MODES_OPTIONS},
                allQuestionsCompleted: {kind: 'boolean', storeServer: false, retrieveServer: true},
                warningCount: {kind: 'integer', storeServer: false, retrieveServer: true},
                completed: {kind: 'boolean', storeServer: false, retrieveServer: true},
                completeEnabled: {kind: 'boolean', storeServer: false},
                completedDate: {kind: 'date', storeServer: false, retrieveServer: true},
                userCompleted: {kind: 'object'},
                completedQuestionCount: {kind: 'int', storeServer: false, retrieveServer: true},
                totalQuestionCount: {kind: 'int', storeServer: false, retrieveServer: true},
                completedSignoffCount: {kind: 'int', storeServer: false, retrieveServer: true},
                totalSignoffCount: {kind: 'int', storeServer: false, retrieveServer: true},
                totalPhotoCount: {kind: 'integer', storeServer: false, retrieveServer: false},
                preview: {kind: 'boolean', displayName: 'Preview mode', storeServer: false},
                draft: {kind: 'boolean', displayName: 'Draft mode', storeServer: false},
                children: {kind: [ref('ProcedureTask')]},
                visible: {kind: 'boolean'},
                visibleRuleQuery: {kind: 'string'},
                assignments: {
                    kind: [ref('ExecutionAssignment')],
                    storeServer: false,
                    keepLocal: true,
                    comment: 'De-normalised from ExecutionRoot in UI for assignment purposes'
                },
                completedRatio: {kind: 'float', displayName: '', storeServer: false},
                denormalised: {kind: 'boolean', storeServer: false, displayName: 'Has the data been denormalised'},
                ruleIds: {...ref(['ExecutionRule']), storeServer: false, keepLocal: true},
                completeAccess: {
                    ...ref('NodeCompleteAccess'),
                    storeServer: false,
                    storeDb: false,
                    storeRedux: false,
                    apiClientDisagreementOn: false
                },
                disabled: {kind: 'int', displayName: 'Node disabled', storeServer: false, storeDb: false},
            },
            factory: nodeFactory('ExecutionStep', {
                children: [],
                warningCount: 0,
                allQuestionsCompleted: false,
                deleted: false,
                completed: false
            }),
            isRoot: false,
            storeServer: true,
            storeDb: true,
            storeRedux: true,
            dependentNodePaths: {
                'parentId': ['visible', 'deleted', 'parentId', 'completed', 'completedDate', 'allQuestionsCompleted', 'completedQuestionCount', 'totalQuestionCount', 'completedSignoffCount', 'totalSignoffCount', 'warningCount', 'totalPhotoCount', 'canView'],
                'children': ['visible', 'completeMode']
            },
            processOrder: 8
        },
        ExecutionTask: {
            type: 'ExecutionTask',
            displayName: 'Task',
            properties: {
                ...SYSTEM_PROPERTIES,
                processedFull: {kind: 'boolean', displayName: 'Node tree has been evaluated once', storeRedux: true, storeDb: false, storeServer: false, apiClientDisagreementOn: false},
                columnWidth: {kind: 'int', displayName: 'Width of columns for questions', storeRedux: true, storeDb: false, storeServer: false},
                parentId: ref('ExecutionStep'),
                name: {kind: 'string', displayName: 'Task Name'},
                procedureTaskId: ref('ProcedureTask'),
                visibleMode: {kind: 'enum', displayName: 'Visible', values: VISIBLE_MODE_OPTIONS},
                statisticsMode: {kind: 'enum', displayName: 'Statistics Mode', values: STATISTICS_MODES_OPTIONS},
                allQuestionsCompleted: {kind: 'boolean', storeServer: false, retrieveServer: true},
                warningCount: {kind: 'integer'},
                completed: {kind: 'boolean', storeServer: false, retrieveServer: true},
                completeEnabled: {kind: 'boolean', storeServer: false},
                completedDate: {kind: 'date', storeServer: false, retrieveServer: true},
                userCompleted: {kind: 'object'},
                completedQuestionCount: {kind: 'int', storeServer: false, retrieveServer: true},
                totalQuestionCount: {kind: 'int', storeServer: false, retrieveServer: true},
                completedSignoffCount: {kind: 'int', storeServer: false, retrieveServer: true},
                totalSignoffCount: {kind: 'int', storeServer: false, retrieveServer: true},
                totalPhotoCount: {kind: 'integer', storeServer: false, retrieveServer: false},
                preview: {kind: 'boolean', displayName: 'Preview mode', storeServer: false},
                draft: {kind: 'boolean', displayName: 'Draft mode', storeServer: false},
                children: {kind: [ref('ExecutionQuestion')]},
                visible: {kind: 'boolean'},
                visibleRuleQuery: {kind: 'string'},
                assignments: {
                    kind: [ref('ExecutionAssignment')],
                    storeServer: false,
                    keepLocal: true,
                    comment: 'De-normalised from ExecutionRoot in UI for assignment purposes'
                },
                completedRatio: {kind: 'float', displayName: '', storeServer: false},
                denormalised: {kind: 'boolean', storeServer: false, displayName: 'Has the data been denormalised'},
                ruleIds: {...ref(['ExecutionRule']), storeServer: false, keepLocal: true},
                completeAccess: {
                    ...ref('NodeCompleteAccess'),
                    storeServer: false,
                    storeDb: false,
                    storeRedux: false,
                    apiClientDisagreementOn: false
                },
                disabled: {kind: 'int', displayName: 'Node disabled', storeServer: false, storeDb: false},
            },
            factory: nodeFactory('ExecutionTask', {
                children: [],
                allQuestionsCompleted: false,
                deleted: false,
                completed: false
            }),
            isRoot: false,
            storeServer: true,
            storeDb: true,
            storeRedux: true,
            dependentNodePaths: {
                'parentId': ['visible', 'deleted', 'parentId', 'completed', 'completedDate', 'allQuestionsCompleted', 'completedQuestionCount', 'totalQuestionCount', 'completedSignoffCount', 'totalSignoffCount', 'warningCount', 'totalPhotoCount'],
                'children': ['visible']
            },
            processOrder: 7
        },
        ExecutionQuestion: {
            type: 'ExecutionQuestion',
            displayName: 'Task',
            properties: {
                ...SYSTEM_PROPERTIES,
                processed: {kind: 'boolean', displayName: 'Node has been evaluated once', storeRedux: true, storeDb: false, storeServer: false, apiClientDisagreementOn: false},
                parentId: ref('ExecutionTask'),
                name: {kind: 'string', displayName: 'Question Name'},

                // DATA COPIED PROCEDURE
                procedureQuestionId: ref('ProcedureTask'),
                questionType: {
                    kind: 'enum',
                    displayName: 'Type',
                    values: QUESTION_TYPE_OPTIONS
                },
                format: {
                    kind: 'enum',
                    displayName: 'Format',
                    values: FORMAT_OPTIONS
                },
                formatDisplay: {
                    kind: 'string',
                    displayName: 'Custom number format'
                },
                addButton: {
                    kind: 'string',
                    displayName: 'Query question add button label',
                    storeServer: false,
                    storeDb: false
                },
                inputPattern: {
                    kind: 'string',
                    displayName: 'Input pattern to apply to the input field',
                    storeServer: false,
                    storeDb: false
                },
                textMultipleLines: {
                    kind: 'boolean', nullFalse: true,
                    displayName: 'Multiple lines',
                },
                selectMany: {
                    kind: 'boolean', nullFalse: true,
                    displayName: 'Multiple options',
                },
                selectRenderMode: {
                    kind: 'enum',
                    displayName: 'Display using',
                    values: SELECT_RENDER_MODE_OPTIONS
                },
                selectDataSource: {
                    kind: 'enum',
                    displayName: 'Options source',
                    values: SELECT_DATA_SOURCES_OPTIONS
                },
                selectExecutionFilter: {
                    kind: 'string',
                    displayName: 'For worq item select the where clause to pass to the server',
                    storeServer: false,
                    storeDb: false
                },
                options: {
                    kind: 'string',
                    displayName: 'Options',
                    apiClientDisagreementOn: false,
                },
                optionsParsed: {
                    kind: ['string'],
                    displayName: 'Valid options',
                    storeServer: false,
                    storeDb: false
                },
                validOptions: {
                    kind: ['string'],
                    displayName: 'Valid options'
                },
                minInclusive: {
                    kind: 'number',
                    displayName: 'Min (Inclusive)'
                },
                maxInclusive: {
                    kind: 'number',
                    displayName: 'Max (Inclusive)'
                },
                warningMessage: {
                    kind: 'string',
                    displayName: 'Warning Message'
                },
                fixModeResolveFlag: {
                    kind: 'boolean', nullFalse: true,
                    displayName: 'Fix Mode Resolve',
                },
                fixModeCommentFlag: {
                    kind: 'boolean', nullFalse: true,
                    displayName: 'Fix Mode Comment',
                },
                fixModePhotoFlag: {
                    kind: 'boolean', nullFalse: true,
                    displayName: 'Fix Mode Photo',
                },
                messageType: {
                    kind: 'enum',
                    displayName: 'Message type',
                    values: MESSAGE_TYPE_OPTIONS
                },
                attachmentType: {
                    kind: 'enum',
                    displayName: 'Attachment type',
                    values: ATTACHMENT_TYPE_OPTIONS
                },
                mobilePhotoMode: {
                    kind: 'enum',
                    displayName: 'Mobile camera mode',
                    values: MOBILE_CAMERA_MODES_OPTIONS
                },
                commentMode: {kind: 'enum', displayName: 'Comment', values: CONDITIONAL_VALUES_OPTIONS},
                initialCommentInstructions: {
                    kind: 'string',
                    displayName: 'Comment Instructions'
                },
                resolvedCommentInstructions: {
                    kind: 'string',
                    displayName: 'Resolved Comment Instructions'
                },
                photoInstructions: {kind: 'string', displayName: 'Photo Instructions'},
                photoMode: {kind: 'enum', displayName: 'Photo', values: CONDITIONAL_VALUES_OPTIONS},
                unknownMode: {kind: 'enum', displayName: 'Unknown', values: UNKNOWN_VALUES},
                visibleMode: {kind: 'enum', displayName: 'Visible', values: VISIBLE_MODE_OPTIONS},
                linkStyle: {
                    kind: 'enum',
                    displayName: 'Link style',
                    values: PROCEDURE_LINK_STYLE_OPTIONS
                },
                linkRuleQuery: {
                    kind: 'string',
                    displayName: 'Code executable version of the rule'
                },
                linkedExecutionIds: {kind: [ref('ExecutionRoot')], storeServer: false, retrieveServer: true},
                linkedAddOptions: {
                    kind: 'object',
                    displayName: 'List of allowed executions that may be created and added',
                    apiClientDisagreementOn: false,
                    storeServer: false,
                    retrieveServer: false,
                },
                initialValue: {kind: 'object'},
                initialValueStoreDb: {kind: 'boolean', storeServer: false, storeDb: false},
                initialValueFormatted: {
                    kind: 'string',
                    displayName: 'Formatted version of initialValue',
                    storeServer: false,
                    retrieveServer: true,
                    storeDb: false
                },
                initialValueInvalidReason: {kind: 'string', storeServer: false, storeDb: false},
                initialValueValid: {kind: 'boolean', storeServer: false, storeDb: false},
                initialValueEnabled: {kind: 'boolean', storeServer: false, storeDb: false},
                initialValueUnknown: {kind: 'boolean', nullFalse: true},
                initialValueUnknownEnabled: {kind: 'boolean', storeServer: false, storeDb: false},
                initialValueByUser: {kind: 'string', displayName: 'User that last modified the initialValue'},
                initialValueDateTime: {kind: 'date', displayName: 'Date when initialValue was last modified'},
                initialValueCoordinates: {kind: 'Coordinates', displayName: 'Location when value answered'},
                initialValueReadOnly: {
                    kind: 'boolean', nullFalse: true,
                    displayName: 'When the question is controlled by a rule this will be true',
                    storeServer: false,
                    retrieveServer: true,
                    storeDb: false
                },
                initialCommentRequested: {
                    kind: 'string',
                    nullFalse: true,
                    displayName: 'User has requested to add comment'
                },
                initialCommentHideAction: {
                    kind: 'string',
                    displayName: 'User cannot show/hide comment',
                    storeServer: false,
                    storeDb: false
                },
                initialCommentRequestedEnabled: {kind: 'boolean', storeServer: false, storeDb: false},
                initialComment: {kind: 'string', displayName: 'Question Comment'},
                initialCommentEnabled: {kind: 'boolean', storeServer: false, storeDb: false},
                initialCommentRequired: {kind: 'boolean', storeServer: false, storeDb: false},
                initialCommentByUser: {kind: 'string', displayName: 'User that last modified the initialComment'},
                initialCommentDateTime: {kind: 'date', displayName: 'Date when initialComment was last modified'},
                initialCommentCoordinates: {kind: 'Coordinates', displayName: 'Location when value answered'},
                initialPhotoIds: {kind: [ref('Photo')]},
                initialPhotoIdsEnabled: {kind: 'boolean', storeServer: false, storeDb: false},
                initialPhotoIdsRequired: {kind: 'boolean', storeServer: false, storeDb: false},

                issueType: {kind: 'enum', displayName: 'Issue Type', values: ISSUE_TYPE_OPTIONS},
                issueDescription: {kind: 'string', displayName: 'Issue Description'},
                issueResolution: {kind: 'enum', displayName: 'Issue Resolution', values: ISSUE_RESOLUTION_TYPE_OPTIONS},

                escalatedFlag: {kind: 'boolean', nullFalse: true},
                escalatedComment: {kind: 'string', displayName: 'Escalated Comment'},
                escalatedCommentByUser: {kind: 'string', displayName: 'User that last modified the escalatedComment'},
                escalatedCommentDateTime: {kind: 'date', displayName: 'Date when escalatedComment was last modified'},
                escalatedCommentCoordinates: {
                    kind: 'Coordinates',
                    displayName: 'Location when escalatedComment was last modified'
                },
                escalatedToName: {kind: 'string', displayName: 'Escalated To Name'},
                escalatedToNameByUser: {kind: 'string', displayName: 'User that last modified the escalatedToName'},
                escalatedToNameDateTime: {kind: 'date', displayName: 'Date when escalatedToName was last modified'},
                escalatedToNameCoordinates: {
                    kind: 'Coordinates',
                    displayName: 'Location when escalatedComment was last modified'
                },

                resolvedEnabled: {kind: 'boolean', storeServer: false, storeDb: false},
                resolvedValue: {kind: 'object'},
                resolvedValueFormatted: {
                    kind: 'string',
                    displayName: 'Formatted version of resolvedValue',
                    storeServer: false,
                    retrieveServer: true,
                    storeDb: false
                },
                resolvedValueInvalidReason: {kind: 'string', storeServer: false, storeDb: false},
                resolvedValueEnabled: {kind: 'boolean', storeServer: false, storeDb: false},
                resolvedValueUnknown: {kind: 'boolean', nullFalse: true},
                resolvedValueUnknownEnabled: {kind: 'boolean', storeServer: false, storeDb: false},
                resolvedValueByUser: {kind: 'string', displayName: 'User that last modified the initialValue'},
                resolvedValueDateTime: {kind: 'date', displayName: 'Date when initialValue was last modified'},
                resolvedValueCoordinates: {kind: 'Coordinates', displayName: 'Location when value answered'},
                resolvedCommentRequested: {
                    kind: 'string',
                    nullFalse: true,
                    displayName: 'User has requested to add comment'
                },
                resolvedCommentHideAction: {
                    kind: 'string',
                    displayName: 'User cannot show/hide comment',
                    storeServer: false,
                    storeDb: false
                },
                resolvedCommentRequestedEnabled: {kind: 'boolean', nullFalse: true, storeServer: false, storeDb: false},
                resolvedComment: {kind: 'string', displayName: 'Resolved Comment'},
                resolvedCommentEnabled: {kind: 'boolean', storeServer: false, storeDb: false},
                resolvedCommentRequired: {kind: 'boolean', storeServer: false, storeDb: false},
                resolvedCommentByUser: {kind: 'string', displayName: 'User that last modified the initialComment'},
                resolvedCommentDateTime: {kind: 'date', displayName: 'Date when initialComment was last modified'},
                resolvedCommentCoordinates: {kind: 'Coordinates', displayName: 'Location when value answered'},
                resolvedPhotoIds: {kind: [ref('Photo')]},
                resolvedPhotoIdsEnabled: {kind: 'boolean', storeServer: false, storeDb: false},
                resolvedPhotoIdsRequired: {kind: 'boolean', storeServer: false, storeDb: false},

                notAnIssueComment: {kind: 'string', displayName: 'Not an issue Comment'},
                notAnIssueCommentEnabled: {kind: 'boolean', storeServer: false, storeDb: false},
                notAnIssueCommentByUser: {kind: 'string', displayName: 'User that last modified the initialComment'},
                notAnIssueCommentDateTime: {kind: 'date', displayName: 'Date when initialComment was last modified'},
                notAnIssueCommentCoordinates: {kind: 'Coordinates', displayName: 'Location when value answered'},

                completed: {kind: 'boolean', storeServer: false, retrieveServer: true},
                completedDate: {kind: 'date', storeServer: false, retrieveServer: true},
                preview: {kind: 'boolean', displayName: 'Preview mode', storeServer: false},
                draft: {kind: 'boolean', displayName: 'Draft mode', storeServer: false},
                visible: {kind: 'boolean'},
                visibleRuleQuery: {kind: 'string'},
                totalPhotoCount: {kind: 'integer', storeServer: false, retrieveServer: false},
                invalidRuleIds: {...ref(['ExecutionRule'])},
                calculateRuleIds: {...ref(['ExecutionRule'])},
                ruleIds: {...ref(['ExecutionRule']), storeServer: false, keepLocal: true, storeDb: false},
                denormalised: {kind: 'boolean', storeServer: false, displayName: 'Has the data been denormalised'},
                // holds resolved value when available, else holds initial value
                finalValue: {kind: 'object', storeServer: false, storeDb: false},
                finalValueFormatted: {kind: 'object', storeServer: false, storeDb: false},
                notCompletedReasons: {kind: ['string'], storeServer: false},
                completeAccess: {
                    ...ref('NodeCompleteAccess'),
                    storeServer: false,
                    storeDb: false,
                    storeRedux: false,
                    apiClientDisagreementOn: false
                },
                disabled: {kind: 'int', displayName: 'Node disabled', storeServer: false, storeDb: false},
                columnWidth: {
                    kind: 'int',
                    displayName: 'Width of columns for questions',
                    storeRedux: true,
                    storeDb: false,
                    storeServer: false
                },
            },
            factory: nodeFactory('ExecutionQuestion', {
                initialValue: null,
                initialPhotoIds: [],
                resolvedPhotoIds: [],
                deleted: false,
            }),
            isRoot: false,
            storeServer: true,
            storeDb: true,
            storeRedux: true,
            dependentNodePaths:
                {
                    parentId: ['completed', 'completedDate', 'issueType', 'visible', 'deleted', 'parentId', 'totalPhotoCount']
                },
            processOrder: 6
        },
        ExecutionLink: {
            type: 'ExecutionLink',
            displayName: 'Link',
            properties: {
                ...SYSTEM_PROPERTIES,
                // TODO Remove when client no longer has any
                parentId: {...ref('ExecutionRoot'), storeServer: false},
                linkType: {kind: 'enum', displayName: 'Link Type', values: LINK_TYPE_SELECTABLE_OPTIONS},
                toNodeId: ref('ExecutionRoot'),
                toNodeTitle: {kind: 'string'},
                toNodeKey: {kind: 'string'},
                toNodeProcedureId: ref('ProcedureRoot'),
                toNodeName: {kind: 'string'},
                preview: {kind: 'boolean', displayName: 'Preview mode', storeServer: false},
                draft: {kind: 'boolean', displayName: 'Draft mode', storeServer: false},
                ruleIds: {
                    kind: [ref('ExecutionRule')],
                    displayName: 'Link is managed by these rules. e.g. auto link rule, auto create rule, execution select rule.',
                    storeServer: false,
                    retrieveServer: true
                },
                activeRuleIds: {
                    kind: [ref('ExecutionRule')],
                    displayName: 'These rules are actively managing this link',
                    storeServer: false,
                    keepLocal: true
                },
            },
            isRoot: false,
            factory: nodeFactory('ExecutionLink', {}),
            storeServer: true,
            storeDb: true,
            storeRedux: true,
            dependentNodePaths: {},
            processOrder: 12
        },
        ExecutionAssignment: {
            type: 'ExecutionAssignment',
            displayName: 'Assignment',
            properties: {
                ...SYSTEM_PROPERTIES,
                // TODO Remove when client no longer has any
                parentId: {...ref('ExecutionRoot'), storeServer: false},
                assignmentType: {kind: 'string', displayName: 'Assignment Type'},
                assignedNodeId: {kind: 'string', displayName: 'Assigned Node'},
                entityId: ref('GroupRoot'),
                entityName: {kind: 'string'},
                entityType: {kind: 'enum', displayName: 'Entity Type', values: ASSIGNMENT_ENTITY_TYPE_OPTIONS},
                preview: {kind: 'boolean', displayName: 'Preview mode', storeServer: false},
                draft: {kind: 'boolean', displayName: 'Draft mode', storeServer: false},
                ruleIds: [ref('ExecutionRule')],
            },
            isRoot: false,
            factory: nodeFactory('ExecutionAssignment', {}),
            storeServer: true,
            storeDb: true,
            storeRedux: true,
            dependentNodePaths: {},
            processOrder: 11
        },
        ExecutionRule: {
            type: 'ExecutionRule',
            displayName: 'Rule',
            properties: {
                ...SYSTEM_PROPERTIES,
                // TODO Remove when client no longer has any
                parentId: {...ref('ExecutionRoot'), storeServer: false},
                actionType: {kind: 'enum', displayName: 'Action', values: RULE_ACTION_TYPE_OPTIONS},
                // FOR
                procedureRuleId: ref('ProcedureRule'),
                nodeIds: {kind: ['string'], displayName: 'Node (Step/Task/Question) the rule is applied to'},
                // WHEN
                alwaysOn: {kind: 'boolean', nullFalse: true, displayName: 'Always'},
                linkMatchOn: {kind: 'boolean', nullFalse: true, displayName: 'Match links'},
                linkMatchLinkTypes: {kind: ['string'], displayName: 'Link types'},
                linkMatchProcedureIds: {kind: ['string'], displayName: 'Template'},
                conditionOn: {kind: 'boolean', nullFalse: true, displayName: 'Condition'},
                conditionQuery: {kind: 'string', displayName: 'Rule'},
                conditionQueryPartial: {
                    kind: 'string',
                    displayName: 'Partially evaluated query used by filter',
                    storeServer: false
                },
                // THEN
                invalidInputOn: {kind: 'boolean', nullFalse: true, displayName: 'Make input invalid'},
                invalidInputMessage: {kind: 'string', displayName: 'Invalid input error message'},
                raiseIssueOn: {kind: 'boolean', nullFalse: true, displayName: 'Raise an issue'},
                raiseIssueResolveOn: {kind: 'boolean', nullFalse: true, displayName: 'Resolve'},
                raiseIssueReAnswerOn: {kind: 'boolean', displayName: 'Answer after resolved'},
                raiseIssueMessage: {kind: 'string', displayName: 'Message to display to user'},
                photoRequiredOn: {kind: 'boolean', nullFalse: true, displayName: 'Make photo required'},
                photoInstructionsOn: {kind: 'boolean', displayName: 'Display photo instructions'},
                photoInstructionsMessage: {kind: 'string', displayName: 'Instructions'},
                commentRequiredOn: {kind: 'boolean', nullFalse: true, displayName: 'Make comment required'},
                commentInstructionsOn: {kind: 'boolean', nullFalse: true, displayName: 'Display photo instructions'},
                commentInstructionsMessage: {kind: 'string', displayName: 'Instructions'},
                createExecutionOn: {kind: 'boolean', nullFalse: true, displayName: 'Automatically create a ...'},
                createExecutionProcedureId: ref('ProcedureRoot'),
                createExecutionLinkType: {kind: 'enum', displayName: 'Link Type', values: LINK_TYPE_SELECTABLE_OPTIONS},
                createExecutionIds: {kind: ['string'], displayName: 'Executions created via this rule'},
                createExecutionStopCode: {
                    kind: 'enum',
                    displayName: 'Stop Code',
                    values: EXECUTION_CREATE_STOP_CODE_OPTIONS
                },
                evaluatedValue: {kind: 'string'},
                linkToQuestionOn: {kind: 'boolean', nullFalse: true, displayName: 'Display in table'},
                addExistingOn: {kind: 'boolean', nullFalse: true, displayName: 'Select existing'},
                addNewOn: {kind: 'boolean', displayName: 'Add new'},
                evaluatedValueDateTime: {kind: 'date'},
                calculateValueOn: {kind: 'boolean', nullFalse: true, displayName: 'Calculate'},
                calculateValueQuery: {kind: 'string', displayName: 'Formula'},
                calculateValue: {kind: 'object', displayName: 'Calculated value'},
                calculateValueDateTime: {kind: 'object', displayName: 'Date/Time when calculated value last changed'},
                calculateNodeIds: {
                    ...ref(['ExecutionQuestion']),
                    storeServer: false,
                    displayName: 'List of nodes calculated by this rule.'
                },
                copyToOn: {kind: 'boolean', displayName: 'Copy'},
                copyToNodeIds: {kind: ['string'], displayName: 'Procedure Id of the question to be copied into'},
                messageOn: {kind: 'boolean', nullFalse: true, displayName: 'Display a message'},
                message: {kind: 'string', displayName: 'Message to display'},
                messageType: {
                    kind: 'enum',
                    displayName: 'Message type',
                    values: MESSAGE_TYPE_OPTIONS
                },
                visibleOn: {kind: 'boolean', nullFalse: true, displayName: 'Show/Hide'},
                orderByDirection: {kind: 'enum', displayName: 'Order by', values: EXECUTION_ORDER_BY_DIRECTION_OPTIONS},
                procedureId: {kind: 'string', displayName: 'Template for this column'},
                format: {kind: 'string', displayName: 'Used by custom key for zero fill'},
                preview: {kind: 'boolean', displayName: 'Preview mode', storeServer: false},
                draft: {kind: 'boolean', displayName: 'Draft mode', storeServer: false},
                denormalised: {kind: 'boolean', storeServer: false, displayName: 'Has the data been denormalised'},
                finalValue: {kind: 'object', storeServer: false, displayName: 'Value seen by rule'},
                ruleIds: {...ref(['ExecutionRule']), storeServer: false, keepLocal: true},
            },
            isRoot: false,
            factory: nodeFactory('ExecutionRule', {evaluatedValue: null}),
            storeServer: true,
            storeDb: true,
            storeRedux: true,
            dependentNodePaths: {},
            processOrder: 10
        },
        Photo: {
            type: 'Photo',
            displayName: 'Photo',
            properties: {
                ...RESOURCE_SYSTEM_PROPERTIES,
                convertedUrl: {kind: 'string'},
                photoCaptureId: ref('PhotoCapture', {storeServer: false, keepLocal: true}),
                photoCaptureDeleteStarted: {kind: 'bool', storeDb: false, storeServer: false, keepLocal: true},
                projectId: ref('ProjectRoot'),
                executionId: ref('ExecutionRoot'),
                executionQuestionId: ref('ExecutionQuestion'),
                preview: {kind: 'boolean'},
                executionTitle: {kind: 'string'},
                executionName: {kind: 'string'},
                executionKey: {kind: 'string'},
                executionQuestionName: {kind: 'string'},
                procedureId: {kind: 'string'},
                procedureQuestionId: {kind: 'string'},
                propertyName: {kind: 'string'},
                filename: {kind: 'string'},
                photoTakenDate: {kind: 'date'},
                thumbnailData: {kind: 'base64'},
                thumbnailWidth: {kind: 'integer'},
                thumbnailHeight: {kind: 'integer'},
                thumbnailUrl: {kind: 'string'},
                thumbnailInMemoryUrl: {kind: 'string', storeDb: false, storeServer: false, keepLocal: true},
                thumbnailLoaded: {kind: 'boolean', storeDb: false, storeServer: false},
                thumbnailSize: {kind: 'integer'},
                largeWidth: {kind: 'integer'},
                largeHeight: {kind: 'integer'},
                largeUrl: {kind: 'string'},
                largeSize: {kind: 'integer'},
                largeInMemoryUrl: {kind: 'string', storeDb: false, storeServer: false, keepLocal: true},
                originalData: {kind: 'base64', storeServer: false},
                originalWidth: {kind: 'integer'},
                originalHeight: {kind: 'integer'},
                originalUrl: {kind: 'string'},
                originalInMemoryUrl: {kind: 'string', storeDb: false, storeServer: false, keepLocal: true},
                originalSize: {kind: 'integer'},
                coordinates: {kind: 'object', displayName: 'Location when value answered'},
                orientation: {kind: 'object', displayName: 'Device orientation when photo was taken'},
                isSelected: {kind: 'boolean', storeDb: false, storeServer: false},
                isDeleting: {kind: 'boolean', storeDb: false, storeServer: false},
                isImage: {kind: 'boolean', storeDb: false, storeServer: false},
                markup: {kind: 'object'},
                uploadCompletedBytes: {kind: 'integer', storeDb: false, storeServer: false},
                uploadTotalBytes: {kind: 'integer', storeDb: false, storeServer: false},
            },
            factory: nodeFactory('Photo', {}),
            isRoot: true,
            storeServer: true,
            storeServerPreview: true,
            storeDb: true,
            storeDbDeleted: false,
            storeRedux: true,
            storeServerUnsavedDeleted: false,
            dependentNodePaths: {},
            incrementalLoad: {
                property: 'lastUpdatedDateTime',
                getParameter: 'updatedAfterDateTime'
            }
        },
        PhotoCapture: {
            type: 'PhotoCapture',
            displayName: 'PhotoCapture',
            comment: 'As photo data is large keeping it out of redux.',
            properties: {
                ...SYSTEM_PROPERTIES,
                photoId: ref('Photo'),
                projectId: ref('ProjectRoot'),
                executionId: ref('ExecutionRoot'),
                executionQuestionId: ref('ExecutionQuestion'),
                propertyName: {kind: 'string'},
                filename: {kind: 'string'},
                photoTakenDate: {kind: 'date'},
                originalData: {kind: 'base64', storeServer: false},
                thumbnailData: {kind: 'base64', storeServer: false},
                coordinates: {kind: 'object', displayName: 'Location when value answered'},
                orientation: {kind: 'object', displayName: 'Device orientation when photo was taken'},
                redux: {kind: 'bool'},
            },
            factory: nodeFactory('PhotoCapture', {}),
            isRoot: true,
            storeServer: false,
            storeDb: true,
            storeDbDeleted: false,
            storeRedux: false,
            dependentNodePaths: {}
        },
        ProjectRoot: {
            type: 'ProjectRoot',
            displayName: 'Project',
            properties: {
                ...RESOURCE_SYSTEM_PROPERTIES,
                name: {kind: 'string', displayName: 'Name'},
                designerName: {kind: 'string', displayName: 'Designer Name'},
                designerEmail: {kind: 'email', displayName: 'Designer Email'},
                designerPhoneNumber: {kind: 'telephone', displayName: 'Designer Phone Number'},
                assignedGroups: {kind: [ref('GroupRoot')], displayName: 'Assigned Groups'},
                offline: {kind: 'boolean', displayName: 'Offline', storeServer: false, keepLocal: true},
                descriptionDraft: {kind: 'string', displayName: 'Project description'},
                selectedCategory: {kind: 'int', storeServer: false, keepLocal: true},
                selectedFilter: {kind: 'string', storeServer: false, keepLocal: true},
                displayDeletedExecutions: {kind: 'bool', storeServer: false, keepLocal: true},
                projectStatistics: {kind: 'object', storeServer: false, retrieveServer: true},
                status: {kind: 'string', storeServer: false},
            },
            factory: nodeFactory('ProjectRoot', {name: '', deleted: false}),
            isRoot: true,
            viewUrl: '/project/',
            storeServer: true,
            storeDb: true,
            storeRedux: true,
            dependentNodePaths: {}
        },
        ProjectStatistics: {
            // Note: Currently leaving as object on ProjectRoot and will not appear as root in redux
            type: 'ProjectStatistics',
            properties: {
                projectId: {kind: 'string'},
                completed: {kind: 'boolean'},
                completedDate: {kind: 'date', storeServer: false, keepLocal: true},
                completedQuestionCount: {kind: 'int', storeServer: false, keepLocal: true},
                totalQuestionCount: {kind: 'int', storeServer: false, keepLocal: true},
                completedSignoffCount: {kind: 'int', storeServer: false, keepLocal: true},
                totalSignoffCount: {kind: 'int', storeServer: false, keepLocal: true},
                totalExecutionCount: {kind: 'int', storeServer: false, keepLocal: true},
                completedExecutionCount: {kind: 'int', storeServer: false, keepLocal: true},
                totalPhotoCount: {kind: 'int', storeServer: false, keepLocal: true},
                procedureLastUpdatedDateTime: {kind: 'date', storeServer: false, keepLocal: true},
                completedRatio: {kind: 'float', storeServer: false, keepLocal: true},
                status: {kind: 'string'},
            },
            isRoot: false,
        },
        CreateExecutionsRoot: {
            type: 'CreateExecutionsRoot',
            properties: {
                ...SYSTEM_PROPERTIES,
                projectId: ref('ProjectRoot'),
                procedureId: ref('ProcedureRoot', {displayName: 'ITP'}),
                processedProcedureId: ref('ProcedureRoot', {displayName: 'ITP'}),
                procedureCount: {kind: 'integer'},
                taskName: {kind: 'string'},
                signOffNodeId: {kind: 'string', displayName: 'Node to signoff'},
                signOffEnabled: {kind: 'boolean', displayName: 'Sign-off Task'},
                completeFlag: {kind: 'boolean', displayName: 'Sign-off'},
                submit: {kind: 'boolean', displayName: 'Submit', notes: 'True will kick of the create rules'},
                children: {kind: [ref('CreateExecutionsQuestion')]},
                businessErrors: {kind: ['string'], notes: 'Displayed to user when invalid scenario.'},
                createdExecutionIds: {
                    kind: [ref('ExecutionsRoot')],
                    notes: 'List of executions created by this builder'
                },
            },
            factory: nodeFactory('CreateExecutionsRoot', {
                children: [],
                procedureCount: 0,
                submit: false,
                deleted: false,
                createdExecutionIds: [],
            }),
            isRoot: true,
            storeServer: false,
            storeDb: false,
            storeRedux: true,
            dependentNodePaths: {}
        },
        CreateExecutionsQuestion: {
            type: 'CreateExecutionsQuestion',
            properties: {
                ...SYSTEM_PROPERTIES,
                parentId: ref('CreateExecutionsRoot'),
                procedureQuestionId: ref('ProcedureQuestionId'),
                fromValue: {kind: 'dynamic'},
                fromValueKind: {kind: 'string'},
                fromValueMin: {kind: 'integer'},
                fromValueMax: {kind: 'integer'},
                fromValueName: {kind: 'string'},
                fromValueOptions: [{kind: 'string'}],
                toValue: {kind: 'dynamic'},
                toValueKind: {kind: 'string'},
                toValueName: {kind: 'string'},
                toValueEnabled: {kind: 'boolean'},
                toValueMin: {kind: 'integer'},
                toValueMax: {kind: 'integer'},
                toValueOptions: [{kind: 'string'}],
            },
            factory: nodeFactory('CreateExecutionsQuestion', {deleted: false, fromValue: null, toValue: null}),
            isRoot: false,
            storeServer: false,
            storeDb: false,
            storeRedux: true,
            dependentNodePaths: {}
        },
        ExecutionPreview: {
            type: 'ExecutionPreview',
            about: 'Used on procedure to live preview an execution',
            properties: {
                ...SYSTEM_PROPERTIES,
                procedureId: ref('ProcedureRoot', {displayName: 'ITP'}),
                executionId: ref('ExecutionRoot', {displayName: 'Execution'}),
                created: {kind: 'boolean', displayName: 'Has it created yet', storeServer: false},
            },
            factory: nodeFactory('ExecutionPreview', {
                procedureId: null,
                executionId: null,
            }),
            isRoot: true,
            storeServer: false,
            storeDb: false,
            storeRedux: true,
            dependentNodePaths: {},
            maxRunPerStateChange: 2
        },
        ExecutionLinkNew: {
            type: 'ExecutionLinkNew',
            properties: {
                ...SYSTEM_PROPERTIES,
                procedureId: ref('ProcedureRoot', {displayName: 'ITP'}),
                executionId: ref('ExecutionRoot', {displayName: 'Execution'}),
                fromExecutionId: ref('ExecutionRoot', {displayName: 'Execution'}),
                fromExecutionIds: [ref('ExecutionRoot', {displayName: 'Execution'})],
                projectId: {kind: 'string', displayName: 'Project Id'},
                linkType: {kind: 'string', displayName: 'Link Type'},
                preview: {kind: 'boolean', displayName: 'Preview mode', storeServer: false},
                submit: {kind: 'boolean', displayName: 'Submit', notes: 'True will kick of the create rules'},
                cancel: {kind: 'boolean', displayName: 'Cancel', notes: 'True will kick abort the draft execution'},
                submitOnDone: {
                    kind: 'boolean',
                    displayName: 'submitOnDone',
                    notes: 'Automatically submit when execution is done'
                },
                source: ref('ExecutionSource'),
                inline: {kind: 'boolean', displayName: 'Inline', notes: 'Will auto-submit execution once available'},
            },
            factory: nodeFactory('ExecutionLinkNew', {
                procedureId: null,
                executionId: null
            }),
            isRoot: true,
            storeServer: false,
            storeDb: false,
            storeRedux: true,
            dependentNodePaths: {}
        },
        ExecutionSource: {
            type: 'ExecutionSource',
            properties: {
                createdFromExecutionId: {kind: 'string', displayName: 'Created from Execution'},
                createdFromRuleId: {kind: 'string', displayName: 'Created from Rule'},
                kind: {kind: 'enum', displayName: 'Source Kind', values: EXECUTION_SOURCE_OPTIONS},
            }
        },
        ExecutionResourceDependencies: {
            type: 'ExecutionResourceDependencies',
            properties: {
                executionListPaths: {kind: ['string'], displayName: 'Paths of Execution Lists needed by the Execution'},
                projectIds: {kind: ['string'], displayName: 'Project IDs the Execution belongs to'},
                procedureIds: {kind: ['string'], displayName: 'IDs of Procedures that the Execution is dependent to'},
                photoPaths: {kind: ['string'], displayName: 'Paths of Photos that the Execution uses'},
                loading: {kind: 'boolean', displayName: 'Whether or not at least one dependency is still loading'},
                loaded: {kind: 'boolean', displayName: 'Whether or not all dependencies have loaded'},
                loadingError: {kind: 'boolean', displayName: 'Whether or not at least one dependency had a loading error'},
            },
        },
        NodeCompleteAccess: {
            type: 'NodeCompleteAccess',
            properties: {
                completed: {kind: 'boolean', display: 'Completed'},
                completedUserName: {kind: 'string', displayName: 'Completed by user name'},
                completedUserEmail: {kind: 'string', displayName: 'Completed by user email'},
                completedDate: {kind: 'int', displayName: 'Date completed'},
                completeEnabled: {kind: 'boolean', displayName: 'Complete is enabled'},
                canComplete: {kind: 'boolean', displayName: 'Can complete'},
                canCancel: {kind: 'boolean', displayName: 'Can cancel'},
                completeMode: {kind: 'enum', displayName: 'Complete mode', values: COMPLETE_MODES_OPTIONS},
                allQuestionsCompleted: {kind: 'boolean', displayName: 'All questions completed'},
                nextStepIndex: {kind: 'int', displayName: 'Index for next step'},
                previousStepIndex: {kind: 'int', displayName: 'Index for previous step'},
                completeActionStyle: {kind: 'enum', displayName: 'Complete action style', values: COMPLETE_ACTION_STYLES_OPTIONS},
                nextStepOnComplete: {kind: 'boolean', displayName: 'Move to next step on complete'},
                nextTaskIndex: {kind: 'int', displayName: 'Index for next task'},
                completeLabels: {kind: 'object', displayName: 'Complete labels'},
                navigateNextOnComplete: {kind: 'boolean', displayName: 'Move to next node on complete'},
                disabled: {kind: 'boolean', displayName: 'Node disabled'},
            },
            storeServer: false,
            retrieveServer: false,
            storeDb: true,
            apiClientDisagreementOn: false,
            dependentNodePaths: {}
        },
        ResourceSync: {
            type: 'ResourceSync',
            properties: {
                ...RESOURCE_SYSTEM_PROPERTIES,
                resourcePath: {kind: 'string'},
                nodeType: {kind: 'string'},
                filter: {kind: 'string'},
                reloadIntervalSeconds: {kind: 'integer'},
                nodeIds: {kind: ['string']},
                itemCount: {kind: 'integer'},
                /**
                 * This has the impact of:
                 * - On reload, only loading the root and not all children
                 * - Full refresh every 7 days, not every hour
                 * - Throttled loading only when indexeddb free, tab active (TODO)
                 */
                offline: {kind: 'boolean', displayName: 'A special type of ResourceSync to load many scopes at once'},
                hasNextPage: {kind: 'boolean'},
                total: {kind: 'integer'},
                pageSize: {kind: 'integer'},
                pageNumber: {kind: 'integer'},
                includeSchema: {kind: 'boolean'}
            },
            factory: nodeFactory('ResourceSync', {deleted: false, nodeIds: null}),
            isRoot: true,
            storeServer: false,
            storeDb: true,
            storeRedux: true,
            dependentNodePaths: {}
        },
        GroupRoot: {
            type: 'GroupRoot',
            properties: {
                ...SYSTEM_PROPERTIES,
                name: {kind: 'string'}
            },
            isRoot: true,
            storeServer: false,
            storeDb: true,
            storeRedux: true,
            dependentNodePaths: {}
        },
        UserSettings: {
            type: 'UserSettings',
            properties: {
                ...SYSTEM_PROPERTIES,
                selectedProcedureTabIndex: {kind: 'integer'},
                recentExecutionsTabIndex: {kind: 'integer'},
                fastReloadOn: {kind: 'boolean', displayName: 'Fast Reload'},
                diagnosticMode: {kind: 'string', obsolete: true},
                lastUserActivityDateTime: {kind: 'int'},
                showExecutionProperties: {kind: 'boolean', displayName: 'Execution Properties'},
                showGlobalLinks: {kind: 'boolean'},
            },
            factory: nodeFactory('UserSettings', {deleted: false}),
            isRoot: true,
            storeServer: false,
            storeDb: true,
            storeRedux: true,
            dependentNodePaths: []
        },
        UserDevice: {
            type: 'UserDevice',
            properties: {
                ...SYSTEM_PROPERTIES,
                assignmentsOffline: {kind: 'boolean', displayName: 'Download assigned items'},
                assignmentsOfflineCompleted: {kind: 'boolean', displayName: 'Assignments downloaded'},
                assignmentsOfflineState: {kind: 'object', displayName: 'Download progress'},
                mapsOffline: {kind: 'boolean', displayName: 'Download maps'},
                mapsOfflineState: {kind: 'object', displayName: 'Number of assignments'},
                cachedAssignments: {kind: 'array'},
                cachingRunning: {kind: 'boolean', storeDb: false},
                procedureView: {kind: 'object'},
                procedureExecutionView: {kind: 'object'},
                lastUsedExecutionAddOption: {kind: 'string'},
                offlineExecutions: {kind: 'object'},
                offlineResources: {kind: 'object'},
                internetAvailable: {kind: 'boolean'},
                lastIndexeddbClearedDateTime: {kind: 'string'},
                indexeddbClearedAge: {kind: 'int'},
                diagnosticMode: {kind: 'string'},
                troubleshootOn: {kind: 'boolean'},
                saveRunning: {kind: 'boolean', storeDb: false},
            },
            factory: nodeFactory('UserDevice', {deleted: false, mapsOfflineState: {}, cachedAssignments: []}),
            isRoot: true,
            storeServer: false,
            storeDb: true,
            storeRedux: true,
            dependentNodePaths: []
        },
        User: {
            type: 'User',
            properties: {
                ...SYSTEM_PROPERTIES,
                userId: {kind: 'string'},
                name: {kind: 'string'},
                email: {kind: 'string'},
                auditName: {kind: 'string'},
                roles: {kind: ['string']},
                groups: {kind: ['string']},
                permissions: {kind: ['string']},
                permissionsMap: {kind: 'object'},
                clientId: {kind: 'string'},
                tenantId: {kind: 'string'},
                executionId: {kind: 'string'},
                tokenExpired: {kind: 'boolean'},
                renewTokenAfterDate: {kind: 'int'},
                tokenExpiredDate: {kind: 'int'},
                tokenIssuedDate: {kind: 'int'},
                lastRenewAttemptedDate: {kind: 'int'},
                anonymous: {kind: 'boolean'}
            },
            factory: nodeFactory('User', {deleted: false}),
            isRoot: true,
            storeServer: false,
            storeDb: true,
            storeRedux: true,
            dependentNodePaths: {}
        },
        Coordinates: {
            type: 'Coordinates',
            properties: {
                ...SYSTEM_PROPERTIES,
                mode: {kind: 'string'},
                latitude: {kind: 'float'},
                longitude: {kind: 'float'},
                accuracy: {kind: 'float'},
                altitude: {kind: 'float'},
                altitudeAccuracy: {kind: 'float'},
                heading: {kind: 'float'},
                speed: {kind: 'float'},
                timestamp: {kind: 'date'}
            },
            factory: nodeFactory('Coordinates'),
            isRoot: false,
            storeServer: false,
            storeDb: true,
            storeRedux: true,
            dependentNodePaths: {}
        },
        Location: {
            type: 'Location',
            properties: {
                ...SYSTEM_PROPERTIES,
                mode: {kind: 'string'},
                position: {kind: 'object'},
                feature: {kind: 'object', displayName: 'Location using feature data type. Null if none.'}
            },
            factory: nodeFactory('Location', {deleted: false}),
            isRoot: true,
            storeServer: false,
            storeDb: false,
            storeRedux: true,
            dependentNodePaths: {},
        },
        NodeView: {
            type: 'NodeView',
            properties: {
                ...SYSTEM_PROPERTIES,
                viewedDateTime: {kind: 'date'},
                viewType: {kind: 'string'},
                viewedId: {kind: 'string'},
            },
            factory: nodeFactory('NodeView', {deleted: false}),
            isRoot: true,
            storeServer: true,
            storeDb: true,
            storeRedux: true,
            discardAfterSave: true,
            silentSave: true,
            dependentNodePaths: {},
        },
        NodeAssignment: {
            type: 'NodeAssignment',
            displayName: 'NodeAssignment',
            properties: {
                ...RESOURCE_SYSTEM_PROPERTIES,
                rootTitle: {kind: 'string'},
                assignedDateTime: {kind: 'string'},
                assignmentType: {kind: 'string'},
                clientId: {kind: 'string'},
                entityId: {kind: 'string'},
                entityType: {kind: 'string'},
                nodeId: {kind: 'string'},
                nodeTitle: {kind: 'string'},
                nodeType: {kind: 'string'},
                procedureId: {kind: 'string'},
                procedureType: {kind: 'string'},
                executionId: {kind: 'string'},
                rootKey: {kind: 'string'},
                rootType: {kind: 'string'},
                status: {kind: 'string'},
                feature: {kind: 'object'},
                parentIds: {
                    kind: 'array',
                },
                parents: {
                    kind: 'array',
                },
            },
            factory: nodeFactory('NodeAssignment'),
            isRoot: true,
            storeServer: false,
            storeDb: true,
            storeRedux: true,
            dependentNodePaths: {}
        },
        NodeActivity: {
            type: 'NodeActivity',
            displayName: 'NodeActivity',
            properties: {
                ...RESOURCE_SYSTEM_PROPERTIES,
                activityDateTime: {kind: 'string'},
                activityType: {kind: 'string'},
                activityTitle: {kind: 'string'},
                clientId: {kind: 'string'},
                nodeId: {kind: 'string'},
                nodeTitle: {kind: 'string'},
                nodeType: {kind: 'string'},
                procedureId: {kind: 'string'},
                procedureType: {kind: 'string'},
                rootId: {kind: 'string'},
                rootKey: {kind: 'string'},
                rootType: {kind: 'string'},
                rootTitle: {kind: 'string'},
                user: {kind: 'object'},
                userId: {kind: 'string'},
                parentIds: {
                    kind: 'array',
                },
                parents: {
                    kind: 'array',
                },
            },
            factory: nodeFactory('NodeAssignment'),
            isRoot: true,
            storeServer: false,
            storeDb: true,
            storeRedux: true,
            dependentNodePaths: {},
            incrementalLoad: {
                property: 'activityDateTime',
                getParameter: 'since'
            }
        },
        ExecutionSelector: {
            type: 'ExecutionSelector',
            properties: {
                ...SYSTEM_PROPERTIES,
                searchTerm: {kind: 'string'},
                location: {kind: 'object'},
                mentions: {kind: 'object'},
            },
            isRoot: true,
            storeServer: false,
            storeDb: true,
            storeRedux: true,
            dependentNodePaths: {},
            factory: nodeFactory('ExecutionSelector', {}),
        },
        ExecutionListPage: {
            type: 'ExecutionListPage',
            properties: {
                ...SYSTEM_PROPERTIES,
                procedureId: {kind: 'string'},
                procedureType: {kind: 'string'},
                questionId: {kind: 'string'},
                searchTerm: {kind: 'string'},
                pageNumber: {kind: 'string'},
                selectedViewId: {kind: 'string'},
                selectedViewToDefault: {kind: 'bool'},
                filter: {kind: 'string'},
                filterMode: {kind: 'string'},
                sortMode: {kind: 'string'},
                filterModel: {kind: 'object'},
                sortModel: {kind: 'object'},
                additionalFilter: {kind: 'object'},
                orderBy: {kind: 'string'},
                orderByDirection: {kind: 'string'},
                boundingBox: {kind: 'object'},
                mapAvailable: {kind: 'boolean', notes: 'When true the page supports a map view'},
                mapSearchAvailable: {kind: 'boolean', notes: 'When true the page search this area'},
                showMap: {kind: 'boolean', notes: 'When true use has requested to see the map view'},
                includeDeleted: {kind: 'boolean', notes: 'When true also show deleted items'},
                listScrollTop: {kind: 'string'},
                mapExpanded: {kind: 'boolean'},
                focusedItemId: {kind: 'string'},
                loadUrl: {kind: 'string'},
                isLoading: {kind: 'boolean', notes: 'True when results are being loaded'},
                columns: {kind: ['object']},
                views: {kind: ['object']},
                executionIds: {kind: ['string']},
                total: {kind: 'integer'},
                totalTitle: {kind: 'string'},
                recentlyCreated: {kind: ['object']},
                pivot: {kind: 'boolean', notes: 'For pivot table', keepLocal: true, storeServer: false},
                columnsVisibility: {kind: 'object', notes: 'Visible and loaded columns', keepLocal: true, storeServer: false},
                selectedRowIds: {kind: ['string']},
                checkboxSelection: {kind: 'bool'},
                availableActions: {kind: 'object'},
                viewingPageNumber: {kind: 'integer'},
                pivotSettings: {
                    kind: 'object',
                    notes: 'For persisting pivot table settings',
                },
                queryFilter: {kind: 'object', displayName: 'For query table filters to pass to the server'},
                viewingPageSize: {kind: 'integer'},
                linkTree: {kind: 'boolean'},
                tree: {kind: 'object'},
                ancestryMap: {kind: 'object'},
            },
            isRoot: true,
            storeServer: false,
            storeDb: true,
            storeRedux: true,
            dependentNodePaths: {},
            factory: nodeFactory('ExecutionListPage', {
                pageNumber: 1,
                orderBy: 'created',
                listScrollTop: 0,
                mapExpanded: true
            }),
        },
        MyAssignmentPage: {
            type: 'MyAssignmentPage',
            properties: {
                ...SYSTEM_PROPERTIES,
                listScrollTop: {kind: 'string'},
                mapExpanded: {kind: 'boolean'}
            },
            isRoot: true,
            storeServer: false,
            storeDb: true,
            storeRedux: true,
            dependentNodePaths: {},
            factory: nodeFactory('MyAssignmentPage', {
                listScrollTop: 0,
                mapExpanded: false
            }),
        },
        MapView: {
            type: 'MapView',
            properties: {
                ...SYSTEM_PROPERTIES,
                bounds: {kind: 'object'},
                zoom: {kind: 'integer'},
                layer: {kind: 'string'}
            },
            isRoot: true,
            storeServer: false,
            storeDb: true,
            storeRedux: true,
            dependentNodePaths: {},
            factory: nodeFactory('MapView', {
                bounds: null,
                zoom: null,
                layer: null
            }),
        },
        ProcedureConfig: {
            type: 'ProcedureConfig',
            properties: {
                ...SYSTEM_PROPERTIES,
                name: {kind: 'string'},
                can: {kind: 'object'},
                createScopes: {kind: ['integer']},
                listScopes: {kind: ['string']},
                deleteScopes: {kind: ['string']},
                fieldSearchNodeIds: {kind: ['string']}
            },
            isRoot: true,
            storeServer: false,
            storeDb: false,
            storeRedux: false,
            dependentNodePaths: {},
            factory: nodeFactory('ProcedureConfig', {
                bounds: null,
                zoom: null,
                layer: null
            }),
        },
        ClientConfig: CLIENT_CONFIG_SCHEMA
    }
};
const executionRootFullToSummaryFieldIds = Object.entries(GRAPH_INITIAL_STATE.schema.ExecutionRoot.properties)
    .filter(([id, prop]) => prop.fullOnly !== true && prop.summaryOnly !== true && !RESOURCE_SYSTEM_PROPERTIES[id] && id !== 'fields')
    .map(([id]) => id)
nop(GRAPH_INITIAL_STATE.schema);


class GraphProcessingError extends Error {
}

const domainRules = {
    "ResourceSync": domainRuleNOP,
    "GroupRoot": domainRuleNOP,
    User: USER_DOMAIN_RULES,
    "UserDevice": {
        ...domainRuleNOP,
        onPut: (state, node, beforeState, raisedAction, update) => {
            if (!node.offlineExecutions) {
                node.offlineExecutions = {};
            }
            if (!node.offlineResources) {
                node.offlineResources = {
                    procedureIds: []
                };
            }
            const beforeNode = getNodeOrNull(beforeState, node.id)
            for (let entry of Object.values(node.offlineExecutions)) {
                if (entry.onImplicit == null) {
                    entry.onImplicit = false;
                }
                if (entry.onImplicit) {
                    const parent = node.offlineExecutions[entry.parentId]
                    entry.on = parent?.on || false;
                }
            }
            let entries = Object.entries(node.offlineExecutions);


            let executionEntries = entries
                .filter(([, data]) => data?.on)
                .map(([id, data]) => ({id: id, ...data, dirty: false}));

            for (let i = 0; i < executionEntries.length; i++) {
                const entry = executionEntries[i];
                delete executionEntries[entry.id]
                let id = entry.id;
                let before = node.offlineExecutions[id]
                const parentId = entry.parentId || before?.parentId;
                addDependency(update, {
                    from: id,
                    to: node,
                    properties: ['loadedFull', 'links', 'loaded', 'loading', 'loadingError', 'lastUpdatedDateTime']
                });

                const parent = node.offlineExecutions[parentId];
                let execution = getNodeOrNull(state, id);
                let executionOffline = {
                    id: id,
                    on: true,
                    onImplicit: before?.onImplicit == null ? true : before?.onImplicit,
                    procedureIds: [],
                    photoPaths: [],
                    executionListPaths: [],
                    projectIds: [],
                    loading: false,
                    loaded: before?.loaded || false,
                    loadingError: null,
                    parentId: parentId,
                    status: execution?.status,
                    rootId: parent?.rootId ?? id,
                    autoOffWhenDone: entry.autoOffWhenDone
                };

                if(executionOffline.autoOffWhenDone === undefined) {
                    executionOffline.autoOffWhenDone = executionOffline.id === executionOffline.rootId && execution?.status !== EXECUTION_STATUS.done.id;
                }

                node.offlineExecutions[id] = executionOffline;
                if (!execution) {
                    if (before != null) {
                        executionOffline = before;
                    }
                    executionOffline.on = true;
                    node.offlineExecutions[id] = executionOffline;
                    continue;
                }
                executionOffline.on = true;
                executionOffline.loaded = true;

                if(isRootNodeDirty(state, id)) {
                    executionOffline.dirty = true;
                    if(node.offlineExecutions[executionOffline.rootId]) {
                        node.offlineExecutions[executionOffline.rootId].dirty = true;
                    }
                }

                if (execution.loadedFull) {
                    // As we only keep the root in redux this will only run when loaded in full
                    node.offlineExecutions[id] = executionOffline;

                    // Templates
                    const usedProcedureResult = getDependentTemplateIds(state, id);
                    for (let procedureId of usedProcedureResult.procedureIds) {
                        executionOffline.procedureIds.push(procedureId);
                    }
                    for (let procedureId of usedProcedureResult.preloadProcedureIds) {
                        executionOffline.procedureIds.push(procedureId);
                    }

                    // Photo
                    const photoPath = NODE_IDS.PhotosForExecution(execution.id, execution.projectId);
                    executionOffline.photoPaths.push(photoPath);

                    // Scoped (related)
                    const scopedPath = NODE_IDS.ExecutionSummaryScoped(id, execution.scopeReturnFields || []);
                    executionOffline.executionListPaths.push(scopedPath);

                    // Project
                    if (execution.projectId) {
                        executionOffline.projectIds.push(execution.projectId);
                    }

                    // Selects
                    let descendants = getActiveDescendantsAndSelfIfPresent(state, id);
                    let nodes = descendants
                        .filter(a => a.questionType === QUESTION_TYPES.select.id && a.selectDataSource === SELECT_DATA_SOURCES.executionDynamic.id);
                    for (let q of nodes) {
                        addDependency(update, {from: id, to: node, properties: ['selectExecutionFilter']})
                        if (!q.selectExecutionFilter) {
                            continue;
                        }
                        const path = NODE_IDS.ExecutionQuestionSelect(q);
                        executionOffline.executionListPaths.push(path);
                    }

                    // Children
                    const children = getActivesNodesSafe(state, execution.links, execution)
                        .filter(a => a.linkType === LINK_TYPES.child.id)
                        .map(a => a.toNodeId);
                    for (let child of children) {

                        addDependency(update, {from: child, to: node, properties: ['draft', 'preview']});
                        let childNode = getNodeOrNull(state, child);
                        if (childNode?.draft) {
                            continue;
                        }
                        let childEntry = node.offlineExecutions[child]
                        if (childEntry == null || !childEntry.on) {
                            childEntry = {id: child, parentId: id};
                            executionEntries.push(childEntry);
                        }
                    }
                } else if (before) {
                    executionOffline.procedureIds = before.procedureIds;
                    executionOffline.photoPaths = before.photoPaths;
                    executionOffline.projectIds = before.projectIds;
                    executionOffline.executionListPaths = before.executionListPaths;
                }

                const dependentLoaders = [id,
                    ...executionOffline.procedureIds,
                    ...executionOffline.photoPaths,
                    ...executionOffline.executionListPaths,
                    ...executionOffline.projectIds]

                // Loaded/Loading?
                for (let loaderId of dependentLoaders) {
                    addDependency(update, {
                        from: loaderId,
                        to: node,
                        properties: ['loaded', 'loading', 'loadingError']
                    });
                    const loader = getNodeOrNull(state, loaderId);
                    executionOffline.loaded = executionOffline.loaded && !!loader?.loaded;
                    executionOffline.loading = executionOffline.loading || !!loader?.loading;
                    executionOffline.loadingError = executionOffline.loadingError || loader?.loadingError;
                }

                if (executionEntries.length > 2000) {
                    reportBusinessError('Too many offline resources required. Count ' + executionEntries.length);
                    break;
                }
            }

            // Update parents from children
            for (let entry of Object.values(node.offlineExecutions)) {
                if(entry.autoOffWhenDone && !entry.dirty && entry.status === EXECUTION_STATUS.done.id) {
                    node.offlineExecutions[entry.id].on = false;
                }

                if (entry.parentId && entry.on) {
                    const parentEntry = node.offlineExecutions[entry.parentId]
                    if (parentEntry) {
                        parentEntry.loaded = parentEntry.loaded && entry.loaded;
                        parentEntry.loading = parentEntry.loading || entry.loading;
                        parentEntry.loadingError = parentEntry.loadingError || entry.loadingError;
                    }
                }
            }

            let procedureIds = {};
            let photoPaths = {};
            let scopePaths = {};
            let projectIds = {};
            for (let executionOffline of Object.values(node.offlineExecutions)) {
                executionOffline.executionListPaths?.map(a => scopePaths[a] = true)
                executionOffline.projectIds?.map(a => projectIds[a] = true)
                executionOffline.procedureIds?.map(a => procedureIds[a] = true)
                executionOffline.photoPaths?.map(a => photoPaths[a] = true)
            }

            node.offlineResources = {
                procedureIds: Object.keys(procedureIds),
                photoPaths: Object.keys(photoPaths),
                executionListPaths: Object.keys(scopePaths),
                projectIds: Object.keys(projectIds)
            };

            // Assigned To Me Offline
            const loader = getNodeOrNull(state, NODE_IDS.MyAssignedExecutions)
            if (node.assignmentsOffline) {
                const assignedLoader = getNodeOrNull(state, NODE_IDS.MyAssignments)
                const finished = loader?.loaded && !loader?.hasNextPage
                node.assignmentsOfflineCompleted = node.assignmentsOfflineCompleted || finished
                if (node.assignmentsOfflineState == null) {
                    node.assignmentsOfflineState = {
                        worqItems: {
                            name: 'Worq Items',
                            total: assignedLoader?.total,
                            downloaded: 0,
                            lastUpdatedDateTime: null,
                            progress: 0
                        }
                    }
                }
                const assignmentState = cloneDeep(node.assignmentsOfflineState)
                node.assignmentsOfflineState = assignmentState
                if (loader?.loaded === true && loader?.lastUpdatedDateTime !== assignmentState.worqItems.lastUpdatedDateTime) {
                    if (finished) {
                        assignmentState.worqItems.downloaded = assignedLoader?.total
                    } else {
                        assignmentState.worqItems.downloaded = assignmentState.worqItems.total - loader.total + (loader.itemCount || 0)
                    }
                    assignmentState.worqItems.lastUpdatedDateTime = loader.lastUpdatedDateTime
                }
                assignmentState.worqItems.total = assignedLoader?.total
                if (assignmentState.worqItems.total) {
                    if (assignmentState.worqItems.downloaded > assignmentState.worqItems.total) {
                        assignmentState.worqItems.downloaded = assignmentState.worqItems.total
                    }
                    assignmentState.worqItems.progress = assignmentState.worqItems.downloaded / assignmentState.worqItems.total * 100
                } else {
                    assignmentState.worqItems.progress = 100
                }
                addDependency(update, {
                    from: NODE_IDS.MyAssignedExecutions,
                    to: node,
                    properties: ['loaded', 'hasNextPage', 'lastUpdatedDateTime']
                });
                addDependency(update, {
                    from: NODE_IDS.MyAssignments,
                    to: node,
                    properties: ['total']
                });
            } else {
                node.assignmentsOfflineCompleted = false
                node.assignmentsOfflineState = null
                if (loader) {
                    update[loader.id] = {
                        ...loader,
                        nodeIds: [],
                        lastUpdatedDateTime: null,
                        total: null,
                        lastReloadTicks: null,
                        lastFullReloadTicks: null,
                        hasNextPage: null,
                        loaded: false,
                        loadedFull: false,
                        offline: false
                    }
                }
            }
            if (node.mapsOffline) {
                if (node.mapsOfflineState == null) {
                    node.mapsOfflineState = {}
                }
                // Updates do not include other layers
                if (beforeNode) {
                    node.mapsOfflineState = {
                        ...beforeNode.mapsOfflineState,
                        ...node.mapsOfflineState
                    }
                }
            } else {
                node.mapsOfflineState = {}
                node.cachedAssignments = []
            }

            if (node.indexeddbClearedAge == null) {
                node.indexeddbClearedAge = HOURS_24 * 7
            }
            return update;
        }
    },
    "ProcedureRoot": {
        ...domainRuleNOP,
        onPut: (state, node, beforeState, raisedAction, update) => {
            const beforeNode = getNodeOrNull(beforeState, node.id);

            // If we reload from server via summary we will lose some properties which will break offline
            if (beforeNode && makeArray(node.children).length === 0 && makeArray(beforeNode.children).length !== 0) {
                node.children = [...beforeNode.children];
                node.keyField1QuestionId = beforeNode.keyField1QuestionId;
                node.keyField2QuestionId = beforeNode.keyField2QuestionId;
                node.keyField3QuestionId = beforeNode.keyField3QuestionId;
                node.keyField4QuestionId = beforeNode.keyField4QuestionId;
                node.keyField5QuestionId = beforeNode.keyField5QuestionId;
                node.titleTemplate = beforeNode.titleTemplate;
                node.rules = [...beforeNode.rules];
                // As we only got the summary and not full we want to make sure the next time
                // we visit the execution we get children changes, so lets not keep lastUpdatedDateTime
                node.lastUpdatedDateTime = beforeNode.lastUpdatedDateTime;
                let dirty = getDirty(state, node.id);
                let dirtyBefore = getDirty(beforeState, node.id);
                if (dirty.storeServer && dirtyBefore && dirtyBefore.storeServer) {
                    dirty.storeServer.children = dirtyBefore.storeServer.children;
                    dirty.storeServer.keyField1QuestionId = dirtyBefore.storeServer.keyField1QuestionId;
                    dirty.storeServer.keyField2QuestionId = dirtyBefore.storeServer.keyField2QuestionId;
                    dirty.storeServer.keyField3QuestionId = dirtyBefore.storeServer.keyField3QuestionId;
                    dirty.storeServer.keyField4QuestionId = dirtyBefore.storeServer.keyField4QuestionId;
                    dirty.storeServer.keyField5QuestionId = dirtyBefore.storeServer.keyField5QuestionId;
                    dirty.storeServer.titleTemplate = dirtyBefore.storeServer.titleTemplate;
                    dirty.storeServer.rules = dirtyBefore.storeServer.rules;
                }
            }
            if (beforeNode) {
                if (hasValue(beforeNode.children) && !hasValue(node.children)) {
                    reportDeveloperWarning('ProcedureRoot.children went mia', {before: beforeNode, after: node});
                    let serverVersion = getDirty(state, node.id)?.storeServer;
                    if (hasValue(serverVersion?.children)) {
                        node.children = [...serverVersion.children];
                        reportDeveloperWarning('Children lost, restoring from server version.', {
                            before: beforeNode,
                            after: node
                        });
                    } else {
                        reportDeveloperWarning('Children lost, also missing from server version.', {
                            before: beforeNode,
                            after: node
                        });
                    }
                }
                if (hasValue(beforeNode.rules) && !hasValue(node.rules)) {
                    reportDeveloperWarning('ProcedureRoot.rules went mia', {before: beforeNode, after: node});
                }
            }
            if (hasValue(node.rules) && !hasValue(node.children)) {
                reportDeveloperWarning('ProcedureRoot.children are empty but rules are not ... how', {
                    before: beforeNode,
                    after: node
                });
            }
            uniqueChildren(state, beforeNode, node);

            node = update[node.id];

            // Permissions
            if (beforeNode && beforeNode.loadedFull && !node.loadedFull) {
                console.info('Loaded full should not go true to false.');
            }

            let loadDirectlyUrl = `/procedures?id=${node.id}&includeDeleted=true`;
            if (node.children && node.children.length > 0 && !node.scopes.includes(loadDirectlyUrl)) {
                node.scopes.push(loadDirectlyUrl);
            }

            if (node._metadata && !node._metadata.permission) {
                node._metadata.permission = {}
                for (let p of node._metadata.permissions) {
                    node._metadata.permission[p.permission] = true
                }
            }

            // Only run if not summary and edit is on
            let loadedChildren = getNodesIfPresent(state, node.children || []);
            node.loadedFull = loadedChildren.length > 0;
            const compileErrors = [];
            if (node.loadedFull) {
                // TITLE TEMPLATE
                if (beforeNode && !beforeNode.titleTemplate && node.titleTemplate) {
                    node.titleTemplateEditorEnabled = true;
                }
                node.titleTemplateEditorEnabled = node.titleTemplateEditorEnabled == null ? node.titleTemplate != null : node.titleTemplateEditorEnabled;

                if (!node.titleTemplateEditorEnabled) {
                    node.titleTemplate = null;
                    node.titleTemplateEditor = null;
                }
                let isFocused = state.focusedNode && state.focusedNode.id === node.id && state.focusedNode.propertyName === 'titleTemplateEditor';
                // Copy from editor back to template
                if (isFocused) {
                    const textTemplateHuman = textTemplateFromHuman(state, node, node.titleTemplateEditor);
                    if (textTemplateHuman.compileErrors.length > 0) {
                        compileErrors.push(...textTemplateHuman.compileErrors.map(a => 'Title template is invalid. ' + a));
                    } else {
                        node.titleTemplate = textTemplateHuman.template;
                    }
                    addDependency(update, textTemplateHuman.dependencies);
                } else {
                    // Convert from template to human version
                    let textTemplateResult = textTemplateToHuman(state, node, node.titleTemplate);
                    node.titleTemplateEditor = textTemplateResult.templateEditor;
                    node.titleTemplate = textTemplateResult.template;
                    compileErrors.push(...textTemplateResult.compileErrors.map(a => 'Title template is invalid. ' + a));
                    addDependency(update, textTemplateResult.dependencies);
                }

                if (node.editOn) {
                    // KEY FIELDS
                    // Default key fields to the first Task
                    const firstStep = getChildrenSafe(state, node).find(child => child.deleted !== true);
                    const firstTask = firstStep && getChildrenSafe(state, firstStep).find(child => child.deleted !== true);
                    const questions = (firstTask && getActiveChildrenSafe(state, firstTask))?.filter(q => q.questionType !== QUESTION_TYPES.link.id) || [];
                    for (let i = 1; i <= KEY_FIELD_COUNT; i++) {
                        let questionId = i - 1 < questions.length ? questions[i - 1].id : null;
                        node['keyField' + i + 'QuestionId'] = questionId;
                        addDependency(update, {from: questionId, to: node, properties: ['deleted']});
                    }
                    addDependency(update, {from: firstStep, to: node, properties: ['children', 'deleted']});
                    addDependency(update, {from: firstTask, to: node, properties: ['children', 'deleted']});
                }
                // NUMBER
                if (!node.number) {
                    computeNodeNumber(state, node, update);
                } else {
                    reviseNodeNumber(state, beforeState, node, node.id, update);
                }
            }

            // Schemas
            if (node.schemas) {
                const isOld = node.schemas.some(s => typeof s !== "string");
                if (isOld) {
                    delete node.schemas;
                }
            }

            // These rules need to always run
            if (node.loadedFull) {
                // Edit On
                if (node.editOn) {
                    // COMPILE ERRORS
                    const firstStep = loadedChildren?.filter(stp => !stp.deleted)?.[0];
                    const firstTask = getNodesIfPresent(state, firstStep?.children)?.filter(tsk => !tsk.deleted)?.[0];
                    const questions = getNodesIfPresent(state,firstTask?.children)?.filter(qst => !qst.deleted);
                    if (!questions.length) {
                        compileErrors.push('There are no questions under the first task.');
                    }
                    if (!node.name) {
                        compileErrors.push('Name is required.');
                    }
                    const descendants = getActiveDescendants(state, node.id);
                    const descendantWithCompileWarnings = descendants
                        .filter(a => hasValue(a.compileWarnings));
                    const descendantNonRulesWithCompileWarnings = descendantWithCompileWarnings.filter(a => a.type !== NODE_TYPE_OPTIONS.ProcedureRule || a.nodeIds == null || a.nodeIds.includes(node.id) || a.nodeIds.length === 0);
                    // Show non-rules, but for orphaned rule show them too
                    const useWarnings = descendantNonRulesWithCompileWarnings.length ? descendantNonRulesWithCompileWarnings : descendantWithCompileWarnings;
                    const descendantCompileWarnings = combineCompileWarnings(useWarnings);
                    node.compileWarnings = [...compileErrors, ...descendantCompileWarnings];
                    for (let descendant of descendants) {
                        addDependency(update, {from: descendant, to: node, properties: ['compileWarnings']});
                    }

                    // Rule Number
                    if (beforeNode?.rules?.length !== node.rules.length) {
                        for (let i = 0; i < node.rules.length; i++) {
                            const rule = getNodeOrNull(state, node.rules[i]);
                            if (rule && rule.number !== (i + 1)) {
                                const cloned = {...rule, number: i + 1};
                                update[cloned.id] = cloned;
                            }
                        }
                    }
                }

                // Has Location
                if (node.hasLocationField == null || node.editOn) {
                    computeHasLocation(state, node);
                }

                // Rules applied to this
                computeRuleIds(state, node);
            }

            // Only save changes if edit is on
            if (beforeNode === null || beforeNode?.editOn !== node.editOn) {
                const descendants = getDescendantsAndSelfIfPresent(state, node.id);
                if (node.editOn) {
                    for (let desc of descendants.filter(a => a.storeServer !== undefined)) {
                        let cloned = update[desc.id] || {...desc};
                        delete cloned.storeServer;
                        update[desc.id] = cloned;
                    }
                } else {
                    for (let desc of descendants.filter(a => a.storeServer !== false)) {
                        let cloned = update[desc.id] || {...desc};
                        cloned.storeServer = false;
                        update[desc.id] = cloned;
                    }
                }
            }

            return update;
        }
    },
    "ProcedureStep": {
        ...domainRuleNOP,
        onPut: (state, node, beforeState, raisedAction, update) => {
            let procedure = getRootNodeOrError(state, node.id);
            if (!node.completeMode) {
                node.completeMode = 'step';
            }
            // Default visible for existing records
            node.visible = node.visible || 'visible';
            node.visibleRuleEnabled = node.visible === 'rule';
            if (!node.visibleRuleEnabled) {
                node.visibleRuleTree = null;
                node.visibleRuleQuery = null;
                node.visibleRuleHuman = null;
            }
            node.scopes = mergeUnique(node.scopes, [node.rootId]);

            let beforeNode = getNodeOrNull(beforeState, node.id);
            if (beforeNode && node.statisticsMode !== beforeNode.statisticsMode) {
                const convertToInclude = node.statisticsMode === STATISTICS_MODES.include.id;
                for (const taskId of node.children) {
                    let taskNode = state.nodes[taskId];
                    if (convertToInclude) {
                        taskNode = shallowClone(taskNode);
                        taskNode.statisticsMode = STATISTICS_MODES.include.id;
                    }
                    update[taskNode.id] = taskNode;
                }
            }
            uniqueChildren(state, beforeNode, node);

            // Rules
            computeRuleIds(state, node);

            // Edit Mode
            addDependency(update, {from: procedure, to: node, properties: ['editOn']});
            if (procedure.editOn) {
                // Validate
                let compileErrors = [];
                if (!node.name) {
                    compileErrors.push('Name is required.');
                }

                if (node.visibleRuleEnabled && node.visibleRuleQuery) {
                    let result = validateVisibleRule(state, node, node.visibleRuleQuery);
                    compileErrors.push(...result.compileWarnings);
                    addDependency(update, result.dependencies);
                }

                const mentionedRules = getActiveMentionedRules(state, node.id);
                addDependency(update, mentionedRules.map(a => ({
                    from: a,
                    to: node,
                    properties: ['compileWarnings', 'draft']
                })));
                const ruleCompileWarnings = combineCompileWarnings(mentionedRules);
                compileErrors = [...compileErrors, ...ruleCompileWarnings];

                node.compileWarnings = compileErrors.filter(a => a != null && a !== 0);

                reviseNodeNumber(state, beforeState, node, node.rootId, update);
            }


            return update;
        }
    },
    "ProcedureTask": {
        ...domainRuleNOP,
        onPut: (state, node, beforeState, raisedAction, updatedNodes) => {
            const beforeNode = getNodeOrNull(beforeState, node.id);
            let procedure = getRootNodeOrError(state, node.id);
            // Default visible for existing records
            node.visible = node.visible || 'visible';
            node.visibleRuleEnabled = node.visible === 'rule';
            if (!node.visibleRuleEnabled) {
                node.visibleRuleTree = null;
                node.visibleRuleQuery = null;
                node.visibleRuleHuman = null;
            }
            let stepNode = getNodeByProperty(state, node, 'parentId');
            node.scopes = mergeUnique(node.scopes, [node.rootId]);
            node.parentDeleted = stepNode.deleted;

            const parentStatisticsExcluded = stepNode.statisticsMode === STATISTICS_MODES.exclude.id;
            node.statisticsMode = parentStatisticsExcluded ? STATISTICS_MODES.exclude.id : node.statisticsMode;
            node.statisticsModeEnabled = !parentStatisticsExcluded;

            uniqueChildren(state, beforeNode, node);

            // Rules
            computeRuleIds(state, node);

            // Validate
            addDependency(updatedNodes, {from: procedure, to: node, properties: ['editOn']});
            if (procedure.editOn) {
                let compileErrors = [];
                if (!node.name) {
                    compileErrors.push('Name is required.');
                }
                if (node.visibleRuleEnabled && node.visibleRuleQuery) {
                    let result = validateVisibleRule(state, node, node.visibleRuleQuery);
                    compileErrors.push(...result.compileWarnings);
                    addDependency(updatedNodes, result.dependencies);
                }

                let mentionedRules = getActiveMentionedRules(state, node.id);
                addDependency(updatedNodes, mentionedRules.map(a => ({
                    from: a,
                    to: node,
                    properties: ['compileWarnings', 'draft']
                })));
                const ruleCompileWarnings = mentionedRules
                    .filter(a => Array.isArray(a.compileWarnings) && a.compileWarnings.length > 0)
                    .flatMap(a => a.name + ' - ' + a.compileWarnings.join(' '));
                compileErrors = [...compileErrors, ...ruleCompileWarnings];

                node.compileWarnings = compileErrors.filter(a => a != null);

                reviseNodeNumber(state, beforeState, node, node.rootId, updatedNodes);
            }

            return updatedNodes;
        }
    },
    "ProcedureQuestion": {
        ...domainRuleNOP,
        onPut: (state, node, beforeState, raisedAction, updates) => {
            const beforeNode = getNodeOrNull(beforeState, node.id);
            const procedure = getNodeOrError(state, node.rootId);
            const editOn = procedure.editOn;
            const isTypeNumber = node.questionType === QUESTION_TYPES.number.id;
            const isCustomFormat = node.format === FORMATS.custom.id;
            node.formatEnabled = isTypeNumber;
            if (!node.formatEnabled) {
                node.format = null;
            }
            if (isTypeNumber) {
                node.formatOptions = FORMAT_OPTIONS
            }
            if (node.format === "undefined") {
                node.format = null
            }
            if (node.questionType === QUESTION_TYPES.date.id) {
                node.formatDisplay = DATE_FORMAT_DISPLAY;
            } else if (node.questionType === QUESTION_TYPES.datetime.id) {
                node.formatDisplay = DATETIME_FORMAT_DISPLAY;
            } else if (node.questionType === QUESTION_TYPES.time.id) {
                node.formatDisplay = TIME_FORMAT_DISPLAY;
            }
            // Check that all conditions are met before setting default format display
            //     #1 - if question type is number and format is custom
            //     #2 - if beforeNode has format, we don't want to override formatDisplay when we just load it from server
            //     #3 - if we switch format eg. from $ to Custom OR if we switch from email to number and select custom format
            else if (isTypeNumber && isCustomFormat) {
                const switchIntoCustomFormat = !isNullOrUndefined(beforeNode?.format) && beforeNode?.format !== node.format;
                if(switchIntoCustomFormat || node.formatDisplay === null) {
                    node.formatDisplay = '#,##0.00';
                }
            } else if (!isCustomFormat) {
                let option = FORMATS[node.format];
                node.formatDisplay = node.format ? option?.postfix || option?.prefix : null;
            }
            node.minInclusiveEnabled = isTypeNumber;
            if (!node.minInclusiveEnabled) {
                node.minInclusive = null;
            } else {
                node.minInclusive = node.minInclusive == null ? null : Number.parseFloat(node.minInclusive.toFixed(2));
            }
            node.maxInclusiveEnabled = isTypeNumber;
            if (!node.maxInclusiveEnabled) {
                node.maxInclusive = null;
            } else {
                node.maxInclusive = node.maxInclusive == null ? null : Number.parseFloat(node.maxInclusive.toFixed(2));
            }

            // for displaying formatDisplay
            node.formatDisplayVisible = isTypeNumber && isCustomFormat;

            node.selectDataSourceEnabled = node.selectRenderModeEnabled = node.selectManyEnabled = node.questionType === QUESTION_TYPES.select.id;
            node.selectMany = node.selectManyEnabled ? !!node.selectMany : null;

            uniqueChildren(state, beforeNode, node);

            // Rules
            computeRuleIds(state, node);

            if (!node.selectDataSourceEnabled) {
                node.selectRenderMode = null;
                node.selectDataSource = null;
            } else {
                // Default
                node.selectDataSource = node.selectDataSource || SELECT_DATA_SOURCES.static.id;

                // Switch render mode as required
                if (node.selectMany && node.selectRenderMode === SELECT_RENDER_MODES.radio.id) {
                    node.selectRenderMode = SELECT_RENDER_MODES.checkbox.id;
                } else if (!node.selectMany && node.selectRenderMode === SELECT_RENDER_MODES.checkbox.id) {
                    node.selectRenderMode = SELECT_RENDER_MODES.radio.id;
                } else if (node.selectRenderMode == null) {
                    node.selectRenderMode = node.selectMany ? SELECT_RENDER_MODES.checkbox.id : SELECT_RENDER_MODES.radio.id;
                }
                // Set default render mode if data source is execution dynamic
                // and it is not in the same patch or not set yet
                if (beforeNode
                    && beforeNode.selectDataSource !== node.selectDataSource
                    && node.selectDataSource === SELECT_DATA_SOURCES.executionDynamic.id
                    && (beforeNode.selectRenderMode === node.selectRenderMode
                        || (!beforeNode.selectRenderMode && !node.selectRenderMode)
                        )
                ) {
                    node.selectRenderMode = SELECT_RENDER_MODES.autocomplete.id;
                }
            }

            if ((node.questionType === QUESTION_TYPES.select.id && node.selectDataSource === SELECT_DATA_SOURCES.executionDynamic.id) || node.questionType === QUESTION_TYPES.link.id) {
                if (editOn) {
                    const nodeAutoRules = getLinkToQuestionRules(state, node.id);
                    if (nodeAutoRules.length === 0) {
                        const ruleAttr = {
                            nodeIds: [node.id],
                            selfOn: false,
                            linkMatchOn: true,
                            alwaysOn: true,
                            linkToQuestionOn: true,
                            addNewOn: node.questionType === QUESTION_TYPES.link.id,
                            addExistingOn: node.questionType === QUESTION_TYPES.select.id,
                        };
                        const ruleNode = createChildNode(procedure, state.schema[NODE_TYPE_OPTIONS.ProcedureRule], ruleAttr);
                        updates[ruleNode.id] = ruleNode;
                    } else {
                        if (node.questionType === QUESTION_TYPES.link.id && beforeNode && beforeNode.questionType !== node.questionType) {
                            updates[nodeAutoRules[0].id] = {
                                ...nodeAutoRules[0], addNewOn: true, addExistingOn: false,
                            };
                        } else if (node.questionType === QUESTION_TYPES.select.id && beforeNode && (beforeNode.questionType !== node.questionType || (beforeNode.questionType === node.questionType && beforeNode.selectDataSource !== node.selectDataSource))) {
                            updates[nodeAutoRules[0].id] = {
                                ...nodeAutoRules[0], addExistingOn: true,
                            };
                        }
                    }
                }
                // Select filter
                const {filter} = computeFilter(state, node.id, SELECTOR_FILTER_TYPE.procedureSelect.id);
                node.selectExecutionFilter = filter;
            }

            node.optionsEnabled = node.selectDataSource === SELECT_DATA_SOURCES.static.id;
            if (!node.optionsEnabled && node.questionType !== QUESTION_TYPES.select.id) {
                node.options = null;
            }
            if (node.questionType === QUESTION_TYPES.yesno.id) {
                node.options = YES_NO_OPTIONS_STRING;
                node.selectDataSource = SELECT_DATA_SOURCES.static.id;
                node.selectRenderMode = SELECT_RENDER_MODES.chip.id;
            }
            node.optionsParsed = parseOptions(node.options);

            // Unknown
            node.unknownVisible = node.questionType !== QUESTION_TYPES.message.id && node.questionType !== QUESTION_TYPES.photo.id;
            if (!node.unknownVisible) {
                node.unknown = UNKNOWN_TYPES.notallowed.id;
            }

            // Photo question type
            if (procedure.editOn && node.questionType === QUESTION_TYPES.photo.id && node.photo === CONDITIONAL_VALUES.none.id && beforeNode !== null && beforeNode.questionType !== QUESTION_TYPES.photo.id) {
                node.photo = CONDITIONAL_VALUES.required.id;
            }

            // Adjust select render mode options if multiple selection or not
            if (!node.selectManyEnabled) {
                node.selectRenderModeOptions = null;
            } else if (node.selectMany) {
                node.selectRenderModeOptions = Object.values(SELECT_RENDER_MODES).filter(d => d.id !== SELECT_RENDER_MODES.radio.id);
            } else {
                node.selectRenderModeOptions = Object.values(SELECT_RENDER_MODES).filter(d => d.id !== SELECT_RENDER_MODES.checkbox.id);
            }

            if (editOn && (node.optionsParsed.length > 8)) {
                node.selectRenderMode = SELECT_RENDER_MODES.autocomplete.id;
                node.selectRenderModeOptions = node.selectRenderModeOptions?.map(d => ({
                    ...d,
                    disabled: d.id !== SELECT_RENDER_MODES.autocomplete.id && node.optionsParsed.length > 8
                }));
            }

            node.validOptionsEnabled = node.selectDataSource === SELECT_DATA_SOURCES.static.id;
            if (!node.validOptionsEnabled) {
                node.validOptions = null;
            } else if (node.validOptions == null) {
                node.validOptions = [];
            } else if (typeof node.validOptions === 'string') {
                // Temporary upgrade from when this was stored as a string
                node.validOptions = node.validOptions.split('\n');
            }
            if (node.validOptions && node.optionsParsed) {
                node.validOptions = node.validOptions.filter(a => node.optionsParsed.find(b => b.value === a) != null);
                let revisedValidOptions = [];
                for (let validOption of node.validOptions) {
                    let exists = node.optionsParsed.find(b => b.value === validOption);
                    if (exists) {
                        revisedValidOptions.push(validOption);
                    }
                }
                node.validOptions = revisedValidOptions;
            }
            node.messageTypeEnabled = node.questionType === 'message';
            if (node.messageTypeEnabled && !node.messageType) {
                node.messageType = 'info';
            } else if (!node.messageTypeEnabled) {
                node.messageType = null;
            }
            node.messageRichTextEnabled = node.questionType === 'message';
            if (!node.messageRichTextEnabled) {
                node.initialValue = null;
            }
            node.fixModeEnabled = node.warningMessageEnabled = (node.minInclusiveEnabled || node.maxInclusiveEnabled || node.validOptionsEnabled);
            node.warningMessage = defaultValue(node.warningMessage, null);
            if (!node.warningMessageEnabled) {
                node.warningMessage = null;
            }
            if (!node.fixMode || !node.fixModeEnabled) {
                node.fixMode = 'none';
            }
            let resolveFixModes = ['resolve', 'resolvecomment', 'resolvephoto', 'resolvecommentphoto'];
            node.fixModeResolveFlag = resolveFixModes.includes(node.fixMode);
            node.initialCommentInstructionsEnabled = node.comment !== 'none';
            node.initialCommentInstructions = node.initialCommentInstructions || null;
            if (!node.initialCommentInstructionsEnabled) {
                node.initialCommentInstructions = null;
            }
            node.resolvedCommentInstructionsEnabled = node.fixMode && node.fixMode.includes('comment');
            if (!node.resolvedCommentInstructionsEnabled) {
                node.resolvedCommentInstructions = null;
            }
            node.attachmentTypeEnabled = node.photo !== 'none';
            if (!node.attachmentTypeEnabled) {
                node.attachmentType = null;
            } else {
                node.attachmentType = node.attachmentType || ATTACHMENT_TYPES.photo.id;
            }
            node.mobilePhotoModeEnabled = node.attachmentType === ATTACHMENT_TYPES.photo.id;
            if (!node.mobilePhotoModeEnabled) {
                node.mobilePhotoMode = null;
            } else {
                node.mobilePhotoMode = node.mobilePhotoMode || MOBILE_CAMERA_MODES.cameraonly.id;
            }
            node.photoInstructionsEnabled = node.attachmentType != null;
            node.photoInstructions = node.photoInstructions || null;
            if (!node.photoInstructionsEnabled) {
                node.photoInstructions = null;
            }
            node.visibleRuleEnabled = node.visible === 'rule';
            if (!node.visibleRuleEnabled) {
                node.visibleRuleTree = null;
                node.visibleRuleQuery = null;
                node.visibleRuleHuman = null;
            }

            // LINK
            node.linkStyleEnabled = node.questionType === QUESTION_TYPES.link.id;
            node.linkRuleEnabled = node.linkStyleEnabled;
            if (!node.linkStyleEnabled) {
                node.linkStyle = null;
                node.linkRuleHuman = null;
                node.linkRuleQuery = null;
                node.linkRuleTree = null;
            } else if (node.linkStyleEnabled && node.linkStyle == null) {
                node.linkStyle = PROCEDURE_LINK_STYLE.table.id;
            }

            if(node.linkStyleEnabled) {
                // 1.When user has Alpha permission and client is worqtest/iainroberts/evoenergy, include the table format option of 'Legacy Project'
                //    a.If user does not have Alpha, but is selected, still display it
                const options = {...PROCEDURE_LINK_STYLE_OPTIONS};
                const hasAlphaPermission = SharedAuth.userHasPermission(Permissions.feature.alpha);
                const isLegacySelected = node.linkStyle === PROCEDURE_LINK_STYLE.legacyProject.id;
                const notAlphaAndLegacyIsNotSelected = !hasAlphaPermission && !isLegacySelected;
                const clientSupportsLegacy = ['worqtest', 'iainroberts', 'evoenergy'].includes(SharedAuth.getClientId());
                const isCypress = cypress.isCypress();
                if(!isCypress && (notAlphaAndLegacyIsNotSelected || !clientSupportsLegacy)){
                    delete options[PROCEDURE_LINK_STYLE.legacyProject.id];
                }

                node.linkStyleOptions = options;
            } else {
                node.linkStyleOptions = null;
            }


            // INPUT PATTERN
            if (node.questionType === 'number') {
                node.minInclusivePattern = '[0-9.-]*';
                node.maxInclusivePattern = '[0-9.-]*';
            } else {
                node.minInclusivePattern = null;
                node.maxInclusivePattern = null;
            }
            node.textMultipleLinesEnabled = node.questionType === 'text';
            node.textMultipleLines = node.textMultipleLinesEnabled ? !!node.textMultipleLines : null;

            // Validate
            addDependency(updates, {from: procedure, to: node, properties: ['editOn']});
            if (procedure.editOn) {
                let validationBeingUsed = node.minInclusive != null || node.maxInclusive != null ||
                    (node.validOptions && node.validOptions.length > 0 && node.validOptions.length < node.optionsParsed.length);
                let compileErrors = [];
                let compileSuggestions = [];
                if (node.visibleRuleEnabled && node.visibleRuleQuery) {
                    let result = validateVisibleRule(state, node, node.visibleRuleQuery);
                    compileErrors.push(...result.compileWarnings);
                    addDependency(updates, result.dependencies);
                }

                if (node.questionType == null) {
                    compileErrors.push('Question Type must have a value.');
                }

                if (node.optionsEnabled && !hasValue(node.options)) {
                    compileErrors.push('Options are required.');
                } else if (node.optionsEnabled) {
                    const values = {};
                    node.optionsParsed.forEach((option) => {
                        // Check if there is an option that has no value
                        if(!hasValue(option.value)) {
                            compileErrors.push(`Option [${option.label}] has no key.`);
                        }
                        // check if there's duplicate key
                        else if(values[option.value]) {
                            compileErrors.push(`Option [${values[option.value]}] and [${option.label}] have the same key [${option.value}]. Keys must be unique.`);
                        } else {
                            values[option.value] = option.label
                        }
                    })
                }

                if (validationBeingUsed && !node.warningMessage) {
                    compileSuggestions.push('A Warning Message is suggested so that users know why it is invalid.');
                }
                if (!validationBeingUsed && node.warningMessage) {
                    if (node.questionType === QUESTION_TYPES.number.id) {
                        compileSuggestions.push('Warning message is defined but no validation has been added. Min or Max is required.');
                    } else {
                        compileSuggestions.push('Warning message is defined but no validation has been added. Valid options is required.');
                    }
                }
                if (node.warningMessageEnabled && !validationBeingUsed && node.fixMode && node.fixMode !== 'none') {
                    if (node.questionType === QUESTION_TYPES.number.id) {
                        compileSuggestions.push('Fix Mode is defined but no validation has been added. Min or Max is required.');
                    } else {
                        compileSuggestions.push('Fix Mode is defined but no validation has been added. Valid options is required.');
                    }
                }
                let mentionedRules = getActiveMentionedRules(state, node.id);
                addDependency(updates, mentionedRules.map(a => ({
                    from: a,
                    to: node,
                    properties: ['compileWarnings', 'draft', 'linkToQuestionOn']
                })));
                if (node.linkRuleEnabled) {
                    const matchedRules = mentionedRules.filter(a => a.linkToQuestionOn);
                    if (matchedRules.length === 0) {
                        compileSuggestions.push('Link question type is used however no rule has been added.');
                    }
                }
                if (!node.name) {
                    compileErrors.push('Name is required.');
                }

                if (node.messageTypeEnabled && (node.initialValue == null || node.initialValue === '')) {
                    compileSuggestions.push('Question type is message, but message content is empty. Please enter message.');
                }

                if(isTypeNumber && isCustomFormat) {
                    if(!node.formatDisplay) {
                        compileErrors.push('Custom number format is selected, but no format is entered.');
                    } else if (!validateFormat(node.formatDisplay)) {
                        compileErrors.push('Custom number format is invalid.');
                    }
                }

                if(node.questionType === QUESTION_TYPES.photo.id && node.photo === CONDITIONAL_VALUES.none.id) {
                    compileErrors.push('Attachment should be required.');
                }

                const ruleCompileWarnings = combineCompileWarnings(mentionedRules);

                compileErrors = [...compileErrors, ...ruleCompileWarnings];

                node.compileWarnings = compileErrors.filter(a => a != null && a !== 0);
                node.compileSuggestions = compileSuggestions.filter(a => a != null && a !== 0);
            }

            // Scopes
            node.scopes = mergeUnique(node.scopes, [node.rootId]);

            // Deleted
            let taskNode = getNodeByProperty(state, node, 'parentId');
            node.parentDeleted = taskNode.deleted || taskNode.parentDeleted;
            addDependency(updates, {from: taskNode.id, to: node.id, properties: ['deleted', 'parentDeleted']});

            let changedToGeographic = beforeNode && node.questionType === QUESTION_TYPES.geographic.id && node.questionType !== beforeNode?.questionType;
            if (changedToGeographic) {
                const procedureClone = updates[node.rootId] || getShallowClonedNodeOrError(state, node.rootId);
                computeHasLocation(state, procedureClone);
                updates[node.rootId] = procedureClone;
            }
            // Force re-val of root for compile errors and key fields
            reviseNodeNumber(state, beforeState, node, node.rootId, updates);
            return updates;
        }
    },
    "ProcedureRule": {
        ...domainRuleNOP,
        onPut: (state, node, beforeState, raisedAction, update) => {
            let procedure = getNodeOrError(state, node.rootId);
            const beforeNode = getNodeOrNull(beforeState, node.id);
            const editOn = procedure.editOn;
            node.nodeIds = defaultValue(node.nodeIds, []);
            let appliedToNodes = getNodesIfPresent(state, node.nodeIds);

            // Parent Rule
            const parentRule = getParentRuleOrNull(state, node.id) || node;
            const rootRule = getRootRuleOrNull(state, node) || node;

            // Rules
            computeRuleIds(state, node);

            // Sync Node Ids
            // We want to add the rule to the roots step/task/question
            // And the root, and the parent
            syncRuleIds(beforeState, state, node, node, update);

            // Add to procedure
            let isChildRule = appliedToNodes.some(a => a.type === NODE_TYPE_OPTIONS.ProcedureRule);
            let shouldBeOnProcedure = isChildRule ? appliedToNodes.every(a => a.draft !== true) : node.draft !== true;

            if (shouldBeOnProcedure && node.number == null) {
                delete node.draft;
                const useProcedure = update[procedure.id] || procedure;
                const revisedProcedure = {
                    ...useProcedure,
                    rules: mergeUnique(useProcedure.rules, node.id)
                }
                update[revisedProcedure.id] = revisedProcedure;
                procedure = revisedProcedure;
            }

            // Number
            if (node.number == null) {
                node.number = procedure.rules.indexOf(node.id) + 1;
                if (node.number === 0) {
                    node.number = null;
                }
            }
            // For
            // Switch other off
            if (node.selfOn && node.linkMatchOn && beforeNode) {
                if (!beforeNode.selfOn) {
                    node.linkMatchOn = false;
                } else if (!beforeNode.linkMatchOn) {
                    node.selfOn = false;
                }
            }
            if (node.linkMatchOn && node.nodeIds.length === 0) {
                node.nodeIds.push(node.rootId);
                appliedToNodes.push(procedure);
                syncRuleIds(beforeState, state, node, node, update);
            }
            node.selfOn = node.selfOn == null ? !node.linkMatchOn : node.selfOn;
            node.nodeIdsVisible = node.selfOn;
            node.actionType = defaultValue(node.actionType, RULE_ACTION_TYPE.block.id);

            // Get nodeIds
            if (appliedToNodes.length !== node.nodeIds.length) {
                // This somehow happened to Jack. So lets give a warning, although not sure how user will remove rule.
                node.compileWarnings = [`Rule references nodes [${node.nodeIds.join(', ')}] that do not exist. Please raise a support ticket with WORQ to resolve.`];
                if (appliedToNodes.length === 0) {
                    node.deleted = node.implicitlyDeleted = true;
                }
                return update;
            }
            let hasSome = appliedToNodes.length > 0;
            let compileWarnings = [];


            let reviseVisibleIfOn = (field) => {
                const visible = node[field + 'Visible'];
                if (!visible && node[field]) {
                    let schema = getNodeSchemaOrError(state, node.type);
                    let fieldDisplayName = schema.properties[field].displayName;
                    node[field + 'Visible'] = true;
                    compileWarnings.push(fieldDisplayName + ' is not available for the selected For options');
                }
            }

            // Toggle Always On to Condition
            if (editOn) {
                if (beforeNode && !beforeNode.alwaysOn && node.alwaysOn) {
                    node.conditionOn = false
                }
                // alwaysOn is on in factory, if also conditionOn lets use that
                if (node.conditionOn && (beforeNode == null || !beforeNode.conditionOn)) {
                    node.alwaysOn = false
                }
            }

            // Filter
            if (node.actionType === RULE_ACTION_TYPE.filter.id) {
                // Decision: Store filter in condition or calculate? Decided with condition for consistency with link
                //           plus calculate stores more than boolean json logic. Reason to use calculate is condition
                //           is generally for turning rule on and off.
                node.conditionOn = true;
                node.alwaysOn = false;
            }

            // Condition
            if (!node.conditionOn) {
                if (editOn) {
                    delete node.conditionQuery;
                    delete node.conditionHuman;
                    delete node.conditionTree;
                    delete node.conditionHumanStored;
                    delete node.conditionStyle;
                    delete node.conditionError;
                }
            } else {
                if (node.conditionStyle == null) {
                    node.conditionStyle = node.conditionHumanStored == null ? JSON_LOGIC_EDITORS.logic.id : JSON_LOGIC_EDITORS.formula.id;
                }
                // Clear if editor changed.
                // TODO Support switching between editors where possible
                const conditionChanged = beforeNode?.conditionStyle && node.conditionStyle && beforeNode?.conditionStyle !== node.conditionStyle;
                if (conditionChanged && procedure.editOn) {

                    // if condition changed to formula and conditionHumanStored has value then we can assume that the update is from the server
                    const serverUseFormula = node.conditionStyle === JSON_LOGIC_EDITORS.formula.id && node.conditionHumanStored;

                    // if condition changed to logic and conditionHumanStored has no value but beforeNode has conditionHumanStarted then we can assume that the update is from the server
                    const serverUseLogic = node.conditionStyle === JSON_LOGIC_EDITORS.logic.id && !node.conditionHumanStored && beforeNode.conditionHumanStored;

                    // clear only if we are sure that the recent condition changed is not from the server
                    if(!serverUseFormula && !serverUseLogic) {
                        delete node.conditionQuery;
                        delete node.conditionHuman;
                        delete node.conditionTree;
                        delete node.conditionHumanStored;
                    }
                }
                if (!node.deleted && node.conditionStyle === JSON_LOGIC_EDITORS.formula.id) {
                    processJsonLogicFormula(state, node, editOn, 'condition');
                }
            }

            // Name
            const isRootActionSecurity = rootRule.actionType === RULE_ACTION_TYPE.security.id;
            const action = RULE_ACTION_TYPE[node.actionType] || RULE_ACTION_TYPE.block;
            if (node === rootRule || isRootActionSecurity) {
                let name;
                let index = node.number || 'NA';
                let whenParts = [];
                let forParts = [];
                let nameParts = [];
                if (isRootActionSecurity) {
                } else if (node.alwaysOn) {
                    whenParts.push('Always');
                } else if (node.conditionHuman) {
                    whenParts.push(node.conditionHuman || 'Condition');
                } else {
                    whenParts.push('TBD')
                }

                if (isRootActionSecurity) {
                    nameParts.push(action.name);
                    forParts.push(node.calculateValueHumanStored)
                } else if (node.actionType === RULE_ACTION_TYPE.collectionView.id) {
                    nameParts.push('List View ' + (node.message || 'Blank'));
                } else if (node.linkMatchOn) {
                    let linkTypes = (node.linkMatchLinkTypes || []).map(a => LINK_TYPES[a]?.name).join('/');
                    forParts.push(linkTypes);
                }
                let forPart = forParts.length > 0 ? ' For ' + forParts.join(' and ') : '';
                let namePart = nameParts.length > 0 ? ' ' + nameParts.join(' ') : '';
                let whenPartsCombined = whenParts.length ? ' When ' + whenParts.join(' and ') : '';
                name = 'Rule #R' + index + ':' + namePart + forPart + whenPartsCombined;
                node.name = name.trim();
            } else {
                node.name = parentRule.name;
                if (node.actionType && node.actionType !== RULE_ACTION_TYPE.block.id) {
                    node.name += ' - ' + RULE_ACTION_TYPE[node.actionType]?.name + ' - '
                }
            }

            // Number
            let allNumber = hasSome && appliedToNodes.every(a => a.questionType === QUESTION_TYPES.number.id);
            node.numberMinOnVisible = node.numberMaxOnVisible = node.numberBetweenOnVisible = allNumber;
            if (!allNumber) {
                node.numberMinOn = node.numberMaxOn = node.numberBetweenOn = false;
            }

            // Select
            let allSelect = hasSome && appliedToNodes.every(a => [QUESTION_TYPES.select.id, QUESTION_TYPES.yesno.id].includes(a.questionType));
            node.selectAnyOnVisible = allSelect;
            if (!allSelect) {
                node.selectAnyOn = false;
            }

            // Link
            let allLinks = hasSome && appliedToNodes.every(a => a.questionType === QUESTION_TYPES.link.id)
            let allExecutionDynamic = hasSome && appliedToNodes.every(a => a.selectDataSource === SELECT_DATA_SOURCES.executionDynamic.id);
            let allAddNewOn = hasSome && allExecutionDynamic && appliedToNodes.every(a => a.selectRenderMode === SELECT_RENDER_MODES.autocomplete.id);
            let canLinkToQuestion = (allLinks || allExecutionDynamic) && !!node.linkMatchOn;
            node.linkToQuestionOnVisible = (allLinks || allExecutionDynamic) && !!node.linkMatchOn;
            node.linkToQuestionOn = !!node.linkToQuestionOn;
            reviseVisibleIfOn('linkToQuestionOn');
            node.addNewOnVisible =  allLinks || (node.linkToQuestionOn && allAddNewOn);
            node.addExistingOnVisible = node.linkToQuestionOn;
            reviseVisibleIfOn('addNewOn');
            reviseVisibleIfOn('addExistingOn');
            for (let appliedNode of appliedToNodes) {
                addDependency(update, {from: appliedNode.id, to: node.id, properties: ['questionType', 'selectDataSource', 'selectRenderMode']});
            }

            // Question
            let isQuestion = hasSome && appliedToNodes.every(a => a.type === NODE_TYPE_OPTIONS.ProcedureQuestion);
            let isSelfQuestion = isQuestion && node.selfOn;
            node.messageOnVisible
                = node.invalidInputOnVisible
                = node.photoRequiredOnVisible
                = node.commentRequiredOnVisible
                = node.raiseIssueOnVisible
                = node.commentInstructionsOnVisible
                = node.photoInstructionsOnVisible
                = isSelfQuestion;
            node.messageOnVisible = node.messageOnVisible || node.actionType === RULE_ACTION_TYPE.collectionView.id || node.actionType === RULE_ACTION_TYPE.collectionColumn.id;
            reviseVisibleIfOn('messageOn');
            reviseVisibleIfOn('invalidInputOn');
            reviseVisibleIfOn('photoRequiredOn');
            reviseVisibleIfOn('commentRequiredOn');
            reviseVisibleIfOn('raiseIssueOn');
            reviseVisibleIfOn('commentInstructionsOn');
            reviseVisibleIfOn('photoInstructionsOn');

            // Rules
            let allDeletedOrParentDeleted = node.nodeIds?.length && appliedToNodes.every(a => a.deleted === true || a.parentDeleted === true);
            let linkRuleNotLinkQuestion = !canLinkToQuestion && node.linkToQuestionOn;
            let shouldImplicitDelete = allDeletedOrParentDeleted || linkRuleNotLinkQuestion;
            if (shouldImplicitDelete && node.deleted !== true) {
                node.implicitlyDeleted = true;
                node.deleted = true;
                syncRuleIds(beforeState, state, node, node, update);
            } else if (!shouldImplicitDelete && node.implicitlyDeleted === true) {
                node.implicitlyDeleted = false;
                node.deleted = false;
                syncRuleIds(beforeState, state, node, node, update);
            } else if (appliedToNodes.some(a => a.deleted === true || a.parentDeleted === true) && appliedToNodes.length > 1) {
                // #2566 - Ideally I would not remove it so when restored it is the same, but that is hard.
                appliedToNodes = appliedToNodes.filter(a => a.deleted !== true && a.parentDeleted !== true)
                node.nodeIds = appliedToNodes.map(a => a.id);
                syncRuleIds(beforeState, state, node, node, update);
            }

            // CalculateOn by action
            if (action.calculateValueOn) {
                node.calculateValueOn = true;
            }

            // Create Execution
            const isManualCreate = node.actionType === RULE_ACTION_TYPE.manuallyAddExecution.id;
            const isManualCreateMany = node.actionType === RULE_ACTION_TYPE.manuallyAddExecutionBulk.id;
            let isRoot = hasSome && appliedToNodes.every(a => a.type === NODE_TYPE_OPTIONS.ProcedureRoot);
            node.createExecutionOnVisible = hasSome && node.selfOn;
            if (!node.createExecutionOn && !isManualCreate && !isManualCreateMany) {
                delete node.createExecutionProcedureId;
                delete node.createExecutionLinkType;
            }
            reviseVisibleIfOn('createExecutionOn');

            // Assign To
            node.assignToOnVisible = !isQuestion;

            // Link Match
            if (!node.linkMatchOn) {
                delete node.linkMatchProcedureIds;
                delete node.linkMatchLinkTypes;
            }

            // Display Message
            if (node.messageOn) {
                node.messageType = node.messageType || MESSAGE_TYPES.warning.id;
            } else {
                delete node.messageType;
                delete node.message;
            }

            // Photo Instructions
            if (!node.photoInstructionsOn) {
                delete node.photoInstructionsMessage;
            }

            // Raise an issue
            if (!node.raiseIssueOn) {
                delete node.raiseIssueMessage;
                delete node.raiseIssueResolveOn;
                delete node.raiseIssueReAnswerOn;
            }
            node.raiseIssueResolveOnVisible = !!node.raiseIssueOn;
            // ReAnswer
            node.raiseIssueReAnswerOnVisible = node.raiseIssueResolveOn;

            // Visible
            node.visibleOnVisible = node.selfOn && !isRoot;
            reviseVisibleIfOn('visibleOn');

            // Copy To
            let isQuestionLink = hasSome && appliedToNodes.some(a => a.questionType === QUESTION_TYPES.link.id);
            node.copyToOnVisible = (isQuestion && node.linkMatchOn) || isChildRule;
            if (!node.copyToOn) {
                node.copyToNodeIds = null;
            }
            reviseVisibleIfOn('copyToOn');

            // New Way Using ActionType
            let childRules = getActiveRulesForNode(state, node.id);
            let actionTypesOn = childRules.filter(a => !a.deleted && a.actionType).map(a => a.actionType);

            const isActionBlock = node.actionType === RULE_ACTION_TYPE.block.id;
            // add  all toggled rules to actionTypesOn
            // only if actionType is block
            if(isActionBlock) {
                RULE_ACTION_TYPE_ON.forEach(rule => {
                    if(node[rule.id]) {
                        actionTypesOn.push(rule.id);
                    }
                })
            }

            let availableActionTypes = [];
            let actionTypeAvailable = (actionType, condition) => {
                const isOn = actionTypesOn.includes(actionType.id)
                if (condition) {
                    availableActionTypes.push(actionType.id);
                } else if (isOn)
                {
                    availableActionTypes.push(actionType.id);
                    compileWarnings.push(actionType.name + ' is not available for the selected For options');
                }
            }

            // ComputeOn
            const computeOn = isActionBlock && (node.calculateValueOn || node.createExecutionOn || node.copyToOn);
            actionTypeAvailable(RULE_ACTION_TYPE.computeOnServer, computeOn);
            actionTypeAvailable(RULE_ACTION_TYPE.computeOnClient, computeOn);

            // Calculate For Previous Versions
            const calculatePreviousVersionsOn = isActionBlock && isQuestion
            actionTypeAvailable(RULE_ACTION_TYPE.calculatePreviousVersions, calculatePreviousVersionsOn);
            if (node.actionType === RULE_ACTION_TYPE.calculatePreviousVersions.id) {
                node.calculateValueOn = true;
            }

            // Unique Constraint
            actionTypeAvailable(RULE_ACTION_TYPE.uniqueConstraint, isQuestion && !node.linkMatchOn);

            // Assignment
            actionTypeAvailable(RULE_ACTION_TYPE.assignment, !isQuestion && !node.linkMatchOn);
            if (node.actionType === RULE_ACTION_TYPE.assignment.id) {
                node.calculateValueOn = true;
            }

            // Comment Default Open
            actionTypeAvailable(RULE_ACTION_TYPE.commentHideAction, !node.linkMatchOn);

            // Search
            actionTypeAvailable(RULE_ACTION_TYPE.textSearch, !node.linkMatchOn);
            actionTypeAvailable(RULE_ACTION_TYPE.fieldSearch, !node.linkMatchOn);

            // Custom Key
            let calculateAllowedByAction = false;
            if (node.actionType === RULE_ACTION_TYPE.customKey.id) {
                calculateAllowedByAction = true;
                if (!node.calculateValueQuery && beforeNode == null) {
                    const procedureType = PROCEDURE_TYPES[procedure.procedureType];
                    node.calculateValueQuery = JSON.stringify(procedureType.prefix);
                }
                if (!node.format) {
                    node.format = "0";
                }
                if (node.calculateValueQuery === '"USER-"') {
                    compileWarnings.push('USER- prefix is reserved for the core User template.')
                }
            }

            if (editOn &&
                (node.actionType === RULE_ACTION_TYPE.pivotSettings.id || node.actionType === RULE_ACTION_TYPE.theme.id || node.actionType === RULE_ACTION_TYPE.globalNavigationStyle.id)
            ) {
                calculateAllowedByAction = true;
            }

            if (editOn && node.actionType === RULE_ACTION_TYPE.layoutColumns.id)
            {
                calculateAllowedByAction = true;
                node.deleted = !node.nodeIds || node.nodeIds.length === 0;

                const addedNodeToLayoutColumnRule = (node.nodeIds?.length ?? 0) !== (beforeNode?.nodeIds?.length ?? 0);

                const actionRules =  getChildRulesByAction(state, node.rootId, RULE_ACTION_TYPE.layoutColumns.id, true)

                // we only want to execute this logic if nodeId has been added to nodeIds of rule
                if(addedNodeToLayoutColumnRule) {
                    const rulesByColumnWidth = keyBy(actionRules, r => +r.calculateValueQuery);
                    const nodeIdsByColumnWidth = Object.values(rulesByColumnWidth).reduce((acc, curr) => {
                        curr.nodeIds?.forEach(nodeId => acc[nodeId] = +curr.calculateValueQuery);
                        return acc;
                    }, {});

                    for (const rule of Object.values(rulesByColumnWidth))
                    {
                        for (const nodeId of rule.nodeIds ?? [])
                        {
                            const procedureNode = getNodeOrNull(state, nodeId)
                            if (procedureNode.type === NODE_TYPE_OPTIONS.ProcedureQuestion) {
                                const questionWidth = nodeIdsByColumnWidth[procedureNode.id] || 1
                                const parentWidth = nodeIdsByColumnWidth[procedureNode.parentId] || 1
                                if (questionWidth > parentWidth) {
                                    let currentRule = rulesByColumnWidth[questionWidth];
                                    let newRule = rulesByColumnWidth[parentWidth];

                                    if (newRule) {
                                        update[newRule.id] = newRule;
                                        update[newRule.id].nodeIds = mergeUnique(newRule.nodeIds, nodeId);
                                    }

                                    if(currentRule) {
                                        update[currentRule.id] = currentRule;
                                        update[currentRule.id].nodeIds = without(currentRule.nodeIds, nodeId);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            // Action
            actionTypeAvailable(RULE_ACTION_TYPE.manuallyAddExecution, isRoot && isActionBlock && !node.linkMatchOn);

            // Label
            actionTypeAvailable(RULE_ACTION_TYPE.label, !node.linkMatchOn && (isActionBlock || isManualCreate || isManualCreateMany || node.actionType === RULE_ACTION_TYPE.completeLabels.id));
            const isLabelRule = node.actionType === RULE_ACTION_TYPE.label.id;
            if (isLabelRule) {
                node.format = node.format ?? RULE_FORMAT_OPTION.name.id;
            }


            // if node is label rule
            // check if this rule is applied to query question only
            // if so, show "Apply to" dropdown
            if(isLabelRule) {
                const parentRuleNodeIds = parentRule.nodeIds;
                const appliedToNodes = getNodesIfPresent(state, parentRuleNodeIds);
                const appliedToQueryQuestion = appliedToNodes.every(a => a.questionType === QUESTION_TYPES.link.id);
                for (let applyToNode of appliedToNodes) {
                    addDependency(update, {
                        from: applyToNode,
                        to: node,
                        properties: ['questionType']
                    })
                }

                node.formatVisible = appliedToQueryQuestion;
            }
            // custom key has it's own handling of formatVisible
            else if (node.actionType !== RULE_ACTION_TYPE.customKey.id) {
                node.formatVisible = false;
            }

            // if "Apply to" dropdown is hidden or node format is empty, let's set the rule format to name
            if(isLabelRule && !node.formatVisible && beforeNode?.format === RULE_FORMAT_OPTION.addButton.id) {
                node.format = RULE_FORMAT_OPTION.name.id;
            }

            // if node is label rule then
            // set or remove formatOptions
            if (isLabelRule && !node.formatVisible) {
                node.formatOptions = null;
            } else if (isLabelRule && node.formatVisible) {
                node.formatOptions = RULE_FORMAT_OPTIONS;
            }

            // Geographic
            let allGeographic = hasSome && appliedToNodes.every(a => a.questionType === QUESTION_TYPES.geographic.id);
            actionTypeAvailable(RULE_ACTION_TYPE.geographicTools, allGeographic);
            if (node.actionType === RULE_ACTION_TYPE.geographicTools.id) {
                node.calculateValueQueryOptions = MAP_SHAPE_TYPE_OPTIONS;
            }

            // Inline Camera
            let allPhoto = hasSome && appliedToNodes.every(a => a.questionType === QUESTION_TYPES.photo.id);
            actionTypeAvailable(RULE_ACTION_TYPE.inlineCamera, allPhoto);

            // Inline Camera
            actionTypeAvailable(RULE_ACTION_TYPE.photoAspectRatio, allPhoto);

            // Reload Interval
            actionTypeAvailable(RULE_ACTION_TYPE.reloadInterval, isRoot);

            // Filter
            actionTypeAvailable(RULE_ACTION_TYPE.filter, node.addExistingOn || (isQuestionLink && node.linkToQuestionOn) || node.actionType === RULE_ACTION_TYPE.collectionView.id);

            // Column Order
            actionTypeAvailable(RULE_ACTION_TYPE.collectionOrder, node.actionType === RULE_ACTION_TYPE.collectionView.id || node.actionType === RULE_ACTION_TYPE.globalNavigationStyle.id || (isActionBlock && node.linkToQuestionOn));

            // Column
            actionTypeAvailable(RULE_ACTION_TYPE.collectionColumn, node.actionType === RULE_ACTION_TYPE.collectionView.id);
            if (node.actionType === RULE_ACTION_TYPE.collectionColumnSource.id) {
                if(!!node.calculateValueQuery && editOn) {
                    const vars = [];
                    extractJsonLogicVars(JSON.parse(node.calculateValueQuery), vars);
                    const nodeId = vars[0]?.split("_")?.[0];
                    const calculateValueQueryNode = getNodeOrNull(state, nodeId);

                    if(!!calculateValueQueryNode) {
                        addDependency(update, {from: calculateValueQueryNode, to: node, properties: ['deleted']});
                    }

                    if(calculateValueQueryNode?.deleted) {
                        compileWarnings.push('Selected column no longer available');
                    }
                }
            }
            node.actionTypesAvailable = availableActionTypes;
            node.actionTypesOn = actionTypesOn;

            // Read Only
            actionTypeAvailable(RULE_ACTION_TYPE.readOnly, isActionBlock && !node.linkMatchOn);

            // Collection View
            node.collectionProcedureIds = null;
            if (node.actionType === RULE_ACTION_TYPE.collectionView.id) {
                const collectionNodeId = firstOrNull(node.nodeIds);
                if (collectionNodeId === node.rootId) {
                    node.collectionProcedureIds = [collectionNodeId];
                } else {
                    // Create a child rule per possible template for the source data
                    const linkRules = getActiveRulesForNode(state, collectionNodeId)
                        .filter(a => a.linkToQuestionOn);
                    node.collectionProcedureIds = linkRules
                        .flatMap(a => a.linkMatchProcedureIds);
                    for (let linkRule of linkRules) {
                        addDependency(update, {
                            from: linkRule,
                            to: node,
                            properties: ['linkMatchProcedureIds', 'deleted']
                        })
                    }
                }
            }
            else if (editOn && node.actionType === RULE_ACTION_TYPE.collectionColumn.id) {
                const columnSources = getChildRulesByAction(state, node.id, RULE_ACTION_TYPE.collectionColumnSource.id);
                for (let procedureId of rootRule.collectionProcedureIds || []) {
                    const columnSource = columnSources.find(a => a.procedureId === procedureId);
                    const destProcedure = getNodeOrNull(state, procedureId);
                    if (!columnSource) {
                        // Auto-create a entry per column
                        let attr = {
                            alwaysOn: true,
                            nodeIds: [node.id],
                            actionType: RULE_ACTION_TYPE.collectionColumnSource.id,
                            draft: node.draft,
                            calculateValueOn: true,
                            procedureId,
                            procedureName: destProcedure?.name,
                            procedureOnly: node.procedureOnly
                        };
                        const newRule = createChildNode(procedure, getNodeSchemaOrError(state, NODE_TYPE_OPTIONS.ProcedureRule), attr);
                        update[newRule.id] = newRule;
                        let rootProcedure = update[node.rootId] || getNodeOrError(state, node.rootId);
                        rootProcedure = {...rootProcedure, rules: [...rootProcedure.rules, newRule.id]};
                        update[rootProcedure.id] = rootProcedure;
                    } else if (columnSource.deleted !== node.deleted) {
                        update[columnSource.id] = {
                            ...columnSource,
                            deleted: node.deleted,
                            procedureName: destProcedure?.name
                        }
                    } else if (procedure?.name && !columnSource.procedureName) {
                        update[columnSource.id] = {...columnSource, procedureName: destProcedure?.name}
                    }
                }
                for (let columnSource of columnSources) {
                    let columnSourceBefore = getNodeOrNull(beforeState, columnSource.id);
                    if (!(rootRule.collectionProcedureIds || []).includes(columnSource.procedureId)) {
                        update[columnSource.id] = {...columnSource, deleted: true}
                    }
                    if (!columnSource.calculateValueQuery) {
                        // Default it to the one with a matching name
                        const procedureNodes = getActiveDescendantsAndSelfIfPresent(state, columnSource.procedureId);
                        const option = getProcedureColumnOptions(columnSource.procedureId, procedureNodes)
                            .find(a => a.label === node.message);
                        let updatedColumnSource = update[columnSource.id] || {...columnSource};
                        updatedColumnSource.calculateValueQuery = option?.value || null;
                        update[columnSource.id] = updatedColumnSource;
                    }
                    const oneProcedure = rootRule.collectionProcedureIds?.length === 1;
                    const calculateChanged = columnSourceBefore && columnSourceBefore.calculateValueQuery !== columnSource.calculateValueQuery;
                    if (columnSource.calculateValueQuery && (!node.message || oneProcedure) && calculateChanged) {
                        // Find column name
                        const procedureNodes = getActiveDescendantsAndSelfIfPresent(state, columnSource.procedureId);
                        const allOptions = getProcedureColumnOptions(columnSource.procedureId, procedureNodes);
                        const option = allOptions.find(a => a.value === columnSource.calculateValueQuery);
                        node.message = option?.label || null;
                    }
                    addDependency(update, {from: columnSource, to: node, properties: ['calculateValueQuery']})
                }
                addDependency(update, {
                    from: rootRule,
                    to: node,
                    properties: ['collectionProcedureIds', 'procedureId']
                })
            }

            // Calculate
            node.calculateValueOnVisible = calculateAllowedByAction || isChildRule || (isQuestion && !isQuestionLink && node.selfOn);
            reviseVisibleIfOn('calculateValueOn');
            if (!node.calculateValueOn) {
                node.calculateValueHuman = node.calculateValueHumanStored = node.calculateValueQuery = null;
            } else if (!node.deleted && node.actionType !== RULE_ACTION_TYPE.collectionColumnSource.id) {
                processJsonLogicFormula(state, node, editOn, 'calculateValue');
            }

            // Procedure Only
            const hasChildProcedureOnly = node.actionTypesOn.some(a => RULE_ACTION_TYPE[a].procedureOnlyMode === RULE_APPLY_MODE_MODE.procedure.id);
            const isCollection = node.actionType === RULE_ACTION_TYPE.collectionView.id;
            const isActionTypeProcedureOnly = RULE_ACTION_TYPE[node.actionType]?.procedureOnlyMode === RULE_APPLY_MODE_MODE.procedure.id;
            node.procedureOnly = hasChildProcedureOnly || (isCollection && isRoot) || (rootRule !== node && rootRule.procedureOnly) || isActionTypeProcedureOnly;

            // Validate
            addDependency(update, {from: procedure, to: node, properties: ['editOn']});

            const isReportLink = isQuestionLink && node.linkToQuestionOn && appliedToNodes.some(a => a.linkStyle === PROCEDURE_LINK_STYLE.report.id);

            if (editOn) {
                if (isQuestionLink && node.addExistingOn) {
                    compileWarnings.push('Selecting an existing worq item on query type is not yet supported.');
                }
                if (isQuestionLink && node.linkMatchLinkTypes?.includes(LINK_TYPES.none.id)) {
                    const otherLinks = appliedToNodes
                        .flatMap(n => getActiveRulesForNode(state, n.id).filter(a => n.id !== node.id && a.linkToQuestionOn) ?? []);
                    const allLinkTypesForNode = otherLinks.flatMap(n => n.linkMatchLinkTypes)
                    if (allLinkTypesForNode.some(linkType => linkType !== LINK_TYPES.none.id)) {
                        compileWarnings.push('Cannot have other link types in same query with link type None.');
                    }
                    addDependency(update, otherLinks.map(n => ({from: n, to: node, properties: ['linkMatchLinkTypes', 'linkToQuestionOn']})))
                }
                if (isReportLink && !node.linkMatchLinkTypes?.every(a => a === LINK_TYPES.none.id)) {
                    compileWarnings.push('Cannot have a report with any other link type than None.');
                    const reportNodes = appliedToNodes.filter(a => a.linkStyle === PROCEDURE_LINK_STYLE.report.id);
                    addDependency(update, reportNodes.map(n => ({from: n, to: node, properties: ['linkStyle']})));
                }
                if (allExecutionDynamic && node.addExistingOnVisible && !node.addExistingOn) {
                    compileWarnings.push('Selecting an existing worq item must be enabled for worq item search.');
                }
                if (!node.selfOn && !node.linkMatchOn) {
                    compileWarnings.push('No For specified.');
                }
                if (node.nodeIds.length === 0 && node.actionType !== RULE_ACTION_TYPE.collectionView.id && node.actionType !== RULE_ACTION_TYPE.layoutColumns.id) {
                    compileWarnings.push('No Item(s) specified.');
                }
                if (!node.conditionOn && !node.alwaysOn) {
                    compileWarnings.push('No When specified.');
                }
                if (node.alwaysOn && node.conditionOn) {
                    compileWarnings.push('Always cannot be used with other When options.');
                }
                if (node.linkMatchOn && !hasValue(node.linkMatchLinkTypes)) {
                    compileWarnings.push('Link Type is required.');
                }
                if (node.linkMatchOn && !hasValue(node.linkMatchProcedureIds)) {
                    compileWarnings.push('Template is required.');
                }
                if (node.conditionOn && node.conditionQuery == null) {
                    compileWarnings.push('Condition is required.');
                }
                if (node.actionTypesOn.length === 0 && isActionBlock) {
                    compileWarnings.push('No Then specified.');
                }
                if ((node.createExecutionOn || isManualCreate) && !node.createExecutionLinkType) {
                    compileWarnings.push('Link Type is required.');
                }
                if ((node.createExecutionOn || isManualCreate || isManualCreateMany) && !node.createExecutionProcedureId) {
                    compileWarnings.push('Template is required.');
                }
                // Check for missing label rules and add dependencies
                if (isManualCreate) {
                    const childLabelRules = childRules.filter((a) => a.actionType === RULE_ACTION_TYPE.label.id);
                    const missing = {
                        [ADD_ACTION_LABEL_FORMATS.button.id]: true,
                        [ADD_ACTION_LABEL_FORMATS.group.id]: true,
                    }
                    childLabelRules.forEach((rule) => {
                        missing[rule.format] = false;
                        addDependency(update, {from: rule.id, to: node.id, properties: ['deleted', 'draft', 'format']});
                    });
                    Object.keys(missing).forEach((format) => {
                        if (missing[format] === true) {
                            compileWarnings.push(`${ADD_ACTION_LABEL_FORMATS[format].name} is missing, fix or delete the rule.`)
                        }
                    });
                }
                if ((node.copyToOn || node.createExecutionOn) && node.nodeIds.length > 1) {
                    let field = node.createExecutionOn ? 'createExecutionOn' : 'copyToOn';
                    let schema = getNodeSchemaOrError(state, node.type);
                    let fieldDisplayName = schema.properties[field].displayName;
                    compileWarnings.push(`${fieldDisplayName} cannot be applied to multiple nodes.`);
                }
                if (node.linkToQuestionOn && node.linkMatchOn && node.addNewOn && node.linkMatchLinkTypes?.length > 1) {
                    const appliedToNodes = getNodesIfPresent(state, node.nodeIds);
                    const queryQuestion = appliedToNodes.find((q) => q.questionType === QUESTION_TYPES.link.id);
                    if (!!queryQuestion) {
                        compileWarnings.push(`Adding worq item with multiple link types not allowed for worq item Query`);
                    }
                }
                const isColumnOrOrder = node.actionType === RULE_ACTION_TYPE.collectionColumnSource.id || node.actionType === RULE_ACTION_TYPE.collectionOrder.id;
                if (node.calculateValueOn && !node.calculateValueQuery) {
                    if (node.actionType === RULE_ACTION_TYPE.grant.id || node.actionType === RULE_ACTION_TYPE.deny.id) {
                        compileWarnings.push('Permission is required.');
                    } else if (node.actionType === RULE_ACTION_TYPE.collectionColumnSource.id) {
                        compileWarnings.push('Column is required.');
                    } else if (node.actionType === RULE_ACTION_TYPE.collectionOrder.id) {
                        compileWarnings.push('Order is required.');
                    } else if(node.actionType === RULE_ACTION_TYPE.label.id) {

                        // notCompleted, nodeCompletedPrefix", "nextCompleted", "previousCompleted are optional
                        const optionalLabels = ["notCompleted", "nextCompleted", "previousCompleted", "nodeCompletedPrefix", "group"];
                        if(!optionalLabels.includes(node.format)) {
                            compileWarnings.push('Calculate formula is required.');
                        }
                        const notNullable = ["button"];
                        
                        if (node.calculateValue === null && notNullable.contains(node.format)) {
                            compileWarnings.push('Calculate formula cannot be empty or null.');
                        }
                    } else if (node.actionType === RULE_ACTION_TYPE.globalNavigationStyle.id) {
                        /// Order is optional
                    }
                    else {
                        compileWarnings.push('Calculate formula is required.');
                    }
                }
                let conditionAllowExternalReferences = false;
                if (node.actionType === RULE_ACTION_TYPE.filter.id) {
                    let parentQueryRule = getNodesIfPresent(state, node.nodeIds).find(n => n.linkToQuestionOn);
                    conditionAllowExternalReferences = parentQueryRule?.linkMatchLinkTypes?.includes(LINK_TYPES.none.id);
                }
                let conditionWarning = validateConditionQueryRule(state, node.conditionQuery, 'Condition', node.rootId, conditionAllowExternalReferences);
                if (conditionWarning && conditionWarning.length > 0) {
                    compileWarnings.push(conditionWarning);
                }
                if (!isColumnOrOrder) {
                    // TODO Later validate calculateValueQuery when collectionColumnSource
                    let calculateValueWarning = validateConditionQueryRule(state, node.calculateValueQuery, 'Calculate', node.rootId);
                    if (calculateValueWarning && calculateValueWarning.length > 0) {
                        compileWarnings.push(calculateValueWarning);
                    }
                }
                if (node.conditionOn && node.conditionStyle === JSON_LOGIC_EDITORS.formula.id) {
                    let calculateValueWarning = validateConditionQueryRule(state, node.conditionQuery, 'Condition', node.rootId);
                    if (calculateValueWarning && calculateValueWarning.length > 0) {
                        compileWarnings.push(calculateValueWarning);
                    }
                }
                if (node.calculateValueError) {
                    compileWarnings.push('Error in calculation. ' + node.calculateValueError);
                }
                if (node.conditionError) {
                    compileWarnings.push('Error in condition formula. ' + node.conditionError);
                }
                let isQuestionWithResolve = hasSome && appliedToNodes.every(a => a.fixMode && a.fixMode.includes(FIX_MODES.resolve.id));
                if (node.calculateValueOn && isQuestionWithResolve) {
                    compileWarnings.push('Calculate and Fix Mode resolve is not supported.');
                }
                if (node.copyToOn) {
                    if (node.copyToNodeIds == null || node.copyToNodeIds.length === 0) {
                        compileWarnings.push('Copy question is required.');
                    } else {
                        let toProcedureIds = isChildRule ? appliedToNodes.map(a => a.createExecutionProcedureId) : (node.linkMatchProcedureIds || []);
                        let toProcedures = getNodesIfPresent(state, toProcedureIds);
                        for (let id of toProcedureIds) {
                            addDependency(update, {from: id, to: node, properties: ['loadedFull']});
                        }
                        let areAllProceduresLoaded = toProcedures.filter(a => a.loadedFull).length === toProcedureIds.length;
                        if (areAllProceduresLoaded) {
                            let possibleCopyToNodes = toProcedureIds
                                .flatMap(procedureId => getActiveDescendantsAndSelfIfPresent(state, procedureId))
                                .filter(a => !a.deleted);
                            addDependency(update, possibleCopyToNodes.map(a => ({
                                from: a,
                                to: node,
                                properties: ['deleted']
                            })));
                            let invalidIds = arrayMinus(node.copyToNodeIds, possibleCopyToNodes.map(a => a.id));
                            if (invalidIds.length > 0) {
                                compileWarnings.push('Copy question does not exist on the selected template.');
                            }
                        }
                    }
                    if (node.linkMatchLinkTypes?.includes(LINK_TYPES.none.id))
                    {
                        compileWarnings.push('Copy question does not support link type None.');
                    }
                }
                if (node.actionType === RULE_ACTION_TYPE.collectionView.id) {
                    if (node.message == null) {
                        compileWarnings.push('View name is required.');
                    }
                    let columns = getChildRulesByAction(state, node.id, RULE_ACTION_TYPE.collectionColumn.id).filter(a => !a.deleted);
                    if (columns.length === 0) {
                        compileWarnings.push('At least 1 column is required.');
                    }
                }
                if (node.actionType === RULE_ACTION_TYPE.grant.id || node.actionType === RULE_ACTION_TYPE.deny.id) {
                    if (!hasValue(node.permissions)) {
                        compileWarnings.push('Permissions are required.');
                    }
                }
                if (node.actionType === RULE_ACTION_TYPE.navigationStyle.id && node.format === NAVIGATION_STYLES.none.id) {
                    const steps = procedure.children
                                        .map(stepId => getNodeOrNull(state, stepId));

                    const activeSteps = steps.filter(step => !!step && !step.deleted);

                    if(activeSteps.length > 1 ) {
                        compileWarnings.push('Navigation style None is not available when more than 1 step');
                    }

                    addDependency(update, {from: procedure, to: node, properties: ['children']});
                    activeSteps.forEach(step => {
                        addDependency(update, {from: step, to: node, properties: ['deleted']});
                    })
                }

                if (node.procedureOnly)
                {
                    const onActions = node.actionTypesOn.map(a => RULE_ACTION_TYPE[a]);
                    const executionOnlyActions = onActions.filter(a => a.procedureOnlyMode === RULE_APPLY_MODE_MODE.execution.id);
                    const otherActions = onActions.filter(a => a.procedureOnlyMode !== RULE_APPLY_MODE_MODE.execution.id);

                    // Mixing ProcedureOnly rules with execustions only
                    if(executionOnlyActions.length && otherActions.length) {
                        const executionOnlyNames = executionOnlyActions.map(a => a.name).join(', ');
                        const otherNames = otherActions.map(a => a.name).join(', ')
                        compileWarnings.push(`${executionOnlyNames} cannot be used with ${otherNames}.`);
                    }
                }

                if(node.actionType === RULE_ACTION_TYPE.completeActionStyle.id) {
                    // if completeActionStyle is calculator and there is no anonymouse create rule then display error
                    const isCalculator = node.format === COMPLETE_ACTION_STYLES.calculator.id;
                    if(isCalculator) {
                        const anonymousCanCreateOnly = getRoleHasProcedurePermission(state,node.rootId, SECURITY_SCOPES.anonymous.id, GRANT_DENY_PERMISSIONS.create.id, true);
                        const grantRules = getGrantRulesForProcedure(state, node.rootId);
                        grantRules.forEach(grantRule => {
                            addDependency(update, {from: grantRule, to: node, properties: ['permissions']});
                        })

                        if(!anonymousCanCreateOnly) {
                            compileWarnings.push('Complete mode calculate is only available anonymously');
                        }
                    }
                }

                if(node.actionType === RULE_ACTION_TYPE.calculatePreviousVersions.id) {
                    const blockedRules = getNodesIfPresent(state, node.nodeIds);

                    // check if alwaysOn is true
                    if(blockedRules.some(r => !r.alwaysOn)) {
                        compileWarnings.push('Always On should be enabled for Calculate for previous versions rule');
                    }


                }

                let childErrors = childRules.filter(a => a.compileWarnings != null).flatMap(r => r.compileWarnings);
                compileWarnings.push(...childErrors);
                node.compileWarnings = compileWarnings.filter(a => a != null);
            }

            // This should be called before of the redux dependent - append return to appliedNodes

            // On to sub Rule
            // How to do this when we have 2 sources of truth?
            // One idea is dont, always update via the sub rule?
            // Another idea is to know if we are updating due to server or user

            // extract all node ids from the rules
            let conditionQueryDep = extractReferencedNodeDep(node, node.conditionQuery, ['deleted', 'parentDeleted', 'questionType']);
            let calculateValueQueryDep = extractReferencedNodeDep(node, node.calculateValueQuery, ['deleted', 'parentDeleted', 'questionType', 'number']);
            // Revaluate this when these things change
            let dependencyUpdates = [
                ...appliedToNodes.map(a => ({
                    from: a,
                    to: node,
                    properties: ['createExecutionProcedureId', 'fixMode', 'questionType', 'draft', 'selectDataSource', 'deleted', 'parentDeleted', 'actionType']
                })),
                ...appliedToNodes.map(a => ({
                    from: node,
                    to: a,
                    properties: ['compileWarnings', 'actionType', 'deleted', 'draft']
                })),
                ...childRules.map(a => ({
                    from: a,
                    to: node,
                    properties: ['createExecutionProcedureId', 'compileWarnings']
                })),
                ...(node.linkMatchProcedureIds || []).map(a => ({
                    from: a,
                    to: node,
                    properties: ['loaded', 'loadedFull']
                })),
                ...conditionQueryDep,
                ...calculateValueQueryDep
            ];
            addDependency(update, dependencyUpdates);
            addDependency(update, {from: node.linkMatchProcedureIds, to: node, properties: ['loadedFull']});
            // Node name depends on calculateValueHuman, which might change after name is calculated
            addDependency(update, {from: node, to: node, properties: ['calculateValueHuman']});
            //addDependency(update, {from: node.rootId, to: node, properties: ['rules']});
            if (rootRule !== node) {
                addDependency(update, {from: rootRule, to: node, properties: ['name', 'procedureOnly']});
            }

            return update;
        }
    },
    "ProcedureSchema": { ...domainRuleNOP },
    "ProcedureSchemaProperty": {
        ...domainRuleNOP ,
        onPut: (state, node, beforeState) => {
            let update = {};
            const beforeNode = getNodeOrNull(beforeState, node.id);
            const sourceNode = getNodeOrNull(state, node.nodeId);
            if (beforeNode && beforeNode.elementName !== node.elementName) {
                node.proposed = null;
            }

            if (sourceNode) {
                node.source = sourceNode.name;
            }

            if(beforeNode && beforeNode.nodeId !== node.nodeId && sourceNode) {
                node.format = QUESTION_TYPES_FORMAT[sourceNode.questionType];
                node.propertyType = QUESTION_TYPES_SCHEMA[sourceNode.questionType] ?? "string";
            }


            update[node.id] = node;
            return update;
        }
    },
    ExecutionRoot: {
        ...domainRuleNOP,
        onPut: (state, node, beforeState, raisedAction, updatedNodes) => {
            // // If we reload from server via summary we will lose some properties which will break offline
            // if (!node.loaded || !node.procedureId) {
            //     // This may happen with recomputeAll for an execution that does not exist ?
            //     // Actually if loaded = false we should not get here, so not sure how we are.
            //     return {};
            // }
            const beforeNode = getNodeOrNull(beforeState, node.id);
            if (beforeNode && makeArray(node.children).length === 0 && makeArray(beforeNode.children).length !== 0) {
                node.children = beforeNode.children;
                node.links = beforeNode.links;
                node.assignments = beforeNode.assignments;
                node.titleTemplate = beforeNode.titleTemplate;
                node.rules = beforeNode.rules;
                // As we only got the summary and not full we want to make sure the next time
                // we visit the execution we get children changes, so lets not keep lastUpdatedDateTime
                node.lastUpdatedDateTime = beforeNode.lastUpdatedDateTime;
                let dirty = getDirty(state, node.id);
                let dirtyBefore = getDirty(beforeState, node.id);
                if (dirty.storeServer && dirtyBefore && dirtyBefore.storeServer) {
                    dirty.storeServer.children = dirtyBefore.storeServer.children;
                    dirty.storeServer.links = dirtyBefore.storeServer.links;
                    dirty.storeServer.assignments = dirtyBefore.storeServer.assignments;
                    dirty.storeServer.titleTemplate = dirtyBefore.storeServer.titleTemplate;
                    dirty.storeServer.rules = dirtyBefore.storeServer.rules;
                }
            }
            // Check if we have reloaded the execution before a link is properly saved + replicated
            // I am a little worried this may make the client get out of sync with the server and result in
            // unusual behaviour
            if (beforeNode && beforeNode.links && node.links && beforeNode.links.length !== node.links.length) {
                let lost = beforeNode.links.filter(a => !node.links.includes(a));
                let lostLinks = getNodesIfPresent(state, lost);
                let currentLinks = getNodesIfPresent(state, node.links);
                for (let lostLink of lostLinks) {
                    let linkFound = currentLinks.find(a => a.toNodeId === lostLink.toNodeId && a.linkType === lostLink.linkType);
                    if (lostLink.draft && !linkFound) {
                        node.links = mergeUnique(node.links, lostLink.id);
                    }
                }
            }

            // DEFAULTS
            node.preview = node.preview || false;
            node.treeViewToggleState = node.treeViewToggleState == null ? true : !!node.treeViewToggleState;
            node.treViewSelectedState = node.treViewSelectedState ?? [];
            node.treeViewExpandedState = node.treeViewExpandedState ?? [];
            node.selectedCategory = node.selectedCategory ?? 0;
            // DENORMALISE
            node.denormalised = typeof node.denormalised === 'boolean' ? node.denormalised : !!node.name || !!node.key;
            if (!node.procedureId) {
                throw new GraphProcessingError(`WORQ Item [${node.id}] [${node.key}] does not have procedureId set. Node: ${JSON.stringify(node)}`);
            }
            if (!node.denormalised || window.alwaysComputeDirty) {

                let procedure = getNodeOrNull(state, node.procedureId);
                if (!procedure) {
                    throw new GraphProcessingError(`WORQ template [${node.name}] with id [${node.procedureId}] has not been loaded. Node: [${node.id}]`);
                }
                node.name = procedure.name;
                node.category = procedure.category;
                node.procedureType = procedure.procedureType;
                node.titleTemplate = procedure.titleTemplate;
                node.procedureReleaseVersion = procedure.releaseVersion;

                updatedNodes = rewriteRules(beforeState, state, node);

                for (let i = 1; i <= KEY_FIELD_COUNT; i++) {
                    let name = 'keyField' + i + 'QuestionId';
                    node[name] = procedure[name] || null;
                }

                node.denormalised = true;
            }

            // If downloaded via offline it is missing the root id in the scopes
            // When first created it is missing the resource synch scope
            let projectScope = node.projectId ? [`/executions?projectId=${node.projectId}&includeDeleted=${node.deleted}&summary=true`] : [];
            // When child added it is missing its parent scope
            let linkedScopes = [];
            for (let linkId of node.links || []) {
                let link = getNodeOrNull(state, linkId);
                if (link) {
                    let linkedScope = `/executions?scopeId=${link.toNodeId}&summary=true&includeDeleted=true`;
                    linkedScopes.push(linkedScope);
                }
            }
            node.scopes = mergeUnique(node.scopes, [node.rootId, ...projectScope, ...linkedScopes]);

            // If summary or children not loaded we cannot compute
            calculateLoadedFull(state, node)
            if (beforeNode && beforeNode.loadedFull && !node.loadedFull) {
                reportDeveloperWarning('ExecutionRoot' + node.id + ' went form loadedFull true to false. How?');
            }
            
            // NODE FIELDS
            if (!node.fields) {
                node.fields = {};
            }



            for (let i = 1; i <= KEY_FIELD_COUNT; i++) {
                const procedureQuestionId = node['keyField' + i + 'QuestionId'];
                if (!procedureQuestionId) {
                    continue;
                }

                // add field for default view
                const field = node.fields[procedureQuestionId];
                if(!field) {
                    if(node.fields === beforeNode?.fields) {
                        node.fields = shallowClone(node.fields);
                    }
                    const value = node['keyField' + i + 'Value'];
                    let fieldValue = tryParseValue(value);

                    node.fields[procedureQuestionId] = { 
                        procedureNodeId: procedureQuestionId,
                        name: node['keyField' + i + 'Name'],
                        valueFormatted: value,
                        value: fieldValue ?? value
                    }
                }
            }

            let dependencyUpdates = [];
            if (node.loadedFull) {
                // COMPLETED
                const stepChildren = getVisibleNodesSafe(state, node.children, node);
                node.completed = stepChildren.length > 0 && stepChildren.filter((step) => !step.completed && (step.statisticsMode !== STATISTICS_MODES.exclude.id || node.draft === true)).length === 0;
                node.completedDate = null;
                if (node.completed) {
                    for (let step of stepChildren) {
                        if (step.statisticsMode !== STATISTICS_MODES.exclude.id || node.draft === true) {
                            node.completedDate = getMaxJsonDate(step.completedDate, node.completedDate);
                        }
                    }
                }

                // KEY FIELDS
                for (let i = 1; i <= KEY_FIELD_COUNT; i++) {
                    let questionId = node['keyField' + i + 'ExecutionQuestionId'];
                    let procedureQuestionId = node['keyField' + i + 'QuestionId'];
                    if (!procedureQuestionId) {
                        node['keyField' + i + 'Name'] = null;
                        node['keyField' + i + 'Value'] = null;
                        node['keyField' + i + 'ExecutionQuestionId'] = null;
                        node['keyField' + i + 'QuestionId'] = null;
                        continue;
                    }
                    let mapId = !beforeNode
                        || beforeNode['keyField' + i + 'QuestionId'] !== procedureQuestionId
                        || (procedureQuestionId && !questionId);
                    if (mapId) {
                        let descendents = getActiveExecutionQuestions(state, node.id);
                        let match = descendents.find(a => a.procedureQuestionId === procedureQuestionId);
                        if (match) {
                            questionId = node['keyField' + i + 'ExecutionQuestionId'] = match.id;
                        }
                    }
                    let question = getNodeOrNull(state, questionId);
                    if (question) {
                        node['keyField' + i + 'Name'] = question.name;
                        node['keyField' + i + 'Value'] = question.finalValueFormatted;
                        dependencyUpdates.push({
                            from: question,
                            to: node,
                            properties: ['name', 'finalValueFormatted']
                        });
                    }
                }

                // NAVIGATIONS STYLE
                node.navigationStyle = getNavigationStyle(state, node.id);
                dependencyUpdates.push({
                    from: NODE_IDS.UserDevice,
                    to: node,
                    properties: ['procedureExecutionView', 'troubleshootOn']
                })

                // GLOBAL NAVIGATION STYLE
                const globalNavStyle = getGlobalNavStyle(state, node.procedureId);
                if (globalNavStyle && globalNavStyle.deleted !== true && globalNavStyle.type !== GLOBAL_NAVIGATION_STYLE.default.id) {
                    const linkTreeId = NODE_IDS.ExecutionLinkTree(node.procedureId);
                    let linkTree = getNodeOrNull(state, linkTreeId);
                    if (!linkTree) {
                        let listingSchema = getNodeSchemaOrError(state, NODE_TYPE_OPTIONS.ExecutionListPage);
                        let listingAttributes = {id: linkTreeId, procedureId: node.procedureId, linkTree: true};
                        linkTree = createNode(listingSchema, listingAttributes);
                        updatedNodes[linkTree.id] = linkTree;
                    }
                }

                // ACTIVE CHILDREN
                const userDevice = getNodeOrError(state, NODE_IDS.UserDevice)
                node.activeChildren = getActiveSteps(state, node.id, userDevice.troubleshootOn).map(a => a.id)
                node.selectedStepIndex = node.selectedStepIndex || 0
                if (node.selectedStepIndex >= node.activeChildren.length) {
                    node.selectedStepIndex = node.activeChildren.length - 1;
                }

                // RELOAD INTERVAL
                const reloadIntervalRule = getActiveChildRulesForNodeByActionOrNull(state, node.id, RULE_ACTION_TYPE.reloadInterval.id).filter(a => a.evaluatedValue)?.[0];
                node.reloadInterval = (reloadIntervalRule?.calculateValue || 5 * 60) * 1000;
                if (reloadIntervalRule) {
                    dependencyUpdates.push({from: reloadIntervalRule, to: node, properties: ['calculateValue']})
                }

                // SUMMARY FIELDS
                let summaryId = getSummaryId(node.id);
                let summary = getNodeOrNull(state, summaryId);
                if (summary && summary.fields) {
                    // We need any fields on summary on null so we can compute them and pass them over to summary
                    const summaryClone = getDeepClonedNodeOrError(state, summary);
                    node.fields = {...node.fields, ...summaryClone.fields};
                }

                // FIELDS
                if (node.fields) {
                    for (let [procedureNodeId, field] of Object.entries(node.fields)) {
                        if (!field.id) {
                            const question = getActiveChildrenDescendantsAndSelf(state, node.id).find(a => a.procedureQuestionId === procedureNodeId)
                            field.id = question?.id;
                        }
                        let question = getNodeOrNull(state, field.id);
                        if (question && !areSame(field.value, question.finalValue)) {
                            node.fields = shallowClone(node.fields)
                            field = {
                                ...field,
                                value: question.finalValue,
                                valueFormatted: question.finalValueFormatted
                            }
                            node.fields[field.procedureNodeId] = field
                        }
                        let dirtyNode = getDirty(state, field.id)
                        let isDifferent = !areSame(dirtyNode?.storeServer?.initialValue, question?.initialValue)
                            || !areSame(dirtyNode?.storeServer?.initialValueFormatted, question?.initialValueFormatted)
                        if (isDifferent && !node.fields[field.procedureNodeId].isDifferent) {
                            node.fields = shallowClone(node.fields)
                            node.fields[field.procedureNodeId] = {
                                ...field,
                                isDifferent: isDifferent
                            }
                        }
                        if (!isDifferent && node.fields[field.procedureNodeId].isDifferent) {
                            node.fields = shallowClone(node.fields)
                            delete node.fields[field.procedureNodeId].isDifferent
                        }
                        dependencyUpdates.push({from: field.id, to: node.id, properties: ['finalValue']})
                    }
                }

                // TITLE
                if (node.titleTemplate) {
                    const textTemplateHuman = textTemplateFromHuman(state, node, node.titleTemplate);
                    node.title = textTemplateToFormatted(state, node, textTemplateHuman.template);
                    for (let dep of textTemplateHuman.dependencies) {
                        dependencyUpdates.push(dep);
                    }
                } else {
                    let executionTitle = node.name + ' for ';
                    for (let i = 1; i <= KEY_FIELD_COUNT; i++) {
                        let keyFieldValue = node['keyField' + i + 'Value'];
                        if (keyFieldValue) {
                            let keyFieldName = node['keyField' + i + 'Name'];
                            executionTitle += keyFieldName + ' ' + keyFieldValue + ' ';
                        }
                    }
                    node.title = executionTitle.trim();
                }

                if(!node.title) {
                    node.title = strings.execution.show.blankTitle
                }

                // COUNTS
                let completedQuestionCount = 0;
                let totalQuestionCount = 0;
                let completedSignoffCount = 0;
                let totalSignoffCount = 0;
                let totalPhotoCount = 0;
                let warningCount = 0;
                let children = getVisibleNodesSafe(state, node.children, node);
                for (let step of children) {
                    completedQuestionCount += step.completedQuestionCount || 0;
                    totalQuestionCount += step.totalQuestionCount || 0;
                    completedSignoffCount += step.completedSignoffCount || 0;
                    totalSignoffCount += step.totalSignoffCount || 0;
                    totalPhotoCount += step.totalPhotoCount || 0;
                    warningCount += step.warningCount || 0;
                }
                node.completedQuestionCount = completedQuestionCount;
                node.totalQuestionCount = totalQuestionCount;
                node.completedSignoffCount = completedSignoffCount;
                node.totalSignoffCount = totalSignoffCount;
                node.totalPhotoCount = totalPhotoCount;
                node.warningCount = warningCount;
                // Scope load return fields
                // This is the fields used in any Query question types
                // I'm a little worried this wil be $$$ so only run once and on preview, and on pull if rules change
                //if (node.scopeReturnFields == null || node.preview === true || beforeNode?.rules?.length !== node.rules.length) {
                //let queryQuestions = getActiveChildrenDescendantsAndSelf(state, node.id)
                //    .filter(a => a.questionType === QUESTION_TYPES.link.id);
                //const allReturnFields = {};
                //for (let q of queryQuestions) {
                //    const listingPage = getNodeOrNull(state, NODE_IDS.ExecutionListingPage(q.id));
                //    if (listingPage && listingPage.columns) {
                //        const returnFields = listingPage.columns
                //            .flatMap(a => Object.values(a.sources || {}))
                //            .flatMap(a => a.returnFields || []);
                //        for (let field of returnFields) {
                //            allReturnFields[field] = true;
                //        }
                //        addDependency(updatedNodes, {from: listingPage, to: node, properties: ['columns']});
                //    }
                //}
                //node.scopeReturnFields = Object.keys(allReturnFields);
                //node.scopeReturnFields.sort();
                //}
            }

            // MARK PROCESSED
            if (!node.processedFull && !isSummaryId(node.id)) {
                let steps = getNodesSafe(state, node.children, node);
                node.processedFull = steps.reduce((stepsProcessed, step) => stepsProcessed && !!step.processedFull, true);
                for (let step of steps) {
                    addDependency(updatedNodes, {from: step.id, to: node.id, properties: ['processedFull']});
                }
            }
            

            let totalCount = (node.totalQuestionCount || 0) + (node.totalSignoffCount || 0);
            let completedCount = (node.completedQuestionCount || 0) + (node.completedSignoffCount || 0);
            node.completedRatio = totalCount ? Math.round(completedCount * 100 / totalCount) / 100.0 : node.processedFull ? 1 : 0;
            node.status = node.completedRatio === 1 ? EXECUTION_STATUS.done.id : (node.completedRatio === 0 ? EXECUTION_STATUS.notstarted.id : EXECUTION_STATUS.inprogress.id);

            // UPDATE SUMMARY
            if (node.loadedFull) {
                let summaryId = getSummaryId(node.id);
                let summary = getNodeOrNull(state, summaryId);
                let dirty = getDirty(state, node.id);
                // Update summary if full is newer or edited
                if (summary && dirty && dirty.storeServer) {
                    summary = cloneDeep(summary);
                    let fullNewer = isDateGreaterThan(node.lastUpdatedDateTime, summary.lastUpdatedDateTime);
                    let copyProperties = executionRootFullToSummaryFieldIds
                        .filter(a => fullNewer || !areSame(node[a], dirty.storeServer[a]));
                    // Update if full changed or full is newer
                    for (let p of copyProperties) {
                        summary[p] = node[p];
                    }
                    for (let fieldId of Object.keys(node.fields || {})) {
                        const copyNow = fullNewer || !summary.fields[fieldId] || node.fields?.[fieldId]?.isDifferent
                        if (copyNow) {
                            summary.fields[fieldId] = node.fields[fieldId]
                        }
                    }
                    updatedNodes[summary.id] = summary;
                }
                dependencyUpdates.push({from: summaryId, to: node, properties: ['lastUpdatedDateTime', 'fields']});
            }

            // RETAIN EXISTING FIELDS
            // This is for when a Summary appears in 2 lists with different returnFields, such as different
            // views on the summary page. This does mean we may have old field data on an otherwise updated summary
            if (node.fields && beforeNode?.fields) {
                node.fields = {
                    ...beforeNode?.fields,
                    ...node.fields
                }
            }

            if (node.loadedFull) {
                // Resource dependencies
                const executionListPaths = new Set();
                const preloadExecutionListPaths = new Set();
                const projectIds = [];
                const photoPaths = new Set();
                const procedureIds = new Set();
                const preloadProcedureIds = new Set();
                const loaderDeps = [];
                const executionIds = new Set();
                const preloadExecutionIds = new Set();
                let scopePaths = new Set();
                let scopePathKeys = new Set();

                const userDevice = getNodeOrNull(state, NODE_IDS.UserDevice);

                // Query
                let queryQuestions = getNodesIfPresent(state, node.queryQuestionIds);
                for (let q of queryQuestions) {
                    dependencyUpdates.push({
                        from: q.id,
                        to: node.id,
                        properties: ['questionType', 'initialValue', 'linkStyle']
                    })
                    if (!q.visible) {
                        continue;
                    }
                    if (q.questionType === QUESTION_TYPES.link.id && q.linkStyle === PROCEDURE_LINK_STYLE.report.id) {
                        let queryRules = getActiveRulesForNode(state, q.id).filter(a => a.linkToQuestionOn);
                        let reportProcedureIds = queryRules.flatMap(r => r.linkMatchProcedureIds);
                        mergeSet(procedureIds, reportProcedureIds);
                    }
                    if (q.questionType === QUESTION_TYPES.link.id && q.linkStyle === PROCEDURE_LINK_STYLE.repeatingSections.id) {
                        const selectorId = NODE_IDS.ExecutionListingPage(q.id);
                        const selector = getNodeOrNull(state, selectorId);
                        const useExecutionIds = q.initialValue || selector?.executionIds || [];
                        useExecutionIds.forEach((id) => {
                            executionIds.add(id);
                            dependencyUpdates.push({
                                from: id,
                                to: node.id,
                                properties: ['loaded', 'loading', 'resourceDependencies']
                            });
                            const sectionExecution = getNodeOrNull(state, id);
                            if (!sectionExecution || !sectionExecution.resourceDependencies) return;
                            const {
                                executionIds: sectionExecutionIds,
                                preloadExecutionIds: sectionPreloadExecutionIds,
                                executionListPaths: sectionExecutionListPaths,
                                preloadExecutionListPaths: sectionPreloadExecutionListPaths,
                                procedureIds: sectionProcedureIds,
                                preloadProcedureIds: sectionPreloadProcedureIds,
                                photoPaths: sectionPhotoPaths,
                                scopePaths: sectionScopePaths,
                                scopePathKeys: sectionScopePathKeys,
                            } = sectionExecution?.resourceDependencies;
                            mergeSet(executionIds, sectionExecutionIds);
                            mergeSet(preloadExecutionIds, sectionPreloadExecutionIds);
                            mergeSet(executionListPaths, sectionExecutionListPaths);
                            mergeSet(preloadExecutionListPaths, sectionPreloadExecutionListPaths);
                            mergeSet(procedureIds, sectionProcedureIds);
                            mergeSet(preloadProcedureIds, sectionPreloadProcedureIds);
                            mergeSet(photoPaths, sectionPhotoPaths);
                            mergeSet(scopePaths, sectionScopePaths);
                            mergeSet(scopePathKeys, sectionScopePathKeys);
                        }, executionIds);
                        loaderDeps.push(...useExecutionIds);
                    }
                    // If item template has bulk actions available, lets preload the template to know what the actions are
                    //const queryRules = getActiveRulesForNode(state, q);
                    // Get rules and then list of procedure ids and add to the preload list
                    // Only do this if there are any items in the liss
                    const usedProcedureIds = getActiveRulesForNode(state, q.id)
                        .filter(r => r.linkToQuestionOn)
                        .flatMap(r => r.linkMatchProcedureIds || []);
                    const tenantConfig = getNodeOrNull(state, NODE_IDS.ClientConfig);
                    if (tenantConfig?.procedures) {
                        for (let procedureId of usedProcedureIds) {
                            const procedureConfig = tenantConfig?.procedures[procedureId]
                            if (procedureConfig?.hasBulkActions) {
                                preloadProcedureIds.add(procedureId)
                            }
                        }
                    }
                    dependencyUpdates.push({from: NODE_IDS.ClientConfig, to: node.id, properties: ['procedures']});
                }

                // Select
                let selectQuestions = getNodesIfPresent(state, node.selectDynamicQuestionIds);
                for (let q of selectQuestions) {
                    dependencyUpdates.push({
                        from: q.id,
                        to: node.id,
                        properties: ['questionType', 'selectExecutionFilter', 'disabled', 'optionsParsed']
                    })
                    if (q.questionType === QUESTION_TYPES.select.id && q.selectExecutionFilter && !q.disabled) {
                        const path = NODE_IDS.ExecutionQuestionSelect(q);
                        loaderDeps.push(path);
                        const nodeIsFocused = state.focusedNode?.id === q.id;
                        const isDynamicChipWithoutData = q.visible && q.selectRenderMode === SELECT_RENDER_MODES.autocomplete.id && q.options === null;
                        if (isDynamicChipWithoutData || nodeIsFocused) {
                            executionListPaths.add(path);
                        } else {
                            preloadExecutionListPaths.add(path);
                        }
                    }
                }

                // New links
                // In case of copy to
                let links = getNodesIfPresent(state, node.newLinksForLoad || []);
                const newlyLinkedExecutionIds = [];
                const forRemoval = [];
                links.forEach((link) => {
                    const e = getNodeOrNull(state, link.toNodeId);
                    if (!e || !e.loadedFull || !isNodeSaved(link) || isRecent(link.createdDateTime, 60 * 1000)) {
                        newlyLinkedExecutionIds.push(link.toNodeId);
                    } else {
                        forRemoval.push(link.id);
                    }
                    dependencyUpdates.push({from: link.id, to: node.id, properties: ['createdDateTime']});
                    dependencyUpdates.push({from: link.toNodeId, to: node.id, properties: ['loadedFull']});
                })
                if (forRemoval.length) {
                    node.newLinksForLoad = without(node.newLinksForLoad, ...forRemoval);
                }
                mergeSet(preloadExecutionIds, newlyLinkedExecutionIds);

                // Project
                if (node.projectId) {
                    projectIds.push(node.projectId);
                    loaderDeps.push(node.projectId)
                }

                // COMPLETE ACCESS
                const [completeAccess, dependencies] = computeCompleteAccess(state, node, node.draft, null, true);
                node.completeAccess = completeAccess;
                addDependency(updatedNodes, dependencies);

                // Photo
                if (completeAccess.canView && isNodeSaved(node)) {
                    const photoPath = NODE_IDS.PhotosForExecution(node.id, node.projectId);
                    photoPaths.add(photoPath);
                    loaderDeps.push(photoPath);
                }

                // Scope
                if (completeAccess.canView && isNodeSaved(node)) {
                    const scopePath = NODE_IDS.ExecutionSummaryScoped(node.id, node.scopeReturnFields || []);
                    scopePaths.add(scopePath);
                    scopePathKeys.add(scopePath + node.maxLinkLastUpdatedDateTime);
                }

                const requiresOwnProcedure = selectQuestions.some((q) => q.selectDataSource === SELECT_DATA_SOURCES.executionDynamic.id && q.selectRenderMode === SELECT_RENDER_MODES.autocomplete.id && q.optionsParsed?.length > 8);

                // Templates
                let getTemplateIdOptions = {
                    includeCreated: userDevice?.troubleshootOn,
                    includeSelf: requiresOwnProcedure,
                    forOffline: false
                };
                let usedProcedureResult = getDependentTemplateIds(state, node.id, getTemplateIdOptions);
                dependencyUpdates = [...dependencyUpdates, ...usedProcedureResult.dependencyUpdates];
                dependencyUpdates.push({from: NODE_IDS.UserDevice, to: node.id, properties: ['troubleshootOn']});
                for (let procedureId of usedProcedureResult.procedureIds) {
                    procedureIds.add(procedureId);
                    loaderDeps.push(procedureId)
                }
                for (let procedureId of usedProcedureResult.preloadProcedureIds) {
                    preloadProcedureIds.add(procedureId);
                    loaderDeps.push(procedureId)
                }

                node.resourceDependencies = {
                    scopePaths: [...scopePaths],
                    scopePathKeys: [...scopePathKeys]
                }
                if (scopePaths.size > 0) {
                    node.resourceDependencies.scopePaths = [...scopePaths];
                }
                if (scopePathKeys.size > 0) {
                    node.resourceDependencies.scopePathKeys = [...scopePathKeys];
                }
                if (executionListPaths.size > 0) {
                    node.resourceDependencies.executionListPaths = [...executionListPaths];
                }
                if (preloadExecutionListPaths.size > 0) {
                    node.resourceDependencies.preloadExecutionListPaths = [...preloadExecutionListPaths];
                }
                if (procedureIds.size > 0) {
                    node.resourceDependencies.procedureIds = [...procedureIds];
                }
                if (preloadProcedureIds.size > 0) {
                    node.resourceDependencies.preloadProcedureIds = [...preloadProcedureIds];
                }
                if (projectIds.length > 0) {
                    node.resourceDependencies.projectIds = projectIds;
                }
                if (photoPaths.size > 0) {
                    node.resourceDependencies.photoPaths = [...photoPaths];
                }
                if (executionIds.size > 0) {
                    node.resourceDependencies.executionIds = [...executionIds];
                }
                if (preloadExecutionIds.size > 0) {
                    node.resourceDependencies.preloadExecutionIds = [...preloadExecutionIds];
                }

                for (let resourceId of loaderDeps) {
                    dependencyUpdates.push({
                        from: resourceId,
                        to: node.id,
                        properties: ['loaded', 'loading', 'loadingError']
                    });
                    const loader = getNodeOrNull(state, resourceId);
                    node.resourceDependencies.loading = !!loader?.loading;
                    node.resourceDependencies.loadingError = !!loader?.loadingError;
                    node.resourceDependencies.loaded = !!loader?.loaded;
                }
            }

            addDependency(updatedNodes, dependencyUpdates);

            return updatedNodes;
        }
    },
    ExecutionStep: {
        ...domainRuleNOP,
        onPut: (state, node, beforeState, raisedAction, updated) => {
            let execution = getNodeByProperty(state, node, 'parentId');

            // DENORMALISE
            // When loaded from server check this via completeMode
            node.denormalised = typeof node.denormalised === 'boolean' ? node.denormalised : !!node.name;
            if (!node.denormalised || window.alwaysComputeDirty) {
                let procedureStep = getNodeByProperty(state, node, 'procedureStepId');
                node.name = procedureStep.name;
                node.completeMode = procedureStep.completeMode;
                node.visibleMode = procedureStep.visible;
                node.statisticsMode = procedureStep.statisticsMode;
                node.deleted = procedureStep.deleted;
                node.denormalised = true;
            }

            // If downloaded via offline it is missing the root id in the scopes
            node.scopes = mergeUnique(node.scopes, [node.rootId]);

            // DELETED
            if (node.deleted) {
                return {[node.id]: node};
            }

            // VISIBLE
            node.visible = evaluateVisibleRule(state, node, node.visibleMode, node.visibleRuleQuery, updated).visible;

            // COMPLETED
            node.completeEnabled = node.completeMode === 'step';
            // If cannot sign off step, lets derive completion date
            if (node.completeEnabled) {
                node.completed = (node.visible && node.userCompleted && node.userCompleted.completed) || false;
                node.completedDate = node.completed ? (node.userCompleted && node.userCompleted.completedDate) || null : null;
            } else {
                node.completed = node.visible;
                node.completedDate = null;
                if (node.visible) {
                    const taskChildren = getVisibleNodesSafe(state, node.children, node)
                    node.completed = taskChildren.length > 0 && taskChildren.filter((task) => !task.completed).length === 0;
                    for (let child of taskChildren) {
                        node.completedDate = getMaxJsonDate(child.completedDate, node.completedDate);
                    }
                }
                if (!node.completed) {
                    node.completedDate = null;
                }
            }
            node.completed = defaultValue(node.completed, false);
            node.completedDate = dateToJson(node.completedDate);
            
            // TOTAL
            const includeStatistics = node.visible && node.statisticsMode !== 'exclude';
            let allQuestionsCompleted = true;
            let completedQuestionCount = 0;
            let totalQuestionCount = 0;
            let completedSignoffCount = 0;
            let totalSignoffCount = 0;
            let warningCount = 0;
            let totalPhotoCount = 0;
            let taskChildren = getVisibleNodesSafe(state, node.children, node);
            for (let i = 0; i < taskChildren.length; i++) {
                let taskChild = taskChildren[i];
                allQuestionsCompleted = allQuestionsCompleted && !!taskChild.allQuestionsCompleted;
                if (includeStatistics) {
                    completedQuestionCount += taskChild.completedQuestionCount || 0;
                    totalQuestionCount += taskChild.totalQuestionCount || 0;
                    completedSignoffCount += taskChild.completedSignoffCount || 0;
                    totalSignoffCount += taskChild.totalSignoffCount || 0;
                    warningCount += taskChild.warningCount || 0;
                }
                if (node.visible) {
                    totalPhotoCount += taskChild.totalPhotoCount || 0;
                }
            }
            node.allQuestionsCompleted = allQuestionsCompleted;
            node.completedQuestionCount = completedQuestionCount;
            let stepSignedOff = node.completeEnabled && node.completed;
            // So that it stays at 100% if we push in a new question to a signed off step
            node.totalQuestionCount = stepSignedOff ? completedQuestionCount : totalQuestionCount;
            node.completedSignoffCount = includeStatistics && node.completeEnabled ? (node.completed ? 1 : 0) : completedSignoffCount;
            node.totalSignoffCount = includeStatistics && node.completeEnabled ? 1 : totalSignoffCount;
            node.warningCount = warningCount;
            node.totalPhotoCount = totalPhotoCount;
            let completedCount = completedQuestionCount + completedSignoffCount;
            let totalCount = totalQuestionCount + totalSignoffCount;
            node.completedRatio = totalCount ? Math.round(completedCount * 100 / totalCount) / 100.0 : 0;
            
            // COMPLETE ACCESS
            const [completeAccess, dependencies] = computeCompleteAccess(state, node, execution.draft, null, true);
            node.completeAccess = completeAccess;
            addDependency(updated, dependencies);
            // DISABLED
            node.disabled = node.completeAccess?.disabled === true || !node.visible;

            // MARK PROCESSED
            if (!node.processedFull) {
                let tasks = getNodesSafe(state, node.children, node);
                node.processedFull = tasks.reduce((tasksProcessed, task) => tasksProcessed && !!task.processedFull, true);
                for (let task of tasks) {
                    addDependency(updated, {from: task.id, to: node.id, properties: ['processedFull']});
                }
            }

            return updated;
        }
    },
    ExecutionTask: {
        ...domainRuleNOP,
        onPut: (state, node, beforeState, raisedAction, updated) => {
            let executionStep = getNodeByProperty(state, node, 'parentId');
            let execution = getNodeByProperty(state, executionStep, 'parentId');

            // DENORMALISE
            // When loaded from server check this via visibleMode
            node.denormalised = typeof node.denormalised === 'boolean' ? node.denormalised : !!node.name;
            if (!node.denormalised || window.alwaysComputeDirty) {
                let procedure = getNodeOrNull(state, execution.procedureId);
                if (!procedure) {
                    return {[node.id]: node};
                }
                let procedureTask = getNodeByProperty(state, node, 'procedureTaskId');
                node.name = procedureTask.name;
                node.visibleMode = procedureTask.visible;
                node.statisticsMode = procedureTask.statisticsMode;
                node.deleted = procedureTask.deleted || procedureTask.parentDeleted;
                node.denormalised = true;
            }

            // DELETED
            if (node.deleted) {
                return {[node.id]: node};
            }

            // VISIBLE
            node.visible = evaluateVisibleRule(state, node, node.visibleMode, node.visibleRuleQuery, updated).visible;

            // COMPLETE
            node.completeEnabled = executionStep.completeMode === 'task';
            // If cannot sign off task, lets derive completion date
            if (node.completeEnabled) {
                node.completed = (node.visible && node.userCompleted && node.userCompleted.completed) || false;
                node.completedDate = node.completed ? (node.userCompleted && node.userCompleted.completedDate) || null : null;
            } else {
                node.completed = node.visible;
                node.completedDate = null;
                if (node.visible) {
                    const questionChildren = getVisibleNodesSafe(state, node.children, node)
                    node.completed = questionChildren.length > 0 && questionChildren.filter((question) => !question.completed).length === 0;
                    for (let child of questionChildren) {
                        node.completedDate = getMaxJsonDate(child.completedDate, node.completedDate);
                    }
                }
                if (!node.completed) {
                    node.completedDate = null;
                }
            }
            node.completed = defaultValue(node.completed, false);
            node.completedDate = dateToJson(node.completedDate);

            // TOTAL
            let includeStatistics = node.visible && node.statisticsMode !== 'exclude';
            let allQuestionsCompleted = true;
            let completedQuestionCount = 0;
            let totalQuestionCount = 0;
            let warningCount = 0;
            let totalPhotoCount = 0;
            let questionChildren = getVisibleNodesSafe(state, node.children, node);
            for (let i = 0; i < questionChildren.length; i++) {
                let questionChild = questionChildren[i];
                allQuestionsCompleted = allQuestionsCompleted && !!questionChild.completed;
                if (includeStatistics && node.visible) {
                    completedQuestionCount += questionChild.completed ? 1 : 0;
                    totalQuestionCount += 1;
                    warningCount += questionChild.issueType === ISSUE_TYPES.warning.id ? 1 : 0;
                }

                if (node.visible) {
                    totalPhotoCount += questionChild.totalPhotoCount || 0;
                }
            }
            node.allQuestionsCompleted = allQuestionsCompleted;
            node.completedQuestionCount = completedQuestionCount;
            let taskSignedOff = node.completeEnabled && node.completed;
            node.totalQuestionCount = taskSignedOff ? completedQuestionCount : totalQuestionCount;
            node.totalSignoffCount = node.completeEnabled && includeStatistics ? 1 : 0;
            node.completedSignoffCount = node.completeEnabled && node.completed && includeStatistics ? 1 : 0;
            node.warningCount = warningCount;
            node.totalPhotoCount = totalPhotoCount;
            let completedCount = completedQuestionCount + node.completedSignoffCount;
            let totalCount = totalQuestionCount + node.totalSignoffCount;
            node.completedRatio = totalCount ? Math.round(completedCount * 100 / totalCount) / 100.0 : 0;

            // COMPLETE ACCESS
            const [completeAccess, dependencies] = computeCompleteAccess(state, node, execution.draft, null, true);
            node.completeAccess = completeAccess;
            addDependency(updated, dependencies);

            // DISABLED
            node.disabled = executionStep?.disabled || node.completeAccess?.disabled === true || node.completeAccess?.canComplete === false || (executionStep?.completeEnabled === true && executionStep?.completed === true);
            addDependency(updated, {from: executionStep?.id, to: node, properties: ['disabled', 'completeEnabled', 'completed']});

            // MARK PROCESSED
            if (!node.processedFull) {
                let questions = getNodesSafe(state, node.children, node);
                node.processedFull = !!questions.length && questions.reduce((questionsProcessed, question) => questionsProcessed && !!question.processed, true);
                for (let question of questionChildren) {
                    addDependency(updated, {from: question.id, to: node.id, properties: ['processed']});
                }
            }

            return updated;
        }
    },
    ExecutionQuestion: {
        ...domainRuleNOP,
        onPut: (state, node, beforeState, processAction, updated) => {
            let executionTask = getNodeByProperty(state, node, 'parentId');
            let executionStep = getNodeByProperty(state, executionTask, 'parentId');
            let execution = getNodeByProperty(state, executionStep, 'parentId');

            // DENORMALISE
            // When loaded from server check this via questionType
            node.denormalised = typeof node.denormalised === 'boolean' ? node.denormalised : !!node.questionType;
            let procedureQuestion
            if (!node.denormalised || window.alwaysComputeDirty) {
                procedureQuestion = getNodeByProperty(state, node, 'procedureQuestionId');
                node.name = procedureQuestion.name;
                node.questionType = procedureQuestion.questionType;
                node.visibleMode = procedureQuestion.visible;
                node.minInclusive = procedureQuestion.minInclusive;
                node.maxInclusive = procedureQuestion.maxInclusive;
                node.warningMessage = procedureQuestion.warningMessage;
                node.unknownMode = procedureQuestion.unknown;
                node.photoMode = procedureQuestion.photo;
                node.initialCommentInstructions = procedureQuestion.initialCommentInstructions;
                node.resolvedCommentInstructions = procedureQuestion.resolvedCommentInstructions;
                node.photoInstructions = procedureQuestion.photoInstructions;
                node.commentMode = procedureQuestion.comment;
                node.format = procedureQuestion.format;
                node.formatDisplay = procedureQuestion.formatDisplay;
                node.messageType = procedureQuestion.messageType;
                if (node.questionType === 'message' && !node.initialValueReadOnly) {
                    // This is not 100% correct as a readonly rule now means live preview will not update it ...
                    node.initialValue = procedureQuestion.initialValue;
                }
                node.attachmentType = procedureQuestion.attachmentType;
                node.mobilePhotoMode = procedureQuestion.mobilePhotoMode;
                node.validOptions = procedureQuestion.validOptions;
                let resolveFixModes = ['resolve', 'resolvecomment', 'resolvephoto', 'resolvecommentphoto'];
                node.fixModeResolveFlag = resolveFixModes.includes(procedureQuestion.fixMode);

                let photoRequiredFixModes = ['commentphoto', 'photo', 'resolvephoto', 'resolvecommentphoto'];
                node.fixModePhotoFlag = photoRequiredFixModes.includes(procedureQuestion.fixMode);

                let commentRequiredFixModes = ['commentphoto', 'comment', 'resolvecomment', 'resolvecommentphoto'];
                node.fixModeCommentFlag = commentRequiredFixModes.includes(procedureQuestion.fixMode);
                node.textMultipleLines = procedureQuestion.textMultipleLines;
                node.selectMany = procedureQuestion.selectMany;
                node.selectRenderMode = procedureQuestion.selectRenderMode;
                node.selectDataSource = procedureQuestion.selectDataSource;
                if (node.selectDataSource === SELECT_DATA_SOURCES.static.id) {
                    node.options = procedureQuestion.options;
                }
                node.linkStyle = procedureQuestion.linkStyle;
                node.deleted = procedureQuestion.deleted || procedureQuestion.parentDeleted;
                node.denormalised = true;
            }
            let dependencyUpdates = [];

            // Do after denormalise as some procedureQuestion fields may be undefined
            const beforeNode = getNodeOrNull(beforeState, node.id);
            if (beforeNode == null) {
                //addMissingProperties(state, node);
            }

            // DELETED
            if (node.deleted) {
                // Calculated for important values even when deleted
                const [completeAccess, dependencies] = computeCompleteAccess(state, node, execution.draft, node.disabled, true);
                node.completeAccess = completeAccess;
                dependencyUpdates.push(...dependencies);

                node.disabled = executionTask?.disabled || node.completeAccess?.disabled === true || node.completeAccess?.canComplete === false || (executionTask?.completeEnabled === true && executionTask?.completed === true);
                dependencyUpdates.push({
                    from: executionTask?.id,
                    to: node,
                    properties: ['disabled', 'completeEnabled', 'completed']
                });
                return {[node.id]: node};
            }

            // DEFAULTS
            node.escalatedFlag = nullOrTrue((node.escalatedFlag == null) ? false : node.escalatedFlag);
            if (!node.escalatedFlag) {
                delete node.escalatedFlag;
            }
            node.escalatedToName = node.escalatedToName == null ? null : node.escalatedToName;
            if (!node.escalatedToName) {
                delete node.escalatedToName;
            }
            node.escalatedComment = node.escalatedComment == null ? null : node.escalatedComment;
            if (!node.escalatedComment) {
                delete node.escalatedComment;
            }

            if (node.initialValueUnknown) {
                node.initialValue = null;
            }

            // ReadOnly and calculated value
            // Is readonly if there is an on calculate, or a on copy to
            let readOnly = nullOrTrue(node.initialValueReadOnly);
            if (node.calculateRuleIds && node.calculateRuleIds.length > 0) {
                let rules = getNodesIfPresent(state, node.calculateRuleIds);
                let rulesLoaded = rules.length === node.calculateRuleIds.length;
                let ruleToUse = rules.find(a => !a.deleted && a.evaluatedValue && (a.calculateValueOn || a.copyToOn));
                if (ruleToUse || !rulesLoaded) {
                    // As often copy to rule is not loaded, we will assume its on
                    readOnly = true;
                } else if (rulesLoaded) {
                    readOnly = false;
                }
                // Make sure the rules know they control this node
                for (let rule of rules) {
                    let ids = rule.calculateNodeIds || [];
                    if (!ids.includes(node.id)) {
                        let clonedRule = updated[rule.id] || shallowClone(rule);
                        clonedRule.calculateNodeIds = [...ids, node.id];
                        updated[rule.id] = clonedRule;
                    }
                }
                // Re-evaluate this when rule is loaded
                dependencyUpdates.push(...node.calculateRuleIds.map(id => ({
                    from: id,
                    to: node.id,
                    properties: ['deleted', 'evaluatedValue', 'calculateValueOn', 'copyToOn']
                })));
            } else {
                readOnly = null;
            }

            const readonlyRules = getEnabledChildRulesForNodeByActionOrNull(state, node.id, RULE_ACTION_TYPE.readOnly.id).length > 0;
            node.initialValueReadOnly = nullOrTrue(readOnly || readonlyRules);
            if (!node.initialValueReadOnly) {
                delete node.initialValueReadOnly;
            }


            // CONVERT
            const convert = (value) => {
                if (value === '' || value == null || (Array.isArray(value) && value.length === 0)) {
                    value = null;
                }
                if (value != null && typeof value === 'number' && node.questionType !== QUESTION_TYPES.number.id) {
                    value = '' + value;
                }
                if (value != null && typeof value === 'boolean') {
                    value = '' + value;
                }
                if (value && node.selectMany && !Array.isArray(value)) {
                    // When live preview and selectMany changed
                    value = [value];
                }
                if (value && node.selectMany === false && Array.isArray(value)) {
                    // When live preview and selectMany changed to single.
                    // Will take first selection
                    value = value.length === 0 ? null : value[0];
                }
                if (value && node.selectMany) {
                    value = mergeUnique(value, [])
                }
                if (value && node.selectMany && Array.isArray(value)) {
                    const filtered = value.filter(a => a != null && a !== '');
                    value = filtered.length >= value.length ? value : filtered;
                }
                if (value === '' || value == null || (Array.isArray(value) && value.length === 0)) {
                    value = null;
                }
                return value;
            };
            node.initialValue = convert(node.initialValue);
            node.resolvedValue = convert(node.resolvedValue);

            node.optionsParsed = parseOptions(node.options);
            if (!node.optionsParsed?.length) {
                delete node.optionsParsed;
            }

            if (!!beforeNode) {
                if (beforeNode.initialValue !== node.initialValue) {
                    delete node.initialValueInvalidReason;
                }
                if (beforeNode.resolvedValue !== node.resolvedValue) {
                    delete node.resolvedValueInvalidReason;
                }
            }

            // VISIBLE
            let visibleResult = evaluateVisibleRule(state, node, node.visibleMode, node.visibleRuleQuery, updated);
            node.visible = visibleResult.visible;

            // COMPLETE ACCESS
            const [completeAccess, dependencies] = computeCompleteAccess(state, node, execution.draft, null, true);
            node.completeAccess = completeAccess;
            dependencyUpdates.push(...dependencies);

            // WHEN ANONYMOUS
            if (SharedAuth.isAnonymous()) {
                // CHECK ATTACHMENTS
                const attachmentsDeps = [];
                if (!attachmentsUploaded(state, node.id, 'initial', attachmentsDeps)) {
                    node.initialValueInvalidReason = strings.execution.questions.attachments.uploading;
                } else {
                    delete node.initialValueInvalidReason;
                }
                if (!attachmentsUploaded(state, node.id, 'resolved', attachmentsDeps)) {
                    node.resolvedValueInvalidReason = strings.execution.questions.attachments.uploading;
                } else {
                    delete node.resolvedValueInvalidReason;
                }
                dependencyUpdates.push(...attachmentsDeps);
            }

            // DISABLED
            node.disabled = executionTask?.disabled || node.completeAccess?.disabled === true || node.completeAccess?.canComplete === false || (executionTask?.completeEnabled === true && executionTask?.completed === true);
            dependencyUpdates.push({
                from: executionTask?.id,
                to: node,
                properties: ['disabled', 'completeEnabled', 'completed']
            });
            switch (node.questionType) {
                case QUESTION_TYPES.number.id: {
                    const convertNumber = number => {
                        if (number == null || number === '') {
                            return null;
                        }
                        if (typeof number === 'number') {
                            return number;
                        }
                        if (typeof number === 'string') {
                            let cleaned = (number || '').replace(/[^0-9-.]/g, '');
                            // Only keep the first negative and decimal
                            cleaned = stripAfter(cleaned, /[.]/g, 0);
                            cleaned = stripAfter(cleaned, /[-]/g, 0);
                            const parsed = Number.parseFloat(cleaned);
                            if (isNaN(parsed)) {
                                return null;
                            }

                            let decimalPlaces = 2;
                            if(node.format === FORMATS.custom.id) {
                                const { maxScale } = parseFormat(node.formatDisplay);
                                decimalPlaces = maxScale;
                            }

                            return Number.parseFloat(parsed.toFixed(decimalPlaces));
                        }
                        throw new Error("Failed to convert number. Unknown object type " + typeof number)
                    };
                    node.initialValue = convertNumber(node.initialValue);
                    node.resolvedValue = convertNumber(node.resolvedValue);
                    break;
                }
                case QUESTION_TYPES.link.id: {
                    // Register Select to avoid getting all descendants
                    if (execution.queryQuestionIds == null || !execution.queryQuestionIds.includes(node.id)) {
                        const updatedIds = [...(execution.queryQuestionIds || []), node.id];
                        execution = {...execution, queryQuestionIds: updatedIds};
                        updated[execution.id] = execution
                    }
                    break;
                }
                case QUESTION_TYPES.date.id: {
                    node.initialValue = node.initialValue ? moment(node.initialValue).format(DATE_FORMAT_STORED) : null;
                    node.resolvedValue = node.resolvedValue ? moment(node.resolvedValue).format(DATE_FORMAT_STORED) : null;
                    break;
                }
                case QUESTION_TYPES.datetime.id: {
                    node.initialValue = node.initialValue ? moment.utc(node.initialValue).toISOString() : null;
                    node.resolvedValue = node.resolvedValue ? moment.utc(node.resolvedValue).toISOString() : null;
                    break;
                }
                case QUESTION_TYPES.email.id: {
                    let validateField = field => {
                        let value = node[field];
                        if (value && !validateEmail(value)) {
                            node[field + 'InvalidReason'] = strings.execution.questions.email.invalidFormat;
                        }
                    }
                    validateField('initialValue');
                    validateField('resolvedValue');
                    break;
                }
                case QUESTION_TYPES.geographic.id: {
                    delete node.initialValueInvalidReason;
                    delete node.resolvedValueInvalidReason;
                    let validateGeographic = field => {
                        let value = node[field];
                        if (checkLocationFeatureInvalid(value)) {
                            node[field + 'InvalidReason'] = strings.execution.questions.geographic.invalidFormat;
                        } else if (value) {
                            const validCoords = checkLocationCoordinatesAreInBounds(value);
                            const coordinates = value?.geometry?.coordinates;
                            if (validCoords?.latitude === false) {
                                node[field + 'InvalidReason'] = strings.formatString(strings.execution.questions.geographic.latitudeOutOfBounds, coordinates[1]);
                            }
                            if (validCoords?.longitude === false) {
                                node[field + 'InvalidReason'] = (node[field + 'InvalidReason'] ? node[field + 'InvalidReason'] + ', ' : '') + strings.formatString(strings.execution.questions.geographic.longitudeOutOfBounds, coordinates[0]);
                            }
                            value.id = node.id;
                        }
                    }
                    validateGeographic('initialValue');
                    validateGeographic('resolvedValue');
                    break;
                }
                case QUESTION_TYPES.time.id: {
                    if (node.initialValue instanceof Date) {
                        node.initialValue = convertExecutionQuestionTime(node.initialValue);
                    }
                    if (node.resolvedValue instanceof Date) {
                        node.resolvedValue = convertExecutionQuestionTime(node.resolvedValue);
                    }
                    break;

                }
                case QUESTION_TYPES.select.id: {
                    if (node.selectDataSource === SELECT_DATA_SOURCES.executionDynamic.id) {

                        // Register Select to avoid getting all descendants
                        if (execution.selectDynamicQuestionIds == null || !execution.selectDynamicQuestionIds.includes(node.id) || state.focusedNode?.id === node.id) {
                            const updatedIds = [...(execution.selectDynamicQuestionIds || []), node.id];
                            execution = {...execution, selectDynamicQuestionIds: updatedIds};
                            updated[execution.id] = execution
                        }
                        // Compute filter
                        const {
                            filter,
                            filterReady,
                            dependencies
                        } = computeFilter(state, node.id, SELECTOR_FILTER_TYPE.select.id);
                        node.selectExecutionFilter = filter;
                        let selectorId = NODE_TYPE_OPTIONS.ExecutionSelector + `-${node.id}_initialValue`;
                        dependencyUpdates.push({from: selectorId, to: node.id, properties: ['searchTerm']});
                        dependencyUpdates.push(...dependencies)
                        const rules = getNodesIfPresent(state, node.ruleIds);

                        const linkRules = rules.filter((rule) => rule.linkMatchOn !== undefined);
                        let beforeLinkRules;
                        if (linkRules && linkRules.length) {
                            beforeLinkRules = getNodesIfPresent(beforeState, linkRules.map(l => l.id));
                            dependencyUpdates.push(...linkRules.map(l => ({from: l.id, to: node.id, properties: ['linkMatchOn', 'linkMatchProcedureIds']})));
                        }

                        // Compute options if none available,
                        // or recompute if options source changed,
                        // procedure ids changed,
                        if (!node.disabled && node.selectRenderMode !== SELECT_RENDER_MODES.autocomplete.id) {
                            if (beforeNode && beforeLinkRules) {
                                if (beforeNode.selectDataSource !== node.selectDataSource ||
                                    !shallowEqual(linkRules.flatMap(l => l.linkMatchProcedureIds), beforeLinkRules.flatMap(l => l.linkMatchProcedureIds))) {
                                    delete node.options;
                                    delete node.optionsParsed;
                                }
                            }
                            delete node.initialValueInvalidReason;
                            const [options, optionsParsed, dependencies] = computeExecutionSelectOptions(state, node, true);
                            node.options = options;
                            node.optionsParsed = optionsParsed;
                            dependencyUpdates.push(...dependencies)
                        }
                        if (node.optionsParsed?.length > 8 && !node.draft && filterReady) {
                            node.selectRenderMode = SELECT_RENDER_MODES.autocomplete.id;
                        }

                        

                        if (node.selectRenderMode === SELECT_RENDER_MODES.autocomplete.id) {
                            // Need to make sure all selected items appear in .options
                            let selectedIds = makeArray(node.initialValue);
                            let invalidIds = selectedIds.filter(a => isSummaryId(a));
                            if (invalidIds.length > 0) {
                                reportDeveloperWarning(`initialValue contains a summary id: ${invalidIds.join(', ')}`);
                            }
                            let existingOptions = keyBy(node.optionsParsed, a => a.value);
                            let selectedOptionMissing = selectedIds.filter(a => !existingOptions[a]);
                            let addOptions = [];
                            for (let missing of selectedOptionMissing) {
                                let missingExecution = getExecutionFullSummaryOrNull(state, missing);
                                if (missingExecution && missingExecution.title) {
                                    addOptions.push(`${missingExecution.rootId}=${missingExecution.title}`);
                                } else {
                                    dependencyUpdates.push({from: missing, to: node.id, properties: ['title']});
                                }
                            }
                            if (addOptions.length > 0) {
                                if (node.options) {
                                    node.options += '\n';
                                } else {
                                    node.options = '';
                                }
                                node.options += addOptions.join('\n');
                                node.optionsParsed = parseOptions(node.options);
                            }
                        } else {
                            if (!node.disabled) {
                                // Need to make sure all selected items appear in .options
                               let selectedIds = makeArray(node.initialValue);
                               let invalidIds = selectedIds.filter(a => isSummaryId(a));
                               if (invalidIds.length > 0) {
                                   reportDeveloperWarning(`initialValue contains a summary id: ${invalidIds.join(', ')}`);
                               }
                               let existingOptions = keyBy(node.optionsParsed, a => a.value);
                               let addOptions = [];
                               let valueInvalid = false;
                               const missingExecutionTitles = [];
                               for (let selectedId of selectedIds) {
                                   if (node.selectDataSource === SELECT_DATA_SOURCES.executionDynamic.id) {
                                       let selectedExecution = getExecutionFullSummaryOrNull(state, selectedId);
                                       if (selectedExecution) {
                                           if (selectedExecution.deleted) {
                                               addOptions.push(`${selectedId}=${selectedExecution.title}`);
                                               valueInvalid = true;
                                               missingExecutionTitles.push(selectedExecution.title);
                                               continue;
                                           }
                                           dependencyUpdates.push({from: selectedId, to: node.id, properties: ['deleted']});
                                       }
                                       if (!existingOptions[selectedId]) {
                                           if (selectedExecution) {
                                                let optionsResourceUrl = NODE_IDS.ExecutionQuestionSelect(node);
                                                let optionsResource = getNodeOrNull(state, optionsResourceUrl);
                                                if (optionsResource && optionsResource.loaded) {
                                                    addOptions.push(`${selectedId}=${selectedExecution.title}`);
                                                    valueInvalid = true;
                                                    missingExecutionTitles.push(selectedExecution.title);
                                                }
                                                dependencyUpdates.push({from: optionsResourceUrl, to: node.id, properties: ['loading', 'loaded', 'nodeIds']});
                                           } else {
                                               let optionsResourceUrl = NODE_IDS.ExecutionQuestionSelect(node);
                                               let optionsResource = getNodeOrNull(state, optionsResourceUrl);
                                               if (optionsResource && optionsResource.loaded) {
                                                    let previousMissingOptionTitle;
                                                    if (beforeNode) {
                                                        let previousOptions = keyBy(beforeNode.optionsParsed, (o) => o.value);
                                                        previousMissingOptionTitle = previousOptions[selectedId]?.label;
                                                    }
                                                   addOptions.push(`${selectedId}=${previousMissingOptionTitle || "Unknown option"}`);
                                                   valueInvalid = true;
                                                   missingExecutionTitles.push(previousMissingOptionTitle || "unknown");
                                               }
                                               dependencyUpdates.push({from: optionsResourceUrl, to: node.id, properties: ['loading', 'loaded', 'nodeIds']});
                                           }
                                           dependencyUpdates.push({from: selectedId, to: node.id, properties: ['title', 'loading', 'loaded']});
                                       }
                                   }
                               }
                               if (addOptions.length > 0) {
                                   if (node.options) {
                                       node.options += '\n';
                                   } else {
                                       node.options = '';
                                   }
                                   node.options += addOptions.join('\n');
                                   node.optionsParsed = parseOptions(node.options, true);
                                   node.options = node.optionsParsed.map(o => `${o.value}=${o.label}`).join("\n");
                                }
                                if (valueInvalid) {
                                    node.initialValueInvalidReason = `Selected option${missingExecutionTitles.length > 1 ? "s" : ""} ${missingExecutionTitles.join(", ")} unavailable. ${node.selectMany ? "Please select other options" : "Please select another option"}.`;
                                } else {
                                    delete node.initialValueInvalidReason;
                                }
                           }
                        }
                    }
                    break;
                }
                case QUESTION_TYPES.text.id: {
                    let convertText = value => {
                        if (value == null) {
                            return null;
                        }
                        if (value === '') {
                            return null;
                        }
                        if (Array.isArray(value)) {
                            return value.map(a => a?.toString()).join(', ');
                        }
                        if (!(typeof value === 'string')) {
                            return value.toString();
                        }
                        return value;
                    };
                    node.initialValue = convertText(node.initialValue);
                    node.resolvedValue = convertText(node.resolvedValue);
                    break;
                }
                case QUESTION_TYPES.message.id:
                case QUESTION_TYPES.richText.id: {
                    let convertRichText = (value) => {
                        if (value == null) {
                            return null;
                        }
                        if (value === '') {
                            return null;
                        }
                        if (Array.isArray(value)) {
                            value = value.map(a => a?.toString()).join(', ');
                        }
                        if (!isDraftJsString(value)) {
                            return toDraftJs(value);
                        }
                        return value;
                    };
                    node.initialValue = convertRichText(node.initialValue);
                    node.resolvedValue = convertRichText(node.resolvedValue);
                    break;
                }
                case QUESTION_TYPES.phoneNumber.id: {
                    let validateField = field => {
                        let value = node[field] || "";
                        let upgraded = upgradePhoneNumber(value, DEFAULT_PHONE_NUMBER_COUNTRY) || "";
                        // Only consider existing AU phone numbers
                        if (value && !isValidPhoneNumber(value) && !isValidPhoneNumber(upgraded)) {
                            node[field + 'InvalidReason'] = strings.execution.questions.phoneNumber.invalidFormat;
                        }
                    }
                    validateField('initialValue');
                    validateField('resolvedValue');
                    break;
                }
                default: {
                    break;
                }
            }

            // GET RULES
            let rules = getActiveRulesForNode(state, node.id);
            if (!node.ruleIds) {
                node.ruleIds = rules.map(a => a.id);
            }


            // INPUT PATTERN
            if (node.questionType === QUESTION_TYPES.number.id) {
                node.inputPattern = node.minInclusive >= 0 ? '[0-9.]*' : '[0-9.-]*';
            } else {
                node.inputPattern = null;
            }

            // LINK
            delete node.initialValueStoreDb;
            if (node.questionType === QUESTION_TYPES.link.id || (node.questionType === QUESTION_TYPES.select.id && node.selectDataSource === SELECT_DATA_SOURCES.executionDynamic.id)) {
                if (node.questionType === QUESTION_TYPES.link.id) {
                    let result = evaluateLinkRule(state, node, node.initialValue, updated, QUESTION_TYPES.link.id);
                    node.initialValue = result.ids;
                    node.initialValueStoreDb = false;
                }
                if (!node.initialValue?.length) {
                    node.initialValue = null
                }
                node.linkedAddOptions = evaluateLinkAddOptions(state, node, node.linkedAddOptions);
                if (node.linkedAddOptions.length > 0) {
                    dependencyUpdates.push({from: NODE_IDS.ClientConfig, to: node.id, properties: ['procedures']})
                }
            }
            // VALIDATE
            delete node.issueType;
            delete node.issueDescription;
            if (node.initialValue != null) {
                node.initialValueValid = true;
                let calculatedValid = true;
                switch (node.questionType) {
                    case QUESTION_TYPES.yesno.id:
                    case QUESTION_TYPES.select.id: {
                        if (node.validOptions && Array.isArray(node.validOptions) && node.validOptions.length > 0) {
                            const invalidOptions = node.optionsParsed?.map(a => a.value).filter(a => !node.validOptions.includes(a));
                            calculatedValid = !doesIntersect(invalidOptions, node.initialValue);
                        }
                        break;
                    }
                    case QUESTION_TYPES.number.id: {
                        calculatedValid = calculatedValid && (node.minInclusive == null || (node.initialValue >= node.minInclusive));
                        calculatedValid = calculatedValid && (node.maxInclusive == null || (node.initialValue <= node.maxInclusive));
                        break;
                    }
                    default:
                        break;
                }
                // Validation Rules
                const raiseAnIssueRulesActive = rules.filter(a => a.evaluatedValue && a.raiseIssueOn);
                calculatedValid = calculatedValid && raiseAnIssueRulesActive.length === 0;
                node.initialValueValid = calculatedValid;
                if (calculatedValid) {
                    delete node.issueType;
                    delete node.issueResolution;
                } else {
                    node.issueType = ISSUE_TYPES.warning.id;
                    node.issueDescription = node.warningMessage;
                }
            } else {
                node.initialValueValid = node.questionType === QUESTION_TYPES.link.id;
            }

            // Compute Stuff
            let hasWarning = node.issueType === ISSUE_TYPES.warning.id;
            let unknownEnabled = node.unknownMode === UNKNOWN_TYPES.allowed.id;
            let photoIdsRequiredViaRule = rules.filter(a => a.evaluatedValue && a.photoRequiredOn).length > 0;
            let photoIdsRequired = node.photoMode === 'required' || (hasWarning && node.fixModePhotoFlag) || photoIdsRequiredViaRule;
            let photoIdsEnabled = photoIdsRequired || node.photoMode === 'optional';
            let commentRequiredWarning = hasWarning && node.fixModeCommentFlag === true;

            let commentRequiredViaRule = rules.filter(a => a.evaluatedValue && a.commentRequiredOn).length > 0;

            // INITIAL
            node.initialValueEnabled = node.questionType !== QUESTION_TYPES.message.id && node.questionType !== QUESTION_TYPES.photo.id;
            if (unknownEnabled) {
                node.initialValueUnknownEnabled = unknownEnabled;
            } else {
                delete node.initialValueUnknownEnabled
            }
            if (!node.initialValueUnknownEnabled) {
                delete node.initialValueUnknown;
            }
            let commentForceRequired = node.initialValueUnknown === true || commentRequiredWarning || commentRequiredViaRule;

            node.initialCommentHideAction = node.initialCommentHideAction == null || node.preview ? isRuleOn(state, RULE_ACTION_TYPE.commentHideAction.id, node.id, true) : node.initialCommentHideAction;
            if (!node.initialCommentHideAction) {
                delete node.initialCommentHideAction
            }
            node.initialCommentRequestedEnabled = (node.commentMode === 'optional' && !commentForceRequired) || node.initialComment != null;
            if (!node.initialCommentRequestedEnabled) {
                delete node.initialCommentRequestedEnabled
            }
            node.initialCommentRequired = node.commentMode === 'required' || commentForceRequired;
            if (!node.initialCommentRequired) {
                delete node.initialCommentRequired
            }
            node.initialCommentEnabled = node.initialCommentRequired || hasValue(node.initialComment) || node.initialCommentRequested || (node.initialCommentHideAction && node.commentMode !== 'none') || false;
            if (!node.initialCommentEnabled) {
                delete node.initialCommentEnabled
            }
            node.initialPhotoIdsEnabled = photoIdsEnabled;
            if (!node.initialPhotoIdsEnabled) {
                delete node.initialPhotoIdsEnabled
            }
            node.initialPhotoIdsRequired = photoIdsRequired;
            if (!node.initialPhotoIdsRequired) {
                delete node.initialPhotoIdsRequired
            }
            node.initialPhotoIds = node.initialPhotoIds || [];

            // RESOLVE
            let resolveRulesActive = rules.filter(a => a.evaluatedValue && a.raiseIssueResolveOn);
            const resolveFeatureOn = node.fixModeResolveFlag || resolveRulesActive.length > 0;
            node.resolvedEnabled = hasWarning && resolveFeatureOn;
            if (!node.resolvedEnabled) {
                delete node.resolvedEnabled
            }
            if (!node.resolvedEnabled) {
                delete node.issueResolution;
            }
            const reAnswerFeatureOn = node.fixModeResolveFlag || resolveRulesActive.filter(a => a.raiseIssueReAnswerOn).length > 0;
            node.resolvedValueEnabled = reAnswerFeatureOn && node.issueResolution === ISSUE_RESOLUTION_TYPES.resolved.id;
            if (!node.resolvedValueEnabled) {
                delete node.resolvedValueEnabled;
                delete node.resolvedValue;
            }
            node.resolvedValueUnknownEnabled = node.resolvedValueEnabled && unknownEnabled;
            if (!node.resolvedValueUnknownEnabled) {
                delete node.resolvedValueUnknownEnabled;
                delete node.resolvedValueUnknown;
            }
            node.resolvedCommentHideAction = node.initialCommentHideAction;
            if (!node.resolvedCommentHideAction) {
                delete node.resolvedCommentHideAction
            }
            let resolvedCommentForceRequired = node.initialValueUnknown || commentRequiredWarning;
            node.resolvedCommentRequestedEnabled = node.resolvedValueEnabled && node.commentMode === 'optional' && !resolvedCommentForceRequired;
            if (!node.resolvedCommentRequestedEnabled) {
                delete node.resolvedCommentRequestedEnabled;
                delete node.resolvedCommentRequested;
            }

            node.initialCommentRequired = node.initialCommentRequired || (node.resolvedValueEnabled && (node.commentMode === 'required' || resolvedCommentForceRequired));
            if (!node.initialCommentRequired) {
                delete node.initialCommentRequired
            }
            node.resolvedCommentEnabled = (node.resolvedValueEnabled && (node.initialCommentRequired || hasValue(node.resolvedComment) || node.resolvedCommentRequested === true || node.resolvedCommentHideAction)) || false;
            if (!node.resolvedCommentEnabled) {
                delete node.resolvedCommentEnabled;
                delete node.resolvedComment;
            }
            node.resolvedCommentRequired = node.resolvedValueEnabled && (node.initialCommentRequired || hasValue(node.resolvedComment));
            if (!node.resolvedCommentRequired) {
                delete node.resolvedCommentRequired;
            }
            node.resolvedPhotoIdsEnabled = node.resolvedValueEnabled && photoIdsEnabled;
            if (!node.resolvedPhotoIdsEnabled) {
                delete node.resolvedPhotoIdsEnabled
            }
            node.resolvedPhotoIdsRequired = node.resolvedValueEnabled && photoIdsRequired;
            if (!node.resolvedPhotoIdsRequired) {
                delete node.resolvedPhotoIdsRequired;
            }
            node.resolvedPhotoIds = node.resolvedPhotoIds || [];

            // NOT AN ISSUE
            node.notAnIssueCommentEnabled = node.issueResolution === "notanissue";
            if (!node.notAnIssueCommentEnabled) {
                delete node.notAnIssueCommentEnabled;
                delete node.notAnIssueComment;
            }

            // RULES
            let activeInvalidRules = rules.filter(a => a.evaluatedValue && a.invalidInputOn);

            // FORMAT
            node.initialValueFormatted = formatValue(node, node.initialValue);
            node.resolvedValueFormatted = formatValue(node, node.resolvedValue);

            // FINAL VALUE
            // Note: There could be a dependency between visible and finalValue. But as it seems unlikely a fields
            // visibility will depend on itself we will do it after
            // final field is used by the rule builder. Holds resolved when available else holds the initial value
            const useResolved = node.resolvedValue != null;
            node.finalValue = useResolved ? node.resolvedValue : node.initialValue;
            node.finalValueFormatted = useResolved ? node.resolvedValueFormatted || node.resolvedValue : node.initialValueFormatted || node.initialValue;
            if (node.deleted || (!node.visible && visibleResult.conditionallyVisible)) {
                node.finalValue = null;
                node.finalValueFormatted = null;
            }

            // COMPLETED
            let notCompletedReasons = [];
            const addIf = (condition, propertyName, reason) => {
                if (condition) {
                    notCompletedReasons.push({
                        propertyName: propertyName,
                        reason: reason
                    });
                }
            }
            if (node.visible) {
                let initialValueRequired = node.initialValueUnknown !== true && node.initialValueEnabled === true && node.questionType !== QUESTION_TYPES.link.id;
                addIf(initialValueRequired && node.initialValue == null, 'initialValue', 'Not initial answered');
                addIf(node.initialCommentRequired && node.initialComment == null, 'initialComment', 'No initial comment');
                addIf(node.initialPhotoIdsRequired && node.initialPhotoIds.length === 0, 'initialPhotoIds', 'No initial photos');
                addIf(node.resolvedEnabled && node.issueResolution == null, 'issueResolution', 'No issue resolution');
                addIf(node.resolvedValueEnabled && node.resolvedValue == null && node.resolvedValueUnknown !== true, 'resolvedValue', 'No resolved answer');
                addIf(node.resolvedCommentRequired && node.resolvedComment == null, 'resolvedComment', 'No resolved comment');
                addIf(node.resolvedPhotoIdsRequired && node.resolvedPhotoIds.length === 0, 'resolvedPhotoIds', 'No resolved photos');
                addIf(node.escalatedFlag && node.escalatedComment == null, 'escalatedComment', 'No escalated comment');
                addIf(node.escalatedFlag && node.escalatedToName == null, 'escalatedToName', 'No escalated to');
                addIf(node.notAnIssueCommentEnabled && node.notAnIssueComment == null, 'notAnIssueComment', 'No not an issue comment');
                addIf(activeInvalidRules.length > 0, 'initialValue', 'Invalid by rule');
                addIf(node.initialValueInvalidReason != null, 'initialValue', node.initialValueInvalidReason);
            }
            node.notCompletedReasons = notCompletedReasons;
            if (node.visible) {
                node.completed = (node.initialValueUnknown === true || node.initialValue != null || node.initialValueEnabled !== true || node.questionType === QUESTION_TYPES.link.id)
                    && (node.resolvedEnabled !== true || node.issueResolution != null)
                    && (node.resolvedValueEnabled !== true || node.resolvedValue != null || node.resolvedValueUnknown === true)
                    && (node.resolvedCommentRequired !== true || node.resolvedComment != null)
                    && (node.initialCommentRequired !== true || node.initialComment != null)
                    && (node.initialPhotoIdsRequired !== true || node.initialPhotoIds.length > 0)
                    && (node.resolvedPhotoIdsRequired !== true || node.resolvedPhotoIds.length > 0)
                    && (node.escalatedFlag !== true || (node.escalatedComment != null && node.escalatedToName != null))
                    && (node.notAnIssueCommentEnabled !== true || node.notAnIssueComment != null)
                    && (node.initialValueInvalidReason == null)
                    && (activeInvalidRules.length === 0);

                if (node.completed !== (notCompletedReasons.length === 0)) {
                    reportDeveloperInfo("Refactor of ExecutionQuestion.complete error " + node.completed + " " + notCompletedReasons.join(', '));
                }
            } else {
                node.completed = false;
            }
            if (node.completed) {
                // If this is completed as not visible lets use null completedDate
                node.completedDate = node.resolvedValueDateTime || node.initialValueDateTime;
            } else {
                node.completedDate = null;
            } 

            // STATISTICS
            node.totalPhotoCount = node.initialPhotoIds.length + node.resolvedPhotoIds.length;

            // LISTING
            if (node.questionType === QUESTION_TYPES.link.id) {
                const selectorId = NODE_IDS.ExecutionListingPage(node.id);
                let selector = getNodeOrNull(state, selectorId);
                let execution = getNodeOrError(state, node.rootId);
                let updateSelector = execution?.deleted !== selector?.includeDeleted;
                if (!selector) {
                    let listingSchema = getNodeSchemaOrError(state, NODE_TYPE_OPTIONS.ExecutionListPage);
                    let listingAttributes = {id: selectorId, questionId: node.id}
                    selector = createNode(listingSchema, listingAttributes);
                    updateSelector = true;
                }
                if (updateSelector)
                {
                    updated[selector.id] = {...selector, includeDeleted: execution.deleted};
                }
                dependencyUpdates.push({from: node.rootId, to: node.id, properties: ['draft', 'deleted']});
            }

            // MARK PROCESSED
            node.processed = true;

            addDependency(updated, dependencyUpdates);
            return updated;
        }
    },
    ExecutionLink: {
        ...domainRuleNOP,
        onPut: (state, node, beforeState, raisedAction, update) => {
            delete node.parentId
            let execution = getNodeOrError(state, node.rootId);
            if (!node.toNodeId) {
                reportDeveloperWarning(`ExecutionLink [${node.id}] from root [${node.rootId}] [${execution.key}] [${execution.name}] has no toNodeId`, node)
                return update;
            }
            let toExecution = getExecutionFullSummaryOrNull(state, node.toNodeId);
            let prevToExecution = getExecutionFullSummaryOrNull(beforeState, node.toNodeId);
            // Copy draft from execution
            let link = update[node.id];
            link.draft = link.draft == null ? execution.draft : link.draft;
            if (link.ruleIds && link.ruleIds.length > 0) {
                // Server will auto create/delete links for rules, so no need to save this one
                link.draft = true;
            }
            if (link.draft !== true) {
                delete link.draft;
            }

            link.preview = link.preview || execution.preview || toExecution?.preview || false;
            link.toNodeProcedureId = link.toNodeProcedureId || toExecution?.procedureId;
            link.toNodeName = link.toNodeName || toExecution?.name;
            if (toExecution && prevToExecution?.deleted !== toExecution.deleted && link.draft) {
                link.deleted = toExecution?.deleted;
            }

            // If parent, lets deal with that
            let isParentLink = link.linkType === LINK_TYPES.parent.id;
            if (isParentLink && (!execution.parents || execution.parents.length === 0 || execution.parents.slice(-1)[0].id !== link.toNodeId)) {
                // Server will evaluate, no drama if MIA
                let parent = getNodeOrNull(state, link.toNodeId);
                if (parent != null) {
                    execution = shallowClone(execution);
                    let thisParent = {
                        id: parent.id,
                        title: parent.title,
                        key: parent.key,
                        procedureId: parent.procedureId,
                        deleted: parent.deleted,
                        procedureName: parent.name,
                        procedureType: parent.procedureType
                    };
                    execution.parents = [...(parent.parents || []), thisParent];
                    update[execution.id] = execution;
                }
            }

            // Links Added Via Add Link Dialog are only on one side.
            // To make copyTo work we need the reverse link
            let beforeNode = getNodeOrNull(beforeState, node.id);
            let isNodeNew = !node.lastUpdatedDateTime;
            if (beforeNode == null && toExecution?.loadedFull && !hasValue(node.ruleIds) && !node.deleted && isNodeNew) {
                let toLinks = getNodesIfPresent(state, toExecution.links || []);
                let reverseLinkType = LINK_TYPES[node.linkType].reverseId;
                let existingLink = toLinks.find(a => a.linkType === reverseLinkType && a.toNodeId === node.rootId);
                if (existingLink) {
                    if (existingLink.deleted) {
                        update[existingLink.id] = {...existingLink, deleted: false};
                    }
                } else {
                    let linkAttr = {
                        linkType: reverseLinkType,
                        toNodeId: execution.id,
                        toNodeTitle: execution.title,
                        toNodeKey: execution.key,
                        toNodeProcedureId: execution.procedureId,
                        toNodeName: execution.name,
                        draft: true,
                        preview: toExecution.preview
                    };
                    let linkSchema = getNodeSchemaOrError(state, node.type);
                    let linkNode = createChildNode(toExecution, linkSchema, linkAttr);
                    update[linkNode.id] = linkNode;
                    update[toExecution.id] = {...toExecution, links: [...toExecution.links, linkNode.id]};
                }
            }
            addDependency(update, {from: node.toNodeId, to: node, properties: ['key', 'title', 'deleted']});
            if (link.lastUpdatedDateTime && (execution.maxLinkLastUpdatedDateTime == null || execution.maxLinkLastUpdatedDateTime < link.lastUpdatedDateTime))
            {
                execution = update[execution.id] || shallowClone(execution)
                execution.maxLinkLastUpdatedDateTime = link.lastUpdatedDateTime
                update[execution.id] = execution
            }
            return update;
        }
    },
    ExecutionAssignment: {
        ...domainRuleNOP,
        onPut: (state, node, beforeState, raisedAction, update) => {
            delete node.parentId

            // De-normalise onto assignments node
            const assignedNode = getNodeOrNull(state, node.assignedNodeId);
            if (assignedNode && (!assignedNode.assignments || !assignedNode.assignments.includes(node.id))) {
                let updated = {
                    ...assignedNode,
                    assignments: [...(assignedNode.assignments || []), node.id]
                };
                update[updated.id] = updated;
            }

            // Copy draft from execution
            let execution = getNodeOrNull(state, node.rootId);
            let assignment = update[node.id];
            assignment.draft = assignment.draft == null ? execution.draft : assignment.draft;
            if (assignment.draft != true) {
                delete assignment.draft
            }
            assignment.preview = !!execution.preview;
            return update;
        }
    },
    ExecutionRule: {
        ...domainRuleNOP,
        onPut: (state, node, beforeState, raisedAction, update) => {
            delete node.parentId
            const beforeNode = getNodeOrNull(beforeState, node.id);
            const currentNode = getNodeOrNull(state, node.id);
            let initialNode = null;
            // Denormalise
            node.denormalised = typeof node.denormalised === 'boolean' ? node.denormalised : node.actionType !== undefined || node.alwaysOn !== undefined || node.linkMatchOn !== undefined;
            if (!node.denormalised || window.alwaysComputeDirty) {
                let procedureRule = getNodeOrError(state, node.procedureRuleId);
                node.actionType = procedureRule.actionType;
                node.alwaysOn = procedureRule.alwaysOn;
                if (node.alwaysOn != true) {
                    delete node.alwaysOn;
                }
                node.linkMatchOn = procedureRule.linkMatchOn;
                if (node.linkMatchOn != true) {
                    delete node.linkMatchOn;
                }
                node.linkMatchLinkTypes = procedureRule.linkMatchLinkTypes;
                if (node.linkMatchLinkTypes == null) {
                    delete node.linkMatchLinkTypes;
                }
                node.linkMatchProcedureIds = procedureRule.linkMatchProcedureIds;
                if (node.linkMatchProcedureIds == null) {
                    delete node.linkMatchProcedureIds;
                }
                node.conditionOn = procedureRule.conditionOn;
                if (node.conditionOn != true) {
                    delete node.conditionOn;
                }
                node.invalidInputOn = procedureRule.invalidInputOn;
                if (node.invalidInputOn != true) {
                    delete node.invalidInputOn;
                }
                node.invalidInputMessage = procedureRule.invalidInputMessage;
                if (node.invalidInputMessage == null) {
                    delete node.invalidInputMessage;
                }
                node.raiseIssueOn = procedureRule.raiseIssueOn;
                if (node.raiseIssueOn != true) {
                    delete node.raiseIssueOn;
                }
                node.raiseIssueMessage = procedureRule.raiseIssueMessage;
                if (node.raiseIssueMessage == null) {
                    delete node.raiseIssueMessage
                }
                node.raiseIssueResolveOn = procedureRule.raiseIssueResolveOn;
                if (node.raiseIssueResolveOn == null) {
                    delete node.raiseIssueResolveOn
                }
                node.raiseIssueReAnswerOn = procedureRule.raiseIssueReAnswerOn;
                if (node.raiseIssueReAnswerOn != true) {
                    delete node.raiseIssueReAnswerOn;
                }
                node.createExecutionOn = procedureRule.createExecutionOn;
                if (node.createExecutionOn != true) {
                    delete node.createExecutionOn;
                }
                node.createExecutionProcedureId = procedureRule.createExecutionProcedureId;
                if (node.createExecutionProcedureId == null) {
                    delete node.createExecutionProcedureId
                }
                node.createExecutionLinkType = procedureRule.createExecutionLinkType;
                if (node.createExecutionLinkType == null) {
                    delete node.createExecutionLinkType;
                }
                node.linkToQuestionOn = procedureRule.linkToQuestionOn;
                if (node.linkToQuestionOn != true) {
                    delete node.linkToQuestionOn;
                }
                node.addExistingOn = procedureRule.addExistingOn;
                if (node.addExistingOn != true) {
                    delete node.addExistingOn;
                }
                node.addNewOn = procedureRule.addNewOn;
                if (node.addNewOn != true) {
                    delete node.addNewOn;
                }
                node.calculateValueOn = procedureRule.calculateValueOn;
                if (node.calculateValueOn != true) {
                    delete node.calculateValueOn;
                }
                node.copyToOn = procedureRule.copyToOn;
                if (node.copyToOn != true) {
                    delete node.copyToOn;
                }
                node.copyToNodeIds = procedureRule.copyToNodeIds;
                if (node.copyToNodeIds == null) {
                    delete node.copyToNodeIds;
                }
                node.commentRequiredOn = procedureRule.commentRequiredOn;
                if (node.commentRequiredOn != true) {
                    delete node.commentRequiredOn;
                }
                node.commentInstructionsOn = procedureRule.commentInstructionsOn;
                if (node.commentInstructionsOn != true) {
                    delete node.commentInstructionsOn;
                }
                node.commentInstructionsMessage = procedureRule.commentInstructionsMessage;
                if (node.commentInstructionsMessage == null) {
                    delete node.commentInstructionsMessage;
                }
                node.photoRequiredOn = procedureRule.photoRequiredOn;
                if (node.photoRequiredOn != true) {
                    delete node.photoRequiredOn;
                }
                node.photoInstructionsOn = procedureRule.photoInstructionsOn;
                if (node.photoInstructionsOn != true) {
                    delete node.photoInstructionsOn;
                }
                node.photoInstructionsMessage = procedureRule.photoInstructionsMessage;
                if (procedureRule.photoInstructionsMessage == null) {
                    delete node.photoInstructionsMessage
                }
                node.messageOn = procedureRule.messageOn;
                if (node.messageOn != true) {
                    delete node.messageOn;
                }
                node.message = procedureRule.message;
                if (procedureRule.message == null) {
                    delete node.message
                }
                node.messageType = procedureRule.messageType;
                if (node.messageType == null) {
                    delete node.messageType;
                }
                node.visibleOn = procedureRule.visibleOn;
                if (node.visibleOn != true) {
                    delete node.visibleOn;
                }
                node.deleted = procedureRule.deleted;
                node.procedureId = procedureRule.procedureId;
                node.orderByDirection = procedureRule.orderByDirection;
                if (node.orderByDirection == null) {
                    delete node.orderByDirection;
                }
                node.format = procedureRule.format;
                if (node.format == null) {
                    delete node.format;
                }
                node.denormalised = true;
            }
            if (!node.nodeIds) {
                node.nodeIds = [];
            }
            if (node.calculateValue === undefined) {
                node.calculateValue = null;
            }
            let otherDependencyUpdates = [];

            // Store this rule's id on its applied to nodes
            syncRuleIds(beforeState, state, node, node, update);
            computeRuleIds(state, node);

            // Action Type
            let ruleActionType = RULE_ACTION_TYPE[node.actionType] || RULE_ACTION_TYPE.block;

            // Root Rule
            let applyToNodes = getNodesOrError(state, node.nodeIds || []);
            let originalApplyToNodes = applyToNodes;
            let rootRule = node;
            if (applyToNodes.length === 1 && applyToNodes[0].type === NODE_TYPE_OPTIONS.ExecutionRule) {
                // The parent rule holds the For
                rootRule = applyToNodes[0];
                applyToNodes = getNodesOrError(state, rootRule.nodeIds || []);
            }

            // If rule actually applies to all questions under the root/step/task
            if (ruleActionType.applyToDescendants) {
                let descendants = [];
                for (let applyNode of applyToNodes) {
                    let thisDescendants = getDescendantsAndSelf(state, applyNode.id)
                    descendants = [...descendants, ...thisDescendants];
                }
                applyToNodes = descendants;
            }
            if (ruleActionType.applyToTypes)
            {
                applyToNodes = applyToNodes.filter(a => ruleActionType.applyToTypes.includes(a.type));
            }
            const applyToNames = applyToNodes.map(a => a.name).join(', ');

            // Compute On
            const computeOnServer = getActiveChildRuleByActionOrNull(state, rootRule.id, RULE_ACTION_TYPE.computeOnServer.id);
            let computeNow = true;
            if (computeOnServer) {
                otherDependencyUpdates.push({
                    from: computeOnServer,
                    to: node,
                    properties: ['deleted', 'actionType', 'evaluatedValue']
                })
                let computeOnClient = getActiveChildRuleByActionOrNull(state, rootRule.id, RULE_ACTION_TYPE.computeOnClient.id);
                computeNow = computeOnClient != null;
                otherDependencyUpdates.push({
                    from: computeOnClient,
                    to: node,
                    properties: ['deleted', 'actionType', 'evaluatedValue']
                })
            }

            // Evaluate Value
            let evaluatedValue = null;
            let matchedNodeIds = [];
            if (node.deleted) {
                evaluatedValue = null;
            } else if (rootRule !== node) {
                evaluatedValue = rootRule.evaluatedValue;
            } else if (node.alwaysOn) {
                evaluatedValue = true;
            } else if (node.conditionOn && node.conditionQuery) {
                const conditionQuery = node.conditionQuery;
                evaluatedValue = evaluateRule(state, conditionQuery, node, node.evaluatedValue);
                // Leaving null as null and not false to avoid re-computing on load existing records.
                evaluatedValue = evaluatedValue == null ? null : !!evaluatedValue;
            }
            if (node.linkMatchOn) {
                let evaluationResult = evaluateLinkRule(state, node, [], update, RULE_ACTION_TYPE.copyToOn.id);
                matchedNodeIds = evaluationResult.ids;
            }
            let evaluatedValueChanged = !areSame(evaluatedValue, node.evaluatedValue);
            if (evaluatedValueChanged) {
                initialNode = initialNode || beforeNode || raisedAction?.payload?.nodes?.find(a => a.id === node.id);
                if (initialNode && areSame(evaluatedValue, initialNode.evaluatedValue)) {
                    node.evaluatedValue = initialNode.evaluatedValue;
                    node.evaluatedValueDateTime = initialNode.evaluatedValueDateTime;
                } else {
                    node.evaluatedValue = evaluatedValue;
                    node.evaluatedValueDateTime = getJsonDate();
                }
            }

            // CREATE EXECUTION
            let autoLinks = [];
            let couldHaveAutoLink = false;
            let parentExecution = update[node.rootId] || getShallowClonedNodeOrError(state, node.rootId);
            let createGo = evaluatedValue && !!node.createExecutionOn && !!node.createExecutionProcedureId && !!node.createExecutionLinkType && parentExecution.deleted !== true;
            let createdExecutions = getExecutionFullSummaryByIdIfPresent(state, node.createExecutionIds || [])
                .filter(a => a.loaded && a.procedureId);
            // If created ones are not loaded we cannot evaluate, so lets skip that
            if (createGo) {
                // So we re-run when template is loaded
                otherDependencyUpdates.push({
                    from: node.createExecutionProcedureId,
                    to: node.id,
                    properties: ['loadedFull']
                });
            }
            if (computeNow && createdExecutions != null) {
                // CREATE EXECUTION: Create a new execution as required
                let createTemplate = node.createExecutionProcedureId ? getNodeOrNull(state, node.createExecutionProcedureId) : null;
                let allCreatedAreLoaded = createdExecutions.length === (node.createExecutionIds?.length || 0);
                couldHaveAutoLink = allCreatedAreLoaded;
                let createNow = createGo
                    && !node.createExecutionStopCode
                    && allCreatedAreLoaded
                    && createdExecutions.filter(e => e.procedureId === node.createExecutionProcedureId).length === 0
                    && createTemplate
                    && createTemplate.loadedFull
                    && (createTemplate.compileWarnings || []).length === 0
                    && (hasProcedurePermission(state, createTemplate?.id, Permissions.execution.create));

                let serverMightCreate = isSaveRunningOnExecution(state, node.rootId, node.id)
                if (createNow && serverMightCreate) {
                    createNow = false;
                    otherDependencyUpdates.push({
                        from: NODE_IDS.UserDevice,
                        to: node.id,
                        properties: ['saveRunning']
                    });
                }

                let createStopTooDeep = createNow && (parentExecution.parents || []).length + 1 >= EXECUTION_MAX_DEPTH;
                let createStopTooMany = createNow && state.rootNodesCreatedThisAction >= EXECUTION_MAX_CREATE;
                if (createStopTooDeep) {
                    node.createExecutionStopCode = EXECUTION_CREATE_STOP_CODE.PARENT_CHILD_TOO_DEEP.id;
                    reportBusinessError(EXECUTION_CREATE_STOP_CODE.PARENT_CHILD_TOO_DEEP.message);
                } else if (createStopTooMany) {
                    node.createExecutionStopCode = EXECUTION_CREATE_STOP_CODE.EXECUTION_MAX_CREATE.id;
                    reportBusinessError(EXECUTION_CREATE_STOP_CODE.EXECUTION_MAX_CREATE.message);
                } else if (createNow) {
                    // #1 Create Execution
                    let newExecution;
                    let createdNodes;
                    {
                        let createRequest = {
                            procedureId: node.createExecutionProcedureId,
                            children: [],
                            procedureCount: 1,
                            projectId: null,
                            source: {
                                kind: EXECUTION_SOURCE_TYPES.autoCreate.id,
                                createdFromExecutionId: node.rootId,
                                createdFromRuleId: node.id
                            }
                        };
                        createdNodes = createExecutions(state, createRequest);
                        state.rootNodesCreatedThisAction++;
                        newExecution = createdNodes.find(a => a.type === 'ExecutionRoot');
                        node.createExecutionIds = mergeUnique(node.createExecutionIds, newExecution.id);
                        createdExecutions.push(newExecution);
                    }
                    createdNodes.forEach(a => a.preview = !!parentExecution.preview);
                    if (parentExecution.draft) {
                        createdNodes.forEach(a => a.draft = true);
                    } else {
                        createdNodes.forEach(a => delete a.draft);
                    }

                    createdNodes.forEach(a => update[a.id] = a);
                }
                // CREATE EXECUTION: Sync delete + Link
                for (let createdExecution of createdExecutions) {
                    let shouldBeDeleted = !createGo || createdExecution.procedureId !== node.createExecutionProcedureId;
                    if (shouldBeDeleted !== createdExecution.deleted) {
                        createdExecution = update[createdExecution.id] || shallowClone(createdExecution);
                        createdExecution.deleted = shouldBeDeleted;
                        update[createdExecution.id] = createdExecution;
                    }
                    if (!shouldBeDeleted) {
                        autoLinks.push(
                            {
                                toNodeId: createdExecution.rootId,
                                linkType: node.createExecutionLinkType,
                                fromPreview: !!parentExecution.preview,
                                fromDraft: true,
                                toPreview: parentExecution.preview,
                                toDraft: parentExecution.draft
                            });
                    }
                }
            }
            update[parentExecution.id] = parentExecution;

            // Calculate
            const ruleLogPrefix = `Rule [${node.id}] for [${applyToNames}]`;
            let copyCalculationToNodes = [];
            let calculateReverted = false;
            let calculateChanged = false;
            if (node.calculateValueQuery && evaluatedValue && !ruleActionType.skipCalculate) {
                let calculatedValue = computeNow ? evaluateRule(state, node.calculateValueQuery, node, node.calculateValue) : node.calculateValue;
                if (Number.isNaN(calculatedValue) || calculatedValue === Infinity) {
                    calculatedValue = null;
                }
                if (!areSame(calculatedValue, node.calculateValue)) {
                    initialNode = initialNode || beforeNode || raisedAction?.payload?.nodes?.find(a => a.id === node.id);
                    if (initialNode && areSame(calculatedValue, initialNode.calculateValue)) {
                        console.info(`${ruleLogPrefix}: Reverting calculateValue. Before: ${node.calculateValue} After/Previous: ${calculatedValue}`)
                        node.calculateValue = initialNode.calculateValue;
                        node.calculateValueDateTime = initialNode.calculateValueDateTime;
                        calculateReverted = true;
                        calculateChanged = true;
                    } else {
                        console.info(`${ruleLogPrefix}: Setting calculateValue. Before: ${node.calculateValue} After: ${calculatedValue}`)
                        node.calculateValue = calculatedValue;
                        node.calculateValueDateTime = getJsonDate();
                        calculateChanged = true;
                    }
                }
                if (isDateGreaterThan(node.evaluatedValueDateTime, node.calculateValueDateTime)) {
                    // When the rule turned off and on again we need to consider this as a updated calculation so
                    // that it flows to the question
                    console.info(`${ruleLogPrefix}: Setting calculateValueDateTime as evaluatedValueDateTime is greater. Before: ${node.calculateValueDateTime} After: ${node.evaluatedValueDateTime}`)
                    node.calculateValueDateTime = node.evaluatedValueDateTime;
                    calculateChanged = true;
                }
                if (!node.copyToOn && node.actionType === RULE_ACTION_TYPE.block.id) {
                    copyCalculationToNodes.push(...applyToNodes.filter(a => a.type === NODE_TYPE_OPTIONS.ExecutionQuestion));
                }
            } else if(!node.calculateValueQuery && evaluatedValue && node.actionType === RULE_ACTION_TYPE.label.id) {
                node.calculateValue = undefined;
            }

            // Pretend calculate for copy
            if (computeNow && evaluatedValue && !node.calculateValueQuery && node.copyToOn) {
                // On first create nodeIds is empty until the rule re-write
                if (applyToNodes.length === 1) {
                    let question = applyToNodes[0];
                    if (!areSame(node.calculateValue, question.initialValue)) {
                        node.calculateValue = question.initialValue;
                        node.calculateValueDateTime = getJsonDate();
                    }
                    if (isDateGreaterThan(node.evaluatedValueDateTime, node.calculateValueDateTime)) {
                        // When the rule turned off and on again we need to consider this as a updated calculation so
                        // that it flows to the question
                        node.calculateValueDateTime = node.evaluatedValueDateTime;
                    }
                }
            }

            // Copy To
            if (node.copyToNodeIds && node.copyToNodeIds.length) {
                let copyToExecutionIds = [...matchedNodeIds];
                // Auto Create
                for (let parentRule of originalApplyToNodes.filter(a => a.type === NODE_TYPE_OPTIONS.ExecutionRule)) {
                    let autoCreated = parentRule.createExecutionIds || [];
                    copyToExecutionIds.push(...autoCreated);
                    otherDependencyUpdates.push({from: parentRule.id, to: node.id, properties: ['createExecutionIds']});
                }
                // Linked Nodes
                // This will copy data between executions
                if (evaluatedValue && !parentExecution.deleted) {
                    for (let toNodeId of copyToExecutionIds) {
                        // When the destination is loaded lets re-compute just in case we need to copy to it
                        otherDependencyUpdates.push({from: toNodeId, to: node.id, properties: ['loadedFull']})

                        let toNode = getNodeOrNull(state, toNodeId);
                        if (!toNode || toNode.deleted) {
                            // Node not loaded, lets not worry
                            continue;
                        }
                        let descendants = getActiveDescendantsAndSelfIfPresent(state, toNodeId);
                        let toQuestionNodes = descendants.filter(a => node.copyToNodeIds.includes(a.procedureQuestionId));
                        copyCalculationToNodes.push(...toQuestionNodes);
                    }
                }
            }

            // Apply Copy To
            let nodeIdsCalculated = {};
            for (let copyToNode of copyCalculationToNodes) {
                nodeIdsCalculated[copyToNode.id] = true;
                const ruleByUser = "Rule:" + node.rootId + "/" + node.id;
                const sameRoot = copyToNode.rootId === node.rootId;
                let copyNow = false;
                if (!computeNow) {
                    // Leave Server to do the copy.
                    // As we want the field to be read-only we will still do that part
                } else if (sameRoot) {
                    // Due to calculate revert logic the datetime may change and then revert, so we cannot only copy the most recent
                    if (copyToNode.initialValueByUser !== ruleByUser) {
                        console.info(`${ruleLogPrefix}: Setting initialValue as difference source. Before: ${copyToNode.initialValueByUser} After: ${ruleByUser}`);
                        copyNow = true;
                    } else if (!areSame(copyToNode.initialValueDateTime, node.calculateValueDateTime)) {
                        copyNow = true;
                        console.info(`${ruleLogPrefix}: Setting initialValue as date/time different. Before: ${copyToNode.initialValueDateTime} After: ${node.calculateValueDateTime}`)
                    } else if (calculateReverted) {
                        copyNow = true;
                        console.info(`${ruleLogPrefix}: Setting initialValue as reverted value. Before: ${copyToNode.initialValueDateTime} After: ${node.calculateValueDateTime}`)
                    } else if (calculateChanged) {
                        copyNow = true;
                        console.info(`${ruleLogPrefix}: Setting initialValue as calculate changed. Before: ${copyToNode.initialValueDateTime} After: ${node.calculateValueDateTime}`)
                    }
                } else {
                    // If source is stale we do not want to copy
                    if (!isDateGreaterThanOrEqual(copyToNode.initialValueDateTime, node.calculateValueDateTime)) {
                        console.info(`${ruleLogPrefix}: Setting initialValue as date/time different. Before: ${copyToNode.initialValueDateTime} After: ${node.calculateValueDateTime}`);
                        copyNow = true;
                    } else if (copyToNode.initialValueByUser !== ruleByUser) {
                        console.info(`${ruleLogPrefix}: Setting initialValue as difference source. Before: ${copyToNode.initialValueByUser} After: ${ruleByUser}`);
                        copyNow = true;
                    } else if (calculateReverted && areSame(copyToNode.initialValueDateTime, currentNode.calculateValueDateTime)) {
                        copyNow = true;
                        console.info(`${ruleLogPrefix}: Setting initialValue as date/time matches pre-revert value. Before: ${copyToNode.initialValueDateTime} After: ${node.calculateValueDateTime}`)
                    }
                }
                if (copyNow) {
                    let cloned = update[copyToNode.id] || shallowClone(copyToNode);
                    update[cloned.id] = cloned;
                    cloned.initialValue = node.calculateValue;
                    cloned.initialValueDateTime = node.calculateValueDateTime;
                    cloned.initialValueByUser = "Rule:" + node.rootId + "/" + node.id;
                }
                if (!copyToNode.calculateRuleIds?.includes(node.id)) {
                    let cloned = update[copyToNode.id] || shallowClone(copyToNode);
                    update[cloned.id] = cloned;
                    cloned.calculateRuleIds = mergeUnique(cloned.calculateRuleIds, node.id);
                }
                otherDependencyUpdates.push({
                    from: copyToNode.id,
                    to: node.id,
                    properties: ['initialValue', 'initialValueDateTime', 'initialValueByUser']
                });
            }

            // Remove CalculateRuleIds when rules are deleted, turn off, or links removed
            // This worries me a little, what if the source is old and server side is off, but the copy we have
            // is still on? Fingers crossed this will be relatively rare... and the server will correct.
            for (let calculateNodeId of node.calculateNodeIds || []) {
                addDependency(update, {from: calculateNodeId, to: node.id, properties: ['calculateRuleIds']})
                if (nodeIdsCalculated[calculateNodeId]) {
                    continue;
                }
                let calculateNode = update[calculateNodeId] || getNodeOrNull(state, calculateNodeId);
                if (!calculateNode || calculateNode.calculateRuleIds == null || !calculateNode.calculateRuleIds.includes(node.id)) {
                    continue;
                }

                let cloned = shallowClone(calculateNode);
                cloned.calculateRuleIds = arrayMinusItem(cloned.calculateRuleIds, node.id);
                update[cloned.id] = cloned;
            }

            // Auto Link
            if (node.addExistingOn && node.linkToQuestionOn && evaluatedValue) {
                couldHaveAutoLink = true;
                let selectExecutionQuestions = applyToNodes.filter(a => a.selectDataSource === SELECT_DATA_SOURCES.executionDynamic.id);
                let autoLinkTo = selectExecutionQuestions.flatMap(a => makeArray(a.finalValue || []));
                for (let toNodeId of autoLinkTo) {
                    for (let linkType of (node.linkMatchLinkTypes || []).filter(a => a !== LINK_TYPES.none.id)) {
                        // Lets put preview on to node as we need to save from node anyway
                        autoLinks.push({
                            toNodeId: toNodeId,
                            linkType: linkType,
                            fromPreview: parentExecution.preview,
                            fromDraft: parentExecution.draft,
                            toPreview: parentExecution.preview,
                            toDraft: true
                        });
                    }
                }
            }
            if (couldHaveAutoLink || autoLinks.length > 0) {
                // Add links
                for (let autoLink of autoLinks) {
                    let toNode = update[autoLink.toNodeId] || shallowClone(getExecutionFullSummaryOrNull(state, autoLink.toNodeId));
                    // If toNode is not loaded this implies they either don't have access to it, it is still loading, or
                    // this is a copy to or calculate and hence its hasn't been loaded yet
                    // As we want to load the auto-link item but we cannot tell if we don't have access we will add the link
                    // regardless and not show it to the user
                    linkExecutions(state, parentExecution, toNode || autoLink.toNodeId, autoLink.linkType, {
                        preview: autoLink.fromPreview,
                        draft: autoLink.fromDraft
                    }, {preview: autoLink.toPreview, draft: autoLink.toDraft}, node.id, update);
                    // If to node is not fully loaded we need to re-evaluate when it is loaded to add link if missing
                    otherDependencyUpdates.push({from: autoLink.toNodeId, to: node.id, properties: ['loaded']});
                    otherDependencyUpdates.push({
                        from: getSummaryId(autoLink.toNodeId),
                        to: node.id,
                        properties: ['loaded']
                    });
                }
                // Delete links
                // e.g. Select is de-selected remove the link
                let parentLinks = getNodesIfPresent(state, (parentExecution && parentExecution.links) || [])
                    .filter(a => (a.activeRuleIds || []).includes(node.id));
                for (let link of parentLinks) {
                    let keepLink = autoLinks.find(a => a.toNodeId === link.toNodeId && a.linkType === link.linkType);
                    if (keepLink) {
                        continue;
                    }
                    link = shallowClone(link);
                    link.activeRuleIds = arrayMinusItem(link.activeRuleIds, node.id);
                    link.deleted = link.activeRuleIds.length === 0;
                    update[link.id] = link;
                    // Delete reverse link too (if possible)
                    if (link.deleted) {
                        let otherNode = update[link.toNodeId] || getExecutionFullOrNull(state, link.toNodeId);
                        let otherNodeLinks = getNodesIfPresent(state, otherNode?.links);
                        let reverseLink = LINK_TYPES[link.linkType].reverseId;
                        let otherNodeLink = otherNodeLinks.find(a => a.toNodeId === node.rootId && a.linkType === reverseLink);
                        // If this is auto-create turning off there is no need to delete the link
                        if (otherNodeLink && !otherNode.deleted && !otherNodeLink.deleted) {
                            otherNodeLink = update[otherNodeLink.id] || shallowClone(otherNodeLink);
                            otherNodeLink.deleted = true;
                            // Let the API manage it's restore/delete
                            otherNodeLink.draft = true;
                            update[otherNodeLink.id] = otherNodeLink;
                            console.info(`Marking link ${otherNodeLink.rootId} ${otherNodeLink.id} as deleted.`);
                        }
                    }
                }
            }

            // Filter
            const filterOn = ruleActionType.id === RULE_ACTION_TYPE.filter.id && !node.deleted;
            if (filterOn) {
                const isLinkQ = applyToNodes.every(a => a.questionType === QUESTION_TYPES.link.id);
                const parentQueryRule = getNodesIfPresent(state, node.nodeIds).find(n => n.linkToQuestionOn || n.addExistingOn);
                const link = isLinkQ && !parentQueryRule?.linkMatchLinkTypes?.includes(LINK_TYPES.none.id);
                const format = link ? 'summaryLinked' : 'query';
                node.conditionQueryPartial = convertJsonLogicForQuery(state, node, node.conditionQuery, format)?.result;
            }

            // ReadOnly
            const readOnlyOn = getActiveChildRuleByActionOrNull(state, rootRule.id, RULE_ACTION_TYPE.readOnly.id);

            // Dynamic Label
            if (node.actionType === RULE_ACTION_TYPE.label.id && node.calculateValueQuery && node.format && evaluatedValue) {
                // we don't want to push calculated values to node if format is for complete buttons
                if(node.format === RULE_FORMAT_OPTION.name.id || node.format === RULE_FORMAT_OPTION.addButton.id) {
                    const calculatedValue = evaluateRule(state, node.calculateValueQuery, node, node.calculateValue);
                    applyToNodes.forEach(nodeToUpdate => {
                        const cloned = update[nodeToUpdate.id] || shallowClone(nodeToUpdate);
                        cloned[node.format] = calculatedValue !== null ? calculatedValue.toString() : cloned[node.format];
                        update[cloned.id] = cloned;
                    })
                }
            }

            // Column Width
            if (node.actionType === RULE_ACTION_TYPE.layoutColumns.id && node.calculateValueQuery) {
                const calculatedValue = node.deleted ? DEFAULT_LAYOUT_COLUMNS_WIDTH : evaluateRule(state, node.calculateValueQuery, node, node.calculateValue);
                applyToNodes.forEach(nodeToUpdate => {
                    const cloned = update[nodeToUpdate.id] || shallowClone(nodeToUpdate);
                    cloned.columnWidth = parseInt(calculatedValue !== null ? calculatedValue : DEFAULT_LAYOUT_COLUMNS_WIDTH);
                    update[cloned.id] = cloned;
                });
                beforeNode?.nodeIds.forEach((previousNodeId) => {
                    if (!node.nodeIds.includes(previousNodeId)) {
                        const cloned = update[previousNodeId] || shallowClone(getNodeOrNull(state, previousNodeId));
                        const rule = getChildRuleByActionOrNull(state, cloned.id, node.actionType);
                        if (!rule) {
                            cloned.columnWidth = DEFAULT_LAYOUT_COLUMNS_WIDTH;
                            update[cloned.id] = cloned;
                        }
                    }
                });
            }

            // Final rule for calcuations
            if (node.createExecutionOn) {
                if (node.evaluatedValue === true && hasValue(node.createExecutionIds)) {
                    // For will only create 1, if 2 that means template id changed and earlier was deleted
                    node.finalValue = node.createExecutionIds[node.createExecutionIds.length - 1];
                } else {
                    node.finalValue = null;
                }
            } else {
                delete node.finalValue;
            }

            // Compute these after me
            let computeAfter = node.invalidInputOn || node.raiseIssueOn || node.commentRequiredOn
                || node.photoRequiredOn || node.visibleOn || ruleActionType.recomputeNodeIdsAfter
                || filterOn || readOnlyOn;
            if (computeAfter) {
                for (let applyNode of applyToNodes) {
                    otherDependencyUpdates.push({
                        from: node.id,
                        to: applyNode.id,
                        properties: [
                            'evaluatedValue',
                            'nodeIds',
                            'conditionQueryPartial',
                            'actionType',
                            'invalidInputOn',
                            'raiseIssueOn',
                            'commentRequiredOn',
                            'photoRequiredOn',
                            'visibleOn',
                        ],
                    });
                    if (readOnlyOn) {
                        otherDependencyUpdates.push({
                            from: readOnlyOn.id,
                            to: applyNode.id,
                            properties: ['evaluatedValue', 'nodeIds', 'conditionQueryPartial']
                        });
                    }
                    if (rootRule !== node) {
                        otherDependencyUpdates.push({
                            from: rootRule.id,
                            to: applyNode.id,
                            properties: [
                                'evaluatedValue',
                                'nodeIds',
                                'conditionQueryPartial',
                                'actionType',
                                'invalidInputOn',
                                'raiseIssueOn',
                                'commentRequiredOn',
                                'photoRequiredOn',
                                'visibleOn',
                            ],
                        });
                    }
                }
                for (let nodeId of node.nodeIds) {
                    otherDependencyUpdates.push({from: node.id, to: nodeId, properties: ['evaluatedValue']});
                }
            }
            if (node.linkToQuestionOn) {
                for (let applyNode of applyToNodes) {
                    otherDependencyUpdates.push({
                        from: node.id,
                        to: applyNode.id,
                        properties: ['linkMatchProcedureIds', 'linkMatchLinkTypes']
                    });
                }
            }

            for (let id of node.createExecutionIds || []) {
                otherDependencyUpdates.push({from: id, to: node.id, properties: ['loaded', 'loadedFull', 'deleted']});
                otherDependencyUpdates.push({from: getSummaryId(id), to: node.id, properties: ['loaded', 'deleted']});
            }

            // Re-run me when these change
            let watchNode = node.copyToOn || node.addExistingOn;
            if (watchNode) {
                for (let nodeId of rootRule.nodeIds) {
                    otherDependencyUpdates.push({
                        from: nodeId,
                        to: node.id,
                        properties: ['initialValue', 'initialValueDateTime']
                    });
                }
            }
            if (rootRule !== node) {
                otherDependencyUpdates.push({
                    from: rootRule.id,
                    to: node.id,
                    properties: ['nodeIds', 'evaluatedValue']
                });
            }
            otherDependencyUpdates.push({from: node.rootId, to: node.id, properties: ['deleted']});

            let conditionQueryDep = extractReferencedNodeDep(node, node.conditionQuery);
            let calculateValueQueryDep = extractReferencedNodeDep(node, node.calculateValueQuery);

            // Reevaluate this if procedure is loaded later (so we can compute add child options on question)
            let procedureDependencies = (node.linkMatchProcedureIds || []).map(a => ({
                from: a,
                to: node.id
            }));

            let dependencyUpdates = [
                ...procedureDependencies,
                ...otherDependencyUpdates.filter(a => a.from || a.to),
                ...conditionQueryDep,
                ...calculateValueQueryDep,
            ];

            addDependency(update, dependencyUpdates)
            return update;
        }
    },
    ProjectRoot: {
        ...domainRuleNOP,
        onPut: (state, node, beforeState, raisedAction, update) => {
            // Defaults for local only properties
            node.selectedCategory = node.selectedCategory || 0;
            node.displayDeletedExecutions = node.displayDeletedExecutions || false;
            node.offline = !!node.offline;
            node.rootId = node.id;
            const scopes = [node.rootId]
            if (node.offline) {
                // We need project to appear in other item scopes so cache clear sees that
                scopes.push(NODE_IDS.ProjectExecutionSummary(node.id, false))
                scopes.push(NODE_IDS.PhotosForExecution(null, node.id))
                scopes.push(NODE_IDS.ExecutionsForProject(node.id))
            }
            node.scopes = mergeUnique(node.scopes, scopes);
            if (node.projectStatistics) {
                node.status = node.projectStatistics.completed ? 'Done' : (node.projectStatistics.completedRatio === 0 ? 'Not Started' : 'In Progress');
            } else {
                node.status = 'Reload';
            }
            return update;
        }
    },
    CreateExecutionsRoot: {
        ...domainRuleNOP,
        onPut: (state, node, beforeState, raisedAction, update) => {
            node.rootId = node.id;
            node.businessErrors = [];

            addDependency(update, {from: node.procedureId, to: node, properties: ['loadedFull']});
            let selectedProcedureChanged = (node.processedProcedureId ?? null) !== (node.procedureId ?? null);
            let procedure = getNodeOrNull(state, node.procedureId);
            let recomputeProcedure = node.procedureId && procedure?.loadedFull && (node.children.length === 0 || selectedProcedureChanged);
            if (recomputeProcedure) {
                node.children = [];
                node.processedProcedureId = node.procedureId;
                let procedure = getNodeByProperty(state, node, 'procedureId');
                let firstStep = getActiveChildrenOrError(state, procedure).find(a => a);
                if (!firstStep) {
                    node.businessErrors.push('Template is missing a first Step');
                    return node;
                }
                let firstTask = getActiveChildrenOrError(state, firstStep).find(a => a);
                if (!firstTask) {
                    node.businessErrors.push('Template is missing a first Task');
                    return node;
                }
                if (firstTask.children.length === 0) {
                    node.businessErrors.push('Templates first Task is missing any questions');
                    return node;
                }
                let questionIds = firstTask.children;
                let questionSchema = state.schema.CreateExecutionsQuestion;
                for (let questionId of questionIds) {
                    let procedureQuestion = getNodeOrError(state, questionId);
                    if (procedureQuestion.deleted) {
                        continue;
                    }
                    let enableTo = procedureQuestion.questionType === QUESTION_TYPES.number.id;
                    let kind = null;
                    let options = null;
                    switch (procedureQuestion.questionType) {
                        case QUESTION_TYPES.text.id:
                            kind = "string";
                            break;
                        case QUESTION_TYPES.number.id:
                            kind = "integer";
                            break;
                        case QUESTION_TYPES.yesno.id:
                        case QUESTION_TYPES.select.id:
                            kind = "enum";
                            options = procedureQuestion.optionsParsed;
                            break;
                        default:
                            kind = null;
                            break;
                    }
                    if (kind == null) {
                        continue;
                    }
                    let questionAttributes = {
                        procedureQuestionId: questionId,
                        fromValueName: procedureQuestion.name + (enableTo ? ' [Range From]' : ''),
                        fromValueKind: kind,
                        fromValueOptions: options,
                        fromValueMin: procedureQuestion.minInclusive,
                        fromValueMax: procedureQuestion.maxInclusive,
                        toValueKind: kind,
                        toValueEnabled: enableTo,
                        toValueOptions: options,
                        toValueName: procedureQuestion.name + ' [Range To]',
                        toValueMin: procedureQuestion.minInclusive,
                        toValueMax: procedureQuestion.maxInclusive
                    };
                    let question = createChildNode(node, questionSchema, questionAttributes);
                    update[question.id] = question;
                    node.children.push(question.id);
                    node.procedureCount = 1;
                    node.taskName = firstStep.name + ' > ' + firstTask.name;
                }

                if (firstStep.completeMode === "task") {
                    node.signOffEnabled = true;
                    node.signOffNodeId = firstTask.id;
                } else {
                    node.signOffEnabled = firstStep.children.length === 1;
                    node.signOffNodeId = firstStep.id;
                }
                node.completeFlag = node.signOffEnabled;

            }
            if (node.procedureId == null) {
                node.children = [];
                node.procedureCount = 0;
            }

            if (node.submit) {
                let createdNodes = createExecutions(state, node);
                node.createdExecutionIds = createdNodes.filter(a => a.type === 'ExecutionRoot').map(a => a.id);
                node.submit = false;
                createdNodes.forEach(a => update[a.id] = a);

                // Reset the answers
                let children = getActiveChildrenOrError(state, node);
                for (let child of children) {
                    update[child.id] = {
                        ...child,
                        fromValue: null,
                        toValue: null
                    };
                }
            }
            return update;
        }
    },
    CreateExecutionsQuestion: {
        ...domainRuleNOP,
        onPut: (state, node, beforeState, raisedAction, update) => {
            node.rootId = node.parentId;
            let parentNode = shallowClone(getNodeByProperty(state, node, 'parentId'));
            let maxRange = 1;
            for (let questionId of parentNode.children) {
                let question = questionId === node.id ? node : getNodeOrError(state, questionId);
                let from = question.fromValue;
                let to = question.toValue;
                let range = from != null && to != null ? to - from + 1 : 0;
                maxRange = Math.max(maxRange, range);
                if (questionId === node.id) {
                    node.toValueMin = from;
                }
            }
            parentNode.procedureCount = maxRange;
            update[parentNode.id] = parentNode;
            return update;
        }
    },
    ExecutionPreview: {
        ...domainRuleNOP,
        onPut: (state, node, beforeState) => {
            let nodes = {};
            let procedureNodes = getDescendantsAndSelf(state, node.procedureId);
            let execution = getNodeOrNull(state, node.executionId);
            if (!node.created) {
                // Create new Execution
                let createRequest = {
                    procedureId: node.procedureId,
                    children: [],
                    procedureCount: 1,
                    projectId: null,
                    executionId: node.executionId
                };
                let createdNodes = createExecutions(state, createRequest, true);
                node.executionId = createdNodes.find(a => a.type === 'ExecutionRoot').id;
                node.created = true;
                createdNodes.forEach(a => a.preview = true);
                createdNodes.forEach(a => delete a.draft);
                createdNodes.forEach(a => nodes[a.id] = a);
                let dependencyUpdates = procedureNodes.map(a => ({from: a, to: node}));
                nodes[NODE_IDS.ReduxDependencies] = {type: NODE_IDS.ReduxDependencies, updates: dependencyUpdates};
            } else {
                // Live Preview requires me to update the execution when the procedure changes
                let procedure = getNodeOrError(state, node.procedureId);
                // Some unit tests unload, so lets ignore
                if (execution) {
                    nodes = synchronousExecution(beforeState, state, procedure, execution);
                }
            }
            Object.values(nodes).forEach(a => a.preview = true);
            Object.values(nodes).forEach(a => delete a.draft);
            let dependencyUpdates = procedureNodes.map(a => ({from: a, to: node}));
            nodes[NODE_IDS.ReduxDependencies] = {type: NODE_IDS.ReduxDependencies, updates: dependencyUpdates};
            return {
                ...nodes,
                [node.id]: node
            };
        },
    },
    ExecutionLinkNew: {
        ...domainRuleNOP,
        onPut: (state, node) => {
            let updatedNodes = {};
            const execution = getNodeOrNull(state, node.executionId);
            if (node.executionId == null) {
                // Create new Execution
                let createRequest = {procedureId: node.procedureId, source: node.source, children: [], procedureCount: 1, projectId: node.projectId};
                let createdNodes = createExecutions(state, createRequest, true);
                let newExecution = createdNodes.find(a => a.type === 'ExecutionRoot');
                node.executionId = newExecution.id;
                createdNodes.forEach(a => a.preview = node.preview);
                createdNodes.forEach(a => a.draft = true);
                createdNodes.forEach(a => updatedNodes[a.id] = a);
                // Link
                if (node.linkType && node.fromExecutionId && node.linkType !== LINK_TYPES.none.id) {
                    let fromExecution = getShallowClonedNodeOrError(state, node.fromExecutionId);

                    // Making from link preview as server will generate it
                    linkExecutions(state, fromExecution, newExecution, node.linkType, {draft: true}, {preview: node.preview}, null, updatedNodes);
                    updatedNodes[fromExecution.id] = fromExecution;
                }
                // Auto Answer
                if (node.fromExecutionIds && node.fromExecutionIds.length > 0) {
                    const task = getFirstTaskOrNull(state, node.procedureId);
                    const matchingQuestions = getChildrenSafe(state, task)
                        .filter(a => a.selectDataSource === SELECT_DATA_SOURCES.executionDynamic.id && a.selectMany);
                    const first = matchingQuestions.length > 0 ? matchingQuestions[0] : null;
                    if (first) {
                        const executionQuestion = createdNodes.find(a => a.procedureQuestionId === first.id)
                        if (executionQuestion) {
                            executionQuestion.initialValue = node.fromExecutionIds
                        }
                    }
                }
                // Auto Submit
                if (!node.fromExecutionId && node.submitOnDone) {
                    addDependency(updatedNodes, {from: node.executionId, to: node, properties: ['completed']})
                }
                if (node.inline) {
                    node.submit = true;
                    addDependency(updatedNodes, {from: node.executionId, to: node.id, properties: ['processedFull']});
                }
            } else if (node.submit || node.cancel || (node.submitOnDone && execution?.completed)) {
                let shouldPatch = true;
                if (!node.cancel && node.fromExecutionId) {
                    let fromExecution = getNodeOrError(state, node.fromExecutionId);
                    shouldPatch = fromExecution.draft !== true;
                }
                if (shouldPatch) {

                    let patch = node.cancel ? {deleted: true} : {draft: false};
                    let patchNode = removeFromNodes => {
                        for (let node of removeFromNodes) {
                            if (node.deleted) {
                                continue;
                            }
                            updatedNodes[node.id] = {...node, ...patch};
                            if (updatedNodes[node.id].draft === false) {
                                delete updatedNodes[node.id].draft
                            }
                        }
                    }

                    // Remove draft from linked but not linked above this one
                    let patchNotDraft = executionId => {
                        let execution = updatedNodes[executionId] || getNodeOrError(state, executionId);
                        if (!execution.draft || execution.deleted || execution.id === node.fromExecutionId) {
                            return;
                        }
                        patchNode(getDescendantsAndSelf(state, execution));
                        let createdFromMeNodes = getExecutionLinkNewFrom(state, executionId)
                        for (let link of createdFromMeNodes) {
                            patchNotDraft(link.executionId);
                        }
                    }
                    patchNotDraft(node.executionId);

                    // Remove draft from any auto created executions
                    let patchAutoCreated = executionId => {
                        let ruleNodes = getDescendantsAndSelf(state, executionId).filter(n => n.type === NODE_TYPE_OPTIONS.ExecutionRule);
                        for (let ruleNode of ruleNodes) {
                            for (let autoCreatedId of ruleNode.createExecutionIds || []) {
                                patchNode(getDescendantsAndSelf(state, autoCreatedId));
                                patchAutoCreated(autoCreatedId);
                            }
                        }
                    }
                    patchAutoCreated(node.executionId);

                }
                node.cancel = node.submit = node.submitOnDone = false;
            }
            return {
                ...updatedNodes,
                [node.id]: node
            };
        },
    },
    "Photo": {
        ...domainRuleNOP,
        onPut: (state, node) => {
            let dirty = state.dirtyNodes[node.id];
            const updates = {
                [node.id]: node
            };
            node.rootId = node.id;
            node.scopes = mergeUnique(node.scopes, [node.rootId]);

            let question = getNodeOrNull(state, node.executionQuestionId);
            // Not loving adding this client side, next to fix Integration to set the field to avoid needing this
            // for ag audit report.
            if(question) {
                const property = node.propertyName === 'resolvedPhotoIds' ? 'resolvedPhotoIds' : 'initialPhotoIds';
                const photoIds = question[property] || [];
                const isPhotoIdIncluded = photoIds.includes(node.id);

                // if photo is not deleted and not included to question's photos then add it
                if(!node.deleted && !isPhotoIdIncluded) {
                    updates[question.id] = {
                        ...question,
                        [property]: [...photoIds, node.id]
                    }
                }
                // if photo is deleted and included to question's photos then remove it
                else if (node.deleted && isPhotoIdIncluded) {
                    updates[question.id] = {
                        ...question,
                        [property]: photoIds.filter(id => id !== node.id)
                    }
                }
            }

            // Once uploaded no need to re-attempt storing it
            const isStoredServer = !!dirty?.storedServer;

            // If page is reloaded after photo save and before photo list load it is disappearing
            // Lets load it on the
            if (!isStoredServer) {
                const extraScope = NODE_IDS.PhotosForExecution(node.executionId, node.projectId)
                if (!node.scopes.includes(extraScope)) {
                    node.scopes.push(extraScope)
                }
            }

            // Denormalise data
            let root = getNodeOrNull(state, node.executionId);
            if (root && !node.executionName && !isStoredServer) {
                node.preview = node.preview === undefined ? root.preview : node.preview;
                node.executionTitle = node.executionTitle === undefined ? root.title : node.executionTitle;
                node.executionKey = node.executionKey === undefined ? root.key : node.executionKey;
                node.executionName = node.executionName === undefined ? root.name : node.executionName;
                node.executionQuestionName = node.executionQuestionName === undefined && question ? question?.name : node.executionQuestionName;
                node.procedureId = node.procedureId === undefined ? root.procedureId : node.procedureId;
                node.procedureQuestionId = node.procedureQuestionId === undefined && question ? question.procedureQuestionId : node.procedureQuestionId;
            }

            const extension = node.filename?.split('.')?.pop()?.toLowerCase() ?? "";
            node.isImage = imageExtensions.includes(extension);
            return updates;
        },
        onStored: (state, node, beforeState, action) => {
            // Once uploaded delete the photo data
            // But, only once we successfully store the fact its uploaded into indexeddb
            if (node.photoCaptureId && node.originalUrl && !node.photoCaptureDeleteStarted) {
                reportDeveloperInfo('Deleting PhotoCapture as upload complete for photo ' + node.id);
                action.asyncDispatch({
                    type: PUT_NODE_PROPERTY,
                    payload: {node: {id: node.id, photoCaptureDeleteStarted: true}}
                });
                deleteDbNode(node.photoCaptureId).then(() => {
                    action.asyncDispatch({
                        type: PUT_NODE_PROPERTY,
                        payload: {node: {id: node.id, photoCaptureId: null}}
                    });
                })
            }

            return {
                [node.id]: node
            };
        },
    },
    "PhotoCapture": {
        ...domainRuleNOP,
        onLoad: (state, node) => {

            let additionalUpdates = {};
            let photo = getNodeOrNull(state, node.photoId);

            // If app is reloaded before photo is uploaded lets restore the thumbnail
            if (photo && !photo.thumbnailInMemoryUrl && node.thumbnailData) {
                reportDebug('Restoring in memory thumbnail for ' + photo.id);
                //photo = cloneDeep(photo);
                photo.thumbnailInMemoryUrl = createImageObjectUrl(node.thumbnailData);
                additionalUpdates[photo.id] = photo;
            }

            return {
                [node.id]: node,
                ...additionalUpdates
            };
        },
        onPut: (state, node) => {
            // If photo is uploaded delete me
            // Code on photo should do this, but lets do it here too
            if (node.photoId) {
                let photo = getNodeOrNull(state, node.photoId);
                if (photo?.thumbnailUrl) {
                    reportDebug('Deleting PhotoCapture as upload complete for photo ' + photo.id);
                    deleteDbNode(node.id);
                    node.deleted = true;
                    node.storeDb = false;
                }
            } else {
                console.info('PhotoCapture.photoId is null')
            }
            node.scopes = mergeUnique(node.scopes, ['AlwaysLoad']);
            return {
                [node.id]: node
            };
        }
    },
    "UserSettings": {
        ...domainRuleNOP,
        onPut: (state, node) => {
            if (node.rootId == null) {
                node.rootId = node.parentId || node.id;
            }
            if (node.scopes == null || node.scopes.length === 0) {
                node.scopes = mergeUnique(node.scopes, [node.rootId]);
            }
            calculateLoadedFull(state, node);
            let update = {};
            update[node.id] = node;

            const user = getNodeOrNull(state, NODE_IDS.User);
            addDependency(update, {
                from: user,
                to: node,
                properties: ['clientId']
            });

            if(isNullOrUndefined(node.showExecutionProperties) && user.clientId) {
                node.showExecutionProperties = cypress.isCypress()
            }

            return update;
        },
    },
    "Location": {
        ...domainRuleNOP,
        onPut: (state, node) => {
            if (node.position != null) {
                node.feature = {
                    "type": "Feature",
                    "geometry": {
                        "type": "Point",
                        "coordinates": [node.position.longitude, node.position.latitude, node.position.altitude].filter(a => a)
                    },
                    "properties": {
                        "accuracy": node.position.accuracy
                    }
                };
            } else {
                node.feature = null;
            }
            // Delete null/undefined as the server strips nulls making the client think its dirty
            stripNulls(node);
            stripNulls(node.position);
            return {
                [node.id]: node
            }
        }
    },
    "NodeView": {
        ...domainRuleNOP
    },
    "NodeAssignment": {
        ...domainRuleNOP
    },
    "NodeActivity": {
        ...domainRuleNOP
    },
    "ExecutionSelector": {
        ...domainRuleNOP
    },
    "ExecutionListPage": {
        ...domainRuleNOP,
        onPut: (state, node, beforeState) => {            
            const beforeNode = getNodeOrNull(beforeState, node.id);
            const updates = {};

            const isQueryQuestion = !!node.questionId;
            // Workspace listing page of mixed templates
            const isVeryMixedTypes = !node.procedureId && !isQueryQuestion;
            const question = getNodeOrNull(state, node.questionId);

            let isQueryListing = false;

            // Is query link type none
            let queryRules;
            if (isQueryQuestion) {
                queryRules = getActiveRulesForNode(state, node.questionId).filter(a => a.linkToQuestionOn);
                isQueryListing = queryRules?.flatMap(r => r.linkMatchLinkTypes).includes(LINK_TYPES.none.id)
                addDependency(updates, queryRules.map(r => ({
                    from: r,
                    to: node,
                    properties: ['linkMatchLinkTypes'],
                })));

                node.pivot = question.linkStyle === PROCEDURE_LINK_STYLE.report.id;
                addDependency(updates, {from: question, to: node, properties: ['linkStyle']});
            }

            let isListing = !isQueryQuestion || isQueryListing;

            // #1 Map Available
            // If template and/or execution have features
            const procedure = getNodeOrNull(state, node.procedureId);
            node.mapAvailable = procedure?.hasLocationField === true;
            addDependency(updates, {from: node.procedureId, to: node, properties: ['hasLocationField']})
            if (node.executionIds) {
                let nodes = getExecutionSummaryFullByIdIfPresent(state, node.executionIds);
                let anyHaveFeature = nodes.find(a => a.feature);
                if (anyHaveFeature) {
                    node.mapAvailable = true;
                }
                for (let id of node.executionIds) {
                    addDependency(updates, {from: getSummaryId(id), to: node, properties: ['feature']})
                }
            }

            // #2 Available Views
            // TODO Consider caching this later.
            let includeNearMe = false;
            if (node.mapAvailable) {
                let location = getNodeOrNull(state, NODE_IDS.Location);
                includeNearMe = location && isDeviceLocationKnown(location);
            }
            const defaultViews = Object.values(EXECUTION_SEARCH_VIEWS);
            node.views = [];
            if (isQueryQuestion) {
                // Query
                const customViews = computeTableViews(state, node.questionId);
                for (let customView of customViews) {
                    node.views.push(customView);
                }
                if (node.views.length === 0) {
                    node.views.push({
                        id: EXECUTION_SEARCH_VIEWS.created.id,
                        name: EXECUTION_SEARCH_VIEWS.created.name
                    });
                }
                node.mapSearchAvailable = false;
                node.filterMode = isQueryListing ? EXECUTION_FILTER_MODE.server.id : EXECUTION_FILTER_MODE.client.id;
                node.sortMode = isQueryListing ? EXECUTION_FILTER_MODE.server.id : EXECUTION_FILTER_MODE.client.id;
            } else {
                // Listing Page
                node.views = defaultViews
                    .map(a => ({id: a.id, name: a.name}))
                    .filter(a => includeNearMe || a.id !== EXECUTION_SEARCH_VIEWS.nearMe.id);
                if (procedure && procedure.loaded && node.views) {
                    // For re-compute always just in case this is used in live preview
                    // Otherwise, can do this once if performance issues
                    const customViews = computeTableViews(state, procedure.id);
                    for (let customView of customViews) {
                        node.views.push(customView);
                    }
                }
                node.mapSearchAvailable = node.mapAvailable;
                node.filterMode = EXECUTION_FILTER_MODE.server.id;
                node.sortMode = EXECUTION_FILTER_MODE.server.id;
            }

            // #3 Default view
            if (node.selectedViewToDefault == null) {
                node.selectedViewToDefault = node.selectedViewId == null;
            }
            const defaultSelectedView = node.selectedViewId == null || (node.selectedViewToDefault && beforeNode && beforeNode.selectedViewId && beforeNode.selectedViewId === node.selectedViewId);
            if (node.views && node.views.length && (defaultSelectedView || !node.views.some(a => a.id === node.selectedViewId))) {
                node.selectedViewId =
                    // Default to first custom view
                    first(node.views.filter(a => !EXECUTION_SEARCH_VIEWS[a.id]))?.id
                    || EXECUTION_SEARCH_VIEWS.created.id;
            }
            let view = EXECUTION_SEARCH_VIEWS[node.selectedViewId] || null;
            const isCustomView = !view;

            // #4 Order
            const viewChanged = beforeNode?.selectedViewId !== node.selectedViewId;

            // Include Deleted
            // When query question we include deleted when self is deleted
            // For listing page, we will always include deleted in case they are doing a
            if (!isQueryQuestion) {
                node.includeDeleted = true;
            }

            // Query listing filters
            if (isQueryQuestion) {
                const root = getRootNodeOrError(state, node.questionId);
                const {
                    filter,
                    dependencies
                } = computeFilter(state, node.questionId, SELECTOR_FILTER_TYPE.link.id, node.id);
                node.queryFilter = filter;
                addDependency(updates, dependencies);
                addDependency(updates, {from: root, to: node, properties: ['links', 'rules']});
            }

            let orders = null;
            if (viewChanged && !node.linkTree) {
                if (beforeNode?.selectedViewId) {
                    node.selectedViewToDefault = false;
                }
                node.columns = null;
                if (isCustomView) {
                    orders = computeListingPageOrderBy(state, node.selectedViewId);
                    if (orders && orders.length) {
                        orders = orders.map(a => a.orderBy === 'feature' ? {...a, orderBy: 'distance'} : a);
                        node.orderBy = orders[0].orderBy;
                        node.orderByDirection = orders[0].orderByDirection;
                    }
                } else {
                    node.orderBy = view?.orderBy || EXECUTION_SEARCH_VIEWS.created.orderBy;
                    node.orderByDirection = view?.orderByDirection;
                }
                if (node.selectedRowIds) {
                    node.selectedRowIds = [];
                }
            }
            
            if (node.linkTree) {
                const globalNavigationStyle = getGlobalNavStyle(state, node.procedureId);
                if (globalNavigationStyle) {
                    if (globalNavigationStyle.orderBy) {
                        let jsonLogic = globalNavigationStyle.orderBy ? JSON.parse(globalNavigationStyle.orderBy) : null;
                        const properties = extractJsonLogicProperties(jsonLogic);
                        node.orderBy = properties.length === 1 ? properties[0] : null;
                        node.orderByDirection = globalNavigationStyle.orderByDirection;
                    }
                    if(globalNavigationStyle.ruleId) {
                        addDependency(updates, {from: globalNavigationStyle.ruleId, to: node.id, properties: ['format', 'calculateValueQuery', 'orderByDirection']});
                    }
                }
            }

            if (isQueryListing && !node.sortModel && node.queryFilter?.orderBy) {
                node.orderBy = node.queryFilter.orderBy;
                node.orderByDirection = node.queryFilter.orderByDirection;
            }
            node.orderByDirection ??= EXECUTION_SEARCH_VIEWS.created.orderByDirection;

            // #5 Filter
            node.filter = '';
            switch (node.selectedViewId) {
                case EXECUTION_SEARCH_VIEWS.completed.id:
                    node.filter = `statuses=done`;
                    break;
                case EXECUTION_SEARCH_VIEWS.assignedToMe.id:
                    node.filter = `assignedEntities=me`;
                    break;
                case EXECUTION_SEARCH_VIEWS.nearMe.id:
                    break;
                default:
                    break;
            }

            var filters = []

            if (node.additionalFilter) {
              filters.push(node.additionalFilter)
            }

            if (isCustomView && node.selectedViewId) {
                let filter = computeListingPageFilter(state, node.selectedViewId);
                let {result: partiallyEvaluatedFilter, dependencies: filterDependencies} = convertJsonLogicForQuery(state, node, filter, 'querySelf') ?? {};
                addDependency(updates, filterDependencies);
                if (partiallyEvaluatedFilter != null) {
                  // node.filter += `where=${encodeURIComponent(JSON.stringify(partiallyEvaluatedFilter))}`
                  filters.push(partiallyEvaluatedFilter)
                }
            }
            if (isQueryListing && node.queryFilter?.where) {
                filters.push(JSON.parse(node.queryFilter.where));
            }

            if(filters.length === 1) {
              node.filter += `&where=${encodeURIComponent(JSON.stringify(filters[0]))}`
            } else if (filters.length > 1) {
              const where = { 'and': filters }
              node.filter += `&where=${encodeURIComponent(JSON.stringify(where))}`
            }

            if (node.searchTerm) {
                node.filter += `&q=${encodeURIComponent(node.searchTerm)}`
            }
            if (node.boundingBox) {
                node.boundingBox.forEach(x => {
                    node.filter += `&boundingBox=${encodeURIComponent(x)}`;
                })
            }

            // LOCATION
            if (node.orderBy === EXECUTION_SEARCH_VIEWS.nearMe.orderBy) {
                let location = getNodeOrNull(state, NODE_IDS.Location);
                if (location?.mode === LOCATION_MODES.unavailable.id) {
                    //reportBusinessError(strings.location.unavailableMessage);
                } else if (location?.mode === LOCATION_MODES.userDenied.id) {
                    //reportBusinessError(strings.location.userDeniedMessage);
                } else if (location?.position) {
                    node.filter = `&lat=${location.position.latitude}&lng=${location.position.longitude}`;
                    addDependency(updates, {from: NODE_IDS.Location, to: node, properties: ['position']})
                }
            }

            // URL
            let loadFilteredUrl = `/executions?`;
            if (!node.questionId || question?.linkStyle !== PROCEDURE_LINK_STYLE.repeatingSections.id) {
                loadFilteredUrl += "summary=true"
            }
            if (node.procedureId) {
                loadFilteredUrl += `&procedureId=${encodeURIComponent(node.procedureId || '')}`;
            } else if (node.procedureType && node.procedureType !== 'all') {
                loadFilteredUrl += `&procedureType=${encodeURIComponent(node.procedureType || '')}`;
            } else if (node.queryFilter?.procedureIds) {
                node.queryFilter.procedureIds.forEach(id => loadFilteredUrl += `&procedureIds=${encodeURIComponent(id)}`);
            }
            if (node.filter) {
                const prefix = node.filter[0] === '&' ? '' : '&';
                loadFilteredUrl += prefix + node.filter;
            }
            if (orders) {
                for (let order of orders) {
                    loadFilteredUrl += `&orderBy=${order.orderBy}&orderByDirection=${order.orderByDirection}`;
                }
            } else {
                loadFilteredUrl += `&orderBy=${node.orderBy}&orderByDirection=${node.orderByDirection}`;
            }

            if (!node.linkTree) {
                const shouldRecomputeColumns =
                    (beforeNode && beforeNode.pivot !== node.pivot) ||
                    (node.pivot && beforeNode?.selectedViewId !== node.selectedViewId);
                if (shouldRecomputeColumns) {
                    node.columns = null;
                    node.columnsVisibility = null;
                }

                // Custom Columns
                if (isCustomView && node.selectedViewId) {
                    const result = computeTableColumns(state, node.selectedViewId, node);
                    node.columns = result.columns;
                    for (let dep of result.dependencies) {
                        dep.to = node;
                        addDependency(updates, dep)
                    }
                }

                // Standard fields
                if (node.pivot) {
                    node.columns = computePivotTableColumns(state, node);
                    const {pivotSettings, dependencies} = computePivotSettings(state, node.id, node.selectedViewId)
                    node.pivotSettings = pivotSettings;
                    addDependency(updates, {from: node.procedureId, to: node.id, properties: ['loadedFull']});
                    addDependency(updates, dependencies);
                } else {
                    const defaultColumns = computeDefaultColumns(state, node);
                    if (!node.columns) {
                        node.columns = [];
                    }
                    node.columns.push(...defaultColumns);
                }

                if (node.procedureId) {
                    const {result: questionColumns, dependencies} = computeQuestionColumns(state, node);
                    addDependency(updates, dependencies);
                    node.columns.push(...questionColumns);
                }
                if (node.columnsVisibility) {
                    node.columns?.forEach((column) => {
                        column.hidden = node.columnsVisibility[column.field] === false;
                    });
                }
            }

            if (node.columns) {
                let useColumns = node.columns;
                useColumns = useColumns.filter((col) => !col.hidden);
                let sources = useColumns.flatMap(a => Object.values(a?.sources || {}));
                let loadExtra = sources.flatMap(a => a?.returnFields || []);
                if (loadExtra.length > 0) {
                    loadFilteredUrl += loadExtra.map(a => `&returnFields=${encodeURIComponent(a)}`).join('&');
                }
            }

            // Loader
            const dependencies = processSelectorLoaders(state, node, beforeNode, updates, isListing, isQueryListing, loadFilteredUrl);
            addDependency(updates, dependencies);

            // Dynamic columns based on data
            if (node.executionIds && view && !isVeryMixedTypes && !node.linkTree) {
                const extraColumns = computeDynamicColumns(state, node);
                for (let extraColumn of extraColumns.reverse()) {
                    node.columns.splice(1, 0, extraColumn);
                }
            }

            // Handle duplicate columns
            if (node.columns) {
                postProcessColumns(state, node);
            }

            // Link Tree
            if (node.linkTree) {
                const {tree, ancestryMap} = computeLinkTree(state, node);
                node.tree = tree;
                node.ancestryMap = ancestryMap;
            }

            // Compute sort model for query question
            if (isQueryQuestion && node.sortModel === undefined && node.queryFilter?.orderBy) {
                const orderByColumn = node.columns.find(c =>
                    c.questionId ?
                    c.questionId === node.queryFilter.orderBy :
                    c.field === node.queryFilter.orderBy ||
                    c.orderBy === node.queryFilter.orderBy
                );
                if (orderByColumn) {
                    node.sortModel = [
                        {
                            field: orderByColumn?.field,
                            sort: EXECUTION_ORDER_BY_DIRECTION[node.queryFilter?.orderByDirection]?.dataTableValue,
                        }
                    ]
                }
            }

            // #X Execution Fields
            // Add missing fields for new local items so if loaded in full they replicate them onto ExecutionRoot

            const returnFieldsByProcedure = {};
            let returnFieldsExecutionIds = [];
            const allReturnFields = new Set();
            const addReturnFields = (procedureId, returnFields) => {
                if (!returnFieldsByProcedure[procedureId]) {
                    returnFieldsByProcedure[procedureId] = new Set();
                }
                for (let returnField of returnFields || []) {
                    returnFieldsByProcedure[procedureId].add(returnField)
                    allReturnFields.add(returnField);
                }
            }
            // Find what fields we need by procedure
            // Columns
            for (let column of node.columns || []) {
                for (let source of Object.values(column?.sources || {})) {
                    addReturnFields(source.procedureId, source.returnFields);
                }
            }
            if (node.executionIds) {
                returnFieldsExecutionIds = node.executionIds;
            }
            // Query question filter
            // Hmmm where to put this. As the filter needs it we need to do this to all executions linked to root.
            if (!isListing) {
                // We need to add the returnFields only all linked executions to know if they should be linked
                const root = getRootNodeOrError(state, node.questionId)
                returnFieldsExecutionIds = getNodesIfPresent(state, root.links).map(a => a.toNodeId);

                const queryRules = getActiveRulesForNode(state, node.questionId).filter(a => a.linkToQuestionOn);
                for (const queryRule of queryRules) {
                    const filterRule = getActiveChildRuleByActionOrNull(state, queryRule.id, RULE_ACTION_TYPE.filter.id);
                    if (!filterRule) {
                        continue;
                    }
                    const filter = JSON.parse(filterRule.conditionQuery);
                    const returnFields = extractJsonLogicReturnFields(filter);
                    for (let procedureId of queryRule.linkMatchProcedureIds || []) {
                        addReturnFields(procedureId, returnFields);
                        if (queryRule.addNewOn) {
                            addDependency(updates, {
                                from: procedureId,
                                to: node.id,
                                properties: ['loadedFull'],
                            });
                        }
                    }
                    addDependency(updates, {
                        from: filterRule,
                        to: node,
                        properties: ['conditionQuery', 'linkMatchProcedureIds']
                    });
                }
                addDependency(updates, {from: root, to: node, properties: ['links', 'rules']});
            }
            for (let id of returnFieldsExecutionIds) {
                const execution = getNodeOrNull(state, id);
                if (!execution) {
                    continue
                }
                const returnFields = returnFieldsByProcedure[execution.procedureId]
                for (let field of returnFields || []) {
                    if (!execution?.fields?.[field]) {
                        let updated = {
                            ...execution,
                            fields: {
                                ...execution.fields,
                                [field]: {procedureNodeId: field}
                            }
                        }
                        updates[updated.id] = updated;
                    }
                }
            }
            let execution;
            let useReturnFields;
            if (!isListing) {
                const root = getRootNodeOrError(state, node.questionId)
                execution = updates[root.id] || root;
                useReturnFields = mergeUnique(execution.scopeReturnFields, allReturnFields);
            }
            // In case return fields change
            // Re-compute loaders
            // Update execution return fields
            if (execution && execution.scopeReturnFields !== useReturnFields) {
                const dependencies = processSelectorLoaders(state, node, beforeNode, updates, isListing, isQueryListing, loadFilteredUrl, useReturnFields);
                // Update dependencies and execution
                addDependency(updates, dependencies);
                updates[execution.id] = {
                    ...execution,
                    scopeReturnFields: useReturnFields,
                }
            }

            // Bulk Actions
            const procedureIdsForBulkActions = [];
            if (procedure) {
                procedureIdsForBulkActions.push(procedure.id);
            }
            if (isQueryQuestion && queryRules && queryRules.length) {
                const linkedProcedureIds = queryRules.flatMap(r => r.linkMatchProcedureIds);
                procedureIdsForBulkActions.push(...linkedProcedureIds);
                addDependency(updates, linkedProcedureIds.map(id => ({
                    from: id,
                    to: node.id,
                    properties: ['loaded', 'loadedFull']
                })));
            }
            if (procedureIdsForBulkActions.length) {
                const availableActions = {};
                const canAssign = procedureIdsForBulkActions.every(p => hasProcedurePermission(state, p, Permissions.execution.assign));
                if (canAssign) {
                    availableActions[LISTING_PAGE_BULK_ACTIONS.assign.id] = LISTING_PAGE_BULK_ACTIONS.assign;
                }
                const canDelete = procedureIdsForBulkActions.every(p => hasProcedurePermission(state, p, Permissions.execution.delete));
                if (canDelete) {
                    availableActions[LISTING_PAGE_BULK_ACTIONS.delete.id] = LISTING_PAGE_BULK_ACTIONS.delete;
                }
                procedureIdsForBulkActions.forEach((p) => {
                    addDependency(updates, {from: p, to: node, properties: ['rules']});
                });
                const bulkActions = procedureIdsForBulkActions.flatMap(p => getActiveRulesByActionForNode(state, p, RULE_ACTION_TYPE.manuallyAddExecutionBulk.id));
                for (let rule of bulkActions.filter(a => !a.deleted)) {
                    const createProcedure = getNodeOrNull(state, rule.createExecutionProcedureId)
                    const label = getActiveChildRuleByActionOrNull(state, rule.id, RULE_ACTION_TYPE.label.id)
                    const useLabel = label && evaluateRule(state, label.calculateValueQuery, label, 'Custom action');
                    const canCreate = hasProcedurePermissionLoaded(createProcedure, Permissions.execution.create);
                    availableActions[rule.id] = {
                        id: rule.id,
                        name: useLabel,
                        maxItems: DEFAULT_MAX_ACTIONABLE_ITEMS,
                        actionType: rule.actionType,
                        procedureId: rule.createExecutionProcedureId,
                        visible: canCreate
                    }
                    addDependency(updates, {
                        from: rule.createExecutionProcedureId,
                        to: node,
                        properties: ['loaded', 'loadedFull']
                    })
                    addDependency(updates, {
                        from: label,
                        to: node,
                        properties: ['deleted', 'calculateValueQuery']
                    })
                    addDependency(updates, {
                        from: rule,
                        to: node,
                        properties: ['deleted']
                    })
                }
                node.availableActions = availableActions;
            }

            // Dependencies
            addDependency(updates, {
                from: node.loadUrl,
                to: node,
                properties: ['loaded', 'loading', 'lastReloadTicks', 'nodeIds']
            })
            // Custom view rules. For now doing all, could just do selected view if this is slow
            if (procedure) {
                addDependency(updates, {from: node.procedureId, to: node, properties: ['hasLocationField', 'ruleIds']})
            } else if (node.questionId) {
                let views = getRulesForNodeByActionIfPresent(state, node.questionId, [RULE_ACTION_TYPE.collectionView.id]);
                for (let view of views) {
                    addDependency(updates, {
                        from: view,
                        to: node,
                        properties: ['message', 'deleted', 'ruleIds']
                    })
                    let columns = getRulesForNodeByActionIfPresent(state, view.id, [RULE_ACTION_TYPE.collectionColumn.id]);
                    for (let column of columns) {
                        addDependency(updates, {
                            from: column,
                            to: node,
                            properties: ['message', 'deleted', 'ruleIds']
                        })
                        let columnSources = getRulesForNodeByActionIfPresent(state, column.id, [RULE_ACTION_TYPE.collectionColumnSource.id]);
                        for (let columnSource of columnSources) {
                            addDependency(updates, {
                                from: columnSource,
                                to: node,
                                properties: ['calculateValueQuery', 'deleted', 'ruleIds']
                            })
                        }
                    }
                }
                addDependency(updates, {from: node.questionId, to: node, properties: ['ruleIds']})
            }
            return {
                [node.id]: node,
                ...updates
            }
        }
    },
    MyAssignmentPage: {
        ...domainRuleNOP
    },
    MapView: {
        ...domainRuleNOP
    },
    ProcedureConfig: {
        ...domainRuleNOP,
        onPut: (state, node) => {
            return {
                [node.id]: node
            }
        }
    },
    ClientConfig: CLIENT_CONFIG_DOMAIN_RULES,
};

const executeDomainRules = (method, state, node, beforeState, nodeProcessedCount, action) => {
    try {
        // Only run for loaded nodes, as was making the demormalise logic run
        if (node.loaded === false) {
            let initialNode = getNodeOrNull(beforeState, node.id);
            if (isEqual(initialNode, node)) {
                node = initialNode;
            }
            return {
                nodes: {[node.id]: node},
                dirtyNodes: {}
            };
        }
        let clonedNode = cloneDeep(node);
        const update = domainRuleNOP.onPut(state, clonedNode);
        if (domainRules[node.type] === undefined) {
            throw new Error("There are no rules defined for " + node.type);
        }
        let nodeUpdates;
        if (Array.isArray(method)) {
            for (let meth of method) {
                nodeUpdates = domainRules[node.type][meth](state, clonedNode, beforeState, action, update);
            }
        } else {
            nodeUpdates = domainRules[node.type][method](state, clonedNode, beforeState, action, update);
        }
        let returnIsANode = nodeUpdates?.type !== undefined;
        if (returnIsANode) {
            nodeUpdates = {[nodeUpdates.id]: nodeUpdates};
        }
        // If the node did not change we don't need to re-compute other nodes
        let areAnyDirty = false;
        for (let afterNode of Object.values(nodeUpdates)) {
            let beforeNode = getNodeOrNull(state, afterNode.id);
            let initialNode = getNodeOrNull(beforeState, afterNode.id);
            if (afterNode && initialNode) {
                // Lets keep any properties that are the same so that react can do shallow equal on render
                for (let property of Object.keys(afterNode)) {
                    if (afterNode[property] === initialNode[property]) {
                        // No touch, already same as initial
                    } else if (isEqual(afterNode[property], initialNode[property])) {
                        afterNode[property] = initialNode[property];
                    } else if (!beforeNode || afterNode[property] === beforeNode[property]) {
                        // No touch, already same as initial
                    } else if (isEqual(afterNode[property], beforeNode[property])) {
                        afterNode[property] = beforeNode[property];
                    }
                }
            }
            let sameAsInitialState = initialNode && afterNode && shallowEqual(initialNode, afterNode);
            let sameAsBeforeDomainRule = beforeNode && afterNode && isEqual(beforeNode, afterNode);
            if (sameAsInitialState) {
                // If node never changed lets put it back
                nodeUpdates[afterNode.id] = initialNode;
                if (!sameAsBeforeDomainRule) {
                    areAnyDirty = true;
                }
            } else if (sameAsBeforeDomainRule) {
                // If node is is same as it was before invoking the domain rule
                nodeUpdates[afterNode.id] = beforeNode;
                if (nodeProcessedCount === 0) {
                    areAnyDirty = true;
                }
            }
            // Note: This will not correctly detect a node that is changed by another node and already processed once
            // Ideally I should reset the processed count each time it changes
            if (nodeProcessedCount === 0) {
                // When a node is patched its merged into the state before here, so
                // after running the rules even though it hasn't changed it did and we need
                // to run the other nodes
                areAnyDirty = areAnyDirty || !sameAsInitialState || !sameAsBeforeDomainRule;
            } else {
                // Once a node is processed once we only want to re-run if it changed this time
                areAnyDirty = areAnyDirty || !sameAsBeforeDomainRule;
            }

        }
        if (!areAnyDirty) {
            // Return just processed node so that the original is put back
            nodeUpdates = {
                [node.id]: nodeUpdates[node.id]
            };
        }
        return {
            nodes: nodeUpdates
        };
    } catch (error) {
        if (node.deleted !== true) {
            // Ignoring errors on deleted nodes as unless it is a rule we want to restore we don't really care.
            node.processingError = error.message || error;
            let root = getNodeOrNull(state, node.rootId);
            let procedureNodeId = node.procedureQuestionId || node.procedureStepId || node.procedureTaskId || node.procedureRuleId || node.procedureId
            let msg = `Error processing node ${node.type} [${node.id}] [${node.name}] [${procedureNodeId}] of root [${node.rootId}] [${root?.name}] [${root?.key}] procedure id [${root?.procedureId}] preview [${node.preview}] draft [${node.draft}]. ${node.processingError}`;
            reportError(msg, error, node);
            state.processingErrorCount++;
        }
    }
    return {
        nodes: {[node.id]: node},
        dirtyNodes: {}
    }
};
const storeNodes = (action, state, nodes, beforeState) => {
    if (!isIndexedDbAvailable()) {
        return;
    }
    let nodesToStore;
    if (Array.isArray(nodes)) {
        nodesToStore = nodes.map(id => state.nodes[id]);
    } else {
        nodesToStore = Object.values(nodes);
    }
    let storeNodes = [];
    let deletePatchNodes = [];
    let allChanges = [];
    let noChangeNodes = 0;
    const jsonNow = getJsonDate();
    for (let node of nodesToStore) {
        let nodeSchema = state.schema[node.type];
        if (!node.id) {
            throw new Error(`Node has no id: ${JSON.stringify(node)}`);
        }
        if (node.type === 'PhotoCapture' && !node.originalData) {
            console.warn('Attempt to break PhotoCapture', node);
            continue;
        }
        if (!node.id) {
            console.warn('Node is missing an id. Not storing.', node);
            continue;
        }
        if (!node.type) {
            console.warn('Node is missing a type. Not storing.', node);
            continue;
        }
        if (nodeSchema.storeDb === false || node.storeDb === false ||
            (nodeSchema.storeDbDeleted === true && node.deleted === true)) {
            // Delete just in case its there
            deleteDbNode(node.id);
            continue;
        }
        let beforeNode = getNodeOrNull(beforeState, node.id);
        let nodeSame = beforeNode === node;
        let dirty = getDirty(state, node.id);
        let beforeDirty = getDirty(beforeState, node.id);
        if (nodeSame) {
            // Need to see if dirty is different too
            // As storedDb will be true on before and undefined on dirty we need to ignore it
            let beforeDirtyCompare = {...beforeDirty, storedDb: null};
            let dirtyCompare = {...(dirty || {}), storedDb: null};
            let dirtySame = isEqual(beforeDirtyCompare, dirtyCompare);
            if (dirtySame) {
                continue;
            }
        }
        // Mark dirty as not yet stored
        // Assuming here that dirty is already cloned
        if (dirty === beforeDirty) {
            reportDeveloperWarning('Dirty node not cloned. Should not mutate state.', dirty);
        } else {
            dirty.storedDb = false;
        }
        let nodeToStore = {id: node.id};
        // if nodeSame then dirty is different
        let storeAsDifferent = nodeSame || beforeNode === null;
        const changes = []
        const checkProperties = new Set();
        for (let name of Object.keys(node)) {
            checkProperties.add(name)
        }
        if (beforeNode) {
            for (let name of Object.keys(beforeNode)) {
                checkProperties.add(name)
            }
        }
        for (let p of checkProperties) {
            const v = node[p]
            let propertySchema = nodeSchema.properties[p];
            if (propertySchema?.storeDb === false) {
                continue;
            }
            if (beforeNode && v !== beforeNode[p]) {
                storeAsDifferent = true;
                if (window.recordProcessing) {
                    changes.push({p: p, b: beforeNode[p], a: v})
                }
            }
            if (v == null) {
                continue;
            }
            nodeToStore[p] = v;
        }
        if (!storeAsDifferent) {
            noChangeNodes++;
            continue;
        }
        if (window.recordProcessing) {
            allChanges.push({id: node.id, changes: changes, newItem: beforeNode === null, metaDifferent: nodeSame});
        }
        let storedNode = {
            id: node.id,
            node: nodeToStore,
            dirtyNode: {...dirty},
            storedDateTime: jsonNow
        };
        storedNode.dirtyNode.storedDb = true
        storeNodes.push(storedNode);

        // If dirty also store an extra node with only the dirty properties
        // This will be used to patch on restore and is being done to avoid losing changes when other tabs load
        // the same node.
        if (dirty?.dirty && dirty.dirtyProperties && isNodeSaved(node)) {
            let patchNode = {
                id: node.id + '-dirty',
                patch: true,
                storedDateTime: jsonNow,
                node: {
                    id: node.id,
                    rootId: node.rootId,
                    scopes: node.scopes
                },
                dirtyNode: {
                    id: node.id,
                    dirtyIndexed: 1,
                    dirty: true,
                    dirtyProperties: dirty.dirtyProperties
                }
            }
            for (let p of dirty.dirtyProperties) {
                patchNode.node[p] = node[p];
            }
            storeNodes.push(patchNode);
        }
        if (beforeDirty && dirty && !dirty.dirty && beforeDirty.dirty) {
            deletePatchNodes.push(node.id + '-dirty');
        }
    }
    if (storeNodes.length === 0) {
        return;
    }
    if (storeNodes.length > 0) {
        if (window.recordProcessing) {
            reportDebug(`Indexeddb: Storing [${storeNodes.length}] nodes with non change nodes [${noChangeNodes}]`, allChanges)
        }
        state.pendingStoreDbNodeCount++;
        if (!action.asyncDispatch) {
            throw new Error(`asyncDispatch is missing on action: ${JSON.stringify(action)}`);
        }
        const start = getTime()
        const pendingStoreCount = state.pendingStoreDbNodeCount
        const promise = storeDbNodes(storeNodes)
        action.promises.push(promise)
        promise
            .then(() => {
                action.asyncDispatch({type: NODES_SAVE_SUCCESS_LOCAL_DB, nodes: storeNodes});
            })
            .catch((error) => {
                const active = getIsDocumentHidden()
                const duration = getTime() - start
                if (storeNodes.length === 1) {
                    const node = storeNodes[0]?.node
                    reportError(`Error storing [${storeNodes.length}] nodes in indexeddb for action [${action.type}] for node [${node?.type}] [${node?.id}]. Tab hidden: ${active}. Duration: ${duration}ms. Pending Operations: ${pendingStoreCount}. ${error}`, error);
                } else {
                    reportError(`Error storing [${storeNodes.length}] nodes in indexeddb for action [${action.type}]. Tab hidden: ${active}. Duration: ${duration}ms. Pending Operations: ${pendingStoreCount}. ${error}`, error);
                }
                action.asyncDispatch({type: NODES_SAVE_ERROR_LOCAL_DB, nodes: storeNodes, error: error});
            });
    }
    if (deletePatchNodes.length > 0) {
        // Mark the patches as being applied so that we do not try applying them again
        // But keep them around for data recovery
        // I don't expect this will create too much data
        const promise = patchDbNodesIfPresent(deletePatchNodes, patchNode => ({
            ...patchNode,
            patchApplied: true,
            dirtyNode: {
                ...patchNode.dirtyNode,
                dirty: false,
                dirtyIndexed: 0
            },
            storedDateTime: jsonNow
        }))
            .then(() => {
                console.info('Marked Applied ' + deletePatchNodes.length + ' patch nodes.');
            })
            .catch((error) => {
                reportError('Error marking applied patch nodes [' + deletePatchNodes.length + '] nodes in indexeddb.' + error, error);
            });
        action.promises.push(promise)
    }
};
let propertiesToCompareCache = {};
const buildDirtyNodes = (state, nodeUpdates) => {
    const dirtyNodes = {};
     const updatedDirtyNodeIds = {...state.dirtyNodeIds};
    for (let updateNode of Object.values(nodeUpdates)) {
        if (updateNode == null || updateNode.id == null) {
            reportDeveloperWarning('buildDirtyNodes has a null node.', updateNode);
        }
        const currentDirtyNode = state.dirtyNodes[updateNode?.id];
        const serverNode = get(currentDirtyNode, 'storeServer') || null;
        checkAgainstSchema(state, updateNode);
        checkAgainstSchema(state, serverNode);
        let nodeSchema = state.schema[updateNode?.type];
        let dirtyProperties;
        let deletedNeverStored = updateNode.deleted && !serverNode;
        let ignoreDeleted = deletedNeverStored && nodeSchema.storeServerUnsavedDeleted === false;
        if (ignoreDeleted || nodeSchema.storeServer === false || (updateNode.preview && nodeSchema.storeServerPreview !== true) || updateNode.draft || updateNode.canComplete === false || !updateNode.loaded || updateNode.nodeExists === false || updateNode.storeServer === false) {
            dirtyProperties = [];
        } else if (serverNode == null) {
            dirtyProperties = ['ALL'];
        } else {
            let propertiesToCompare = propertiesToCompareCache[updateNode.type];
            if (!propertiesToCompare) {
                let nodeSchema = state.schema[updateNode.type];
                propertiesToCompare = Object.keys(nodeSchema.properties)
                    .filter(p => nodeSchema.properties[p]
                        && nodeSchema.properties[p].storeServer !== false
                        && nodeSchema.properties[p].dirtyCompare !== false);
                propertiesToCompareCache[updateNode.type] = propertiesToCompare;
            }
            dirtyProperties = [];
            for (let property of propertiesToCompare) {
                if (updateNode[property + 'StoreDb'] === false) {
                    // Used for Query to not store initialValue
                    continue;
                }
                let left = serverNode[property];
                let right = updateNode[property];
                let same = areSame(left, right);
                if (!same) {
                    // Treat null and false as the same
                    let propertySchema = nodeSchema.properties[property];
                    if (propertySchema.nullFalse) {
                        same = (left === true) === (right === true);
                    }
                }

                if (!same) {
                    dirtyProperties.push(property);
                }
            }
            //let dirty = dirtyProperties.map(p => 'Node [' + updateNode.id + '] property [' + updateNode.type + '.' + p + ']' + ' is dirty as server value ' + typeof (serverNode[p]) + ' [' + serverNode[p] + '] != current value [' + typeof (updateNode[p]) + '] ' + updateNode[p]).join(', ');
        }
        let nextSaveTicks = null;
        let isDirty = dirtyProperties.length > 0;
        if (isDirty) {
            let saveInterval = domainRules[updateNode.type].calculateSaveInterval(state, updateNode);
            nextSaveTicks = getTime() + saveInterval[0] * 1000;
        }
        dirtyNodes[updateNode.id] = {
            ...currentDirtyNode,
            id: updateNode.id,
            rootId: updateNode.rootId,
            storeServer: serverNode,
            dirtyProperties: dirtyProperties,
            dirty: isDirty,
            dirtyIndexed: isDirty ? 1 : 0,
            nextSaveTicks: nextSaveTicks,
            lastUpdatedDateTime: getJsonDate(),
        };
        if (isDirty) {
            updatedDirtyNodeIds[updateNode.id] = true;
        } else {
            delete updatedDirtyNodeIds[updateNode.id];
        }
    }
    state.dirtyNodeIds = updatedDirtyNodeIds;
    let dirtyRootIds = {};
    for (let dirtyNodeId of Object.keys(state.dirtyNodeIds)) {
        let node = getNodeOrNull(state, dirtyNodeId);
        if (node && node.rootId) {
            dirtyRootIds[node.rootId] = true;
        } else {
            console.info('Could not find node ' + dirtyNodeId + ' in state.');
        }
    }
    state.dirtyRootIds = dirtyRootIds;
    return dirtyNodes;
};
const areSame = (a, b) => {
    if (a === b) return true;
    if (isEqual(a, b)) return true;
    if (a instanceof Date) {
        a = a.toISOString();
    }
    if (b instanceof Date) {
        b = b.toISOString();
    }
    a = a === undefined || a === null || (Array.isArray(a) && a.length === 0) ? null : a;
    b = b === undefined || b === null || (Array.isArray(b) && b.length === 0) ? null : b;
    // Don't want to save to API just because of undefined to null
    if ((a === null) && (b === null)) return true;
    if (a === null || b === null) return false;
    let aJson = JSON.stringify(a);
    let bJson = JSON.stringify(b);
    if (aJson === bJson) {
        return true;
    }
    let aObj = typeof a === 'object';
    let bObj = typeof b === 'object';
    if (aObj && bObj) {
        return isEqual(a, b);
    }
    // Don't want to save to API just because of string to int parsing
    if (!aObj && !bObj && a.toString() === b.toString()) {
        return true
    }
    if (typeof a !== typeof b) {
        return false;
    }
    if (Array.isArray(a)) {
        return arraysEqual(a, b);
    }
    // If date/time
    if (a && a.endsWith && a.endsWith("Z") && b && b.endsWith && b.endsWith("Z")) {
        a = a.replace(/0+Z/, '');
        b = b.replace(/0+Z/, '');
        if (a === b) {
            return true;
        }
    }
    return false;
};
const arraysEqual = (a, b) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
};
const checkAgainstSchema = (state, node) => {
    if (node) {
        let nodeSchema = state.schema[node.type];
        let unknownProperties = Object.keys(node)
            .filter(p => !nodeSchema.properties[p])
            // Ignore these for now as I removed some fields from many items
            .filter(p => !RESOURCE_SYSTEM_PROPERTIES[p] && p !== 'ready');
        if (unknownProperties.length > 0) {
            reportDeveloperWarning('Node ' + node.type + ' ' + node.name + ' has unknown properties: ' + unknownProperties.join(', '));
        }
        if (!node.rootId) {
            reportDeveloperWarning('Node ' + node.type + ' ' + node.name + ' has no rootId', node);
        }
    }
};

window.alwaysComputeDirty = false;
window.maxNodeProcessCount = 5;
window.recordProcessing = false;
let KEEP_LOCAL_PROPERTIES = null;

function getKeepLocalProperties(state, nodeType) {
    if (KEEP_LOCAL_PROPERTIES == null) {
        KEEP_LOCAL_PROPERTIES = {};
        for (const schema of Object.values(state.schema)) {
            KEEP_LOCAL_PROPERTIES[schema.type] = Object
                .entries(schema.properties)
                .filter(([, value]) => value.keepLocal)
                .map(([key]) => key);
        }
    }
    return KEEP_LOCAL_PROPERTIES[nodeType];
}

function processNodesOffline(action, method, state, updateNodes, dirtyNodes, updateDb, keepRedux = false, computeDirty) {
    // When we load for offline we need to make sure we do not clobber any unsaved changes
    // For this reason we will split the nodes into those in redux already, and those not. Any in redux go
    // through the existing processing, and not bypass and go to indexeddb

    let revisedUpdateNodes = {};
    let revisedDirtyNodes = {};
    let bypassNodes = {};
    let bypassDirtyNodes = {};
    let storeNodes = [];
    for (let node of Object.values(updateNodes)) {
        let existing = getNodeOrNull(state, node.id);
        if (existing) {
            revisedUpdateNodes[node.id] = node;
            revisedDirtyNodes[node.id] = dirtyNodes[node.id];
        } else {
            domainRuleNOP.onOffline(state, node);
            bypassNodes[node.id] = node;
            bypassDirtyNodes[node.id] = dirtyNodes[node.id];
            storeNodes.push({id: node.id, node: node, dirtyNode: dirtyNodes[node.id], storedDateTime: getJsonDate()});
        }
    }
    storeDbNodes(storeNodes)
        .catch((error) => {
            reportError('Error storing [' + storeNodes.length + '] nodes in indexeddb.' + error, error);
        });
    return processNodes(action, method, state, revisedUpdateNodes, revisedDirtyNodes, updateDb, keepRedux, computeDirty);
}
function processNodes(action, method, state, updateNodes, dirtyNodes, updateDb, keepRedux = false, computeDirty) {
    let start = getTime();
    let totalCount = 0;
    if (keepRedux) {
        // If local is dirty lets discard server update so that we don't
        // lose local changes
        // TODO should we update the server version? Currently no reason as  it is updated when saved. If we ever support
        // undo this could be desirable.
        for (let updateNode of Object.values(updateNodes)) {
            const existingMeta = state.dirtyNodes[updateNode.id];
            const existingNode = state.nodes[updateNode.id];
            if (existingMeta && existingNode) {
                let nodeHasUnsavedChanges = existingMeta.dirty;
                // This is failing cypress as it saves a new question name, then finishes loading all procedures and we see the old name
                // I don't want records to stay stale forever though, so this will load after 5 minutes regardless
                let nodeIsMoreRecent =
                    updateNode.lastUpdatedDateTime
                    && existingNode.lastUpdatedDateTime
                    && isDateGreaterThan(existingNode.lastUpdatedDateTime, updateNode.lastUpdatedDateTime)
                    && isDateGreaterThanOrEqual(existingNode.lastUpdatedDateTime, moment().subtract(5, 'minutes').toDate());
                let skipUpdate = nodeHasUnsavedChanges || nodeIsMoreRecent;
                if (skipUpdate) {
                    existingNode.scopes = mergeUnique(existingNode.scopes, updateNode.scopes);
                    delete updateNodes[updateNode.id];
                    delete dirtyNodes[updateNode.id];
                } else {
                    updateNode.scopes = mergeUnique(existingNode.scopes, updateNode.scopes);
                }
            }
        }
        // Tab index is stored against project/execution and not on server. We want to
        // keep the local property value when reloaded from server
        for (let updateNode of Object.values(updateNodes)) {
            const currentNode = state.nodes[updateNode.id];
            if (currentNode === undefined) {
                continue;
            }
            const keepLocalProperties = getKeepLocalProperties(state, updateNode.type);
            for (const property of keepLocalProperties) {
                updateNode[property] = currentNode[property];
            }
        }
    }
    let initialStateUpdate = {};
    for (let node of Object.values(updateNodes)) {
        if (!node) {
            continue;
        }
        if (!node.id) {
            throw new Error(`Node has no id: ${JSON.stringify(node)}`);
        }
        if (!node.type) {
            throw new Error(`Node has no type: ${JSON.stringify(node)}`);
        }
        const existingNode = state.nodes[node.id];
        if (existingNode && existingNode.rootId && node.rootId && existingNode.rootId !== node.rootId) {
            // Node id's being shared between documents is not designed for, so lets not accept these updates
            throw new Error(`${node.type} has the same id as another node ${existingNode.type} for a different root. Node: ${node.id} Existing Root: ${existingNode.rootId} Nodes Root: ${node.rootId}. Loading the node has been aborted.`)
        }
        const storeRedux = get(state, ['schema', node.type, 'storeRedux']) !== false;
        initialStateUpdate[node.id] = storeRedux ? node : {
            id: node.id,
            type: node.type,
            rootId: node.rootId,
            redux: false
        };
    }
    let updatedState = {
        ...state,
        nodes: {
            ...state.nodes,
            ...initialStateUpdate
        },
        dirtyNodes: {
            ...state.dirtyNodes,
            ...dirtyNodes
        },
        versionNumber: state.versionNumber + 1,
        reduxDependentNodeMap: {
            ...state.reduxDependentNodeMap
        }
    };
    let touchedNodes = {};
    let touchedRootIds = {};
    let touchedNodeTypes = {};
    let nodesByPriority = [];
    // Need to process ExecutionQuestion first to compute finalValue
    for (let i = 1; i < 15; i++) {
        nodesByPriority[i] = [];
    }
    for (let node of Object.values(updateNodes)) {
        let priority = state.schema[node.type]?.processOrder || 9;
        nodesByPriority[priority].push(node.id);
    }
    // Sort so we process in a consistent order
    for (let i = 1; i < 15; i++) {
        nodesByPriority[i].sort();
    }
    let nodesToProcess = nodesByPriority.flat();
    let nodesToProcessLookup = {...updateNodes};
    let initialCount = nodesToProcess.length;
    let nodeProcessCount = {};
    updatedState.lastEvent = {
        nodeProcessCount: nodeProcessCount
    };
    updatedState.rootNodesCreatedThisAction = 0;
    let changedNodes = new Set();
    let nodesAfterUpdate = {};
    let consolesWrites = window.recordProcessing ? [] : null;
    let reduxDependentNodeMapUpdates = {};
    consolesWrites && consolesWrites.push(`${action.type}: Processing ${Object.keys(updateNodes).length} nodes.`);
    while (nodesToProcess.length > 0) {
        let processNodeId = nodesToProcess.shift();
        delete nodesToProcessLookup[processNodeId];
        let processNode = getNodeOrNull(updatedState, processNodeId);
        if (!processNode) {
            // This can happen when ExecutionRoot is loaded from storage using scope and its children are
            // not loaded
            continue;
        }
        if (processNode.redux === false) {
            processNode = nodesAfterUpdate[processNode.id] || updateNodes[processNode.id];
        }
        let consoleWritesEnqueued = [];
        const enqueueNode = (node) => {
            if (!nodesToProcessLookup[node.id] && node.id !== processNode.id) {
                let nodeFromState = getNodeOrNull(updatedState, node.id);
                if (nodeFromState == null) {
                    // This will occur when a node is loaded from storage at summary level but it has previously
                    // been loaded

                } else {
                    nodesToProcess.push(node.id);
                    nodesToProcessLookup[node.id] = true;
                    if (consolesWrites) {
                        consoleWritesEnqueued.push(`${processNode.type}: ${processNode.id} => ${node.type} ${node.id}`);
                    }
                }
            }
        };
        const enqueueNodeId = (nodeId) => {
            if (!nodesToProcessLookup[nodeId] && nodeId !== processNode.id) {
                let nodeFromState = getNodeOrNull(updatedState, nodeId);
                if (nodeFromState == null) {
                    // This will occur when a node is loaded from storage at summary level but it has previously
                    // been loaded
                } else {
                    nodesToProcess.push(nodeId);
                    nodesToProcessLookup[nodeId] = true;
                    if (consolesWrites) {
                        consoleWritesEnqueued.push(`${processNode.type}: ${processNode.id} => ${nodeFromState.type} ${nodeFromState.id}`);
                    }
                }
            }
        };

        // Lets abort any loops after processing a node 4 times
        let count = nodeProcessCount[processNode.id] || 0;
        if (!processNode.type) {
            throw new Error(`Node [${processNode.id}] has no type.`);
        }
        let nodeSchema = getNodeSchemaOrError(state, processNode.type);
        let maxCount = nodeSchema.maxRunPerStateChange || window.maxNodeProcessCount;
        if (count > maxCount) {
            continue;
        }
        nodeProcessCount[processNode.id] = count + 1;
        totalCount++;

        // Execute the domain rules on this node
        let result = executeDomainRules(method, updatedState, processNode, state, count, action);
        let resultsAll = Object.values(result.nodes);
        let results = resultsAll.filter(a => a);
        if (results.length !== resultsAll.length) {
            let nullKeys = Object.entries(resultsAll).filter(([, v]) => v == null).map(([k]) => k).join();
            reportDeveloperWarning(`Null node added, is that intended? Id(s): ${nullKeys}`)
        }


        // Update redux dependencies
        // A node may register to be notified when other nodes change
        let updatedDependencies = results.find(a => a.type === NODE_IDS.ReduxDependencies);
        if (updatedDependencies) {
            for (let dependency of updatedDependencies.updates) {
                let fromId = typeof dependency.from === 'string' ? dependency.from : dependency.from.id;
                let toId = typeof dependency.to === 'string' ? dependency.to : dependency.to.id;
                let currentFrom = reduxDependentNodeMapUpdates[fromId] || updatedState.reduxDependentNodeMap[fromId];
                let currentProperties = get(currentFrom, [toId]) || new Set();
                let newProperties = dependency.properties || ['*'];
                if (!currentProperties.has('*')) {
                    let addProperties = newProperties.filter(a => !currentProperties.has(a));
                    if (addProperties.length > 0) {
                        if (!reduxDependentNodeMapUpdates[fromId]) {
                            reduxDependentNodeMapUpdates[fromId] = {...currentFrom};
                        }
                        let newTo = new Set(currentProperties)
                        for (let addProperty of addProperties) {
                            newTo.add(addProperty)
                        }
                        if (newTo.has('*')) {
                            newTo = new Set(['*']);
                        }
                        reduxDependentNodeMapUpdates[fromId][toId] = newTo;
                    }
                }
            }
        }

        // Merge the changes into the state
        let resultNodes = results.filter(a => a.type !== NODE_IDS.ReduxDependencies);
        for (let updatedNode of resultNodes) {
            let updatedNodeBefore = getNodeOrNull(updatedState, updatedNode.id);
            let beforeOp = getNodeOrNull(state, updatedNode.id);
            let updatedNodeSchema = getNodeSchemaOrError(state, updatedNode.type);

            const updatedNodeToStore = updatedNodeSchema.storeRedux ?
                updatedNode :
                beforeOp || {id: updatedNode.id, type: updatedNode.type, rootId: updatedNode.rootId, redux: false};
            if (!updatedNodeToStore.id) {
                throw new Error(`Node has no id: ${JSON.stringify(updatedNodeToStore)}`);
            }
            updatedState.nodes[updatedNode.id] = updatedNodeToStore;
            nodesAfterUpdate[updatedNode.id] = updatedNode;
            touchedNodes[updatedNode.id] = updatedNode;
            if (!touchedRootIds[updatedNode.rootId]) {
                touchedRootIds[updatedNode.rootId] = 0;
            }
            touchedRootIds[updatedNode.rootId]++;
            if (!touchedNodeTypes[updatedNode.type]) {
                touchedNodeTypes[updatedNode.type] = 0;
            }
            touchedNodeTypes[updatedNode.type]++;
            changedNodes.add(updatedNode.id);

            // Compute Changes
            let updatedProcessNode = updatedNode;
            let processNodeSchema = state.schema[updatedProcessNode.type];
            let changedProperties = new Set();
            let propertiesDidChange = false;
            let computeDiff = (before, after) => {
                if (before !== after) {
                    let beforeNodeUse = before || {};
                    const checkProperties = new Set();
                    for (let name of Object.keys(after)) {
                        checkProperties.add(name)
                    }
                    for (let name of Object.keys(beforeNodeUse)) {
                        checkProperties.add(name)
                    }
                    for (let propertyName of checkProperties) {
                        let beforeProperty = beforeNodeUse[propertyName];
                        let afterProperty = after[propertyName];
                        // Doing shallow equal as earlier code should restore unchanged nodes
                        if (beforeProperty !== afterProperty) {
                            changedProperties.add(propertyName);
                            propertiesDidChange = true;
                        }
                    }
                }
            };
            computeDiff(updatedNodeBefore, updatedProcessNode);
            // On first process use the node from before previous state as well
            // Edge case: If the patch is reverted by a rule leaving no change then we need to re-compute nodes that saw
            // the node before it was reverted.
            if ((nodeProcessCount[updatedProcessNode.id] || 0) <= 1) {
                computeDiff(beforeOp, updatedProcessNode);
            }

            if (consolesWrites) {
                if (propertiesDidChange) {
                    consolesWrites.push(`${updatedProcessNode.type}: ${updatedProcessNode.id}: Diff: ${Object.keys(changedProperties).join(',')}`);
                } else {
                    consolesWrites.push(`${updatedProcessNode.type}: ${updatedProcessNode.id}: No Change`);
                }
            }

            if (propertiesDidChange && updatedNode !== processNode) {
                // Put other returned nodes on the process list
                let source = processNode.type + ' ' + processNode.id + '' + processNode.title || processNode.name;
                enqueueNode(updatedNode, ' returned by business rule for [' + source + ']');
            }

            // Put other dependent nodes on the process list
            if (updatedProcessNode) {
                for (let [path, properties] of Object.entries(processNodeSchema.dependentNodePaths)) {
                    let referencedNodeId = updatedProcessNode[path];
                    let propertyMatch;
                    for (let property of properties) {
                        if (property === '*') {
                            propertyMatch = true;
                            break;
                        }
                        if (changedProperties.has(property)) {
                            propertyMatch = true;
                            break;
                        }
                    }
                    if (propertyMatch && referencedNodeId) {
                        for (let id of makeArray(referencedNodeId)) {
                            enqueueNodeId(id);
                        }
                    }
                }

                // Redux dependencies
                {
                    let watching = reduxDependentNodeMapUpdates[updatedProcessNode.id] || updatedState.reduxDependentNodeMap[updatedProcessNode.id] || null;
                    if (watching != null) {
                        for (let subscribedNodeId of Object.keys(watching)) {
                            let properties = watching[subscribedNodeId];
                            let propertyMatch;
                            for (let property of properties) {
                                if (property === '*') {
                                    propertyMatch = true;
                                    break;
                                }
                                if (changedProperties.has(property)) {
                                    propertyMatch = true;
                                    break;
                                }
                            }
                            if (propertyMatch && subscribedNodeId) {
                                enqueueNodeId(subscribedNodeId);
                            }
                        }
                    }
                }
            }
        }

        for (let item of consoleWritesEnqueued) {
            consolesWrites.push(item);
        }
    }
    for (let id of Object.keys(reduxDependentNodeMapUpdates)) {
        updatedState.reduxDependentNodeMap[id] = reduxDependentNodeMapUpdates[id];
    }
    let finalDirtyNodes = {};
    if (computeDirty || window.alwaysComputeDirty) {
        finalDirtyNodes = buildDirtyNodes(updatedState, touchedNodes);
        for (let id of Object.keys(finalDirtyNodes)) {
            updatedState.dirtyNodes[id] = finalDirtyNodes[id];
        }
        computeDirtyCounts(updatedState);
    }
    let finished = getTime();
    let touchedNodeCount = Object.entries(touchedNodes).length;
    let duration = finished - start;
    let data = {
        touchedRootIds: touchedRootIds,
        touchedNodeTypes: touchedNodeTypes,
        duration: duration,
        actionType: action.type,
        initialIds: nodesToProcess
    };
    let touchedRootCounts = Object.keys(touchedRootIds).length;
    let msg = `Processing action ${action.type} completed in ${duration}ms for initial ${initialCount} node cascading to ${touchedNodeCount} nodes for ${touchedRootCounts} roots processed ${totalCount} times.`;
    reportDebug(msg, data);
    let acceptableDuration = action.type === PUT_NODE ? 500 : 5000;
    if (duration > acceptableDuration) {
        reportDeveloperWarning(msg, data)
    }
    if (updateDb) {
        storeNodes(action, updatedState, nodesAfterUpdate, state);
    }
    return updatedState;
}

const computeDirtyCounts = (state) => {

    // Calculate how many are dirty
    const dirtyNodes = getDirtyNodes(state);
    const rootIdsAll = [];
    const rootIdsUser = [];
    Object.keys(dirtyNodes).forEach(id => {
        let node = getNodeOrNull(state, id);
        let rootId = (node && node.rootId) || id;
        rootIdsAll.push(rootId);
        if (node && getNodeSchemaOrError(state, node.type).silentSave !== true) {
            rootIdsUser.push(rootId)
        }
    });
    state.pendingSaveNodeCount = new Set(rootIdsAll).size;
    state.pendingUserSaveNodeCount = new Set(rootIdsUser).size;
    state.pendingUserSaveNodeIds = [...new Set(rootIdsUser)];
}

function processAction(action, state) {
    try {
        switch (action.type) {
            case PUT_NODE: {
                let node = action.payload.node;
                let updateNodes = {
                    [node.id]: node
                };
                return processNodes(action, 'onPut', state, updateNodes, {}, true, false, true);
            }
            case PUT_NODE_PROPERTY: {
                // Storing before/after for debugging. May be useful later
                let patches = action.payload.node ? [action.payload.node] : action.payload.nodes;
                let updatedNodes = {};
                for (let patch of patches) {
                    checkHasValue(patch.id);
                    let beforeNode = getNodeOrNull(state, patch.id);
                    let updatedNode = {
                        ...beforeNode,
                        ...patch
                    };
                    updatedNodes[updatedNode.id] = updatedNode;
                }
                return processNodes(action, 'onPut', state, updatedNodes, {}, true, false, true);
            }
            case PUT_NODES: {
                let updateNodes = {};
                let dirtyNodes = {};
                let nodes = action.payload.nodes;
                nodes.forEach(node => updateNodes[node.id] = node);
                return processNodes(action, 'onPut', state, updateNodes, dirtyNodes, true, false, true);
            }
            case NODES_LOADED_SERVER: {
                let updateNodes = {};
                let dirtyNodes = {};
                let nodes = action.payload.nodes;
                let dirtyNodesBefore = {};
                nodes.forEach(node => {
                    updateNodes[node.id] = node;
                    let existingDirty = getDirty(state, node.id);
                    dirtyNodes[node.id] = {
                        id: node.id,
                        ...existingDirty,
                        storeServer: cloneDeep(node),
                        storedServer: true,
                        dirty: false,
                        dirtyProperties: []
                    };
                    node.loading = false;
                    node.loaded = true;
                    node.loadedFull = !action.payload.summaryLoad;
                    node.loadingError = null;
                    node.nodeExists = true;
                    if (action.payload.resourceSync) {
                        node.scopes = mergeUnique([action.payload.resourceSync.id], [node.rootId]);
                    } else {
                        node.scopes = [node.rootId];
                    }
                    let existingNodeDirty = getDirty(state, node.id);
                    if (existingNodeDirty && existingNodeDirty.dirty) {
                        dirtyNodesBefore[node.id] = existingNodeDirty.dirtyProperties;
                    }
                });
                const doNotAddToRedux = action.payload.offline === true;
                let updatedState;
                validateBug(state, action)
                if (doNotAddToRedux) {
                    updatedState = processNodesOffline(action, 'onPut', state, updateNodes, dirtyNodes, true, true, true);
                } else {
                    updatedState = processNodes(action, 'onPut', state, updateNodes, dirtyNodes, true, true, true);
                }
                validateBug(state, updatedState)

                // Ideally nodes should not be dirty immediately after load, but due to API/Client processing differences
                // they might be
                // Cannot check if local dirty as we move data between nodes we get false positives.
                let checkApiClientDisagreements = state.pendingUserSaveNodeCount === 0;
                if (checkApiClientDisagreements) {
                    nodes.forEach(node => {
                        let latestDirty = getDirty(updatedState, node.id);
                        if (latestDirty && latestDirty.dirty && !dirtyNodesBefore[node.id]) {
                            let nodeAfter = getNodeOrError(updatedState, node.id);
                            let beforeAfter = [];
                            let schema = getNodeSchemaOrError(state, nodeAfter.type);
                            for (let propertyName of latestDirty.dirtyProperties.filter(a => a !== "ALL")) {
                                let propertySchema = schema.properties[propertyName];
                                if (propertySchema.apiClientDisagreementOn === false) {
                                    continue;
                                }
                                beforeAfter.push({
                                    property: propertyName,
                                    server: latestDirty.storeServer[propertyName],
                                    client: nodeAfter[propertyName]
                                })
                            }
                            if (beforeAfter.length > 0) {
                                let errorObj = {
                                    nodeAfter: nodeAfter,
                                    dirty: latestDirty,
                                    diff: beforeAfter
                                };
                                let context = `${nodeAfter.type + ' ' + nodeAfter.id}. `;
                                nodeAfter.processingWarning = `Client Server Disagreement.\n${JSON.stringify(beforeAfter, null, true)}`;
                                reportDeveloperInfo(context + nodeAfter.processingWarning, errorObj, false);
                                updatedState.processingWarningCount++;
                            }
                        }
                    });
                }

                // Record update time for future refreshes
                if (action.payload.resourceSync) {
                    let resourceBefore = updatedState.nodes[action.payload.resourceSync.id];
                    let resourceAdditionalProps = {
                        lastReloadTicks: getTime(),
                        loading: false,
                        loadingError: null,
                        availableOffline: resourceBefore.loaded,
                        loadingErrorCount: 0,
                    };
                    if (!action.payload.deltaLoad && !action.payload.pageLoad) {
                        resourceAdditionalProps.lastFullReloadTicks = resourceAdditionalProps.lastReloadTicks;
                    }
                    if (action.payload.resourceSync.type === NODE_TYPE_OPTIONS.ResourceSync) {
                        let nodeIds = nodes.filter(a => a.type === action.payload.resourceSync.nodeType).map(a => a.id)
                        if (action.payload.deltaLoad || action.payload.pageLoad) {
                            let existingIds = action.payload.resourceSync.nodeIds || [];
                            if (nodeIds.length === 0) {
                                nodeIds = existingIds
                            } else if (existingIds.length > 0) {
                                nodeIds = mergeUnique(existingIds, nodeIds);
                            }
                        }
                        resourceAdditionalProps.loaded = true;
                        resourceAdditionalProps.loadedFull = true;
                        resourceAdditionalProps.nodeIds = nodeIds;
                        resourceAdditionalProps.hasNextPage = action.payload.hasNextPage;
                        resourceAdditionalProps.total = action.payload.total;
                        resourceAdditionalProps.itemCount = action.payload.itemCount;
                        resourceAdditionalProps.pageNumber = action.payload.pageNumber;
                    } else {
                        if (!action.payload.deltaLoad && resourceBefore.nodeExists == null && action.payload.nodes.length === 0) {
                            resourceAdditionalProps.nodeExists = false;
                        }
                        resourceAdditionalProps.loadedFull = resourceBefore.loadedFull || (resourceBefore.loaded && !action.payload.summaryLoad);
                    }
                    resourceAdditionalProps.availableOffline = resourceAdditionalProps.loadedFull || resourceBefore.loadedFull || resourceBefore.availableOffline;
                    let schema = getNodeSchemaOrError(state, action.payload.resourceSync.nodeType || action.payload.resourceSync.type);
                    if (nodes.length > 0 && schema.incrementalLoad) {
                        let lastUpdatedDateTimes = nodes.filter(a => a.id === a.rootId).map(node => node[schema.incrementalLoad.property]);

                        resourceAdditionalProps.lastUpdatedDateTime = getMaxJsonDate(lastUpdatedDateTimes);
                    }
                    let resourceSync = {
                        ...resourceBefore,
                        ...resourceAdditionalProps
                    };
                    if (!isEqual(resourceBefore, resourceSync)) {
                        let updatedNodes = {
                            [resourceSync.id]: resourceSync
                        }
                        updatedState = processNodes(action, 'onPut', updatedState, updatedNodes, {}, true, false, true);
                    }
                    updatedState.serverLoadingNodeIds = {...updatedState.serverLoadingNodeIds}
                    delete updatedState.serverLoadingNodeIds[action.payload.resourceSync.id]
                    updatedState.serverLoadingCount = Math.max(0, updatedState.serverLoadingCount - 1)
                    updatedState.serverLoading = updatedState.serverLoadingCount > 0
                }
                updatedState.internetAvailable = true
                updatedState.internetAvailableTimestamp = getTime()
                return updatedState;
            }
            case NODES_LOADED_STORAGE: {
                let updateNodes = {};
                let dirtyNodes = {};
                // Restore node and its dirty state as saved
                // Will only restore once on app startup
                let dbNodes = action.payload.nodes;
                let newNodes = dbNodes.filter(n => (state.nodes[n.node.id] === undefined || GRAPH_INITIAL_STATE.nodes[n.node.id] === state.nodes[n.node.id]) && !n.patch);
                let dirtyNodeIds = {};
                let dirtyRootIds = {};
                newNodes.forEach(n => updateNodes[n.id] = n.node);
                newNodes.forEach(n => dirtyNodes[n.id] = (n.dirtyNode || {
                    id: n.id,
                    storeServer: null,
                    storedDb: true
                }));
                newNodes.filter(n => n.dirtyNode.dirty).forEach(n => dirtyNodeIds[n.node.id] = true);
                newNodes.filter(n => n.dirtyNode.dirty).forEach(n => dirtyRootIds[n.node.rootId] = true);
                // Apply the patch node in case of lost changes
                for (let patchNode of dbNodes.filter(a => a.patch && !a.patchApplied)) {
                    let currentNode = updateNodes[patchNode.node.id] || state.nodes[patchNode.node.id];
                    if (!currentNode) {
                        reportDeveloperWarning('PatchNode found but not the node. ', patchNode);
                        continue;
                    }
                    let updatedPatchedNode = null;
                    let patchedProperties = [];
                    for (let p of Object.keys(patchNode.node)) {
                        let v = patchNode.node[p];
                        if (currentNode[p] !== v) {
                            if (updatedPatchedNode == null) {
                                updatedPatchedNode = {...currentNode}
                            }
                            updatedPatchedNode[p] = v;
                            patchedProperties.push(p);
                        }
                    }
                    if (updatedPatchedNode) {
                        reportDeveloperInfo(`Indexeddb Patch changed state. Properties: ${patchedProperties.join(', ')}`, patchNode)
                        updateNodes[patchNode.node.id] = updatedPatchedNode;
                        let currentDirty = dirtyNodes[patchNode.node.id] || getDirty(state, patchNode.node.id);
                        if (currentDirty == null) {
                            reportDeveloperWarning('PatchNode found but not the dirty node. What to do?', patchNode);
                        }
                        dirtyNodes[patchNode.node.id] = {...currentDirty, ...patchNode.dirtyNode};
                        dirtyNodeIds[patchNode.node.id] = true;
                        dirtyRootIds[patchNode.node.rootId] = true;
                    }
                }
                // Need to store the new scope property on load back into indexeddb
                let updateDb = getPreviousVersionNumber() === 1;
                let updatedState = processNodes(action, ['onLoad', 'onPut'], state, updateNodes, dirtyNodes, updateDb, true, false);
                updatedState.nodesLoadedStorage = true;
                updatedState.dirtyNodeIds = {
                    ...updatedState.dirtyNodeIds,
                    ...dirtyNodeIds
                };
                updatedState.dirtyRootIds = {
                    ...updatedState.dirtyRootIds,
                    ...dirtyRootIds
                };
                if (action.payload.resourceSync) {
                    if (!updatedState.nodes[action.payload.resourceSync.id]) {
                        updatedState.nodes[action.payload.resourceSync.id] = action.payload.resourceSync;
                    }
                    let resourceSynchCloned = cloneDeep(updatedState.nodes[action.payload.resourceSync.id]);
                    if (action.payload.offline) {
                        resourceSynchCloned.indexedDbOfflineChecked = true;
                    } else {
                        resourceSynchCloned.indexedDbChecked = true;
                    }
                    resourceSynchCloned.loadingDbError = null;
                    resourceSynchCloned.loadingErrorCount = 0;
                    updatedState.nodes[action.payload.resourceSync.id] = resourceSynchCloned;
                }
                return updatedState;
            }
            case DIRTY_STORAGE_LOADED: {
                return {
                    ...state,
                    dirtyNodesLoaded: true
                };
            }
            case INITALISE_GRAPH: {
                return {
                    ...state,
                    nodes: {...state.nodes},
                    dirtyNodes: {...state.dirtyNodes},
                    schema: GRAPH_INITIAL_STATE.schema
                };
            }
            case CLEAR_GRAPH_STARTED: {
                return {
                    ...state,
                    nodesClearing: true
                };
            }
            case CLEAR_GRAPH_SUCCESS: {
                return {
                    ...state,
                    nodesClearing: false,
                    nodesCleared: true
                };
            }
            case SAVE_NODES_START: {
                let savingRootIds = {};
                action.payload.rootIds.forEach(rootId => savingRootIds[rootId] = true);
                action.asyncDispatch({
                    type: PUT_NODE_PROPERTY,
                    payload: {node: {id: NODE_IDS.UserDevice, saveRunning: true}}
                });
                return {
                    ...state,
                    saveRunning: true,
                    saveStartedTicks: getTime(),
                    savingRootIds: savingRootIds,
                }
            }
            case SAVE_NODE_SUCCESS: {
                let dirtyNodes = {};
                const time = getTime();
                action.payload.savedNodes.forEach(savedNode => dirtyNodes[savedNode.id] =
                    {
                        id: savedNode.id,
                        storeServer: savedNode,
                        storedServer: true,
                        dirty: false,
                        dirtyProperties: [],
                        lastSaveTicks: time,
                        lastSaveError: null,
                        saveErrorCount: 0
                    });
                let updateNodes = {};
                action.payload.dirtyNodeIds.forEach(id => updateNodes[id] = getNodeOrError(state, id));

                let rootDirty = dirtyNodes[action.payload.savedNodeId] || getDirty(state, action.payload.savedNodeId);
                dirtyNodes[action.payload.savedNodeId] = {
                    ...rootDirty,
                    lastSaveError: null,
                    saveErrorCount: 0,
                    saveAborted: false
                };

                let updatedState = processNodes(action, 'onSaveSuccess', state, updateNodes, dirtyNodes, true, false, true);

                if (action.payload.responsePatch && !isSummaryId(action.payload.savedNodeId)) {
                    // Apply response patched nodes
                    let afterUpdateNodes = {};
                    let afterDirtyNodes = {};

                    for (let patch of action.payload.response) {
                        let beforeNode = getNodeOrNull(updatedState, patch.id);
                        // If auto-create deletes/restores on summary we do not want to apply patch results
                        if (beforeNode == null) {
                            if (getNodeOrNull(state, patch.rootId) == null) {
                                continue;
                            }
                        }
                        const patchedNode = {
                            ...beforeNode,
                            ...patch
                        };

                        afterUpdateNodes[patch.id] = patchedNode;
                        afterDirtyNodes[patch.id] =
                        {
                            id: patch.id,
                            storeServer: patchedNode,
                            storedServer: true,
                            dirty: false,
                            dirtyProperties: [],
                            lastSaveError: null,
                            saveErrorCount: 0
                        }
                    }

                    updatedState = processNodes(action, 'onPut', updatedState, afterUpdateNodes, afterDirtyNodes, true, true, true);
                }
                updatedState.internetAvailable = true
                updatedState.internetAvailableTimestamp = getTime()
                return updatedState;
            }
            case SAVE_NODE_FAILURE: {
                const copy = {...state.dirtyNodes};
                const time = getTime();
                let nodeIds = [action.payload.savedNodeId, ...(action.payload.dirtyNodeIds || [])];
                nodeIds.filter(id => copy[id]).forEach(id => {
                    const node = state.nodes[id];
                    const dirtyNode = copy[id];
                    const updatedDirtyNode = {
                        ...dirtyNode,
                        lastSaveTicks: time,
                        lastSaveError: action.payload.error,
                        saveErrorCount: (dirtyNode.saveErrorCount || 0) + 1,
                        lastSavedException: action.payload.exception
                    };
                    const saveInterval = domainRules[node.type].calculateSaveInterval(state, node);
                    if (updatedDirtyNode.saveErrorCount >= saveInterval.length) {
                        updatedDirtyNode.saveAborted = true;
                    } else {
                        updatedDirtyNode.nextSaveTicks = (updatedDirtyNode.lastSaveTicks || time) + saveInterval[updatedDirtyNode.saveErrorCount] * 1000;
                    }
                    copy[id] = updatedDirtyNode;
                });
                let saveFailureState = {
                    ...state,
                    dirtyNodes: {
                        ...copy
                    }
                };
                storeNodes(action, saveFailureState, action.payload.dirtyNodeIds || [], state);
                return saveFailureState;
            }
            case CLEAR_SAVE_ERROR: {
                const copy = {...state.dirtyNodes};
                const dirtyNode = copy[action.payload.node.id];
                copy[action.payload.node.id] = {
                    ...dirtyNode,
                    lastSaveTicks: null,
                    lastSaveError: null,
                    saveErrorCount: 0,
                    nextSaveTicks: getTime(),
                    saveAborted: false
                };

                let clearFailureState = {
                    ...state,
                    dirtyNodes: {
                        ...copy
                    }
                };
                storeNodes(action, clearFailureState, [action.payload.node.id], state);
                return clearFailureState
            }
            case SAVE_NODES_FINISH: {
                action.asyncDispatch({
                    type: PUT_NODE_PROPERTY,
                    payload: {node: {id: NODE_IDS.UserDevice, saveRunning: false}}
                });
                return {
                    ...state,
                    saveRunning: false,
                    savingRootIds: {}
                }
            }
            case LOAD_NODES_START: {
                let resourceSync = action.payload.resourceSync;
                let updatedNodes = {
                    [resourceSync.id]: {
                        loaded: false,
                        ...resourceSync,
                        ...state.nodes[resourceSync.id],
                        loading: true,
                        loadingError: null,
                        lastReloadTicks: getTime()
                    }
                };
                const updatedState = processNodes(action, 'onPut', state, updatedNodes, {}, true, false, true);
                updatedState.serverLoading = true
                updatedState.serverLoadingCount = updatedState.serverLoadingCount + 1
                updatedState.serverLoadingStartedTicks = getTime()
                updatedState.serverLoadingNodeIds = {...updatedState.serverLoadingNodeIds}
                updatedState.serverLoadingNodeIds[action.payload.resourceSync.id] = true
                return updatedState;
            }
            case LOAD_NODES_FAILURE: {
                let resourceSync = action.payload.resourceSync;
                let updatedNodes = {
                    [resourceSync.id]: {
                        loaded: false,
                        ...resourceSync,
                        ...state.nodes[resourceSync.id],
                        loading: false,
                        loadingError: action.payload.error,
                        loadingErrorCount: (state.nodes[resourceSync.id].loadingErrorCount || 0) + 1,
                        loadingException: action.payload.exception
                    }
                };
                const updatedState = processNodes(action, 'onPut', state, updatedNodes, {}, true, false, true);
                if (action.payload.error === 'Network Error') {
                    updatedState.internetAvailable = false
                    updatedState.internetAvailableTimestamp = getTime()
                }
                updatedState.serverLoadingNodeIds = {...updatedState.serverLoadingNodeIds}
                delete updatedState.serverLoadingNodeIds[action.payload.resourceSync.id]
                updatedState.serverLoadingCount = Math.max(0, updatedState.serverLoadingCount - 1)
                updatedState.serverLoading = updatedState.serverLoadingCount > 0
                return updatedState
            }
            case NODES_SAVE_SUCCESS_LOCAL_DB: {
                let dirtyNodes = {};
                action.nodes
                    .filter(node => state.dirtyNodes[node.id])
                    .forEach(node => dirtyNodes[node.id] = {...state.dirtyNodes[node.id], storedDb: true});
                let nodes = {}
                action.nodes
                    .filter(node => state.nodes[node.id])
                    .forEach(node => nodes[node.id] = state.nodes[node.id]);
                let storedDbErrorNodeIds = null;
                if (state.storedDbErrorNodeIds != null) {
                    storedDbErrorNodeIds = {...state.storedDbErrorNodeIds};
                    for (let node of action.nodes) {
                        delete storedDbErrorNodeIds[node.id]
                    }
                    if (Object.keys(storedDbErrorNodeIds).length === 0) {
                        storedDbErrorNodeIds = null;
                    }
                }
                const updatedState1 = processNodes(action, 'onStored', state, nodes, dirtyNodes, false, false, false);
                return {
                    ...updatedState1,
                    storedDbError: null,
                    pendingStoreDbNodeCount: state.pendingStoreDbNodeCount > 0 ? state.pendingStoreDbNodeCount - 1 : 0,
                    storedDbErrorNodeIds: storedDbErrorNodeIds
                }
            }
            case NODES_SAVE_ERROR_LOCAL_DB: {
                let storedDbErrorNodeIds = {...state.storedDbErrorNodeIds};
                action.nodes.filter(a => !a.patch).forEach(node => storedDbErrorNodeIds[node.id] = action.error);
                return {
                    ...state,
                    storedDbError: action.error,
                    pendingStoreDbNodeCount: state.pendingStoreDbNodeCount > 0 ? state.pendingStoreDbNodeCount - 1 : 0,
                    storedDbErrorNodeIds: storedDbErrorNodeIds
                };
            }
            case NODES_LOADED_STORAGE_FAILED: {
                let resourceSync = action.payload.resourceSync;
                if (!resourceSync) {
                    // AlwaysLoad
                    return state;
                }
                let existing = state.nodes[resourceSync.id];
                const resourceSyncCloned = {
                    ...resourceSync,
                    ...existing,
                    loading: false,
                    loadingDbError: action.payload.error,
                    loadingDbErrorCount: ((existing && existing.loadingDbErrorCount) || 0) + 1
                }
                if (action.payload.offline) {
                    resourceSyncCloned.indexedDbOfflineChecked = true;
                } else {
                    resourceSyncCloned.indexedDbChecked = true;
                }
                return {
                    ...state,
                    nodes: {
                        ...state.nodes,
                        [resourceSync.id]: resourceSyncCloned
                    }
                };
            }
            case NODE_PROPERTY_BLUR: {
                let previous = state.focusedNode;
                let updateNodes = {};
                let dirtyNodes = {};
                if (action.payload.id) {
                    previous = getNodeOrNull(state, action.payload.id)
                }
                if (previous) {
                    updateNodes[previous.id] = getNodeOrError(state, previous.id);
                }
                let updatedState = {...state, focusedNode: null, previousFocusedNode: previous};
                return processNodes(action, 'onPut', updatedState, updateNodes, dirtyNodes, true, false, true);
            }
            case NODE_PROPERTY_FOCUS: {
                let previous = state.focusedNode;
                let updateNodes = {};
                let dirtyNodes = {};
                updateNodes[action.payload.id] = getNodeOrError(state, action.payload.id);
                let updatedState = {...state, focusedNode: action.payload, previousFocusedNode: previous};
                return processNodes(action, 'onPut', updatedState, updateNodes, dirtyNodes, true, false, true);
            }
            case NODE_RECOMPUTE_CHECK: {
                // Recompute all nodes and expect no difference
                let allNodeIds = Object.keys(state.nodes).map(a => ({id: a}));
                let innerAction = {
                    type: PUT_NODE_PROPERTY,
                    payload: {nodes: allNodeIds},
                    asyncDispatch: action.asyncDispatch,
                    promises: action.promises
                };
                let allState = processAction(innerAction, state);
                let nodeIds = Object.keys(allState.nodes);
                for (let nodeId of nodeIds) {
                    let afterNode = allState.nodes[nodeId];
                    let diff = jsonDiff.diff(state.nodes[nodeId], afterNode) || null;
                    if (diff) {
                        let context = `${afterNode.type + ' ' + afterNode.id}. `;
                        let msg = `Recompute all resulted in state change. Is a redux dependency missing?\n${JSON.stringify(diff, null, 3)}`;
                        console.error(context + msg);
                        afterNode = {...afterNode, processingWarning: msg};
                        allState.nodes[nodeId] = afterNode;
                        allState.processingWarningCount++;
                        reportDeveloperInfo(context, afterNode);
                        if (action.payload && action.payload.throwOnFail) {
                            throw new Error(context + msg);
                        }
                    }
                }
                return allState;
            }
            case RECOMPUTE_ALL: {
                // Recompute all nodes and expect no difference
                let allNodeIds = Object.keys(state.nodes).map(a => ({id: a}));
                let innerAction = {
                    type: PUT_NODE_PROPERTY,
                    payload: {nodes: allNodeIds},
                    asyncDispatch: action.asyncDispatch,
                    promises: action.promises
                };
                return processAction(innerAction, state);
            }
            case RESET_GRAPH: {
                return {
                    ...GRAPH_INITIAL_STATE,
                    nodesLoadedStorage: true
                };
            }
            case REPORT_EVENT:
                const {id, eventName, eventProperties} = action.payload;
                const reportData = getReportData(state, id);
                reportEvent({
                    name: eventName,
                    ...reportData,
                    ...eventProperties,
                });
                return state;
            default:
                return state;
        }
    } catch (error) {
        reportError(error);
        throw error;
    }
}

function validateBug(state, action) {
    // let procedureWithoutChildren = Object.values(state.nodes).filter(a => a.rules && a.rules.length > 0 && !a.children);
    // if (procedureWithoutChildren.length > 0) {
    //     console.error('Procedure without children.', action);
    // }
}

const graphReducer = (state = GRAPH_INITIAL_STATE, action) => {
    //console.info('GraphReducer.' + action.type, action.payload);
    validateBug(state, action);
    action.processingStartDate = getJsonDate();
    recordAction(action);
    let updatedState = processAction(action, state);
    if (updatedState !== state) {
        computeDirtyCounts(updatedState);
        //let patch = jsonpatch.compare(state || {}, updatedState || {});
        //console.trace('GraphReducer: State Patch', patch)
    }
    action.processingFinishDate = getJsonDate();
    validateBug(state, action);
    return updatedState;
};
export default graphReducer;

const recentActions = [];
const recordAction = (action) => {
    recentActions.push(action);
    if (recentActions.length > 20) {
        recentActions.shift()
    }
}
export const getRecentActions = () => recentActions;
